import "core-js/modules/es7.object.values";
import "core-js/modules/web.dom.iterable";
import CONSTANTS from '@/constants';
export function setLocalStorerage(key, data, client_uuid_login, user_uuid_login) {
  localStorage.setItem(key, JSON.stringify({
    user_uuid: user_uuid_login,
    client_uuid: client_uuid_login,
    data: data
  }));
}
export function getLocalStorage(key) {
  var client_uuid_login = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var user_uuid_login = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  if (!key) {
    return null;
  }
  var _JSON$parse = JSON.parse(localStorage.getItem(key) || '{}'),
    user_uuid = _JSON$parse.user_uuid,
    client_uuid = _JSON$parse.client_uuid,
    data = _JSON$parse.data;
  if (!data || user_uuid !== user_uuid_login || client_uuid !== client_uuid_login) {
    return null;
  }
  return data;
}
export function remove(key) {
  return localStorage.removeItem(key);
}
export function removeAllCommon() {
  Object.values(CONSTANTS.KEY_LOCAL_STORAGE).forEach(function (key) {
    localStorage.removeItem(key);
  });
}