import "core-js/modules/es7.object.values";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.array.find";
import _objectSpread from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import CONSTANTS from '@/constants';
import Cookies from 'js-cookie';
window.elLoading = null;
Vue.mixin({
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState({
    user_uuid: function user_uuid(state) {
      return state.user.uuid;
    },
    client_uuid: function client_uuid(state) {
      return state.user.client_uuid;
    },
    mx_language: function mx_language(state) {
      return state.app.language;
    },
    client_code: function client_code(state) {
      return state.user.client_code;
    },
    displayLanguage: function displayLanguage(state) {
      return state.app.listLanguages;
    }
  })), mapGetters({
    clientSelected: 'client/getClient',
    isSubMerchant: 'user/isSubMerchant',
    isHQ: 'user/isHQ',
    userStores: 'user/stores',
    userStoreGroups: 'user/storeGroups',
    userRoles: 'user/roles',
    userPermissions: 'user/permissions'
  })), {}, {
    isIdClient: function isIdClient() {
      if (this.isSuperAdmin) {
        return CONSTANTS.ID_CLIENT_CODE === this.selectedClientCode;
      }
      return CONSTANTS.ID_CLIENT_CODE === this.client_code;
    },
    mx_isStoreAdmin: function mx_isStoreAdmin() {
      return this.userRoles.some(function (r) {
        return r.code === CONSTANTS.PERMISSION_CODE.ADMIN_STORE;
      });
    }
  }),
  methods: {
    mx_displayLanguage: function mx_displayLanguage() {
      try {
        return this.displayLanguage ? this.displayLanguage : JSON.parse(Cookies.get('listLanguages'));
      } catch (e) {
        return [{
          value: 'en'
        }];
      }
    },
    mx_getLanguageDefault: function mx_getLanguageDefault() {
      var languageDefault = this.mx_displayLanguage()[0].value;
      var item = this.mx_displayLanguage().find(function (item) {
        return item.valueDefault;
      });
      if (item) {
        languageDefault = item.value;
      }
      return languageDefault;
    },
    showLoading: function showLoading() {
      var isShow = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      if (isShow) {
        window.elLoading = this.$loading({
          lock: true,
          background: 'hsla(0, 0%, 100%, .9)'
        });
      } else {
        if (window.elLoading) {
          window.elLoading.close();
        }
      }
    },
    mx_setPageName: function mx_setPageName() {
      var name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      if (name) {
        this.$store.dispatch('app/setPage', name);
      }
    },
    mx_getPageName: function mx_getPageName() {
      return this.$store.state.app.pageName;
    },
    mx_setFunctionClientChange: function mx_setFunctionClientChange() {
      var func = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      if (func) {
        this.$store.dispatch('app/setFunctionClientChange', func);
      }
    },
    mx_getFunctionClientChange: function mx_getFunctionClientChange() {
      return this.$store.state.app.func;
    },
    mx_getStranslate: function mx_getStranslate(item) {
      var field = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'name';
      return item.translate && item.translate[this.mx_language] ? item.translate[this.mx_language][field] : item[field];
    },
    mx_getValueWithItemTranslate: function mx_getValueWithItemTranslate(item) {
      var field = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'name';
      var value = {};
      if (item.translate) {
        for (var language in item.translate) {
          if (typeof item.translate[language][field] !== 'undefined' && item.translate[language][field] !== '') {
            value[language] = item.translate[language][field];
          }
        }
      }
      if (Object.keys(value).length === 0 && value.constructor === Object && typeof item[field] !== 'undefined' && item[field] !== '') {
        value.en = item[field];
      }
      return value;
    },
    mx_updateStatus: function mx_updateStatus(data) {
      this.$emit('updateStatus', data);
    },
    mx_showPopup: function mx_showPopup(uuid) {
      this.$emit('showPopup', uuid);
    },
    mx_isAirAsiaAdmin: function mx_isAirAsiaAdmin() {
      return (!this.userStores || this.userStores.length === 0) && (!this.userStoreGroups || this.userStoreGroups.length === 0);
    },
    mx_isMerchantFood: function mx_isMerchantFood() {
      var merchant = this.userRoles.find(function (e) {
        return e.code === CONSTANTS.FOOD_ROLE_CODE.MERCHANT;
      });
      return merchant;
    },
    mx_notifyPopup: function mx_notifyPopup(errors) {
      var invalidFields = Object.values(errors);
      var errorMessages = '';
      invalidFields.forEach(function (invalidField) {
        invalidField.forEach(function (error) {
          errorMessages += error.message + '<br>';
        });
      });
      if (errorMessages) {
        this.$notify.error({
          title: 'Error',
          dangerouslyUseHTMLString: true,
          message: errorMessages
        });
      }
    },
    mx_setOrderTypes: function mx_setOrderTypes(orderType) {
      switch (orderType) {
        case 1:
          return 'DELIVERY';
        case 2:
          return 'TAKEAWAY';
        case 3:
          return 'EATIN';
        default:
          return '';
      }
    },
    mx_chkPermission: function mx_chkPermission(routename, routeParam) {
      var cond = {
        name: routename
      };
      if (routeParam) {
        cond.params = routeParam;
      }
      var chkData = this.$router.resolve(cond);
      if (chkData && chkData.href !== '/') {
        return true;
      }
      return false;
    }
  }
});