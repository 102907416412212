/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout';
import CONSTANTS from '@/constants';
var userManagementRouter = {
  path: '/user-management',
  component: Layout,
  redirect: 'noredirect',
  meta: {
    title: 'user_management',
    icon: 'user',
    permissions: [CONSTANTS.PERMISSION_NAME.USER.MANAGE]
  },
  children: [{
    path: '',
    component: function component() {
      return import('@/views/userManagement/index.vue');
    },
    name: 'UserManagement',
    meta: {
      title: 'user_management',
      activeMenu: '/user-management'
    }
  }, {
    path: 'edit/:uuid',
    component: function component() {
      return import('@/views/userManagement/components/edit.vue');
    },
    name: 'EditUser',
    meta: {
      title: 'edit_user',
      activeMenu: '/user-management'
    },
    hidden: true
  }, {
    path: 'create',
    component: function component() {
      return import('@/views/userManagement/components/create.vue');
    },
    name: 'CreateUser',
    meta: {
      title: 'create_user',
      activeMenu: '/user-management'
    },
    hidden: true
  }]
};
export default userManagementRouter;