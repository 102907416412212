import Layout from '@/layout';
import CONSTANTS from '@/constants';
import { RouteContainerComponent } from '@/utils';
var newPriceRequestRouter = {
  path: '/pricing-change',
  component: Layout,
  redirect: 'noredirect',
  name: 'NewPriceRequest',
  meta: {
    title: 'pricing_change',
    icon: 'component',
    permissions: [CONSTANTS.PERMISSION_NAME.PRICE_CHANGE_REQUEST.MANAGE]
  },
  children: [{
    path: 'list',
    component: RouteContainerComponent,
    meta: {
      title: 'pricing_change_request_list',
      permissions: [CONSTANTS.PERMISSION_NAME.PRICE_CHANGE_REQUEST.REQUEST_NEW_PRICING]
    },
    children: [{
      path: '',
      component: function component() {
        return import('@/views/unitPriceChangeList/list.vue');
      },
      name: 'PricingChangeRequestList',
      meta: {
        title: 'pricing_change_request_list',
        activeMenu: '/pricing-change/list'
      }
    }]
  }, {
    path: 'approval',
    component: RouteContainerComponent,
    meta: {
      title: 'pricing_change_approval',
      permissions: [CONSTANTS.PERMISSION_NAME.PRICE_CHANGE_REQUEST.APPROVE]
    },
    children: [{
      path: '',
      component: function component() {
        return import('@/views/unitPriceChangeList/listApprovedReq.vue');
      },
      name: 'PricingChangeApproval',
      meta: {
        title: 'pricing_change_approval',
        activeMenu: '/pricing-change/approval'
      }
    }]
  }]
};
export default newPriceRequestRouter;