import _ from 'lodash';
import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
import defaultState from "./state";
var state = function state() {
  return _.cloneDeep(defaultState);
};
var module = {
  state: state,
  mutations: mutations,
  actions: actions,
  namespaced: true,
  getters: getters
};
export { defaultState, module as default };