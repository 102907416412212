import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "/app/node_modules/core-js/modules/es6.array.iterator.js";
import "/app/node_modules/core-js/modules/es6.promise.js";
import "/app/node_modules/core-js/modules/es6.object.assign.js";
import "/app/node_modules/core-js/modules/es7.promise.finally.js";
import Vue from 'vue';
import Cookies from 'js-cookie';
import 'normalize.css/normalize.css'; // A modern alternative to CSS resets

import Element from 'element-ui';
import "./styles/element-variables.scss";
import '@/styles/index.scss'; // global css

import App from "./App";
import store from "./store";
import router from "./router";
import _i18n from "./lang"; // Internationalization
import vueNumeralFilterInstaller from 'vue-numeral-filter';
import PortalVue from 'portal-vue';
import "./icons"; // icon
import "./permission"; // permission control
import "./utils/errorLog"; // error log

import * as filters from "./filters"; // global filters
import VueVirtualScroller from 'vue-virtual-scroller';
import VueLazyload from 'vue-lazyload';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import "./mixins";
import "./directive";
Vue.use(PortalVue);
Vue.use(vueNumeralFilterInstaller);
Vue.use(Element, {
  size: Cookies.get('size') || 'small',
  // set element-ui default size
  i18n: function i18n(key, value) {
    return _i18n.t(key, value);
  }
});
Vue.use(VueVirtualScroller);
Vue.use(VueLazyload, {
  lazyComponent: true,
  observer: true,
  observerOptions: {
    rootMargin: '0px',
    threshold: 0.1
  }
});

// register global utility filters.
Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
Vue.config.productionTip = false;
Vue.prototype.$app = new Vue({
  el: '#app',
  router: router,
  store: store,
  i18n: _i18n,
  render: function render(h) {
    return h(App);
  }
});
window.Vue = Vue;
Vue.mixin({
  methods: {
    handleRemoveImage: function handleRemoveImage() {
      delete this.postForm.image;
    },
    handelBeforeUploadImage: function handelBeforeUploadImage(file) {
      var allowExtension = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif', 'image/bmp'];
      if (allowExtension.indexOf(file.raw.type) <= -1) {
        this.$notify.error(this.$t('message.invalidExtension'));
      }
    }
  }
});