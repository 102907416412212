import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import _createForOfIteratorHelper from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.array.find";
import "core-js/modules/es6.regexp.constructor";
import "core-js/modules/es6.regexp.match";
import "core-js/modules/es6.set";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.array.from";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.regexp.to-string";
import _typeof from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/typeof.js";
/**
 * Created by jiachenpan on 16/11/18.
 */
var _ = require('lodash');
import slug from 'limax';
import moment from 'moment-timezone';
import { zenkakuTohankakuDict } from "./i18n";
import { toKatakana, isKanji } from 'wanakana';
import CONSTANTS from '@/constants';
export function parseTime(time, cFormat) {
  if (!time || arguments.length === 0) {
    return null;
  }
  var format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  var date;
  if (_typeof(time) === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  var time_str = format.replace(/{(y|m|d|h|i|s|a|t)+}/g, function (result, key) {
    var value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '月', '火', '水', '木', '金', '土'][value];
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }
    return value || 0;
  });
  return time_str;
}
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  var d = new Date(time);
  var now = Date.now();
  var diff = (now - d) / 1000;
  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
  }
}
export function formatMoney(amount) {
  var decimalCount = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var decimal = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '.';
  var thousands = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : ',';
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    var negativeSign = amount < 0 ? '-' : '';
    var i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    var j = i.length > 3 ? i.length % 3 : 0;
    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '');
  } catch (e) {
    console.log(e);
  }
}
// 格式化时间
export function getQueryObject(url) {
  url = url == null ? window.location.href : url;
  var search = url.substring(url.lastIndexOf('?') + 1);
  var obj = {};
  var reg = /([^?&=]+)=([^?&=]*)/g;
  search.replace(reg, function (rs, $1, $2) {
    var name = decodeURIComponent($1);
    var val = decodeURIComponent($2);
    val = String(val);
    obj[name] = val;
    return rs;
  });
  return obj;
}

/**
 * @param {Sting} input value
 * @returns {number} output value
 */
export function byteLength(str) {
  // returns the byte length of an utf8 string
  var s = str.length;
  for (var i = str.length - 1; i >= 0; i--) {
    var code = str.charCodeAt(i);
    if (code > 0x7f && code <= 0x7ff) s++;else if (code > 0x7ff && code <= 0xffff) s += 2;
    if (code >= 0xdc00 && code <= 0xdfff) i--;
  }
  return s;
}
export function cleanArray(actual) {
  var newArray = [];
  for (var i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }
  return newArray;
}
export function param(json) {
  if (!json) return '';
  return cleanArray(Object.keys(json).map(function (key) {
    if (json[key] === undefined) return '';
    return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
  })).join('&');
}
export function param2Obj(url) {
  var search = url.split('?')[1];
  if (!search) {
    return {};
  }
  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, ' ') + '"}');
}
export function html2Text(val) {
  var div = document.createElement('div');
  div.innerHTML = val;
  return div.textContent || div.innerText;
}
export function objectMerge(target, source) {
  /* Merges two  objects,
     giving the last one precedence */

  if (_typeof(target) !== 'object') {
    target = {};
  }
  if (Array.isArray(source)) {
    return source.slice();
  }
  Object.keys(source).forEach(function (property) {
    var sourceProperty = source[property];
    if (_typeof(sourceProperty) === 'object') {
      target[property] = objectMerge(target[property], sourceProperty);
    } else {
      target[property] = sourceProperty;
    }
  });
  return target;
}
export function toggleClass(element, className) {
  if (!element || !className) {
    return;
  }
  var classString = element.className;
  var nameIndex = classString.indexOf(className);
  if (nameIndex === -1) {
    classString += '' + className;
  } else {
    classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length);
  }
  element.className = classString;
}
export var pickerOptions = [{
  text: '今天',
  onClick: function onClick(picker) {
    var end = new Date();
    var start = new Date(new Date().toDateString());
    end.setTime(start.getTime());
    picker.$emit('pick', [start, end]);
  }
}, {
  text: '最近一周',
  onClick: function onClick(picker) {
    var end = new Date(new Date().toDateString());
    var start = new Date();
    start.setTime(end.getTime() - 3600 * 1000 * 24 * 7);
    picker.$emit('pick', [start, end]);
  }
}, {
  text: '最近一个月',
  onClick: function onClick(picker) {
    var end = new Date(new Date().toDateString());
    var start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
    picker.$emit('pick', [start, end]);
  }
}, {
  text: '最近三个月',
  onClick: function onClick(picker) {
    var end = new Date(new Date().toDateString());
    var start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
    picker.$emit('pick', [start, end]);
  }
}];
export function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90;
  } else {
    return new Date(new Date().toDateString());
  }
}
export function debounce(func, wait, immediate) {
  var timeout, args, context, timestamp, result;
  var later = function later() {
    // 据上一次触发时间间隔
    var last = +new Date() - timestamp;

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };
  return function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    context = this;
    timestamp = +new Date();
    var callNow = immediate && !timeout;
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait);
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }
    return result;
  };
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 */
export function deepClone(source) {
  if (!source && _typeof(source) !== 'object') {
    throw new Error('error arguments', 'deepClone');
  }
  var targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach(function (keys) {
    if (source[keys] && _typeof(source[keys]) === 'object') {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}
export function uniqueArr(arr) {
  return Array.from(new Set(arr));
}
export function uniqueArrObj(arr, key) {
  return _.uniqBy(arr, function (e) {
    return e[key];
  });
}
export function createUniqueString() {
  var timestamp = +new Date() + '';
  var randomNum = parseInt((1 + Math.random()) * 65536) + '';
  return (+(randomNum + timestamp)).toString(32);
}
export function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
}
export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls;
}
export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    var reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
    ele.className = ele.className.replace(reg, ' ');
  }
}
export function convertNumberToBoolean(status) {
  return status >= 1;
}

/**
 * Set key and value from object to another object
 * @param {object} objectKey
 * @param {object} data
 * @returns {object} string
 */
export function setKeyForObject(objectKey, data) {
  var response = {};
  for (var key in objectKey) {
    if (objectKey.hasOwnProperty(key)) {
      response[key] = data[key];
    }
  }
  return response;
}

/**
 * Set key and value from 3 array object type, channel, tier for pricing
 * @param {array} typeIds
 * @param {array} channelIds
 * @param {array} tierIds
 * @returns {array}
 */
export function setArrPrice(typeIds, channelIds, tierIds) {
  var taxRates = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
  var response = [];
  typeIds.forEach(function (itemType) {
    channelIds.forEach(function (itemChannel) {
      tierIds.forEach(function (itemTier) {
        var taxRate = taxRates.find(function (t) {
          return itemType.key === t.type_id;
        });
        response.push({
          type: {
            key: itemType.key,
            value: itemType.value,
            tax: typeof taxRate !== 'undefined' ? taxRate.tax : 0
          },
          channel: {
            key: itemChannel.key,
            value: itemChannel.value
          },
          tier: {
            key: itemTier.key,
            value: itemTier.value
          },
          price: 0,
          price_with_tax: 0
        });
      });
    });
  });
  return response;
}

/**
 * find oject in object from a field
 * @param {string} field, value
 * @param {} value
 * @returns {object}
 */
export function findObjectInObject(object, field, value) {
  if (!object || !field || !value) {
    return {};
  }
  var keyObjects = Object.keys(object);
  var key = keyObjects.find(function (key) {
    return object[key][field] === value;
  });
  return key ? object[key] : {};
}

/**
 * find oject in object from a field
 * @param {string} field, value
 * @param {} value
 * @returns {object}
 */
export function getObjectInArrayObject(array, field, value) {
  if (!array.length || !field || !value) {
    return {};
  }
  var result = array.find(function (item) {
    return item[field] === value;
  });
  return result || {};
}
export var RouteContainerComponent = {
  render: function render(c) {
    return c('router-view');
  }
};
export function ucwords(str) {
  var delimiter = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  if (delimiter) {
    str = str.replace(new RegExp(delimiter, 'g'), ' ');
  }
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
export function setDataTranslate() {
  var element = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  var listLanguages = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var result = {};
  listLanguages.forEach(function (item) {
    var tmp = {};
    if (element.name) {
      tmp.name = element.name;
    }
    if (element.description) {
      tmp.description = element.description;
    }
    if (element.slug) {
      tmp.slug = slug(element.name.toLowerCase(), {
        tone: false
      });
    }
    if (element.short_description) {
      tmp.short_description = element.short_description;
    }
    if (element.main_description) {
      tmp.main_description = element.main_description;
    }
    if (element.content) {
      tmp.content = element.content;
    }
    result[item.value] = tmp;
  });
  return result;
}
export function formatDataTranslate() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  var listLanguages = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var result = {};
  listLanguages.forEach(function (item) {
    if (data.translate && data.translate[item.value]) {
      result[item.value] = data.translate[item.value];
    } else if (item.value) {
      var tmp = {};
      if (data.name !== undefined) {
        tmp.name = data.name;
      }
      if (data.description !== undefined) {
        tmp.description = data.description;
      }
      if (data.slug !== undefined) {
        tmp.slug = slug(data.name.toLowerCase(), {
          tone: false
        });
      }
      if (data.short_description !== undefined) {
        tmp.short_description = data.short_description;
      }
      if (data.main_description !== undefined) {
        tmp.main_description = data.main_description;
      }
      if (data.content !== undefined) {
        tmp.content = data.content;
      }
      // debugger
      result[item.value] = tmp;
    }
  });
  return result;
}
export function isEmptyObject(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
}
export function calculateAge(birthday) {
  // birthday is a date
  if (!birthday) return 0;
  return moment().diff(birthday, 'years');
}
export function getFirstLastDayOfMonth(date) {
  date = new Date(date);
  var y = date.getFullYear();
  var m = date.getMonth();
  var firstDay = new Date(y, m, 1);
  var lastDay = new Date(y, m + 1, 0);
  return {
    firstDay: firstDay,
    lastDay: lastDay
  };
}
export function parseTimeByTimeZone(date) {
  var timezone = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'Asia/Jakarta';
  var fm = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'YYYY-MM-DD HH:mm:ss';
  var format = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
  if (!date || !Date.parse(date)) {
    return '';
  }
  var result = moment.tz(date, timezone);
  return format ? result.format(fm) : result;
}
export function parseLocalTimeToUTC0(date) {
  if (!date || !Date.parse(date)) {
    return '';
  }
  return moment(date);
}
export function parseUTC0ToLocalTime(date) {
  var fm = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'YYYY-MM-DD HH:mm:ss';
  var format = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  if (!date || !Date.parse(date)) {
    return '';
  }
  var result = moment.tz(date, moment.tz.guess());
  return format ? result.format(fm) : result;
}
export function getCurrentTz() {
  return moment.tz.guess();
}
export function fullWidthToHalfWidth() {
  var string = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var stringArr = string.split('');
  var newStringArr = [];
  var _iterator = _createForOfIteratorHelper(stringArr),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var _string = _step.value;
      if (isKanji(_string.trim())) {
        newStringArr.push('');
      } else {
        newStringArr.push(_string);
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  string = newStringArr.join('');
  var kanaChar = toKatakana(string, {
    passRomaji: true
  });
  kanaChar = kanaChar.replace(/[\uff01-\uff5e]/g, function (r) {
    return String.fromCharCode(r.charCodeAt(0) - 0xfee0);
  });
  var re = new RegExp("[".concat(Object.keys(zenkakuTohankakuDict).join(''), "]"), 'g');
  return kanaChar.replace(re, function (char) {
    return zenkakuTohankakuDict[char];
  });
}
export function getDayOfWeek(date) {
  return [0, 6].includes(new Date(date).getDay()) ? 'OFF' : 'Normal';
}
export function getNameDayOfWeek(date) {
  var lang = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'en';
  var dataLang = {
    en: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    jp: ['日', '月', '火', '水', '木', '金', '土']
  };
  var d = new Date(date);
  var n = dataLang[lang][d.getDay()];
  return n;
}
export function minusMultiFloat() {
  var numFix = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 2;
  for (var _len2 = arguments.length, data = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
    data[_key2 - 1] = arguments[_key2];
  }
  // this func will minus on argument data like fnc( numFix, 3,2,1) => 3-2-1
  return +data.reduce(function (n, c) {
    if (isNaN(Number(n)) || n === null) {
      return c;
    }
    n -= c;
    return Number(n).toFixed(numFix);
  });
}
export function calc2Hour(start_time, end_time) {
  var numFix = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 2;
  var ceil3rd = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
  if (!start_time || !end_time) {
    return 0;
  }
  var endTime = moment(new Date(end_time), 'HH:mm');
  var startTime = moment(new Date(start_time), 'HH:mm');
  var result = Number((startTime.diff(endTime) / 60000 / 60).toFixed(numFix));
  result = ceil3rd ? Math.ceil(Math.abs(result) * 100) / 100 : result;
  return (+result).toFixed(2);
}
export function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}
export function calc2Date(start_time, end_time) {
  if (start_time && end_time) {
    var endTime = moment(new Date(end_time));
    var startTime = moment(new Date(start_time));
    var result = endTime.diff(startTime);
    return result;
  }
  return 0;
}
export function getCurrentBusinessDate() {
  var start_time = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var end_time = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var isGetEndDate = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  var now = moment();
  var start_date = moment().set('hour', start_time).startOf('hour');
  var end_date = moment().set('hour', end_time).startOf('hour');
  var hour = now.hour();
  if (end_time <= start_time && hour < start_time) {
    start_date.subtract(1, 'days');
  }
  if (end_time <= start_time && hour >= start_time) {
    end_date.add(1, 'days');
  }
  return isGetEndDate ? end_date.format('YYYY-MM-DD') : start_date.format('YYYY-MM-DD');
}
export function parseWorkingTime(business_date) {
  var start_time = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var end_time = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  var tz = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'Asia/Tokyo';
  if (!business_date) {
    return {
      start_date: undefined,
      end_date: undefined
    };
  }
  var start_date = moment(business_date).tz(tz).set('hour', start_time);
  var end_date = moment(business_date).tz(tz).set('hour', end_time);
  if (end_time <= start_time) {
    end_date.add(1, 'days');
  }
  return {
    start_date: start_date.toISOString(),
    end_date: end_date.toISOString()
  };
}
export function getBusinessDate() {
  var start_time = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 7;
  var end_time = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 7;
  var isGetEndDate = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var now = moment();
  var start_date = moment().set('hour', start_time).startOf('hour');
  var end_date = moment().set('hour', end_time).startOf('hour');
  var hour = now.hour();
  if (end_time <= start_time && hour < start_time) {
    start_date.subtract(1, 'days');
  }
  if (end_time <= start_time && hour >= start_time) {
    end_date.add(1, 'days');
  }
  return isGetEndDate ? end_date.format('YYYY-MM-DD') : start_date.format('YYYY-MM-DD');
}
export function getOrderFoodStatus(status) {
  for (var i in CONSTANTS.DELIVERY_STATUS) {
    var temp = CONSTANTS.DELIVERY_STATUS[i].value.includes(status);
    if (temp) {
      return CONSTANTS.DELIVERY_STATUS[i];
    }
  }
  return {
    name: '',
    label: '',
    type: ''
  };
}