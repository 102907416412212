import "core-js/modules/es6.array.find-index";
import "core-js/modules/es6.function.name";
import CONSTANTS from '@/constants';
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  stores: function stores(state) {
    return state.product.getStore;
  },
  language: function language(state) {
    return state.app.language;
  },
  size: function size(state) {
    return state.app.size;
  },
  device: function device(state) {
    return state.app.device;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  token: function token(state) {
    return state.user.token;
  },
  avatar: function avatar(state) {
    return state.user.avatar;
  },
  client_uuid: function client_uuid(state) {
    return state.user.client_uuid;
  },
  name: function name(state) {
    return state.user.name;
  },
  email: function email(state) {
    return state.user.email;
  },
  username: function username(state) {
    return state.user.username;
  },
  introduction: function introduction(state) {
    return state.user.introduction;
  },
  roles: function roles(state) {
    return state.user.roles;
  },
  isSuperAdmin: function isSuperAdmin(state) {
    return state.user.roles.findIndex(function (r) {
      return r === CONSTANTS.ROLE_CODE.SUPERADMIN;
    }) > -1;
  },
  permissions: function permissions(state) {
    return state.user.permissions;
  },
  permission_routes: function permission_routes(state) {
    return state.permission.routes;
  },
  addRoutes: function addRoutes(state) {
    return state.permission.addRoutes;
  },
  errorLogs: function errorLogs(state) {
    return state.errorLog.logs;
  },
  page_name: function page_name(state) {
    return state.app.pageName;
  },
  is_edit_page: function is_edit_page(state) {
    return state.app.isEditPage;
  }
};
export default getters;