export default {
  route: {
    authorization: '認可管理',
    role_management: '役割管理',
    permission_management: '権限管理',
    create_role: 'ロールを作成',
    edit_role: '役割を編集',
    end_customer_detail: '向先詳細',
    organization_management: '組織管理',
    user_management: 'ユーザー管理',
    jit_sales_management: '代行店販売',
    jit_list: '納品入力',
    jit_list_master_data: 'JITリストマスター',
    import_data: '納品データ取り込み',
    demand_management: '需要情報',
    demand_list: '需要情報一覧',
    demand_info: '需要情報（品番別）',
    user_profile: 'User Profile',
    trans_order_outlet: 'Trans Order Outlet',
    transaction_order: 'Transaction Order',
    export: 'Export',
    package: 'Package',
    edit_package_information: 'Edit Package Information',
    create_package_information: 'Create Package Information',
    package_information: 'Package Information',
    edit_country: 'Edit Country',
    create_country: 'Create Country',
    country: 'Country',
    edit_group_type: 'Edit Group Type',
    create_group_type: 'Create Group Type',
    group_type: 'Group Type',
    edit_staff: 'Edit Staff',
    create_staff: 'Create Staff',
    staff: 'Staff',
    edit_campaign: 'Edit Campaign',
    create_campaign: 'Create Campaign',
    campaign: 'Campaign',
    detail_store_subscription_plan: 'Detail Store Subscription Plan',
    store_subscription_plan: 'Store Subscription Plan',
    edit_plan: 'Edit Plan',
    create_plan: 'Create Plan',
    plan_listing: 'Plan Listing',
    packages: 'Packages',
    subscription_plan: 'Subscription Plan',
    histories: 'Histories',
    edit_bank: 'Edit Bank',
    create_bank: 'Create Bank',
    banks: 'Banks',
    tags: 'Tags',
    edit_ads_video: 'Edit Ads Video',
    create_ads_video: 'Create Ads Video',
    create_customer: 'Create Customer',
    deviation: 'Deviation',
    ace: 'ACE',
    ads_video: 'Ads Video',
    clear_data: 'Clear Data',
    type: 'Type',
    log_app: 'Log App',
    organization: 'Organization',
    ACE: '各種レポート',
    salad: 'サラダラベル出力',
    tasting_label_output: '検食ラベル出力',
    date_label_output: '日付ラベル出力',
    electronic_journal: '電子ジャーナル',
    store_message: 'メッセージの保存',
    management: '管理',
    notification: 'お知らせ',
    report_dashboard: 'ダッシュボードを報告する',
    earn_redeem_point: '償還ポイントを獲得',
    redemption_rate_voucher: '償還率伝票',
    report_order_promotion: '報告注文プロモーション',
    food_loss: '食物損失',
    dashboard: 'ホーム',
    documentation: 'ドキュメント',
    guide: 'ガイド',
    role: '役割',
    permission: '許可',
    pagePermission: 'ページ許可',
    rolePermission: '役割許可',
    directivePermission: '指示許可',
    icons: 'アイコン',
    components: '2-コンポーネント',
    tinymce: 'Tinymce',
    markdown: 'マークダウン',
    jsonEditor: 'JSONエディター',
    dndList: 'DNDリスト',
    splitPane: 'SplitPane',
    avatarUpload: 'アバターアップロード',
    dropzone: 'ドロップゾーン',
    sticky: '引きつける',
    countTo: '数える',
    componentMixin: '混入します',
    backToTop: 'トップに戻る',
    dragDialog: 'ダイアログをドラッグ',
    dragSelect: 'セレクトをドラッグ',
    dragKanban: '看板をドラッグ',
    charts: 'チャート',
    keyboardChart: 'キーボードチャート',
    lineChart: 'ラインチャート',
    mixChart: 'ミックスチャート',
    example: '例',
    nested: 'ネストされたルート',
    Table: 'テーブル',
    dynamicTable: 'ダイナミックテーブル',
    dragTable: 'ドラッグテーブル',
    inlineEditTable: 'インライン編集',
    complexTable: '複合テーブル',
    tab: 'タブ',
    form: 'フォーム',
    errorPages: 'エラーページ',
    page401: '401',
    page404: '404',
    errorLog: 'エラーログ',
    excel: 'エクセル',
    exportExcel: 'エクセル出力',
    selectExcel: '出力選択',
    mergeHeader: 'ヘッダー統合',
    uploadExcel: 'エクセルアップロード',
    zip: 'Zip',
    pdf: 'PDF',
    exportZip: 'Zipファイル出力',
    theme: 'テーマ',
    clipboardDemo: 'クリップボード',
    i18n: 'I18n',
    externalLink: '外部リンク',
    inventory: '在庫管理メニュー',
    master_material: '品目設定',
    master_uom: '単位マスタ',
    master_conversion_uom: '単位変換マスタ',
    master_sku: 'プロダクトマスタ',
    master_sku_bom: 'プロダクト材料マスタ',
    master_component: 'コンポーネントマスタ',
    master_component_bom: 'コンポーネント材料マスタ',
    option_group: 'サイズ',
    option: '生地',
    user: 'ユーザー',
    create_permission: '権限設定',
    edit_permission: '権限編集',
    create_user: 'ユーザーを作成',
    edit_user: 'ユーザーを編集',
    driver: 'ドライバー',
    customer: '顧客管理メニュー',
    customer_list: '顧客一覧',
    blocked_list: 'ブロックリスト',
    edit_customer: '顧客情報の編集',
    customer_address: '顧客住所',
    master_meterial_category: '原材料カテゴリ',
    create_master_meterial_category: '原材料カテゴリ設定',
    edit_master_meterial_category: '原材料カテゴリ編集',
    create_master_material: '原材料マスタ設定',
    edit_master_material: '原材料マスタ編集',
    create_master_uom: '原材料単位マスタ作成',
    edit_master_uom: '原材料単位マスタ編集',
    create_master_conversion_uom: '単位変換マスタ作成',
    edit_master_conversion_uom: '単位変換マスタ編集',
    master_brand: 'マスターブランド',
    create_master_brand: 'マスターブランド設定',
    edit_master_brand: 'マスターブランド編集',
    master_sku_category: 'プロダクトカテゴリ',
    create_master_sku_category: 'プロダクトカテゴリ設定',
    edit_master_sku_category: 'プロダクトカテゴリ編集',
    master_sku_header: 'プロダクトマスタヘッダー',
    create_master_sku_header: 'プロダクトマスタヘッダー設定',
    edit_master_sku_header: 'プロダクトマスタヘッダー編集',
    create_master_sku: 'プロダクトマスタ設定',
    edit_master_sku: 'プロダクトマスタ編集',
    create_master_sku_bom: 'プロダクト材料マスタ設定',
    edit_master_sku_bom: 'プロダクト材料マスタ 編集',
    create_master_component: 'コンポーネントマスタ設定',
    edit_master_component: 'コンポーネントマスタ編集',
    create_master_component_bom: 'コンポーネント材料マスタ設定',
    edit_master_component_bom: 'コンポーネント材料マスタ編集',
    purchase_order: '注文書',
    create_purchase_order: '注文書作成',
    edit_purchase_order: '注文書編集',
    purchase_order_detail: '注文書詳細',
    create_purchase_order_detail: '注文書詳細 作成',
    edit_purchase_order_detail: '注文書詳細 編集',
    good_reciept: '入出庫一覧',
    create_good_reciept: 'Create Good Reciept',
    edit_good_reciept: '入出庫情報',
    good_reciept_detail: '良い領収書の詳細',
    create_good_reciept_detail: '良い繰り返し詳細を作成',
    edit_good_reciept_detail: '良い繰り返し詳細を編集',
    management_pos: 'POS管理メニュー',
    management_order: 'Order管理',
    order: 'オーダー',
    all_order: 'すべてのオーダー',
    all_order_detail: 'すべて注文詳細',
    current_order: '当日注文一覧',
    advance_order: '予約注文一覧',
    reject_order: 'オーダー拒否',
    problem_order: '問題発生中オーダー',
    process_order: '処理中オーダー',
    order_delivery: 'Order Delivery',
    resend_order: '注文を再送信',
    upper_text: '上段テキスト',
    hide_upper_text: '上部テキストを隠す',
    show_upper_text: '上のテキストを表示',
    view_order: 'オーダー確認',
    view_order_detail: 'オーダー詳細を確認',
    view_order_online_detail: 'View Order Online Detail',
    view_order_online_detail_list: 'View Order Online Detail List',
    view_utilization_detail: '使用率の詳細を表示',
    view_order_payment: 'オーダーの支払確認',
    view_order_log_status: 'オーダーログステータス確認',
    payment_method: '決済方法',
    create_payment_method: '決済方法 設定',
    edit_payment_method: '決済方法 編集',
    menu: 'メニュー',
    topping: 'トッピング',
    create_topping: 'トッピング設定',
    edit_topping: 'トッピング編集',
    doctor: 'ドクター',
    create_doctor: 'Doctorを作成',
    edit_doctor: 'Doctorを編集',
    tag: 'タグ',
    create_tag: 'タグを作成',
    edit_tag: 'タグを編集',
    group_tag: 'Group Tag',
    create_group_tag: 'グループタグを作成',
    edit_group_tag: 'グループタグを編集',
    create_inventory: '在庫の作成',
    edit_inventory: '在庫の編集',
    origin: '起源',
    create_origin: '起源を作成',
    edit_origin: '起源を編集',
    product_provider: '商品プロバイダ',
    create_product_provider: '商品プロバイダを作成',
    edit_product_provider: '商品プロバイダを編集',
    category: '品目カテゴリー ',
    create_category: 'カテゴリー設定',
    edit_category: 'カテゴリー編集',
    product: 'メニュー',
    create_product: 'メニュー設定',
    edit_product: 'メニュー編集',
    add_variant_addon: ' 「バリアントアドオンを追加」',
    add_combo: 'セット・チョイスを追加',
    create_option: 'オプションを作成',
    edit_option: 'オプションを編集',
    create_option_group: 'オプショングループを作成',
    edit_option_group: 'オプショングループの編集',
    kds: 'キッチンディスプレイ',
    create_kds: 'キッチンディスプレイ作成',
    edit_kds: 'キッチンディスプレイ編集',
    store: '店舗',
    qr_code: 'QRコード',
    create_store: 'ストア設定',
    edit_store: '店舗設定',
    edit_store_address_mapping: 'ストアアドレスマッピングの編集',
    store_group: '店舗グループ',
    store_target: '店舗Target',
    create_store_group: '店舗グループを設定',
    edit_store_group: '店舗グループを編集',
    address: '住所',
    system: 'システム',
    client: 'クライアント',
    create_client: 'クライアントの設定',
    edit_client: 'クライアントの編集',
    config: 'コンフィグ',
    create_config: '各種設定',
    edit_config: 'コンフィグ編集',
    partner: 'パートナー',
    create_partner: 'パートナー設定',
    edit_partner: 'パートナー編集',
    printer: 'プリンタ',
    create_printer: 'プリンタ設定',
    edit_printer: 'プリンタ編集',
    shift: 'シフト',
    content: 'コンテンツ',
    create_content: 'コンテンツを作成',
    edit_content: 'コンテンツを編集',
    content_category: 'コンテンツのカテゴリ',
    create_content_category: 'コンテンツカテゴリ作成',
    edit_content_category: 'コンテンツカテゴリ編集',
    promotion: 'プロモーション',
    create_promotion: 'プロモーション作成',
    edit_promotion: 'プロモーション編集',
    promotion_master: 'プロモーションマスタ',
    create_promotion_master: 'プロモーションマスタ作成',
    edit_promotion_master: 'プロモーションマスタ編集',
    promotion_user: 'プロモーションユーザー',
    create_promotion_user: 'プロモーションユーザー作成',
    edit_promotion_user: 'プロモーションユーザー編集',
    promotion_product: 'プロモーション商品',
    create_promotion_product: 'プロモーション商品作成',
    edit_promotion_product: 'プロモーション商品編集',
    promotion_condition: 'プロモーション条件',
    create_promotion_condition: 'プロモーション条件作成',
    edit_promotion_condition: 'プロモーション条件編集',
    order_processing_time: '注文処理時間',
    overview: '概要',
    sale_today: '当日実績',
    sale_history: '売上履歴',
    product_mix: 'メニューミックス',
    store_cash: '現金管理メニュー',
    store_cash_list: 'ペティキャッシュ情報一覧',
    create_store_cash: 'ペティキャッシュ情報登録',
    edit_store_cash: 'ペティキャッシュ情報編集',
    money_bag: 'ポーチ登録',
    create_money_bag: 'ポーチを作成',
    edit_money_bag: 'ポーチを編集',
    payment: '決済',
    payment_method_type: '決済方法',
    create_payment_method_type: '決済方法作成',
    edit_payment_method_type: '決済方法編集',
    payment_method_group: '決済方法グループ',
    create_payment_method_group: '決済方法グループの作成',
    edit_payment_method_group: '決済方法グループの編集',
    payment_method_level1: '支払い方法レベル1',
    edit_payment_method_level1: '支払い方法レベル1を編集する',
    payment_method_bank: '銀行決済',
    create_payment_method_bank: '銀行決済作成',
    edit_payment_method_bank: '銀行決済編集',
    edit_employee: 'クルー情報変更',
    contract: '契約',
    create_contract: 'クルー情報登録',
    edit_contract: '契約内容変更',
    product_half_and_half: 'ハーフアンドハーフ商品',
    create_product_half_and_half: 'ハーフアンドハーフ商品作成',
    edit_product_half_and_half: 'ハーフアンドハーフ商品編集',
    ui_configuration: 'UI構成',
    create_ui_configuration: 'UI構成の作成',
    edit_ui_configuration: 'UI構成の編集',
    banner: 'バナー',
    create_banner: 'バナー作成',
    edit_banner: 'バナー編集',
    units: '単位',
    edit_unit: '単位を編集',
    create_unit: '単位を作成',
    currency: '通貨',
    create_currency: '通貨を作成',
    edit_currency: '通貨を編集',
    print_image: '印刷画像',
    create_item: '項目を作成',
    edit_item: '項目を編集',
    items: '1-プロダクト',
    create_component: '成分設定',
    edit_component: '成分編集',
    component: '2-コンポーネント',
    employee: 'レーバー一覧',
    create_employee: '従業員登録',
    comment: 'コメント',
    create_comment: 'コメントを登録',
    edit_comment: 'コメントを編集',
    report: 'レポート',
    area_management: 'エリアマネジメント',
    device: 'デバイス',
    edit_device: 'デバイスを編集',
    create_device: 'デバイスを登録',
    push_notify: 'プッシュ通知',
    create_notify: '通知の作成',
    booking: '予約',
    detail_booking: '予約の詳細',
    nationality: '国籍',
    create_nationality: '国籍を登録',
    edit_nationality: '国籍を編集',
    rank: 'ランク',
    create_rank: 'ランクを登録',
    edit_rank: 'ランクを編集',
    position: 'ポジション',
    create_position: 'ポジションを登録',
    edit_position: 'ポジションを編集',
    occupation: '職業',
    create_occupation: '職業を創造',
    edit_occupation: '職業を編集',
    changeStatus: 'ステータスの変更',
    create_changeStatus: '変更ステータスの登録',
    edit_changeStatus: '変更ステータスの編集',
    visaStatus: 'ビザステータス',
    create_visaStatus: 'ビザステータスを登録',
    edit_visaStatus: 'ビザステータスを編集',
    nationalityCategory: '国籍区分',
    create_nationalityCategory: '国籍区分を登録',
    edit_nationalityCategory: '国籍区分そ編集',
    bloodType: '血液型',
    create_bloodType: '血液型を登録',
    edit_bloodType: '血液型を編集',
    productList: 'メニューリスト',
    chooseCategoryType: 'カテゴリタイプを選択',
    transfer: '店舗間出庫',
    create_transfer: '店舗間入出庫登録',
    edit_transfer: '転送を編集',
    foodWaste: 'ロス情報登録',
    createFoodWaste: 'ロス情報登録',
    editFoodWaste: '食品廃棄物を編集',
    eod: '店舗精算処理メニュー',
    eod_list: '店舗精算処理',
    eod_management: 'リスト EOD',
    money_closure: '現金過不足確認',
    labour_closure: '勤怠管理',
    inventory_closure: '棚卸情報入力',
    order_promotion: '注文プロモーション',
    product_mix_v2: '製品ミックス',
    manage_order: '注文一覧',
    temporaryAssignment: 'ヘルプ登録',
    create_temporaryAssignment: 'ヘルプ新規登録',
    edit_temporaryAssignment: 'ヘルプ情報編集',
    temporaryAssignmentHQ: 'ヘルプ登録 HQ',
    create_temporaryAssignmentHQ: 'ヘルプ新規登録 HQ',
    edit_temporaryAssignmentHQ: 'ヘルプ情報編集 HQ',
    order_type: '注文タイプ',
    order_status: '注文の状況',
    labor: '人事管理メニュー',
    standardConfiguration: '労務チェック',
    create_standardConfiguration: '労務関連情報設定',
    edit_standardConfiguration: '労務関連情報設定',
    certificate: '証明書',
    create_certificate: '証明書の作成',
    edit_certificate: '証明書の編集',
    order_forecast: '注文予測',
    create_order_forecast: '注文予測を作成',
    edit_order_forecast: '注文予測編集',
    driver_forecast: 'ドライバー予測',
    create_driver_forecast: 'ドライバ予測の作成',
    edit_driver_forecast: 'ドライバの編集',
    store_target_label: 'ターゲット2',
    today_sales: '今日の販売',
    report_inventory: '在庫レポート',
    store_health: '健康',
    store_common: '共通の店',
    store_dashboard: 'ダッシュボードを保存する',
    reportv2: 'レポート V2',
    order_driver_pool: 'オーダードライバプール',
    report_food_cost: '食料費の報告',
    report_order_detail: '命令注文詳細',
    labour_cost: '人件費',
    slot_configuration: 'スロット構成',
    edit_slot_configuration: 'スロット設定の編集',
    driver_roaster: 'Driver Roaster',
    daily_report: '日次レポート',
    sales_details: 'Sales details',
    create_driver_roaster: 'ドライバの登録簿を作成',
    edit_driver_roaster: 'ドライバーの編集ロースター',
    exception_date: '例外日',
    edit_exception_date: '例外日付を編集',
    create_exception_date: '例外日付を作成',
    matrix: 'マトリックス',
    edit_matrix: '行列編集',
    create_matrix: '行列を作成',
    brand: 'ブランド',
    store_category: 'マーチャントカテゴリ',
    create_brand: 'ブランドの作成',
    edit_brand: 'ブランドの作成',
    create_store_category: 'マーチャントカテゴリを作成',
    edit_store_category: 'マーチャントカテゴリを編集',
    contract_period: '契約期間情報',
    create_contract_period: '契約期間を作成',
    edit_contract_period: '契約期間設定',
    store_notification: '店頭通知',
    leafleting_result: 'リーフレット結果',
    edit_leafleting_result: 'リーフレット結果を編集',
    create_leafleting_result: 'リーフレット作成結果を作成',
    leafleting_target: 'リーフレットターゲット',
    edit_leafleting_target: 'リーフレットターゲットの編集',
    create_leafleting_target: 'リーフレットターゲットを作成',
    special_request: '特別な要求',
    add_special_request: '特別な要求を追加',
    edit_special_request: '特別な要求を編集',
    report_order: '命令順',
    loyalist_point_report: 'ロイヤリストポイントレポート',
    payroll_error_message: '給与のエラーメッセージ',
    setting_kpi: 'KPIを設定',
    add_setting_kpi: '設定KPIを追加する ',
    edit_setting_kpi: '編集設定KPI',
    setting_color_kpi: 'カラーKPI',
    add_setting_color_kpi: '設定色KPIを追加',
    edit_setting_color_kpi: '設定の色KPI',
    pj_input: 'PJ入力',
    labor_import: '労働輸入',
    check_order: '注文を確認',
    ace_cancel_refund: 'キャンセル返金操作',
    ace_cancel: 'キャンセル',
    ace_refund: '払い戻し',
    ace_cash_voucher: '現金金券管理',
    ace_cash_count: 'キャッシュカウント履歴',
    ace_coupon_history: '入金時クーポン追加',
    ace_labour_cost: '個人別勤務実績',
    ace_under_18: '労務管理',
    ace_sale: '日計表',
    ace_sale_daily: '日計表',
    report_store_group: 'Report Store Group',
    create_report_store_group: 'Create Report Store Group',
    edit_report_store_group: 'Edit Report Store Group',
    sales_by_hour: '1時間営業',
    sales_mix_by_segment: 'セグメント別セールスミックス',
    report_menu_mix: 'メニューミックス',
    report_usage_analysis: 'Report Usage Analysis',
    dateLabel: '日付ラベル',
    create_master_date_label: '日付ラベル',
    edit_master_date_label: 'マスター日付ラベルの編集',
    date_label_category: '日付ラベルカテゴリ',
    edit_master_date_label_category: '日付ラベルカテゴリの編集',
    create_master_date_label_category: '日付ラベルカテゴリの作成',
    app_version_log: 'App Version Log',
    menu_category: 'Menu Categories',
    create_menu_category: 'Create Menu Category',
    edit_menu_category: 'Edit Menu Category',
    store_menu: 'Store Menus',
    edit_store_menu: 'Edit Store Menu',
    demand_confirm: '需要情報 確認',
    claim_management: 'クレーム管理',
    manufacturing_product: '製造構成指定',
    spot_order_list: 'スポット受注一覧',
    master_data: 'マスターデータ',
    product_price: '製品価格',
    lot_number: 'ロット№',
    pricing_change: '単価変更',
    pricing_change_request_list: '単価変更一覧',
    pricing_change_approval: '単価変更承認',
    pricing_change_history: '価格変更履歴',
    shipping_status_management: '出荷一覧'
  },
  dashboard: {
    welcome_to_diamet: 'ダイヤメットポータルへようこそ',
    top_selling: 'Top selling',
    sale_by_hour_and_date: 'Sale by hour and date',
    avg_order: 'Avg. Order Value',
    total_order: 'Total Order',
    end_date: 'End Date',
    start_date: 'Start Date',
    select_store: 'Select Store',
    new_visits: '新規顧客',
    messages: 'メッセージ',
    purchases: '購入',
    shoppings: 'ショッピング',
    total_sales: '総売上',
    total_bill: '総件数',
    average_bill: '平均件数',
    sos: 'SOS',
    sales_by_channel: 'チャネル別売上実績',
    sales_by_type: 'オーダー区分別売上実績',
    bill_by_channel: 'チャネル別件数',
    bill_by_type: 'オーダー区分別件数',
    top_10: 'トップ10',
    no_data: 'データなし'
  },
  navbar: {
    logOut: 'ログアウト',
    dashboard: 'ダッシュボード',
    github: 'Github',
    theme: ' テーマ',
    size: 'グローバルサイズ'
  },
  login: {
    title: 'OSS Bee Podへようこそ',
    action: 'ログイン情報を入力してください',
    help: 'ヘルプはこちら',
    logIn: 'ログイン',
    username: 'ユーザー名',
    password: 'パスワード',
    confirmpassword: 'パスワードの確認',
    any: 'あらゆる',
    thirdparty: '外部サービスアカウントでログイン',
    remember_me: '記憶する',
    forgot_password: 'パスワードを忘れた場合',
    store_code: 'ストアコード',
    language_for_email: 'Language for notification'
  },
  forgot_password: {
    title: 'パスワードをお忘れですか？',
    action: '入力いただいたメールアドレス宛に,初期化のためのリンクが添付されたメールが届きます。',
    help: ' ログイン画面に戻る',
    username: 'ユーザー名',
    email_note: 'Eメール',
    reset: 'リセット',
    forgot_password: 'パスワードを忘れた場合',
    email: 'メールアドレスの入力'
  },
  reset_password: {
    title: 'パスワードリセット',
    new_password: '新しいパスワード',
    confirm_password: 'パスワードの確認',
    reset: 'リセット'
  },
  documentation: {
    documentation: 'ドキュメンテーション',
    github: 'Githubリポジトリ'
  },
  permission: {
    addRole: '新しい役割',
    editPermission: '編集',
    roles: 'あなたの役割',
    switchRoles: '役割の切り替え',
    delete: '削除',
    confirm: '確認',
    cancel: 'キャンセル'
  },
  guide: {
    button: '表示ガイド'
  },
  common: {
    keyWord: 'キーワード',
    titleDelete: 'レコードの削除しますか？',
    titleClearCache: 'キャッシュクリア',
    sun: '日',
    mon: '月',
    tue: '火',
    wed: '水',
    thu: '木',
    fri: '金',
    sat: '土',
    hour: '時間',
    minute: '分',
    week: '週',
    week_number: '{weekNumber}週'
  },
  table: {
    new_password: '新しいパスワード',
    role_code: '役割コード',
    role_name: 'ロール名',
    role_description: '役割の説明',
    permission_code: '許可コード',
    permission_name: '権限名',
    permission_description: '権限の説明',
    role_permissions: '役割の権限',
    organization_id: '組織ID',
    organization_name: '組織名',
    sale_organization_id: '販売組織ID',
    sale_organization_name: '販売組織名',
    customer: 'お客様',
    end_customer: '向先',
    end_customer_id: '向先ID',
    end_customer_type: '向先種類',
    internal_product_id: '品番',
    customer_product_id: '図番',
    product_name: '品名',
    unit_price: '単価',
    currency: '通貨',
    fixed_weeks_frame: '確定枠',
    sales_plan: 'インフォメーション',
    delivery_slip_number: '納品書番号',
    price_type: '単価区分',
    delivery_quantity: '納入個数',
    total: '金額',
    delivery_date: '納入日',
    update_time: '入力日',
    user_id: '入力者コード',
    user_name: '入力者名',
    user_code: 'ユーザーコード',
    user_roles: 'ユーザーの役割',
    remain: '残',
    order_type: '注文タイプ',
    pin_code: 'Pin Code',
    start_amount: '開始額',
    end_amount: '終了額',
    is_special: '特別な',
    finish: '終了',
    message: '確認項目',
    is_valid: 'ステータス',
    is_verify_eod: 'はEODを確認している',
    period: '限目',
    user_total: 'ユーザーの合計',
    purchase: '購入',
    user_total_redeem: 'ユーザーの合計引き換え',
    user_total_earn: 'ユーザーの合計獲得',
    point_total_earn: 'ポイント合計獲得',
    point_total_redeem: 'ポイント合計引き換え',
    qty_release: '数量リリース',
    qty_redeem: '数量引き換え',
    item: 'Item',
    day_before: '前日分',
    for_day: '当日分',
    bank_balance: '口座残高',
    internal_change: '内釣銭準備金',
    bank: '銀行',
    cash: '現金',
    total_deposit_day: '当日入金計',
    total_withdraw_day: '当日出金計',
    name_cash: '摘要名',
    is_unique_code: 'ユニークなコード',
    limit_quantity_cart: '限界数量カート',
    is_oc_discount: '従業員割引',
    rp: '「ルピア」',
    submit: '登録',
    title: 'タイトル',
    importance: 'インプ',
    type: 'ロス区分',
    type_name: 'タイプ名',
    remark: '備考',
    search: '検索',
    add: '追加',
    export: '出力',
    reviewer: 'レビュアー',
    id: 'ID',
    uom_from_uuid: '単位の取得',
    code: 'レーバーコード',
    name: '顧客氏名',
    name_kanji: '漢字名',
    sku_uuid: 'プロダクト',
    component_uuid: 'コンポーネント',
    uom_to_uuid: '単位',
    material_uuid: '品目名称',
    conversion: '変換',
    uom_uuid: '単位',
    replacement_material_uuid: '代替材料ID',
    replacement_uom_uuid: '代替材料単位ID',
    replacement_quantity: '代替材料数量',
    email: 'Eメール',
    date_of_purchase: '購入日',
    date_of_receive: '受領日',
    amount: '金額',
    username: 'ユーザー名',
    role: '役割',
    date: '日付',
    author: '作成者',
    readings: '読み取り',
    status: 'ステータス',
    is_point: 'ポイント対象',
    actions: '編集',
    edit: '編集',
    publish: '発行',
    draft: 'ドラフト',
    delete: '削除',
    create: '新規作成',
    update: '更新情報',
    cancel: 'キャンセル',
    close: '閉じる',
    confirm: '確認',
    created_at: '作成日',
    description: '説明',
    category: '品目カテゴリー ',
    sku: 'プロダクト',
    price: '単価',
    sequence: 'シーケンス',
    location: '場所',
    client: 'クライアント',
    category_type: 'カテゴリーの種類',
    additional_type: ' 追加のタイプ',
    additional_type_ids: ' 追加タイプ',
    store: '店舗',
    store_type: '店舗タイプ',
    point: 'ポイント',
    start_date: '開始日',
    end_date: '終了日',
    delivery_start_date: '配達開始日 ',
    delivery_end_date: '配達終了日',
    delivery_time: '納期',
    is_chicken_option_group: 'チキン',
    key: 'キー',
    value: '値',
    ip: 'IP',
    store_code: '店舗コード',
    store_name: '店舗名',
    slip_number: '伝票番号',
    building: '建物',
    district: ' 地区',
    petty_cash: '小口現金',
    sale_cash: '売上現金',
    real_cash: '現金総額',
    variant_cash: '現金差異',
    is_open: 'オープン',
    user: 'ユーザー',
    start_time: '開始時間',
    end_time: '終了時間',
    cash_out: 'キャッシュアウト',
    street: '通り',
    phone: '電話',
    createdBy: '作成者',
    updatedBy: '更新者',
    back: '戻る',
    store_type_id: '店舗タイプ',
    keyword: 'キーワード',
    warning: '警告',
    tier: '段',
    channel: 'チャネル',
    image: '画像',
    thumbnail: 'サムネイル',
    stores: '店舗',
    store_groups: '店舗グループ',
    tax: '税',
    group_name: 'グループ名',
    min: '最小',
    max: '最大',
    full_name: '名称',
    short_name: '略称',
    value_type: '値のタイプ',
    en_route: '途中',
    available: '対応可能',
    option_group_name: 'オプショングループ名',
    default: 'デフォルト',
    additinal: '追加',
    choose_product: 'メニューを選択してください',
    products_label: 'メニューを選択してください',
    time_label: '時間',
    time: '時間',
    kds_products_label: 'KDS商品',
    your_roles_label: 'あなたの役割',
    editor_dashboard: '編集者のダッシュボード',
    see_detail: '詳細を見る',
    special: '特別',
    aggregator_code: 'アグリゲーターコード（集計コード）',
    size: 'サイズ',
    active: 'アクティブ',
    content: 'コンテンツ',
    how_to_make_image: 'イメージ作成方法',
    drivertracker_min_version: '最低動作バージョン',
    ios_customerapp_min_version: '最低動作バージョン',
    android_customerapp_min_version: '最低動作バージョン',
    file_name: ' ファイル名',
    icon: ' アイコン',
    avatar: ' アバター',
    rate: 'レート',
    tags: ' タグ',
    assign: '割当',
    detail: '詳細',
    log: 'ログ',
    main_description: '主な説明',
    product_providers: '製品プロバイダ',
    price_with_tax: '税込価格',
    no_of_review: ' レビュー番号',
    origins: ' 起源',
    parent_category: ' カテゴリ',
    sub_category: ' サブカテゴリー',
    no_data: ' データがありません',
    allowed_type_ids: ' 許可されたタイプ',
    short_description: '説明（略）',
    full_description: '説明',
    page: 'ページ',
    url: 'URL',
    app_url: 'APP URL',
    field: 'フィールド',
    show: '表示',
    tag: 'タグ',
    large_image: '画像（大）',
    middle_image: '画像（中）',
    small_image: '画像（小）',
    platform: 'プラットホーム',
    model: 'モデル',
    localizedModel: 'ローカルモデル',
    select: 'Select',
    version: 'バージョン',
    sub_title: 'サブタイトル',
    name_seller: 'Name Seller',
    validity: 'Validity',
    product_additional_information_name: '追加名称',
    discount_percentage: '割引率',
    symbol: 'シンボル',
    doctors: 'Doctors',
    country: '国',
    languages: '言語',
    designation: '指定',
    specialisation: '専門',
    qualifications: '資格',
    review: 'レビュー',
    discounted_price: '割引価格',
    faq: 'Faq',
    contact: '連絡先',
    t_and_c: 'T と C',
    disclaimer: '免責事項',
    number_of_unit: '単位数',
    additional_description: '追加情報',
    origin: '原点',
    doctor: '医師',
    refund_policy: '返金ポリシー',
    cancellation_policy: 'キャンセルポリシー',
    comment: 'コメント',
    reply: '返答',
    subject: '対象',
    body: '体',
    application: 'アプリケーション',
    campaign: 'キャンペーン',
    notify: '通知',
    booking: '予約',
    reservation_time: '予約時間',
    num_of_people: '人数',
    smoking: '喫煙',
    booking_device_uuid: '予約デバイス',
    note: '備考',
    booking_status: '予約状況',
    check_all: 'すべてチェック',
    generate: '生む',
    sku_name: 'SKU名',
    takeaway: '取り除く',
    delivery: '配達',
    eat_in: '食べる',
    aggregator: 'アグリゲーター',
    total_bill: '請求書の総数',
    total_sales: '総売上',
    avg_bill: 'AVG Bill',
    register: '登録',
    available_times: '利用可能な時間',
    available_packets: '利用可能なパケット',
    date_range: '日付範囲',
    time_range: '時間範囲',
    day_of_weeks: '週の日',
    month: '月',
    day: '日',
    week: '週次',
    ordered_quantity: '注文数量',
    advance_day: '前日',
    no: '項番',
    outlet_name: 'アウトレット名',
    outlet_code: 'アウトレットコード',
    total_online_order_delivery: '総秩序',
    total_order_swiped: '総次数スワイプ',
    driver_tracker_usage_percent: '使用法 （％）',
    sos_gt_30: 'SOS > 30',
    sos_gt_30_percent: 'SOS > 30 (%)',
    sos_lt_30: 'SOS < 30',
    sos_lt_30_percent: 'SOS < 30 (%)',
    group_tag: 'Group Tag',
    inventory: '在庫',
    is_master: 'はマスターです',
    is_alcohol: 'アルコール',
    externalCode: '外部コード',
    business_date: '暁日',
    delivery_sale: '配達販売',
    delivery_bill: '配達手形',
    takeaway_sale: 'Takeaway Sale',
    takeaway_bill: 'Takeaway Bill',
    total_sale: '総販売',
    total_bills: '総請求書',
    avo: 'AVO',
    height: '高さ（cm）',
    length: '長さ（cm）',
    weight: '重量（kg）',
    width: '幅（cm）',
    package_type: 'パッケージ型式',
    shift: 'シフト',
    select_app: 'アプリを選択',
    version_code: 'バージョンコード',
    version_name: 'バージョン名',
    app: '応用',
    kds: 'KDS',
    dispatch: 'ディスパッチ',
    taker: 'Taker',
    takeaway_monitor: 'Takeaway Monitor',
    taker_client: 'Taker Client',
    upload_file: 'ファイルをアップロードする',
    required_doctor: '必須医師',
    snap_duration: '滞在期間',
    commodity_id: '商品ID',
    transaction_date: '取引日',
    order_code: '注文コード',
    activity: 'アクティビティ',
    outlet: '出口',
    voucher_name: 'バウチャー名',
    redeem_code: 'コードを引き換える',
    total_count: '総数',
    live: 'Sync直接',
    is_driver_pool: 'ドライバプールを使用',
    organization: '組織',
    api_end_point: 'APIエンドポイント',
    name_group: '名前グループ',
    link_group: 'リンクグループ',
    slot_per_hour: 'スロット/時間',
    driver_trip_per_hour: 'ドライバーの旅/時間',
    order_number: '注文番号',
    lob: 'ロブ',
    brands: 'ブランド',
    store_categories: 'マーチャントカテゴリ',
    menu_name: 'メニュー名',
    brand: 'ブランド',
    total_redeemptions: '全体的な解読',
    is_send_S3: 'EODを送る',
    is_live: 'Live',
    advance_only: '前進',
    free: '自由',
    send: '送信',
    component: '成分',
    error: 'エラー',
    year: '年',
    display_price: '単価',
    print_name: '名前を印刷する',
    marketing_description: 'マーケティングの説明',
    view_subscription_logs: 'サブスクリプションログの表示',
    store_message_type: 'Store Mesage Type',
    assigned_stores: '割り当てられたストア',
    brand_name: 'ブランド名',
    fallback: '後退する',
    advance: '前進',
    category_code: 'カテゴリコード',
    category_name: '種別名',
    product: '製品',
    reason: 'ロス事由',
    material: '品目名称',
    cross_sell: '抱き合わせ販売',
    meta_title: 'メタのタイトル',
    meta_description: 'メタ記述',
    meta_key: 'メタキー',
    date_label_category: '日付ラベルカテゴリ',
    thaw_time: '解凍時間',
    disposal_time_after_thawing: '解凍時間',
    expiration_date_after_opening: '開封後の賞味期限',
    deadline_after_change: '変更後の締め切り',
    information: '情報',
    date_label_detail: '日付ラベルの詳細',
    lot_number: 'ロット№',
    last_modify_date: '最終更新日時',
    last_modify_by: '最終更新ユーザー',
    confirm_status: '確定スターテス',
    claim_or_returns: 'クレーム/返品',
    recorded_or_not_recorded: '計上済み/未計上',
    product_count: '個数',
    from: 'From',
    to: 'To',
    is_update_check_mark: '更新チェックマーク',
    product_group: 'SGRP',
    default_flag: 'デフォルト',
    claim: '請求',
    other: 'その他',
    unknown: '不明',
    new_issue: '不具合内容',
    claim_id: '請求ID',
    register_spot_order: 'スポット受注登録',
    due_date: '納入日',
    order_quantity: '納入個数',
    registered_date: '登録日',
    registered_by: '登録ユーザー',
    confirmed_date: '確定日',
    confirmed_by: '確定ユーザー',
    confirm_order: '確定',
    spot_order_confirm: 'スポット受注確定',
    confirmed: '確定',
    pending_confirmation: '確定待ち',
    price_date: '価格日',
    price_date_from: '価格日（開始）',
    price_date_to: '価格日（終了）',
    new_price: '新価格',
    effective_date: '有効日',
    uneffective_date: '無効日',
    price_change_title: '単価変更依頼登録',
    price_change_detail: '単価変更依頼明細',
    pricing_change_req_id: '単価変更依頼No',
    old_price: '旧単価',
    increase: '値上げ',
    decrease: '割引き',
    price_validity: '期間~',
    price_validity_from: '期間(から)',
    price_validity_to: '期間(に)',
    external_product_id: '図番',
    internal_product_name: '品名',
    chang_value: '変更額',
    change_status: '変更額',
    approved: '承認',
    declined: '却下',
    price_change_approve: '単価変更承認',
    price_change_decline: '単価変更却下',
    new_unit_price_type: '新しい単価タイプ',
    new_unit_price: '新単価',
    new_currency: '新しい通貨',
    old_unit_price_type: '旧単価タイプ',
    old_unit_price: '旧単価',
    old_currency: '古い通貨',
    updated_by: '更新者',
    defective_lot: '不具合ロット返送方法',
    defect_type: '異常内容',
    demand_organization_id: '代理店コード',
    normal: '通常受注',
    spot: 'スポット受注',
    internal_manufacturing_product_name: '商品品番名称',
    internal_manufacturing_product_id: '内部製造製品ID',
    lot_quantity: 'ロット数量',
    update_manufaturing_product_name: '製造製品名の更新',
    ship_date: '出荷日',
    shipping_destination: '届け先',
    plant: '拠点',
    quantity: '数量',
    pcs_per_box: '個 / 箱',
    number_of_boxes: '箱数',
    number_of_incomplete_boxes: '端箱数',
    order_no: '注文番号',
    select_column: 'カラムを選択'
  },
  errorLog: {
    tips: '右上のバグアイコンをクリックしてください。',
    description: '現在の管理システムは,基本的にSPAになっており,ユーザーエクスペリエンスを高めることができますが,小さなエラーが全体のページのデッドロックにつながる等,ページの問題の可能性を高める可能性があります。幸いなことに,Vueは例外を処理する方法を提供しエラーを処理したり例外を報告することができます。',
    documentation: 'ドキュメント説明'
  },
  excel: {
    export: '出力',
    selectedExport: '選択した項目を出力',
    placeholder: 'ファイル名を入力してください（デフォルトのエクセルリスト）'
  },
  zip: {
    export: '出力',
    selectedExport: '選択した項目を出力',
    placeholder: 'ファイル名を入力してください（デフォルトのエクセルリスト）'
  },
  pdf: {
    tips: 'window.printを使ってPDFをダウンロードする機能を実装しています。'
  },
  theme: {
    change: 'テーマの変更',
    documentation: 'テーマのドキュメント',
    tips: 'ヒント:ナビゲーションバーのテーマとは違い,2つのスタイルがそれぞれ違ったシナリオで動作します。詳細はドキュメントを参照してください。'
  },
  tagsView: {
    refresh: 'リフレッシュ',
    close: '閉じる',
    closeOthers: 'その他を閉じる',
    closeAll: 'すべて閉じる'
  },
  settings: {
    title: 'ページスタイルの設定',
    theme: 'テーマカラー',
    tagsView: 'オープンタグビュー',
    fixedHeader: '固定ヘッダー',
    sidebarLogo: 'サイドバーのロゴ'
  },
  message: {
    num_rows_affected: '追加された行数',
    num_rows_already_exists: 'すでに存在する行数',
    num_rows_failed: '失敗した行数',
    hint_to_select_internal_product_id: '続行するには、内部製品IDを選択してください',
    not_allow_remain: '週の合計と日別計があっていません',
    use_hyphen_instead_of_slash: '「/」の代わりに「-」を使用してください',
    please_input_correct_date_format: '正しい日付形式YYYYMMDDで日付を入力してください',
    date_input_out_of_range: '当月分のみ入力み可能です。未来日付は入力できません。',
    no_data: '対象データがありません',
    no_data_matches_filter: 'フィルタ条件に一致するデータはありません',
    add: '正常に追加されました',
    remain_is_not_allowed_negative: '日別個数が週の合計を超えています',
    show_report: 'レポートを表示してください。',
    confirm_export: 'データをエクスポートするにはボタンをクリックしてください',
    update: '正常に更新されました',
    delete: '正常に削除されました',
    import: 'Import file successfully',
    file_has_invalid_data: 'Uploaded file has invalid data. Please recheck the file',
    allow_one_file: 'Only allow upload one file',
    changeStatus: 'ステータスの変更に成功',
    tokenExpired: 'トークンの有効期限が切れています。',
    tokenInvalid: 'トークンが無効です',
    loggedOut: 'ログアウトされています',
    registered: '登録を完了しました。',
    invalidExtension: 'ファイルの拡張子が無効です。',
    confirm_delete: 'この行を削除します。よろしいですか？',
    confirmResend: '再送信してもよろしいですか？',
    confirmReUpload: '再アップロードしますか？',
    noRowSelected: '少なくとも一つ以上選択してください。',
    tokenTimeout: 'セッションの有効期限が切れています。キャンセルを押して,OKを押してログアウトしてください。',
    takeAway: 'C',
    delivery: 'D',
    eatIn: 'E',
    take_away_text: '持ち帰り',
    delivery_text: '配達',
    eatInText: 'イートイン',
    only_accept_image: 'JPEG,BMP,PNG,GIF,JPGのみが可能です',
    delete_file: 'このファイルを完全に削除します。よろしいですか？',
    delete_completed: '削除しました',
    delete_canceled: '削除をキャンセルしました',
    email_not_correct: 'Eメールが正しくありません',
    copy_successfully: '正常にコピーされました',
    confirmAddPromotionUser: 'これらの項目を追加してよろしいですか？',
    confirmDeletePromotionUser: 'これらの項目を削除してもよろしいですか？',
    confirmClone: 'コピーしてもよろしいですか？',
    confirmChangeDataBySku: 'プロダクトのデータで上書きします。よろしいですか？',
    warnSelectDeliverRole: 'You must select leasted one store and driver who created will be belong the first store',
    confirmGenerateCoupon: 'あなたはクーポンを生成したいですか？',
    inputStandardConfig: '標準設定を入力してください',
    confirmUpdate: 'あなたは確実に更新していますか？',
    updateAll: 'すべての価値を確認してください。',
    requiredSizeAndCrust: 'サイズと鳴り込みを追加してください',
    uploadKML: 'KML成功をアップロード',
    dataNotSave: 'あなたの変更は保存されません',
    saveBeforeChange: '数カウンテの前に保存したいですか？',
    name_lang_is_required: '名前：langが必要です',
    num_rows_updated: '更新された行数',
    note_approve_price_change: 'このリクエストを承認すると、製品の新しい価格変更に同意したことになります。このアクションは変更またはキャンセルできないことに注意してください.',
    note_decline_price_change: 'このリクエストを拒否すると、製品の新たな価格変更に同意したことになります。このアクションは変更またはキャンセルできないことに注意してください.',
    date_range_is_required: '日付範囲が必要です',
    product_quantity_is_exceed: '製品数量が超過しています。最大利用可能数量: ',
    product_quantity_must_greater_than_zero: '製品の数量は0より大きくなければなりません。',
    no_data_export: 'エクスポートするデータがありません',
    syncDataToAsprovaSuccess: 'データを Asprova に正常に同期する'
  },
  upload_file: {
    csv_upload_rule: 'アップロードできるのは500KB以下の csv/xlsxファイルのみです',
    txt_upload_rule: 'アップロードできるのは500KB以下の txtファイルのみです',
    drag_file_or_click: 'ここにファイルをドラッグするか',
    upload: '[ファイルを選択]して下さい',
    csv_import_demand_rule: 'csv/xlsxファイルのみアップロード可能（最大5MB）'
  },
  button: {
    add_role: '役割を追加',
    add_permission: '権限を追加',
    on_demand: '需要情報',
    jit: '直売納品',
    update: '修正',
    confirm: '確定',
    edit: '編集',
    apply: '適用',
    download: 'ダウンロード',
    downloading: 'ダウンロード中',
    add_record: 'レコード追加',
    submit: '登録',
    filter: 'フィルター',
    import: 'インポート',
    reset: 'リセット',
    save: ' 保存',
    add_menu: '新規作成',
    remove_group: 'グループを 削除',
    add_group: '新規作成',
    set_price: 'セット価格',
    set_topping: 'トッピング設定',
    set_video: '動画を設定',
    set_doctor_appointment: 'ドクターアポイントメントを設定',
    add_option_group_option: 'オプショングループ追加 ',
    add_topping_option: '新規作成',
    ok: 'OK',
    cancel: '取消',
    add: '新規作成',
    delete: '削除',
    set_schedule: 'スケジュールの設定',
    go_login: 'ログインしてください',
    select: '選択',
    option: 'オプション',
    option_group: 'オプショングループ追',
    add_combo_group_item: 'セット・チョイスグループ追加 - アイテム',
    add_variant_addon: 'アドオンバリアント ‐ 追加',
    set_kds_products: 'KDS設定',
    copy: 'コピー',
    component_bom: 'コンポーネントボム',
    set_size: 'サイズを設定',
    for_approval: ' 承認',
    published: ' 公開済',
    add_user: 'ユーザーを追加する',
    add_promotio_user: 'プロモーションユーザーを追加',
    delete_promotion_user: 'プロモーションユーザーの削除',
    put_on_break: '休憩をかける',
    put_online: 'オンラインにする',
    resend: '再送信',
    connect: '接続',
    show_review: 'レビューを表示',
    add_category: '新規作成',
    add_unit: '新規作成',
    add_option_group: '新規作成',
    add_material: '新規作成',
    add_option: '新規作成',
    add_store: '新規作成',
    add_comment: 'コメントを作成',
    add_banner: 'バナーを作成',
    add_store_group: '新規作成',
    next: '次へ',
    back: '戻る',
    add_new: '新規作成',
    add_store_cash: 'ペティキャッシュ情報登録',
    add_notify: '通知を作成',
    add_campaign: 'キャンペーンを作成',
    add_po: 'Add Purchase Order',
    add_good_reciept: 'Add Good Reciept',
    detail: '詳細',
    add_type: 'タイプを作成',
    down_format: 'フォーマットを取込',
    create: '作成',
    add_payment_method_level1: '支払い方法レベル1',
    add_payment_method: '支払い方法を追加',
    add_order_type: '注文タイプを追加',
    add_order_status: '注文ステータスを追加',
    add_promotion_master: 'プロモーションマスターを追加',
    add_promotion: 'プロモーションを追加',
    add_group_tag: 'グループタグを追加',
    add_inventory: '在庫を追加',
    adjust_status: 'ステータスを調整',
    clear_data: 'データをクリア',
    add_more: 'さらに追加',
    add_ads_video: 'ADSビデオを追加する',
    add_combo_group: 'コンボグループを追加',
    add_product: '商品を追加',
    export: '出力',
    add_zone: 'ゾーンを追加',
    add_matrix: '行列を追加',
    add_brand: 'ブランドを追加',
    add_subscription_plan: '購読計画を追加',
    add_account: 'アカウントを追加する',
    add_store_featured: '追加のリストを追加する',
    add_attachment: '写真',
    save_draft: '下書きを保存',
    add_claim: 'クレームを追加',
    add_spot_order: 'スポット受注登録',
    confirm_order: 'スポットで確定受注を送る',
    add_new_price_req: '新しい価格要求を追加',
    asprova_export: 'Asprova 連携'
  },
  user: {
    isLogin: 'ログイン',
    permisstion: '許可',
    checkAll: 'すべてにチェック',
    text_add_user: '今すぐユーザーを追加しましょう！',
    text_no_user: 'ユーザーが追加されていません',
    loginInfo: 'ログイン情報',
    role_type: '役割の種類',
    sub_role: 'サブロール',
    is_normal_role: '通常の役割',
    is_manager_role: 'マネージャーロール'
  },
  store: {
    openedAt: '開店時間',
    closedAt: '閉店時間',
    mon: '月曜',
    tue: '火曜',
    wed: '水曜',
    thu: '木曜',
    fri: '金曜',
    sat: '土曜',
    sun: '日曜',
    hol: '祝日',
    public_holiday: '祝日',
    basic_information: '基本情報',
    payment: '支払情報',
    jp_information: '提携社',
    time_setting: '営業時間設定',
    store_group: '店舗グループ',
    tier: '段',
    type: '店舗タイプ',
    code: '店舗コード',
    name: '店舗名',
    phone: '電話',
    fax: 'ファックス',
    tax_rate: '店舗名税',
    operation_hour: '営業期間',
    opening_timing: '開店のタイミング',
    aggregator_code: 'アグリゲーターコード（集計コード）',
    am_sv_code: 'AM/SV コード',
    am_sv_name: 'AM/SV 氏名',
    owner_name: 'オーナー名称',
    owner_code: 'オーナーコード',
    email: '店舗Eメールアドレス',
    desc: '説明',
    remark: '備考',
    accept: 'オンライン注文受付可',
    accept_online: 'オンライン注文受付可',
    note: '提携社に受付可否の通知が必要です',
    alcohol: '酒類販売',
    estimated_time: 'デフォルト待ち時間',
    collection: 'コレクション',
    business_period: '営業期間',
    business_hour: '営業期間',
    schedule: '日別営業時間設定',
    lunch_time: 'ランチタイム',
    time_zone: 'タイムゾーン',
    minimum_cart: '最低注文金額',
    lat: '緯度',
    long: '経度',
    location: '場所',
    disposition: '配置',
    cash_on_delivery: '代引き',
    credit_card: 'クレジットカード',
    ocbc: 'OCBC',
    payment_info: '支払情報',
    delivery_fee: '配達料',
    menu_setting: 'メニューコントロール',
    take_away_text: '持ち帰り',
    delivery_text: '配達',
    calendar: '臨時営業時間設定',
    bt_calendar: 'カレンダーを見る',
    period: '期間',
    period_count: '期間カウント',
    start_day_week: '週の始まり',
    title: '名称',
    bt_add: 'イベントを保存',
    start_date: '日付',
    end_date: '終了日',
    event: 'イベント',
    time: '時間',
    click_upload: 'クリックしてアップロード',
    note_kml: 'KMLファイルのみ受け付け',
    confirmDelete: 'イベントを削除しますか？',
    bt_public_menu: 'メインメニュー＆コンボを商品に追加',
    duration: '予約時間幅',
    search_keyword: 'タイプコードか名称',
    menu_option: '生地を取り消す',
    menu_option_group: 'サイズを取り消す',
    mixed_option_group: '生地とサイズを取り消す',
    geometry: 'ジオメトリ',
    sub_trade_zone: 'サブ商圏',
    special_business_hours: '臨時営業時間',
    special_date_close: '臨時閉店日',
    pizza_hut_day: 'ハットの日',
    order_duration: '受注期間',
    public_day: '祝日',
    stop_ordering: '受注停止',
    enter_a_location: '場所を入力してください',
    store_detail: ' 店舗詳細',
    additional_infomation: '追加情報',
    qr_code: 'QRコード',
    pin_code: 'PIN',
    contact_detail: '連絡先詳細',
    integration: '統合',
    language: '言語',
    currency: '通貨',
    tax_detail: '税詳細',
    is_inclusive_tax: '店の価格除き税？',
    owner_detail: 'オーナー詳細',
    marketing_detail: 'マーケティング詳細',
    website: ' ウェブサイト',
    tax_number: '税番号',
    tax_persentage: '税率（％）',
    my_store: ' 自店舗',
    my_store_group: ' 自店舗グループ ',
    add_store: 'データがありません',
    no_store: '店舗が追加されていません。',
    view_map: 'View Map',
    show_all_sub_zone: 'すべてのサブゾーンを表示',
    add_sub_zone: 'サブゾーンを追加',
    whatsapp_number: 'WhatsApp',
    address1: 'Address 1',
    address2: 'Address 2',
    zip_code: 'Zip Code',
    delivery_time: '納期',
    ops_delivery_text: 'Ops配信テキスト',
    breakfast: '朝ごはん',
    full_address: 'フルアドレス',
    postal_code: '郵便番号',
    fallback_banner: 'フォールバックバナー',
    address_level_0: 'アドレスレベル0',
    address_level_1: 'House No',
    address_level_2: '通り',
    address_level_3: '市',
    address_level_4: '状態',
    address_level_5: '国',
    address_level_6: '国番号',
    address_level_7: 'アドレスレベル7',
    address_level_8: 'アドレスレベル8',
    address_level_9: 'アドレスレベル9',
    set_max_order: '最大注文を設定',
    airport_mapping: '空港マッピング',
    sequence: 'シーケンス',
    no_order_forecast: '注文予測なし',
    add_order_forecast: '注文予測を追加',
    no_driver_forecast: '運転手予測なし',
    add_driver_forecast: 'ドライバ予測を追加',
    add_store_tag: '今すぐ店舗のタグを追加！',
    no_store_tag: 'Storeタグがまだ追加されていません',
    store_tag_uuids: 'store tag',
    images: '画像',
    bank_details: '銀行の詳細',
    bank_name: '銀行名',
    bank_branch_name: '支店名',
    bank_account_name: 'アカウント名',
    bank_account_number: '口座番号',
    add_store_notification: '今すぐストア通知を追加！',
    no_store_notification: 'Store通知がまだ追加されていません',
    store_notification_uuids: '店頭通知',
    opening_hours: '営業期間',
    pre_orders: '事前注文',
    pre_order_time: '前注文のリードタイム',
    delivery_options: '配送オプション',
    pre_order_settings: '予約注文設定',
    self_pickup_settings: 'ピックアップの設定',
    self_pickup: '自己ピックアップを有効にする',
    self_pickup_time: '推定予備時間',
    display_phone: '電話番号を表示',
    categories: 'レストランカテゴリー',
    food_categories: 'フードカテゴリ（最大4）',
    dietary_categories: '食事の要件（最大1）',
    pickup_address: 'アドレスをピックアップ',
    dual_shift: '二重シフト',
    opening_time: '開始時間',
    closing_time: '閉店時間',
    open_today: '今日開く',
    subscription_plan: '購読計画',
    plan: '予定',
    view_more_plans: 'もっと計画を見る',
    max_distance: '最大距離',
    store_url: '店のURL',
    no_store_featured: '店なしでおすすめ',
    add_store_featured: '店舗の追加',
    order_commission: '注文手数料',
    expired_subscription_plan: '{days}日の期限が切れる',
    commission_message: '{commices_rate} exer毎の％',
    period_ending_message: '{月}ヶ月に終了する試用期間',
    no_plan: 'ノープラン',
    commission_plan: '委員会プラン',
    commission: '手数料',
    commission_percentage: '委員会の割合',
    store_approval: '承認',
    rejected_reason: '理由を拒否',
    money_bag_warning_price: 'ポーチ限度額設定',
    text_add_config: 'Store Configを追加してください！',
    text_no_config: 'Store Configがまだ追加されました',
    campaign_information: 'レシートメッセージ設定',
    number_ticket_message: '整理券メッセージ設定',
    edit_store_address_mapping: 'ストアアドレスマッピングの編集',
    parcel: '小包',
    remove_trade_zone: '貿易ゾーンを削除',
    timezone: 'タイムゾーン',
    translation: '翻訳',
    applicable_documents: 'ライセンス,許可,および承認は販売者に適用されます。',
    store_code: 'Store Code',
    owner: 'Owner',
    owner_co: 'CO',
    owner_fc: 'FC',
    report_store_group: 'Report Store Group'
  },
  shift: {
    cashOut: '現金引き出し',
    description: '説明',
    createdDate: '作成日',
    createdBy: '作成者'
  },
  topping: {
    shortName: '略称',
    sku: 'プロダクト',
    size: 'サイズ',
    default: 'デフォルト',
    extra: 'エクストラ',
    topping: 'トッピング',
    extra_cheese: 'エクストラチーズ',
    topping_name: 'トッピング名称',
    delivery_tax: 'デリバリー税率',
    dine_in_tax: 'イートイン税率',
    take_away_tax: 'テイクアウト税率',
    no_topping_added_yet: 'トッピングが登録されていません',
    add_a_topping_now: 'トッピングを追加してください',
    add_topping: 'トッピング追加',
    short_name: '略称',
    type: 'ロス区分',
    category: '品目カテゴリー ',
    sequence: 'シーケンス',
    description: '説明',
    topping_detail: 'トッピング詳細',
    status: 'ステータス',
    desposition_pricing: '価格設定',
    isAfterBaking: '焼く後のもの',
    isAttachedCondiments: '付属の調味料',
    relatedItems: '関連商品'
  },
  store_group: {
    store_group_detail: ' 店舗グループ詳細',
    total_store: '総店舗数',
    store_available: '利用可能な店舗',
    store_added: '追加された店舗',
    add_store_group: 'データがありません',
    no_store_group: '店舗グループが追加されていません。'
  },
  address: {
    outlet_code: '店舗コード',
    level1: 'レベル１',
    level2: 'レベル２',
    level3: 'レベル３',
    level4: 'レベル４',
    level5: 'レベル５',
    level6: 'レベル６',
    level7: 'レベル７',
    from_date: '開始日',
    to_date: '現在まで',
    zip: '郵便番号',
    address_code: '住所コード',
    outlet: '店舗',
    from: 'から',
    to: 'に'
  },
  purchase_order: {
    amount: '金額',
    amount_receive: '受領金額',
    supplier_code: '相手先コード',
    supplier_name: '相手先名称',
    supplier_contact: 'サプライヤー連絡先'
  },
  purchase_order_detail: {
    purchase_order_uuid: 'POコード',
    receive_quantity: '受取数量',
    receive_price: '受領価格',
    receive_amount: '受領金額',
    is_cancel: 'キャンセル済',
    is_return: '返却',
    detail: '詳細',
    keyword: 'キーワード'
  },
  material_master: {
    selflife_number: 'シェルフライフ',
    selflife_number_type: 'シェルフライフタイプ',
    inventory_check: '在庫確認',
    inventory_uom: '在庫単位',
    external_refence_code: '外部参照コード',
    inventory_quantity: '在庫数量',
    storage_condition_category: 'ストレージ条件',
    temperature_management_category: '温度管理カテゴリ',
    consumption_time_limit: '消費期限',
    unfreeze_time: '解凍時間（分）',
    min_time: '最低時間',
    time_after_opening: '開封後時間',
    inventory_applied: '在庫適用',
    purchasing_unit: '購買単位',
    stocking_unit: '在庫単位',
    uses_unit: '使用単位',
    max_number_of_order: '最大注文数',
    recipe: '仕入',
    equal: '等しい',
    co: ' CO',
    fc: ' FC',
    account: 'アカウント',
    order_period: '受注期間',
    delivery_period: 'デリバリー',
    condition: '状態',
    cost_management: 'コスト管理',
    purchase_price: '購入金額',
    uom: '単位',
    logistic_information: 'ロジスティック情報',
    inventory_management: '在庫管理',
    detail_information: '詳細情報',
    general_information: '一般情報',
    gram: 'グラム',
    case: 'ケース',
    bag: '袋',
    basic_detail: '基本情報',
    more_detail: '詳細へ',
    max_no_of_order: '最大注文数',
    type_material: '棚卸区分'
  },
  master_material_category: {
    master_material_category_name: '材料カテゴリー'
  },
  customer: {
    is_blocked: 'ブロック',
    register_date: '登録日',
    register_channel: '登録チャネル',
    first_order_date: '初回注文日',
    first_order_channel: '初回注文チャネル',
    amount: '金額',
    bill: 'Bill',
    level_point: 'レベルポイント',
    reward_type: '特典のタイプ',
    reward_point: '特典ポイント',
    point: 'ポイント',
    reward_status: '報酬ステータス',
    reward_success: '成功',
    reward_waiting: '待機中',
    reward_cancel: 'キャンセル',
    reward_use: '使用します',
    reward_earn: '獲る',
    history: '履歴',
    gender: '性別',
    date_time: '日付',
    male: ' 男性',
    female: ' 女性',
    average: 'その他',
    phone: '電話番号',
    birthday: '生年月日',
    note: '備考',
    address: '住所',
    customer_address: '顧客住所',
    view_customer: '顧客表示',
    first_name: '名前',
    last_name: '名字',
    alternative_phone: '予備電話番号',
    street: '通り',
    trade_zone_name: '商圏名',
    trade_zone_lat: '商圏緯度',
    trade_zone_long: '商圏経度',
    disposition: '配置',
    store_name: '店舗名',
    is_headquarter: '本社',
    headquarter_code: '本部のコード'
  },
  validate: {
    order_type_required: 'Order Type is requried',
    pin_code: 'Pin Code is reqired and type is Number',
    limit_quantity_cart: '制限数量カートが必要で,数字',
    platforms: 'プラットフォームは必須です',
    payment_value: 'valueは番号 です。',
    point: 'ポイントは必須です。数字で入力してください。',
    amount: '金額は必須です。数字で入力してください。',
    order_type: 'セグメントを入力してください',
    bill: 'billが必要です',
    store: '店舗を入力してください ',
    reward_type: '特典タイプを入力してください',
    client_required: 'クライアントを入力して下さい',
    option_group: 'オプショングループではオプション設定が必要です',
    is_required: 'が必要です',
    fullname: 'フルネームで入力して下さい',
    option_group_uuid: 'オプショングループを入力して下さい',
    option_uuid: 'オプションを入力して下さい',
    tax_rate: '消費税を入力して下さい',
    sku: 'SKUを入力して下さい',
    store_group_uuids: '店舗グループが必要です',
    sku_header: 'プロダクトヘッダーが必要です',
    name: '名前が必要です',
    short_name_required: '名前が必要です',
    type_ids: '種類を入力してください',
    category_uuid: 'カテゴリーを入力して下さい',
    code: 'コードを入力して下さい',
    duration: '期間を入力して下さい',
    waiting_time: 'プロミスタイムを入力して下さい',
    min_cart: '最低注文金額を入力してください',
    password: 'パスワードを入力してください',
    password_again: 'パスワードを再度入力してください',
    password_dont_match: 'パスワードが一致しません',
    option_empty: 'オプショングループを入力してください',
    ip_required: 'IPを入力してください',
    store_required: '店舗を入力してください',
    pin_code_required: 'Pin Code is required',
    user_type_required: 'User Type is required',
    code_length: 'コードは1桁以上20桁以内で入力してください。',
    selflife_number: '個人番号を入力してください。',
    selflife_number_type: '個人番号種別を入力してください',
    external_refence_code: '外部参照コードを入力してください',
    uom_uuid: 'UOMを入力してください',
    master_material_category_name: '材料カテゴリーを入力してください',
    uom_from_uuid: 'UOM Fromを入力してください',
    uom_to_uuid: 'UOM Toを入力してください',
    conversion: '変換を入力してください',
    material_uuid: '材料を入力してください',
    component_uuid: 'コンポーネントを入力してください',
    start_date: '開始日を入力してください',
    end_date: '終了日を入力してください',
    username_required: 'ユーザー名を入力してください',
    password_required: 'パスワードを入力してください',
    product_uuid: 'メニューを入力してください',
    time_required: '時間を入力してください',
    email: 'メールアドレスを入力して下さい',
    email_required: 'メールアドレスを入力してください',
    phone_required: '電話番号を入力して下さい',
    display_phone_required: 'Display phone is required',
    onwer_name_required: '',
    birthday_required: '誕生日を入力して下さい',
    level1_required: 'レベル１を入力して下さい',
    level2_required: 'レベル２を入力して下さい',
    level3_required: 'レベル３を入力して下さい',
    address_code_required: '住所コードを入力して下さい',
    permissions_required: '権限を入力して下さい',
    tier_required: '階層を入力して下さい',
    pool_required: 'プールが必要です',
    store_type_required: ' 店舗タイプを入力して下さい',
    store_group_required: '店舗グループを入力して下さい',
    operation_hours_required: ' 営業時間を入力して下さい',
    location_required: ' 場所を入力して下さい',
    lat_required: ' 緯度を入力して下さい',
    long_required: '経度を入力して下さい',
    disposition_required: '配置を入力して下さい',
    timezone_required: 'タイムゾーンを入力して下さい',
    description_required: '説明を入力して下さい',
    type_uuid: '種類を入力してください',
    bank_uuid: '銀行を入力してください',
    group_uuid: ' グループを入力してください',
    date_required: '日付を入力してください',
    price_channel: 'チャネルを入力して下さい',
    price_tier: '階層を設定してください',
    price_partner: 'パートナーを入力して下さい',
    price: '価格を入力して下さい',
    kana_name_required: 'カナで入力して下さい',
    kanji_name_required: '漢字で入力して下さい',
    gender_required: '性別を選択して下さい',
    birth_date_required: '生年月日を入力して下さい',
    contract_title_required: '契約内容を入力して下さい',
    contract_period_required: '契約期間を入力して下さい',
    nationality_required: '国籍を入力して下さい',
    position_required: 'レーバー区分を選択して下さい',
    rank_required: 'ランクを選択して下さい',
    additional_type_ids: '追加のタイプを入力して下さい',
    name_required: '名前を入力して下さい',
    kds_make: 'メイク台を選択して下さい',
    kds_cut: 'パウチ台を選択して下さい',
    make_time: 'メイクタイムを入力して下さい',
    cut_time: 'カットタイムを入力して下さい',
    code_required: 'コードを入力して下さい',
    size_required: 'サイズを入力して下さい',
    crust_required: '生地を入力して下さい',
    roles_required: '役割を入力して下さい',
    evidence_number_required: 'エビデンス番号を入力してください',
    payment_type_required: 'お支払い方法を入力してください',
    option_required: 'サイズを入力して下さい',
    option_group_required: '生地を入力してください',
    material_required: '材料を入力してください',
    campaign_required: 'キャンペーンを入力してください',
    application_required: 'アプリケーションを入力してください',
    short_name: '略称を入力してください',
    sub_price: 'Sub Priceが必要です',
    userType_required: 'ユーザータイプは必須です',
    page_required: 'ページが必要です',
    url_required: 'URLが必要です',
    field_required: 'フィールドは必須項目です',
    start_date_required: '開始日を入力してください',
    end_date_required: '終了日を入力してください',
    first_name_required: '姓は必須です',
    last_name_required: '姓が必要です',
    hurigana_first_name_required: 'あくまなず名は必須です',
    hurigana_last_name_required: '去形の姓は必須です',
    topping_required: 'トッピングが必要です',
    from_store_required: '店から必須です',
    to_store_required: '保存する必要があります',
    type_required: 'タイプは必須です',
    slip_number_required: 'スリップ数が必要です',
    product_required: '製品が必要です',
    quantity_required: '数量は必要です',
    reason_required: '理由は必要です',
    kanjiFirstName_required: '漢字の姓',
    kanjiLastName_required: '漢字の姓は必須です',
    kanaFirstName_required: 'カナファーストネームは必須です',
    kanaLastName_required: 'カナ姓は必須です',
    bloodType_required: '血液型は必要です',
    telNumber_required: 'TEL番号が必要です',
    sending_time: '送信時間が必要です',
    account_type_uuid_required: 'アカウントの種類は必須です',
    employee_required: '従業員が必要です',
    registrationDate_required: '登録日は必須です',
    zipCode_required: '郵便番号が必要です',
    address1_required: 'アドレス1が必要です',
    address2_required: 'アドレス2が必要です',
    externalCode_required: '外部コードが必要です',
    bankCode_required: '銀行コードが必要です',
    branchCode_required: 'ブランチコードは必須です',
    accountNumber_required: 'アカウント番号が必要です',
    accountName_required: 'アカウント名が必要です',
    component_required: 'コンポーネントが必要です',
    title_required: 'タイトルが必要です',
    content_required: 'コンテンツが必要です',
    image: '画像が必要です',
    gallery: 'ギャラリーが必要です',
    sub_category: 'サブカテゴリが必要です',
    upload_file_required: 'Upload Fileが必須です',
    display_type_required: '表示タイプが必要です',
    app_required: 'アプリは必須です',
    kds_required: 'KDSが必要です',
    dispatch_required: '派遣は必要です',
    taker_required: 'Takerが必要です',
    takeaway_monitor_required: 'Takeaway Monitorが必要です',
    prices: '価格は必要です',
    height: '高さは必須です',
    length: '長さが必要です',
    weight: '体重が必要です',
    width: '幅は必要です',
    options: 'オプションが必要です',
    tel_number: 'TEL番号は数字でなければならない',
    em_tel_number: '緊急電話番号は数字でなければならない',
    mobile_tel_number: 'モバイルTEL番号は数字でなければなりません',
    driver_license_number: 'ドライバライセンス番号は数字でなければなりません',
    code_number: 'コードは数字でなければなりません',
    zip_code_number: '郵便番号は数字でなければなりません',
    bank_code_number: '銀行コードは数字でなければならない',
    bank_branch_code_number: 'ブランチコードは数字でなければなりません',
    bank_account_number: 'アカウント番号は数字でなければなりません ',
    emTelNumber_required: '緊急TER番号が必要です',
    radius: '半径が必要です',
    postal_code: '郵便番号が必要です',
    origin_point: '起源が必要です',
    destination_point: '宛先ポイントが必要です',
    slot_per_hour: '1時間あたりのスロットが必要です',
    driver_trip_per_hour: '1時間あたりの運転手旅行は必要です',
    user_required: 'ユーザーは必須です',
    current_password: '現在のパスワードを入力してください',
    promo_type: 'プロモーションタイプは必須です',
    discount_value: '割引価値が必要です',
    required: '必須',
    method_required: 'メソッドが必要です',
    distribution_date_required: '配布日付は必須です',
    distributor_required: '販売代理店が必要です',
    commission_rate: 'コミッション率が必要です',
    status: 'ステータスが必要です',
    business_name: 'ビジネス名が必要です',
    brand_name: 'ブランド名は必須です',
    business_number: '営業番号',
    phone: '電話が必要です',
    display_phone: '表示電話が必要です',
    account_name: 'アカウント名が必要です',
    account_number: 'アカウント番号が必要です',
    unit: '単位が必要です',
    city: '都市は必要です',
    currency: '通貨は必要です',
    postcode: '郵便番号は必須です',
    street: '通りが必要です',
    state: '州が必要です',
    type: 'タイプは必須です',
    start_time: '開始時間が必要です',
    end_time: '終了時刻が必要です'
  },
  payment_method: {
    is_show_at_menu_order: 'POSに表示',
    bank: '銀行名',
    is_card_number: '口座番号',
    is_remark: '備考',
    is_approval_code: '承認コード',
    is_active: 'アクティブ',
    group: ' グループ',
    level1: 'Level 1',
    payment_method_detail: '支払方法の詳細',
    no_payment_method: '支払い方法なし',
    add_payment_method: '支払い方法を追加',
    is_change: '変わって',
    cash_count: '現金数で示す',
    input_cash_count: '入力現金数'
  },
  component: {
    component_detail: 'コンポーネント詳細',
    component_code: 'レーバーコード',
    component_name: '名称',
    component_short_name: '略称',
    component_status: 'ステータス',
    size_and_material: '構成',
    quantity: '使用量',
    price: '単価',
    material_name: '材料名',
    s: 'S',
    m: 'M',
    l: 'L',
    unit_name: '単位名',
    add_new: '新規追加'
  },
  order: {
    no: '項番',
    del_vs_quote_time: 'SOS<30',
    order_confirm_csc: '注文確認CSC',
    order_send_store: '店舗へ注文送信',
    store_accept_order: '店舗注文を受ける',
    cooking: '調理',
    start_delivery: '配達開始',
    swipe_done: 'スワイプ完了',
    encashment: '現金化',
    void: '無効',
    dispatch: '配達中',
    finish: '完了時間',
    cooked: '調理済',
    driver_tracker: 'ドライバートラッカーの受け入れ',
    id: 'ID',
    code: '注文番号',
    quote_time: '告知時間',
    type: '注文タイプ',
    type_and_device: 'ロス区分/チャンネル',
    status: '注文ステータス',
    date: '注文日',
    start_date: '開始日',
    end_date: '終了日',
    sale_amount: '売上高',
    sub_total: 'サブトータル',
    sub_delivery_fee: '配送料金',
    address_delivery: '配達住所',
    tax_value: '税額',
    donation: '寄付金',
    action: '各種操作',
    payment_method: '支払方法',
    payment_amount: '支払額',
    variance_status: '不一致の状態',
    view_order: 'オーダー確認',
    edit_order: 'オーダー編集',
    view_payment: '支払確認',
    view_order_detail: 'オーダー内容詳細確認',
    view_utilization_detail: '使用率の詳細を表示',
    view_delivery: '配達確認',
    view_log_status: 'ログの状態確認',
    pulcode: 'プルコード',
    price: '単価',
    sub_price: 'サブ価格',
    menu_name: 'メニュー名',
    menu_price: 'メニュー価格',
    add_on_price: '割増価格',
    value: '値',
    change: '変更',
    total_payment: '支払総額',
    new_status: '新しいステータス',
    reason: 'ロス事由',
    device: '受注端末',
    timestamp: 'タイムスタンプ',
    passed: '合格',
    variance_payment: '分割支払い',
    order_number: ' 注文番号',
    channel: '受注形態',
    order_type: '受注区分',
    order_date: '受注日時',
    kds_time: '店内時間',
    cooked_time: '調理時間',
    delivery_start_time: 'デリバリータイム',
    customer_recieved: '受取時間',
    encashment_time: '清算時間',
    sos_achived: 'SOS',
    upper_text: '上部テキスト',
    choose_agent: ' エージェントを選択してください',
    confirm_by_agent: ' エージェントによって確認されました',
    time_order: '受注日時',
    user_type_guest: 'ゲスト',
    user_type_member: 'メンバー',
    reject_status: '拒否理由',
    confirm_status: 'SMS送信済み',
    duration_time: '継続時間',
    user_type: 'ユーザータイプ',
    keyword: 'キーワード',
    current_point: '現在のポイント',
    earn_point: '獲得ポイント',
    redeemed_point: '償還ポイント',
    request: 'リクエスト',
    detail_Address: '住所と地図',
    past_order_history: '過去の注文履歴',
    delivering_time: '時間の配達',
    delivered_time: '納入された時間',
    note: '注意',
    adjust_status_delivery: 'ステータス配信を調整',
    status_delivery: 'ステータス配信',
    ready_to_ship: '出荷する準備ができて',
    tracking_url: 'トラック順>',
    phone: '電話番号',
    promise_date: 'お届け/お渡し予定日時',
    order_no: '注文番号',
    packing_slip: '梱包スリップ',
    invoice: '請求書',
    invoice_and_payslip: 'スリップと請求書',
    consignment_note: '委託注釈',
    invoice_date: '請求書の日付',
    invoice_no: '請求書番号',
    payment_type: '払いの種類',
    shipping_address: 'お届け先の住所',
    billing_address: '請求先住所',
    item_description: 'アイテム説明',
    unit_price: '単価',
    discount: 'ディスカウント',
    total: '合計',
    store_status: 'Store Status',
    is_invoice: '請求書',
    tracking_number: '追跡番号',
    detail: '詳細'
  },
  sku: {
    sku: 'プロダクト',
    sku_header: 'プロダクトヘッダー',
    brand_uuid: 'ブランド',
    category_uuid: 'プロダクトカテゴリ',
    food_id: '食品ID'
  },
  menu: {
    topping: 'トッピング',
    product: 'メニュー',
    toppings: 'トッピング',
    products: 'メニュー',
    category: '品目カテゴリー ',
    option_group: 'サイズ',
    option: '生地',
    option_groups: 'サイズ',
    options: '生地',
    select_option: '生地の選択',
    add_category: '新規作成',
    no_category: 'データがありません',
    category_detail: 'カテゴリの詳細',
    no_option_group: 'サイズが選択されていません',
    add_option_group: 'データがありません',
    add_comment: 'コメントを入力してください',
    no_comment: 'コメントが入力されていません',
    add_banner: 'バナーを登録してください',
    no_banner: 'バナーが登録されていません',
    no_option: '生地は追加されていません。',
    add_option: 'データがありません',
    add_origin: '今から起源を追加！',
    no_origin: '起源はまだ追加されていません',
    no_order: 'まだ順序が追加されていません',
    add_tag: '今タグを追加してください',
    no_tag: 'データがありません',
    add_country: '今の国を追加してください',
    no_country: 'いかなる国は追加されていません',
    add_group_tag: '今すぐグループタグを追加する',
    no_group_tag: 'グループタグはまだ追加されていません',
    no_group_type: 'グループタイプはまだ追加されていません',
    add_group_type: '今すぐグループの種類を追加してください',
    add_inventory: '今すぐ在庫を追加！',
    no_inventory: '在庫がまだ追加されていません',
    add_doctor: '今医者を追加してください',
    no_doctor: '医師はまだ追加されていません',
    vailable_choices: '選択可能な選択肢',
    your_menu: 'メニュー',
    is_show_website: 'Webサイトを表示',
    no_product_half_and_half: '生産は半分と半分',
    add_new: '新しく追加する',
    set_quantity: '数量設定',
    add_combo_group: '今すぐコンボグループを追加！',
    no_combo_group: 'Combo Groupはまだ追加されました',
    add_product: '今商品を追加してください！',
    no_product: '製品がまだ追加されていません',
    add_brand: '今ブランドを追加する',
    no_brand: 'まだ追加されていません',
    package_information: 'パッケージ情報'
  },
  product: {
    other_info: 'Advance Info',
    attachment_product: '別添品',
    product_name: '名称',
    menu_code: 'レーバーコード',
    default_topping: 'デフォルトトッピング',
    product_topping: '製品トッピング',
    topping: 'トッピング',
    max_default_topping: 'デフォルトトッピング上限',
    max_additional_topping: '追加トッピング上限',
    add_crust_size: '追加',
    auto_cook: '調理済商品',
    target: '目標',
    url: ' URL',
    hide_from_ui: 'OT非表示',
    half_and_half: 'ハーフ＆ハーフ',
    discount: '割引',
    only_lunch: 'ランチのみ',
    hut_day: 'ハットの日',
    video: '動画',
    normal: 'ピザ以外',
    pizza: 'ピザ',
    combo: 'セット・チョイス',
    special: '特注',
    doctor_appointment: '医師の予約',
    addon: 'アドオン',
    donation: '寄付',
    extra_chilli: 'チリソース追加',
    select_add_into_group: 'グループに追加してください',
    product_detail: '詳細',
    maximum_additional_topping: '追加トッピング限度',
    maximum_minus_topping: 'マイナストッピング限度',
    draft: 'ドラフト保存',
    forApproval: '承認済',
    pulished: '公開済',
    marketing_price: 'マーケティング価格',
    kds_make: 'メイク台',
    kds_cut: 'カット台',
    make_time: 'メイクタイム',
    cut_time: 'カットタイム',
    image: '画像',
    thumbnail: 'サムネイル',
    how_to_make: '作り方',
    priceWithTax: '税込価格',
    guide: 'ガイド',
    size_and_crust: 'サイズと鳴り',
    is_salad: 'サラダ',
    addMore: '追加',
    addMultipleLots: 'Add Multiple Lots'
  },
  content: {
    content_category: 'コンテンツカテゴリ'
  },
  store_target: {
    store_code: ' 店舗コード',
    store_name: ' 店舗名',
    target_sale: '売上目標',
    rate: '比率',
    result: '結果',
    month: '月'
  },
  report: {
    order_type: '注文タイプ',
    order_status: '注文の状況',
    channel: 'チャネル',
    order_number: '注文番号',
    total_count: '総数',
    total_amount: '合計金額',
    activity: 'アクティビティ'
  },
  popup: {
    remove_option: 'このオプションを本当に削除しますか？',
    remove_group: 'このグループを本当に削除しますか？',
    remove_product: 'このメニューを本当に削除しますか？',
    warning: '警告',
    error: 'エラー',
    MoneyBagLog: 'マネーバッグログ',
    notify: '通知',
    export: 'データのエクスポート',
    select_date: '日付範囲を選択',
    copy_to_clip: 'クリップボードの成功へのコピー'
  },
  err: {
    submit: 'エラー発生!!',
    checkQRCodeUser: '1つの店舗は1人のユーザーに属しているQRを持っています '
  },
  defined: {
    active: 'アクティブ',
    inactive: '非アクティブ',
    all: 'すべて',
    show: '表示',
    not_show: '非表示'
  },
  dialog: {
    detail_event: 'イベント詳細',
    warn_before_close: 'この子画面を閉じてもよろしいですか？',
    import_manufacturing_product: '輸入製造品',
    import_demand: 'オンデマンド製品を輸入する',
    manufacturing_export: '生産計画ASPROVA連携',
    manufacturing_export_note: '現状の生産計画を基にファイルを生成してASPROVA に連携します。宜しいでしょうか？'
  },
  tooltip: {
    capslock: 'CapsLockがオンになっています'
  },
  clipboard: {
    please_input: '入力してください',
    use_clipboard_by_v_directive: 'v-directiveでクリップボードを使用する',
    use_clipboard_directly: '直接クリップボードを使用する'
  },
  promotion: {
    promotion_master: 'プロモーションマスター',
    promotion: 'プロモーション',
    discount_type: 'ディスカウントタイプ',
    discount_value: 'ディスカウント料金',
    auto_apply: '自動適用',
    multiple: '複数適用可',
    include_stores: '店舗を含む',
    exclude_stores: '除外店舗',
    limit: '限定',
    total_use: '総使用量',
    min_purchase: '最低注文金額',
    category_uuid: '品目カテゴリー ',
    coupon: 'クーポン',
    user_available: '利用可',
    promotion_user: 'プロモーションユーザー',
    is_use: '使用可',
    basic_details: '基本情報',
    active_period: '使用可能期間',
    time_setting: '時間設定',
    condition: '状態',
    product: 'メニュー',
    number_of_order: 'オーダー数',
    no_coupon: 'クーポンが登録されていません',
    add_coupon: 'データがありません',
    upload_user: '対象顧客リストをアップロードしてください',
    upload_file_csv: 'CSVファイルアップロード',
    customer_name: '顧客名称',
    child_promotion_master: '児童推進マスター',
    public: '公開',
    platform: 'プラットフォーム',
    generate_coupon: 'クーポンを生成',
    no_promotion_master: '昇進マスターなし',
    add_promotion_master: 'プロモーションマスターを追加',
    exclude_store: '除外',
    include_store: '店を含む',
    no_promotion: 'プロモーションなし',
    add_promotion: 'プロモーションを追加',
    auto_give: '自動与える',
    adjust_status: 'ステータスを調整',
    is_one_time: '一度だけ',
    is_for_employee: '従業員のためのもの',
    limit_value: '制限値',
    prefix_apply_auto: 'プレフィックスが自動を適用',
    limit_apply_auto: 'limitが自動的に適用されます'
  },
  doctor: 'ドクター',
  review: 'レビュー',
  your_browser_does_not_support_video_playback: ' あなたのブラウザは動画再生をサポートしていません。',
  picker: {
    today: '今日',
    yesterday: '前日',
    this_month: '今月',
    this_year: '今年',
    last_week: '先週',
    last_month: '先月'
  },
  store_cash: {
    name: '摘要名',
    type_date: 'Type Date',
    store_cash: '店舗現金残高',
    evidence_number: '証憑番号',
    payment_type: 'ペティ口座区分',
    cash_payment: '支払関連',
    bank_payment: '口座関連',
    deposit: '入金',
    withdrawal: '出金',
    deposit_amount: '入金額',
    withdrawal_amount: '出金額',
    receipt_date: '領収書日付',
    registration_date: '登録日',
    account_type: 'アカウントの種類',
    account_name: 'アカウント名',
    add_store_cash: 'ストアキャッシュを作成',
    no_store_cash: 'データありません',
    driver: 'ドライバー',
    add_money_bag: 'お金袋を追加',
    no_money_bag: 'お金袋なし！',
    type_store_cash: '入出金区分'
  },
  appointment: {
    appointment: '予約',
    date: '日付',
    time_slot: 'タイムスロット',
    total_slot: '総スロット',
    available_slot: '利用可能なスロット',
    addMore: 'さらに追加',
    recalculate: '再計算',
    save: 'セーブ'
  },
  employee: {
    basic_details: '基本情報',
    company_name: '会社名',
    gender: '性別',
    birth_date: '生年月日',
    male: '男',
    female: '女',
    unknown: 'その他',
    address_contact_details: '住所・連絡先情報',
    cert_zip_code: '郵便番号',
    cert_address1: '都道府県',
    cert_address2: '住所',
    zip_code: '郵便番号',
    address1: '都道府県',
    address2: '住所',
    mobile_tel_number: '携帯',
    tel_number: '電話番号',
    em_tel_number: '緊急連絡先',
    bank_information: '振込先',
    bank_name: '銀行名',
    bank_branch_name: '支店名',
    bank_account_name: '名義人',
    bank_account_number: '口座番号',
    driver_license_information: '運転免許証情報',
    driver_license_number: '免許証番号',
    driver_license_expire_date: '免許証有効期限',
    other_information: 'その他',
    is_temp_worker: 'ヘルプ',
    is_provisional_contract: '仮登録',
    education: '学校・学部・学年',
    retirement_date: '抹消年月日',
    occupation: '職業',
    nationality_category: '国籍',
    visa_status: '在留資格',
    alien_certificate_number: '在留カード等番号',
    period_of_stay: '在留期限',
    change_status: 'ステータスの変更',
    blood_type: '血液型',
    note: 'メモ',
    contract_title: '契約項目',
    contract_period: '契約期間情報',
    position: 'レーバー区分',
    rank: 'ランク',
    nationality: '国籍',
    mon: '月曜日',
    tue: '火曜日',
    wed: '水曜日',
    thu: '木曜日',
    fri: '金曜日',
    sat: '土曜日',
    sun: '日曜日',
    employee_information: '従業員情報',
    salary_and_allowance_information: '給与・手当情報',
    base_hourly_pay: '基本時給',
    allowance: '手当',
    overtime: '残業',
    driving: 'ドライバー',
    holiday: '祝日',
    saturday: '土曜',
    sunday: '日曜',
    commuting: '交通費',
    flyer_posting: 'ポスティング',
    shift_manager: 'シフトマネージャー',
    midnight_shift: '深夜勤務',
    other: 'その他',
    weekly_working_day: '週間勤務日数',
    weekly_working_hour: '週間勤務時間',
    work_time: '勤務時間',
    rest_hour: '休憩時間',
    working_hour: '勤務時間',
    contract: '契約',
    period: '期間',
    em_tel_name: '緊急連絡先',
    bank_details: '銀行情報',
    driver_license_details: '運転免許証情報',
    additional_information: '追加情報',
    visa_information: 'ビザ情報',
    contracts: '契約書',
    new_contract: '新規契約',
    accounts: 'アカウント',
    password: 'パスワード',
    confirm_password: 'パスワードの確認',
    roles: 'ロール',
    new_user_login: '新規ユーザーログイン',
    day: '日',
    text_add_employee: '従業員を登録してください',
    text_no_employee: 'データがありません.',
    upload_driver_license_btn: '運転免許証のアップロード',
    upload_driver_license_tip: '運転免許証の表と裏の写真をアップロード',
    contract_form: {
      overtime: '残業手当',
      driving: 'ドライバー手当',
      holiday: '祝日手当',
      saturday: '土曜手当',
      sunday: '日曜手当',
      commuting: '1日の交通費（往復）',
      flyer_posting: 'ポスティング手当',
      shift_manager: 'シフトマネージャー手当',
      midnight_shift: '深夜勤務手当',
      other: 'その他手当'
    },
    bank_code: '銀行コード',
    bank_branch_code: '支店コード',
    text_add_contract: '契約を登録してください',
    text_no_contract: 'データがありません',
    text_add_user_login: '新規ユーザを登録',
    text_no_user_login: 'データがありません.',
    first_name: 'ファーストネーム',
    last_name: '苗字',
    hurigana_first_name: 'あくまな名',
    hurigana_last_name: '八川姓',
    is_retired: '引退している',
    age: '年齢',
    edit_contract: '契約を編集',
    createNewContract: '新しい契約を作成',
    updateCurrentContract: '現契約内容変更',
    createNextContract: '継続契約更新',
    updateNextContract: '次の契約を更新',
    retirementDate: '退職年月日',
    passwordExpiredDate: 'パスワードの期限切れ',
    driverDisplay: 'ドライバ表示',
    fullName: 'フルネーム',
    driverLicense: '免許証情報',
    certificate: '資格',
    kanjiName: '氏名',
    kanaName: 'ｶﾅ',
    expireDate: '有効期限',
    yearOld: '歳',
    fullTime: '社員',
    partTime: 'クルー',
    employeeType: '従業員タイプ',
    assignToStore: 'storeに割り当てます',
    registrationDate: 'ヘルプ登録日',
    detailInformation: '詳細情報',
    text_add_temporaryAssignment: '今すぐ一時的な割り当てを追加！',
    text_no_temporaryAssignment: '一時的な割り当てはまだ追加されていません',
    role_parent: 'レーバー区分',
    role_child: 'レーバー権限',
    add_role: '役割を追加',
    text_add_standardConfiguration: '標準設定を追加する',
    text_no_standardConfiguration: '標準設定はまだ追加されていません。',
    salaryAndOvertime: '給料と残業',
    minimumSalary: '最低時給',
    realWorkTime: '本当の仕事時間',
    nightWorkTime: '夜間勤務時間',
    overtimeRate: '残業割増率',
    nightRate: '深夜割増率',
    settingToLaborCheck: '労働チェチェの設定',
    breakTime: '休憩時間',
    workingTime: '労働時間',
    minBreakTime: '最小休憩時間',
    dailyWorkTimeLimit: '毎日の仕事の時間制限',
    workTimeRange: '作業時間範囲',
    teenagerUnder18: '年少者（18゚）',
    restTime: '休憩時間',
    ownStore: '所属店舗',
    retirement_flag: '抹消設定',
    address: '住所情報',
    phone_contact: '連絡先情報',
    current_contract: '現契約条項',
    next_contract: '継続更新後契約条項',
    bank_account_type: '口座区分',
    bank_account_type_normal: '普通',
    bank_account_type_current: '当座',
    thirdParty: '派遣フラグ',
    export: '勤務実績一覧出力',
    code: 'レーバーコード',
    type: 'レーバー区分',
    create_employee: 'レーバー登録',
    text_no_contract_period: '契約期間はまだ追加されていません。',
    text_add_contract_period: '今すぐ契約期間を追加！',
    internal_certificate: 'DC認定',
    help_end_flg: 'ヘルプ終了設定',
    help_end_date: 'ヘルプ終了日'
  },
  inventory: {
    not_confirmed: '未確認',
    all: 'すべて',
    name: '名称',
    transfer: '移動',
    action: '入力行追加',
    category_detail: '基本情報',
    no_category_added_yet: 'カテゴリは追加されていません',
    add_category_now: 'データがありません',
    unit_detail: '基本情報',
    no_unit: '単位は追加されていません。',
    add_unit: 'データがありません',
    basic_details: '基本情報',
    size_and_crust: '構成',
    add_new: '新規作成',
    add_new_version: 'バージョン追加',
    add_version: 'バージョン追加',
    pricing: 'スペック',
    crust: '生地',
    size: 'サイズ',
    version_name: 'バージョン名',
    version_code: 'バージョンコード',
    type: 'ロス区分',
    material_component: '品目名称/コンポーネント',
    qty: '数量',
    cost: 'コスト',
    unit: '単位',
    add_item: '新規作成',
    text_add_item: 'データがありません',
    text_no_item: 'プロダクトは追加されていません。',
    no_material: '材料は追加されていません',
    add_material: '材料を追加してください',
    add_component: '新規作成',
    material: '品目名称',
    item: '項目',
    size_and_material: 'サイズと材料',
    text_add_component: 'データがありません',
    text_no_component: '材料は追加されていません',
    normal_product: 'ピザ以外のメニュー',
    add_po: '購入注文を追加',
    no_po: '購入注文はまだありません',
    add_good_reciept: '良い繰り返しを追加',
    no_good_reciept: 'まだ良い録音はありません',
    line_nummber: '行番号',
    date_of_good_reciept: '入出庫日',
    good_reciept_detail: '入出庫詳細',
    purchase_order_detail: '購買発注詳細',
    slip_number: '伝票番号',
    from_store: '自店舗',
    to_store: '相手先店舗',
    pending: '未確定',
    confirmed: '確認済',
    price: '単価',
    amount: '量',
    text_add_transfer: '今すぐ転送を追加！',
    text_no_transfer: '転送はまだ追加されていません。',
    current_stock: '現在の株',
    last_inventory: '最後の在庫',
    warehousing: '倉庫',
    eod_warehousing: 'EODウェアハウス',
    current_quantity: '現在の数量',
    actual_use: '実際の使用',
    eod_actual_use: 'EOD実際の使用',
    unit_price: '単価',
    stock_value: '在庫値',
    actual_usage_amount: '実際の使用量',
    text_add_foodWaste: '今食べ物廃棄物を追加する',
    text_no_foodWaste: 'まだ食品無駄はありません。',
    reason: 'ロス事由',
    timeOver: '時間切れ',
    operationMistake: '操作ミス',
    cancellation: 'キャンセル',
    theft: '盗難',
    is_show_loss: '食料損失を見せる'
  },
  currency: {
    currencies: '通貨',
    currency: '通貨',
    default: 'デフォルト',
    rate: '率'
  },
  placeholder: {
    import_format_type: 'インポート形式の種類',
    search_for_product_of_customer_product_id: '図番または品番で検索',
    date: 'YYYY-MM-DD',
    zipcode: '0000',
    code: '0000',
    phone: '+0(000)000-00-00',
    hour: '00:00',
    select: '選択する',
    search_for_internal_product_id: '内部品番で検索'
  },
  campaign: {
    add_campaign: 'キャンペーンを作成してください',
    no_campaign: 'データがありません'
  },
  notify: {
    warning: '警告',
    add_notify: '通知を作成してください',
    no_notify: 'データがありません',
    totalSuccess: '全て成功',
    totalFalse: '全て失敗',
    success: '正常に完了しました',
    false: '失敗',
    error: 'エラー'
  },
  type: {
    add_type: 'タイプを作成してください',
    no_type: 'データがありません',
    totalSuccess: '全て成功',
    totalFalse: '全て失敗'
  },
  master: {
    text_add_nationality: '国籍を作成してください',
    text_no_nationality: 'データがありません',
    text_add_nationalityCategory: '国籍区分を作成してください',
    text_no_nationalityCategory: 'データがありません',
    text_add_rank: 'ランクを作成してください',
    text_no_rank: 'データがありません',
    text_add_position: 'ポジションを作成してください',
    text_no_position: 'データがありません',
    text_add_occupation: '職業を作成してください',
    text_no_occupation: 'データがありません',
    text_add_changeStatus: 'ステータス変更を作成してください',
    text_no_changeStatus: 'データがありません',
    text_add_visaStatus: 'ビザステータスを作成してください',
    text_no_visaStatus: 'データがありません',
    text_add_bloodType: '血液型を作成してください',
    text_no_bloodType: 'データがありません',
    text_add_certificate: '今すぐ証明書を追加！',
    text_no_certificate: 'chertはまだ追加されていません。'
  },
  system: {
    basic_details: '基本詳細',
    app_version: 'App Version',
    currencies: '通貨',
    currency: '通貨',
    currency_unit: '通貨単位',
    default: 'デフォルト',
    rate: '割合',
    configuration: '構成',
    text_no_client: 'クライアントはまだ追加されました。',
    text_add_client: '今すぐクライアントを追加する',
    text_no_config: 'configがまだ追加されました。',
    text_add_config: 'Configを追加してください！',
    key_value: 'key-value',
    text_no_partner: 'パートナーはまだ追加されていません',
    text_add_partner: '今すぐパートナーを追加！',
    text_no_printer: 'プリンタはまだ追加されていません',
    text_add_printer: '今すぐプリンタを追加してください',
    text_no_uiConfiguration: 'UI設定はまだ追加されました。',
    text_add_uiConfiguration: '今すぐUI設定を追加！',
    text_no_shift: 'シフトはまだ追加されていません。',
    send_notify: '通知を送る',
    sending_time: '送信時間の送信',
    features: '特徴',
    features_cms: 'CMS機能',
    features_order: '特徴順',
    cms_menu_detail: 'CMS詳細',
    auto_create_menu_version: '自動作成メニューバージョン',
    cms_update_waiting_time_fcds: 'CMS更新FCD',
    cms_enable_input_code: 'CMS Input Codeを有効にする',
    dynamodb_qrcode: 'DynamoDB QRCODE',
    config: 'config',
    partner: '相棒',
    cms_sync: 'CMS Sync',
    analytic_sync: 'Analytic Sync',
    sync_local_pos: '地元のPOS',
    send_order_confirm_email: '送信順序を確認メールを送信',
    send_online_order_to_mobile: 'モバイルにオンライン注文を送信',
    send_ges_email: 'GESメールを送信',
    push_ges_email: 'Push Ges Email',
    text_no_data: 'データなし',
    number_of_decimal: 'システムの数',
    text_no_setting: 'まだ設定されていません。',
    text_add_setting: '今すぐ設定を追加！'
  },
  money_bag_log: {
    driver: 'ドライバー',
    money_bag: 'お財布',
    created_at: '作成日',
    active: 'アクティブ',
    disable: '無効'
  },
  eod: {
    eod: '精算処理',
    working_times: '出退勤情報修正',
    step: 'ステップ',
    check_eod: 'データ連携確認',
    Warning: '未実施',
    Confirmed: '済',
    input_quantity: '入力数量',
    quantity_store: '在庫数',
    last_quantity_store: '前回在庫数',
    sales_info: '売上金情報',
    actual: '実残高',
    excess_deficiency: '過不足',
    foh_closing_amount: 'ＯＴ精算金額',
    amount_in_safe: '金庫内金額',
    accounts_receivable: '売掛金',
    sales_info_money: '販売情報お金',
    number_sheets: '枚数',
    amount_of_money: '金額',
    shareholder_complimentary_coupon: '株主の無料クーポン',
    cash_voucher_change: '現金券変更',
    cash_voucher_without_change: '変化なしの現金券',
    point: 'ポイント',
    commission: '委員会',
    order_desposit: '鬱sの注文',
    gross_sales_amount: 'グロス売上金額（税込）',
    total_sales_promotion: '販売促進合計',
    employee_discount: '従業員割引',
    net_sales_amount: 'ネット売上金額（税込）',
    accounts_generate_receivable: 'アカウントは受信可能なものを生成する',
    cash_voucher_total: '金券合計',
    other_deposit_total: 'その他入金合計',
    refund: '（内）返金',
    gourment_card: 'グルメットカード',
    headquarters_remittance: '本部送金',
    scheduled_bank_deposit: '銀行入金予定額',
    bank_deposit_amount: '銀行入金額',
    excess_bank_deposit: '銀行入金過不足',
    petty_information: '小柄な情報',
    cumulative_cash_shortage: '累積現金不足',
    bank_book: '預金通帳',
    store_cash: '店舗現金残高',
    small_cash: '小さな現金',
    safe_drawer_change: '安全な引き出し変化',
    pouch_change: 'ポーチ変更',
    total: '合計',
    cumulative_amount: '累積金額',
    reason_for_excess_or_deficiency: '過剰または欠陥の理由',
    change: '変化する',
    sales: '売上',
    pretty_cash: 'ペティキャッシュ',
    next_day_used_pouch: '翌日使ったポーチ',
    bank_account_excess: '銀行口座超過',
    no: '項番',
    use: 'Use',
    drawer_change: 'ドロア釣銭準備金',
    next_day_drawer_pouch_change: '翌日釣銭',
    next_day_bank_change_balance: '翌日の銀行のバランスのバランス',
    balance_change_safe: '安全なバランス変更',
    balance_pouch_change_safe: 'バランスポーチ変更',
    store_code: '店舗コード',
    employee_code: 'レーバーコード',
    name: '氏名',
    confirm_item: '確認項目',
    full_part_time: 'レーバー区分',
    rank: 'ランク',
    under_18: '年少者',
    number_of_attendance: '出勤回数',
    check_in: '出退勤時刻',
    checkin_hour: '出勤時刻',
    checkout_hour: '退勤時刻',
    start_work: '作業開始時刻',
    end_work: '作業終了時刻',
    working_content: '作業内容',
    working_time: '労働時間',
    break_time: '休憩時間',
    noted: 'Noted',
    confirmed: '確認',
    time_update: 'Time Update',
    is_off: '有給',
    work_division: '勤務区分',
    addMoreWorkingTime: '作業時間追加',
    addMoreCheckin: '出退勤情報追加',
    role_time: 'Role Time',
    is_sent_to_s3: 'S3に送られた',
    start_time: '作業開始時刻',
    end_time: '作業終了時刻',
    resend: '再送信',
    need_to_verify: 'データ連携確認',
    note_need_to_verify: '※以下,ステータスが✔でない項目はデータ連携に失敗したため再送信が必要です。',
    finish_good_reciept: '入出庫情報',
    finish_close_shift: '出退勤情報',
    finish_order: 'オーダー情報',
    finish_money_bag: 'ポーチ返却',
    finish_driver: 'ドライバー割当解除',
    finish_money_closure: 'お金の閉鎖を確認する',
    finish_labour_closure: '労働閉鎖の確認',
    finish_inventory_closure: '在庫閉鎖の確認',
    completed_end_of_date: 'EOD完成',
    is_eod: 'EOD成功',
    have_data: 'データEODを持っている',
    more_info: 'もっと情報',
    weather: 'Weather',
    finish_order_payment: '利用した支払方法を再度実行してください',
    data_order_wrong: 'の注文のデータは正しくないです',
    total_order_payment: '総金額',
    order_payment: '支払方法',
    time_eod: '清算処理時間',
    waiting_for_data: 'データの待機中',
    flash_report: 'フラッシュレポート',
    daily_summary_report: '日計表',
    loadding_report: 'レポートを用意しています。２分ほどお待ちください。'
  },
  weather: {
    sunny: '晴れ',
    cloudy: '曇り',
    rainy: '雨の',
    snow: '雪'
  },
  payment_method_level1: {
    payment_method_level1_detail: '支払い方法レベル1詳細',
    no_payment_method_level1: '支払い方法1',
    add_payment_method_level1: '支払い方法レベル1'
  },
  summary_tracker: {
    no: '項番',
    outlet_name: 'アウトレット名',
    outlet_code: 'アウトレットコード',
    total_online_order_delivery: '総秩序',
    total_order_swiped: '総次数スワイプ',
    driver_tracker_usage_percent: '使用法 （％）',
    sos_gt_30: 'SOS > 30',
    sos_gt_30_percent: 'SOS > 30 (%)',
    sos_lt_30: 'SOS < 30',
    sos_lt_30_percent: 'SOS < 30 (%)',
    all_store: 'すべての店'
  },
  store_target_v2: {
    store_code: 'レーバーコード',
    store_name: '店舗名',
    store_group: 'STORE_GROUP',
    daily_target: '毎日のターゲット',
    monthly_target: '月額目標',
    filter_area_title: 'FILTER BY',
    store_group_filter_label: 'ストアグループ',
    store_name_filter_label: '店舗名',
    business_date_filter_label: '勤務日付',
    checkbox_daiily_target_label: '毎日のターゲット',
    export_button_label: '出力',
    upload_button_label: 'CSVアップロード',
    dialog_expot_title_label: '出力',
    export_option_today: '今日',
    export_option_last7days: '最後7日',
    export_option_this_month: '今月',
    export_option_this_year: '今年',
    export_option_custom_date: 'カスタムデート',
    btn_cancel_export_popup: 'キャンセル',
    btn_ok_export_popup: 'OK',
    btn_cancel_upload: 'キャンセル',
    business_date: '事業日'
  },
  sale_history: {
    filter_title: 'FILTER BY',
    store_group_lb: 'Store Group',
    store_type_lb: 'Store Type',
    store_code_lb: 'Store Code',
    input_store_code_placeholder: 'ストアコードの入力',
    date_select_placeholder: '一日を選ぶ',
    total_sale_by_chanel_title: 'チャンネルによる総売上',
    total_bill_by_channel_title: 'チャンネルによる総請求書',
    sos_title: 'SOS',
    fresh_and_hot_title: '新鮮＆ホット',
    sos_delivery: '配達',
    sos_take_away: '取り除く',
    hot_fresh_delivery: '配達',
    hot_fresh_take_away: '取り除く',
    date_lb: '日付',
    btn_apply: '適用'
  },
  product_mix_v2: {
    filter_area_title: 'FILTER BY',
    store_group_filter_label: 'Store Group',
    store_type_filter_label: 'Store Type',
    product_code_table_header: '製品コード',
    store_group_table_header: 'STORE GROUP',
    store_type_table_header: 'STORE TYPE',
    product_name_table_header: '商品名',
    quantity_table_header: '量',
    button_add: '新しく追加する',
    export_button_label: '出力',
    dialog_expot_title_label: 'エクスポートオプション',
    export_option_today: '今日',
    export_option_last7days: '過去7日',
    export_option_this_month: '今月',
    export_option_this_year: '今年',
    export_option_custom_date: 'カスタムデート',
    dialog_button_cancel: 'キャンセル',
    dialog_button_ok: 'OK',
    message_error: '間違ったときに何か'
  },
  manage_order: {
    filter_area_title: 'FILTER BY',
    store_group_filter_label: 'Store Group',
    store_type_filter_label: 'Store Type',
    product_code_table_header: '製品コード',
    store_group_table_header: 'STORE GROUP',
    store_type_table_header: 'STORE TYPE',
    product_name_table_header: '商品名',
    quantity_table_header: '量',
    button_add: '新しく追加する',
    export_button_label: '出力',
    dialog_expot_title_label: 'エクスポートオプション',
    export_option_today: '今日',
    export_option_last7days: '過去7日',
    export_option_this_month: '今月',
    export_option_this_year: '今年',
    export_option_custom_date: 'カスタムデート',
    dialog_button_cancel: 'キャンセル',
    dialog_button_ok: 'OK',
    message_error: '間違ったときに何か'
  },
  today_sales: {
    filter_title: 'フィルタ',
    store_group_lb: 'Store Group',
    store_name_lb: '店舗名',
    placeholder_store_name: '入力ストア名',
    sales_title: '販売',
    placeholder_date: '一日を選ぶ',
    lb_total_sales: '総売上',
    lb_total_bill: '総請求書',
    lb_avg_bill: '平均請求',
    lb_sos: 'SOS',
    lb_fresh_hot: '新鮮＆ホット',
    target_stat_title: 'Tartget Stats',
    daily_lb: '毎日のターゲット',
    monthly_lb: '月額目標',
    sale_by_target_title: 'ターゲットによる販売',
    sale_by_type_title: 'タイプ別',
    bill_by_channel_title: 'Bill By Channel',
    bill_by_type: 'by type',
    delivery_key: '配達',
    take_away_key: '取り除く',
    dine_in_key: 'で食事をします',
    store_code_lb: 'Store Code',
    placeholder_store_code: '入力ストアコード',
    popover_current_daily_lb: '電流',
    popover_target_daily_lb: '目標',
    popover_current_monthly_lb: '電流',
    popover_target_monthly_lb: '目標',
    btn_apply: '適用'
  },
  order_device: {
    add_order_device: '注文装置を追加',
    order_device: '注文装置',
    no_order_device: '注文デバイスがない'
  },
  clear_data: {
    driver_location: 'ドライバーの場所',
    driver_log: 'ドライバーログ',
    driver_start_log: 'Driver Start Log',
    driver_swipe_done_log: 'ドライバー',
    money_bag_log: 'マネーバッグログ',
    money_bag_tracking: 'マネーバッグトラッキング',
    shift_log_open: 'Shift Log Open',
    user: 'ユーザー',
    user_checkin: 'ユーザーチェックイン',
    driver: 'ドライバー',
    money_bag: 'お財布',
    user_driver: 'ユーザードライバ',
    order_deliver: '配達注文',
    order: '注文',
    order_detail: '注文番号',
    order_promotion: '注文プロモーション',
    order_pament: '注文門',
    order_status_log: '注文ステータスログ',
    order_detail_log_time: '注文詳細ログ時間',
    order_detail_log_drop: '注文詳細ログドロップ',
    shift: 'シフト',
    shift_transaction: 'シフトトランザクション',
    user_break_time: 'ユーザーの休憩時間',
    user_token: 'ユーザートークン',
    working_time_closure: '作業時間閉鎖',
    user_checkin_closure: 'ユーザーチェックインクロージャー',
    material_stock_report: '材料在庫レポート',
    material_potential_utilization: '材料潜在的な利用',
    material_current_stock: '材料電流株',
    material_stock_log: '材料在庫ログ',
    store_cash: '店頭現金',
    end_of_date: '日付の終わり',
    eod: 'eod',
    next_day: '翌日',
    timesheet: 'タイムシート',
    supplier_product: 'サプライヤー製品',
    store_daily: '毎日店',
    menu_mix: 'メニューミックス',
    labour_cost_state: '人件費の状態',
    inventory: '在庫',
    in_out_inventory: '在庫内',
    employee: '従業員',
    petty_cash_statement: 'Petty Cash Statement',
    money_closure: 'お金の閉鎖',
    system: 'システム',
    catalogue_log_drawer: 'カタログログ引き出し',
    material_quantity_log: '品目数量ログ',
    kpi: 'KPI'
  },
  message_store: {
    title: 'メッセージストア',
    title_message: 'タイトルメッセージ',
    content_message: 'コンテンツメッセージ',
    text_no_store_message: 'ストアメッセージなし',
    text_add_store_message: 'ストアメッセージの追加',
    start_time: '始まる時間',
    end_time: '終了時間',
    store_message: 'Store Message'
  },
  ads_video: {
    upload_horizontal_file: '水平ファイルをアップロード',
    upload_vertical_file: '垂直ファイルをアップロードする',
    display_type: '画面タイプ',
    no_ads_video: '広告なしビデオ',
    add_ads_video: 'ADSビデオを追加する'
  },
  app_version: {
    no_app_version: 'アプリなしバージョン',
    add_app_version: 'App Versionを追加する ',
    hotfix: 'ホットフィックス'
  },
  app_version_manager: {
    no_app_version_manager: 'アプリのバージョンマネージャなし',
    add_app_version_manager: 'App Version Managerを追加する '
  },
  store_health: {
    ft_store_code_lb: 'Store Code',
    ft_date_lb: '日付',
    ph_select_store: 'ストアコードを選択',
    ph_date: '日付を選択',
    btn_current_status: '現在の状態',
    btn_log: 'ログ',
    card_filter: 'フィルタ',
    card_device_status: 'デバイスステータス',
    card_log: 'ログ',
    last_update_lb: '最終更新',
    col_business_date: '事業日',
    col_store: 'code / name',
    col_device_type: 'デバイスタイプ',
    col_status: '状態',
    col_status_date_time: 'ステータス日付'
  },
  pool: {
    pool: 'プール',
    code: 'レーバーコード',
    name: '名前',
    description: '説明',
    lat: '緯度',
    long: '長いです',
    pool_detail: 'プールディテール',
    add_pool: 'プールを追加',
    assign_driver: 'ドライバの割り当て',
    reassign_driver: 'ドライバの再割り当て',
    list_driver: 'Driver',
    confirm_assign_driver: 'ドライバを割り当てたいですか？',
    confirm_reassign_driver: '運転手を再割り当てしたいですか？',
    list_order_assign_together: '順に割り当てます'
  },
  store_dashboard: {
    item: 'Item',
    order: 'Order',
    total_food_cost: '総食料費',
    order_cost: '注文費用',
    loss_cost: '損失コスト',
    labour_cost: '人件費',
    other_cost: '利益と損失',
    profit_and_lost: '利益と損失',
    store_dashboard: 'ダッシュボードを保存する',
    sale_by_channel: 'チャンネルによる販売',
    sale_by_device: 'デバイスによる販売',
    sale_by_type: 'タイプ別セール',
    store: 'お店',
    store_group: '店舗グループ',
    lb_total_sales: 'NET売上金額（税抜）',
    lb_total_bill: '注文件数',
    lb_avg_bill: '客単価',
    lb_sos: 'SOS',
    lb_fresh_hot: 'F＆H',
    online: 'オンライン',
    offline: 'オフライン',
    call_center: 'Call center',
    grab: 'Grab',
    gojek: 'Gojek',
    delivery: 'デリバリー',
    take_away: 'テイクアウト',
    dine_in: 'お食事',
    total_delivery: 'Total Delivery',
    total_take_away: 'Total Take Away',
    total_dine_in: 'Total Dine In',
    pos: '位置',
    order_overview: '注文の概要',
    hour: ':00',
    total_bill_overview: '総請求額の概要',
    total_sale_overview: '総販売概要',
    avg_overview: '平均販売概要',
    sos_overview: 'SOS 概要概要',
    fnh_overview: 'F&H 概要概要',
    fresh_and_hot: 'フレッシュでホット',
    not_fresh_and_hot: '新鮮で暑くない',
    sos: 'Sos',
    not_sos: 'Sosではない',
    btn_apply: '適用する',
    total_delivery_bill: '総配送料'
  },
  common_validate: {
    number: '{attr} must be a number',
    length_digit: '{attr} must have exactly {value} digits',
    max_length_digit: '{attr} has a maximum of {value} digits',
    required: '{attr} が必要です',
    missing: '必須{attr}',
    invalid: '無効な{attr}'
  },
  food_cost: {
    date: '日付',
    food_cost: '食費',
    rate_with_revenue: '収益の％',
    food_cost_report: '食料費レポート',
    revenue: '収益'
  },
  labour_cost: {
    date: '日付',
    labour_cost: '人件費',
    order_sale: '総販売',
    percent: 'パーセント',
    no: '項番',
    working_date: '営業日',
    day_of_the_week: '曜日',
    attendance: '出退勤',
    working_time: '労働時間',
    over_time: '残業時間',
    mid_night: '深夜手当',
    allowance: '手当',
    labour_cost_total: '労務費計',
    basic_salary: '基本給',
    overtime_pay: '残業手当',
    pos: 'POS手当',
    driving: 'DV手当',
    festival: '土日祭手当',
    shift_m: 'シフトM手当',
    orther: 'Orther',
    commuting: '交通費',
    paid: '有給手当'
  },
  fleet: {
    active_from: '積極的',
    active_to: '活動的',
    zone_detail: 'ゾーン詳細',
    total_order: '総秩序',
    exception_total_order: '例外合計注文',
    total_driver: '最大合計ダイバー',
    exception_total_driver: '最大例外総運転',
    upload_csv: 'CSVアップロード',
    date_exception: '日付例外',
    value: '値',
    zone_name: 'フルネーム',
    country_name: '国',
    zone_map: 'ゾーン',
    type: '配達ゾーンによる',
    zone_code: 'ゾーンコード',
    radius_distance: '半径距離（m）',
    radius: '半径',
    postal_code: '郵便番号',
    code: 'レーバーコード',
    no_zone: 'ゾーンはまだ追加されていません。',
    add_zone: '今すぐゾーンを追加！',
    btn_add_zone: 'ゾーンを追加',
    origin_point: '起源ポイント',
    destination_point: '目的地',
    origin: '原点',
    destination: '先',
    no_matrix: 'Matrixはまだ追加されていません。',
    add_matrix: '今行列を追加する',
    matrix_detail: '行列の詳細',
    day_of_week: {
      mon: 'mon',
      tue: '火',
      wed: '結婚した',
      thu: 'Thu',
      fri: '金',
      sat: 'sat',
      sun: '太陽'
    },
    validate: {
      time_from_required: 'timeからの時間が必要です',
      time_to_required: '必要な時間',
      driver_config_required: 'ドライバ番号が必要です',
      client_uuid_required: 'クライアントは必須です',
      exception_date_required: '日付は必須です',
      zone_uuid_required: 'ゾーンが必要です'
    },
    view_exception: '例外を表示',
    date_from: 'デートから',
    date_to: '日付',
    time_from: 'time from',
    time_to: 'の時間',
    number_of_driver: '運転手の数',
    add_time: '時間追加',
    remove_time: '時間を除去',
    download_example_template: 'ダウンロード例テンプレート',
    destination_zones: '宛先ゾーン',
    max_distance: '最大距離',
    distance: '距離',
    max_weight: '最大体重',
    earliest_time_slot: '最も早い配達時間スロット',
    is_time_slot_delivery: 'タイムスロット配信',
    delivery_fee: '配送料',
    delivery_sla: '配達SLA',
    min_distance: '最小距離'
  },
  sale_digital_channel: {
    total_bill: '総請求書',
    total_sales: '総売上高（IDR） ',
    average_bill: '平均法案（IDR）'
  },
  subscription_plan: {
    basic_details: '基本詳細'
  },
  store_subscription_plan: {
    order_left: '左に順番',
    renewed_on: '更新された',
    expires_on: '期限切れ',
    view: '見る',
    renew: '更新',
    get_this_plan: 'この計画を入手',
    new: '新着',
    expires: '期限切れ'
  },
  account: {
    account_details: 'アカウント詳細',
    company_name: '会社名',
    business_name: '商号',
    email: 'Eメール',
    phone_number: '電話番号',
    social_media: 'ソーシャルメディア',
    facebook_url: 'Facebook URL',
    instagram_url: 'Instagram URL',
    bank_details: '銀行の詳細',
    bank_account_name: '銀行口座名',
    bank_account_number: '銀行の口座番号',
    bank: 'バンク',
    assigned_stores: '割り当てられたストア',
    date_created: 'Dateが作成されました',
    current_password: '現在のパスワード',
    password: 'パスワード',
    change_password: 'パスワードを変更する',
    link_facebook: 'Link Facebook',
    link_instagram: 'Link Instagram',
    new_password: '新しいパスワード',
    confirm_password: 'パスワードを認証する'
  },
  promotionV3: {
    promo_code: 'プロモーションコード',
    promo_type: 'プロモ型',
    stack_promo_code: 'スタックプロモーションコード',
    show_from_ui: 'ウェブサイト上のプロモーションコードを表示',
    promo_details: 'プロモーションの詳細',
    promo_assignment: 'プロモーション割り当て',
    assign_to: '割りあてる',
    assigned_items: '割り当てられたアイテム',
    redemption_limit: '償還限度',
    minimum_purchase: '最低購入',
    percentage: 'パーセンテージ',
    max_discount_value: '最大割引値',
    assigned_stores: '割り当てられたストア',
    assigned_category: '割り当てられたカテゴリ',
    quick_view: 'クイックビュー',
    redeemed_thus_far: 'これまでの償還',
    chargeback_cost: 'チャージバックコスト',
    is_per_store: '店舗は店舗です',
    is_auto_redeem: '自動交換'
  },
  orders: {
    all: 'すべての命令',
    pre_order: '予約注文',
    on_demand: 'オンデマンド',
    self_pickup: '自己ピックアップ',
    restaurant: 'レストランフィルター',
    zone: 'ゾーン',
    destination_zone: '宛先ゾーン',
    calendar: 'カレンダービュー',
    code: '注文ID',
    delivery_time: '納期',
    receipient_detail: '受信機詳細',
    instruction: '特別な指示',
    order_channel: '注文チャンネル',
    quick_view: 'クイックビュー',
    items: 'アイテム',
    status: '状態',
    action: 'アクション',
    find: '利用可能なライダーを見つける',
    preparing: '準備',
    pending: '保留中',
    stand_by: '待機する',
    pick_up: '選び出す',
    delivering: '配達',
    delivered: '配達',
    canceled: 'キャンセル',
    order_status: '注文の状況',
    payment_status: '支払い状況',
    delivery_status: '配送状況',
    order_type: '注文タイプ',
    date_range: '日付範囲',
    order_detail: '注文番号',
    food_items: '食品',
    quantity: '量',
    item: '項目',
    price: '単価',
    instructions: '指示',
    mobile: '携帯電話番号',
    customer_name: '顧客氏名',
    receipient_name: '受信者名',
    email: 'Eメール',
    delivery_fee: '配送料',
    discount: 'ディスカウント',
    discount_code: 'ディスカウントコード',
    discount_type: '割引タイプ',
    address: '住所',
    order_time: '受注日時',
    rider_detail: 'ライダーの詳細',
    rider_name: 'ライダー名',
    rider_status: '選び出す',
    estimated_time: '推定配達時間',
    commission_details: '手数料の詳細',
    commission_amount: '手数料額',
    commission_rate: '委員会の割合',
    recipient_details: '受信者の詳細',
    order_date_range: '注文日付範囲',
    delivery_date_range: '配達日範囲',
    sure: 'あなたは確かに注文',
    is_ready: '準備ができて',
    is_cancel: '注文をキャンセルしたいですか'
  },
  leafleting: {
    text_no_item: 'アイテムはまだ追加されていません。',
    add_item: 'アイテムを追加',
    text_add_item: '今すぐアイテムを追加！',
    basic_details: '基本詳細',
    date_posting: '日付投稿',
    distributor: '販売代理店',
    posting_date: '郵送日',
    target: '目標',
    result: '結果',
    method: '方法',
    type: 'ロス区分',
    distribution_date: '配布日',
    address1: '住所（1',
    address2: 'アドレス2',
    edit_leafleting: 'リーフレットを編集'
  },
  store_notification: {
    start_date: '開始日',
    end_date: '終了日',
    message: '通知メッセージ'
  },
  special_request: {
    no_special_request: '特別な要求なし',
    add_special_request: '特別な要求を追加'
  },
  payroll: {
    part_code: '部品コード',
    part_name: '部品名',
    error_code: 'エラーコード',
    error_message: 'エラーメッセージ',
    working_date: '営業日',
    text_no_message: 'エラーメッセージはまだ追加されました。'
  },
  ace: {
    on_time: 'オンタイム',
    promise_time: 'プロミスタイム',
    storein_time: '店内時間',
    less_equal: '<=',
    more: '>',
    percent: '％',
    minutes: '分',
    total: '合計',
    output_period: '出力期間',
    business_day: '営業日',
    cancel_refund: '払い戻しをキャンセル',
    cancel: 'キャンセル',
    refund: '払い戻し'
  },
  ace_sale: {
    sales_number_related_figures_over_all: '■売上・件数関連数値（税抜き、売上区分別）',
    sales_number_related_figures_category: '■売上・件数関連数値（税抜き、売上区分別）',
    sales_number_related_figures_web_category: '■WEB関連　売上・件数関連数値（税抜き、売上区分別）',
    target_sale: '目標売上（PJ）',
    gross_sales_amount_tax: 'グロス売上金額（税込）',
    gross_sales_amount: 'グロス売上金額（税抜）',
    net_sales_amount_tax: 'ネット売上金額（税込）',
    net_sales_amount: 'ネット売上金額（税抜）',
    consumption_tax_amount: '消費税金額',
    order_target_actual_number: 'オーダー目標/実績件数',
    order_unit_price_tax: 'オーダー単価（税込）',
    order_unit_price: 'オーダー単価（税抜）',
    number_refund_amount: '返金回数/金額',
    employee_discount_amount: '従業員割引金額',
    promotional_discount_amount: '販促（クーポン）割引金額',
    Amount_indicated_discount: '金額指示値引回数/金額',
    cb_coupon_discount: 'CBクーポン割引枚数/金額',
    phc_points_used: 'PHCポイント利用回数/金額',
    ponta_usage: 'Ponta利用回数/金額',
    store_account_receivable: '店舗売掛発生件数/金額',
    store_account_receivable_amount: '店舗売掛入金金額',
    uncollected_account_receivable: '未回収売掛件数/残高',
    shareholder_benefit_amount: '株主資優待金額',
    gourmet_card_amount: 'グルメカード金額',
    voucher_amount: '金券金額',
    fee: '手数料',
    other_deposit_amount_1: 'その他入金金額（１）',
    other_deposit_amount_2: 'その他入金金額（２）',
    other_deposit_amount_3: 'その他入金金額（３）',
    bank_deposit_amount: '銀行入金金額（売上本部送金）',
    bank_deposit_excess: '銀行入金過不足金額',
    number_of_cancellation_amount: 'キャンセル回数/金額',
    number_of: '数の数',
    sales_amount: '売上高',
    unit_price: '単価',
    minutes: '分',
    seconds: '秒',
    average_order_tech_time: '平均オーダーテク時間（分秒）',
    average_make_up_time: '平均メイク時間（分秒）',
    average_rack_time: '平均ラック時間（分秒）',
    average_delivery_time: '平均配達時間（分秒）,'
  },
  rp_dashboard: {
    pj: 'PJ',
    sales: '販売の詳細',
    analysi: '分析テーブル',
    menu: 'メニューミックス',
    inventory: '在庫命令',
    store: '店舗管理',
    smg: 'SMG'
  },
  ace_sale_daily: {
    daily_account_table: '日計表',
    working_date: '出力対象日',
    weather: '天候',
    first_order: '初回オーダー時刻',
    last_order: '最終オーダー時刻',
    start_time_of_foh: 'FOH開始時刻',
    end_time_of_foh: 'FOH終了時刻',
    end_time_of_boh: 'BOH終了時刻',
    earnings: '売上',
    tax_included: '税込',
    consumption_tax: '消費税',
    tax_excluded: '税抜',
    gross_sales_amount: 'グロス売上金額',
    sales_promotion_total: '販促合計①＋②',
    net_sales_amount: 'ネット売上金額',
    standard_tax_rate: '(内） 標準税率',
    reduced_tax_rate: '(内） 軽減税率',
    discount_details: '割引明細',
    number_amount_of_money: '件数/金額',
    coupon_discount: 'クーポン割引',
    amount_indicated_discount: '金額指示値引き',
    sales_promotion_total_1: '販売促進合計①',
    employee_discount: '従業員割引②',
    deposit_classification: '入金区分',
    payment_breakdown: '入金内訳',
    number: '件数',
    amount_of_money: '金額',
    regular_product_sales: '通常商品売上',
    Refund: '返金［-］',
    total_sales: '売上合計',
    point_deposit_sales_transfer: 'ポイント預り売上振替［-］',
    total_sales_deposit: '売上入金合計',
    point_deposit_transfer_deposit_plus: 'ポイント預り振替預り［+］',
    point_deposit_transfer_deposit_minus: 'ポイント預り振替預り［-］',
    other_deposit_1: 'その他入金１',
    other_deposit_2: 'その他入金２',
    other_deposit_3: 'その他入金３',
    total_deposit: '入金合計',
    fee: '手数料',
    total_deposit_including_fees: '入金合計（手数料含）',
    deposit_type: '入金種別',
    cash_tickets: '現金・券類',
    breakdown_of_tickets: '（券類内訳）',
    cash: '現金',
    mumber_of_collected_sheets: '回収枚数',
    collection_amount: '回収金額',
    excess_or_deficiency: '過不足',
    gourmet_card: 'グルメカード',
    gold_ticket_1: '金券１',
    gold_ticket_2: '金券２',
    gold_ticket_3: '金券３',
    gold_ticket_4: '金券４',
    gold_ticket_5: '金券５',
    point: 'ポイント',
    ticket_total: '券類合計',
    accounts_receivable: '未収入金',
    headquarters_accounts_receivable_amount: '本部売掛発生金額',
    special_accounts_receivable_amount: '特殊売掛発生金額',
    bank_deposit_excess_deficiency: '銀行入金・過不足',
    ticket_change_total: '券類釣銭合計',
    cash_that_should_be: 'あるべき現金',
    bank_deposit_headquarters_remittance: '銀行入金（本部送金）',
    over_under_accounts_receivable: '売掛入金過不足',
    orther: 'その他',
    accounts_receivable_balance: '売掛金残高',
    next_day_reservation: '翌日予約',
    cancel: 'キャンセル',
    deposit_information: '預り金情報',
    last_deposit_total: '前回預り金合計',
    total_deposit_this_time: '今回預り金合計',
    bank_book: '銀行通帳',
    balance_at_the_end_of_the_previous_day: '前日末残高',
    headquarters_deposit_amount: '本部入金額',
    interest_deposit_amount: '利息入金額',
    withdrawal_amount: '引落金額',
    store_to_bank: '銀行←店舗',
    bank_to_store: '銀行→店舗',
    sameday_balance_theoretical_balance: '当日残高（理論残高）',
    sameday_balance_actual_balance: '当日残高（実残高）',
    passbook_excess_deficiency: '通帳過不足',
    store_cash: '店舗現金',
    cost_amount: '費用金額',
    cash_excess_deficiency: '現金過不足',
    next_day_change: '翌日釣銭',
    number_of_pouches_used: '使用ポーチ数',
    pouch_amount: '釣（ポーチ）',
    drawer_amount: '釣（ドロア）',
    remaining_change_in_the_safe: '金庫内釣銭残',
    safe_pouch_change_balance: '金庫内ポーチ釣銭残',
    foh_time_payment: 'FOH精算時点',
    total_excess_and_deficiency_of_cash_vouchers: '金券過不足合計',
    sales_using_points_on_the_day: '当日ポイント使用売上',
    inside_orders_made_on_the_day: '(内）当日注文分',
    inside_preorder: '(内）予約注文分',
    number_of_sheets: 'シート数',
    day_of_the_week: '曜日',
    no: '項番',
    passbook: '通帳',
    petty_cash: 'ペティキャッシュ',
    balance: '残高',
    expenses: '経費'
  },
  ace_labour: {
    labor_related_figures: '労働関連の図',
    number_of_employees: '社員人数',
    total_employee_time: '社員合計時間',
    number_of_part_timers: 'パートレーバー人数',
    part_time_worker_total_time: 'パートレーバー合計時間',
    average_time_for_part_time_workers: 'パートレーバー平均時間',
    total_amount_of_part_time_workers_including_allowance: 'パートレーバー合計金額（手当込）',
    average_hourly_wage_of_part: 'パートレーバー平均時給（手当込）',
    part_time_worker_labor_productivity: 'パートレーバー労働生産性',
    labor_productivity_including_employees: '労働生産性（社員込）',
    number_of_people: '人々の数',
    total_time: '合計時間',
    average_time: '平均時間',
    total_amount: '合計全額',
    average_hourly_wage: '平均毎時',
    labor_productivity: 'オペレーション労働生産性',
    efficiency: '効率',
    employee_included_part_only: '社員込／パートのみ',
    operation: '操作',
    manager: 'マネジメント',
    in_store: 'インストア',
    driver: 'ドライバー',
    service: 'サービス',
    posting: 'ポスティング',
    trainer: 'トレーニング',
    help: 'ヘルプ'
  },
  ace_coupon_history: {
    number_of_sheets: 'シート数',
    day_of_the_week: '曜日',
    working_date: '営業日',
    no: '項番',
    slip_number: 'スリップ数',
    customer_number: '顧客番号',
    order_time: '受注日時',
    input_person: '入力人',
    delivery_person: '配達人',
    coupon: 'クーポン',
    code: 'レーバーコード',
    name: '名前',
    discount_amount: '割引額'
  },
  store_address_mapping: {
    address2_code: 'ADD2_CD',
    zipcode: 'ZIPCODE',
    address1_name: 'ADD1_NAME',
    address1_kana: 'ADD1_KANA',
    address1_ryaku: 'ADD1_RYAKU',
    address2_name: 'ADD2_NAME',
    address2_kana: 'ADD2_KANA',
    address2_ryaku: 'ADD2_RYAKU',
    area_ol_flg: 'AREA_OL_FLG',
    area_ol_com: 'AREA_OL_COM',
    web_null_flg: 'WEB_NULL_FLG',
    kanri_setai_cnt: 'KANRI_SETAI_CNT',
    kyaku_cnt: 'KYAKU_CNT',
    kyogo_tenpo_cnt: 'KYOGO_TENPO_CNT',
    dlvtime: 'DLVTIME',
    map_cd: 'MAP_CD',
    ind_name: 'IND_NAME',
    sdata_make_date: 'SDATA_MAKE_DATE',
    tdata_make_date: 'TDATA_MAKE_DATE',
    yuko_post_cnt: 'YUKO_POST_CNT',
    yobi01: 'YOBI01',
    yobi02: 'YOBI02',
    yobi03: 'YOBI03'
  },
  deviation: {
    no_deviation: '逸脱なし',
    order_number: '注文番号',
    actutal_make_time: '虐待的な時間',
    expected_starting_make_time: '予想される開始時間',
    time_difference: '時差'
  },
  cash_count: {
    no: '項番',
    time_of_day: '処理時刻',
    type: '処理端末',
    processing: '処理',
    before_processing: '処理前全額',
    change: '釣銭',
    sales: '売上（一部含）',
    sales_include: '売上（一部含）',
    earnings: '金券',
    gold_ticket: '経費',
    part: '一部',
    excess_or_deficiency: '過不足(金券)',
    expenses: '経費',
    human_power_amount: '人力金額',
    after_processing: '処理後金額',
    action_name: '処理内容'
  },
  merchant: {
    business_name: '会社名',
    brand_name: 'ブランド名',
    detail: '販売者の詳細',
    country: '国',
    contact: '連絡先',
    sign_up: '日付が登録されました',
    approved: '日付承認',
    all: 'すべて',
    verify: '検索',
    pending: '保留中の検証',
    reject: '拒否',
    approval_status: 'ビジネス承認ステータス',
    business: 'ビジネス情報',
    phone_display: '電話番号を表示',
    contact_number: '連絡先番号',
    email: '電子メールアドレス',
    commission_rate: '委員会率',
    business_number: '事業登録番号',
    status: '状態',
    reject_reason: '理由を拒否',
    bank_info: '銀行情報',
    bank_name: '銀行口座名',
    bank_number: '銀行の口座番号',
    bank: 'バンク',
    pickup: '事業登録住所',
    unit: '単位,ブロック',
    city: '市',
    postcode: '郵便番号',
    street: '住所',
    state: '状態',
    business_doc: 'ビジネス文書',
    document: '資料',
    link: 'リンク',
    restaurant: 'レストランの一覧',
    document_type: 'ドキュメントタイプ',
    document_name: 'ファイル名',
    business_document: 'ビジネス文書',
    view: '見る',
    code: 'レーバーコード',
    store_name: '店舗名',
    tag: 'タグ',
    plan: '予定',
    contact_detail: '連絡先の詳細',
    agreement_sign_off: 'マーチャント契約のサインオフ',
    delivery_timestamp: '配達タイムスタンプ',
    email_deliveries: 'Eメール配達'
  },
  under_18: {
    no: '項番',
    working_date: '営業日',
    day_of_the_week: '曜日',
    employee_registration: '従業員登録',
    sign_up: '新規登録',
    update: '更新',
    retirement: '退職',
    boxed_person: '在籍者',
    help_box: 'ヘルプ在籍者',
    youth: '年少者',
    hourly_wage_change: '時給変更',
    '1_month_unemployed': '1ヶ月未勤務者',
    administrative_check: '管理チェック',
    overtime_occurs: '残業発生',
    juvenile_violation: '年少者違反',
    work_correction: '勤務修正',
    cancellation_of_work: '勤務取消',
    week_over: '週間オーバー',
    continuous_work: '連続勤務',
    no_break: '未休憩'
  },
  history: {
    order_code: 'ID',
    id: 'DBレコードID',
    old_teleport_status_code: '最後のステータス',
    new_teleport_status_code: '現在の状態',
    updated_by: '更新された',
    created_at: '日付が変更されました'
  },
  date_label_output: {
    date_time: '日付時刻',
    availabel_datetime: 'availabel datetime',
    discard_datetime: 'DateTimeを破棄する',
    time_limit: '制限時間'
  },
  packageInformation: {
    code: 'レーバーコード',
    package_name: 'パッケージ名',
    package_type: 'パッケージ型式',
    width: '幅',
    height: '高さ',
    weight: '重量',
    length: '長さ'
  },
  order_v2: {
    order_information: '注文情報',
    order_code: '注文ID',
    store_name: '店舗名',
    customer_name: '顧客氏名',
    customer_id: '顧客ID',
    customer_phone: '電話番号',
    customer_email: 'メールアドレス',
    employee_name: '注文者',
    segment: '注文タイプ',
    delivery_address: '住所',
    waiting_time: 'プロミスタイム',
    collection_time: '配達/お渡し日時',
    order_time: '受注日時',
    order_status_code: '注文ステータス',
    order_driver_name: 'ドライバー名',
    order_detail: 'メニュー情報',
    order_log_time: '注文ログ時間',
    make_time: 'メイク開始時間',
    bake_time: 'ベイク開始時間',
    checkout_time: 'パウチ完了時間',
    start_delivery_time: '配達開始時間',
    finish_time: '配達/お渡し日時',
    actual: '実績 ',
    expected: '予想',
    Payment_information: '支払い情報',
    coupon_used: '使用済みクーポン',
    tax_included: 'Total',
    tax_standard: '標準税率部分',
    tax_reduced: '減税率部分',
    grand_total: '税抜',
    Payment_methods: '支払い方法',
    by: '作成者',
    sub_total: '小計',
    discount: '割引',
    total: '合計',
    tax_value: '税額',
    code: 'レーバーコード',
    name: '名称',
    coupon_information: 'クーポン情報',
    remark: '申し送り事項'
  },
  customer_factory: {
    customer_factory: '顧客工場',
    domains: 'ドメイン',
    full_address: 'フルアドレス',
    factory_address: 'ファクトリアドレス',
    add_customer_factory: '追加',
    no_customer_factory: '顧客工場なし'
  },
  ace_cancel: {
    sale_date: 'セール日',
    day_of_week: '曜日',
    sales_cancellation: '営業キャンセル',
    total: '合計',
    cancel: 'キャンセル',
    number_of_time: '時間の数',
    amount_of_money: '金額',
    employee: '従業員',
    non_employee: '非従業員',
    sales_refund: '販売払い戻し'
  },
  menu_mix: {
    number_of_promo: 'Number Of Promo',
    number_of_sale: '販売数',
    order_ratio: '注文比率',
    code: 'レーバーコード',
    total_number: '総数',
    product_category_name: '製品カテゴリ名'
  },
  usage_analysis: {
    group: 'Group',
    material_code: 'Material Code',
    material_name: 'Material Name',
    unit: 'Unit',
    last_inventory: 'Last Inventory',
    current_inventory: 'Current Inventory',
    amount: 'Amount',
    quantity: 'Quantity',
    unknown_loss: 'Unknown Loss',
    loss: 'Loss',
    theoritical_use: 'Theoritical Use',
    real_use: 'Real Use',
    out: 'Out',
    in: 'In'
  },
  sale_payment: {
    sale_daily: 'Sale daily',
    excess_or_deficiency: 'Excess or deficiency',
    store_amount: 'Store amount',
    deposit_type: 'Deposit Type',
    amount_when_eod_before: 'Amount when eod before',
    this_day_balance: 'This day balance',
    cash_variant: 'Cash variant',
    total: 'Total',
    next_day_change_prepare_amount: 'Next day "change" prepare amount',
    money_bag_amount: 'Money bag amount',
    drawer_amount: 'Drawer amount',
    sale: 'Sale',
    date: 'Date',
    close_store_person: 'Close store person',
    net_sales: 'Net sales',
    expected_bank_deposit: 'Expected bank deposit',
    include_tax: 'Include tax',
    consumer: 'Consumer',
    exclude_tax: 'Exclude tax'
  },
  end_customer_type: {
    distributor: '代理店',
    agent: '代行店'
  },
  import_format_type: {
    main: 'メインフォーマット',
    shikoku: '四国フォーマット',
    izawa: '井澤フォーマット'
  },
  registration_type: {
    claim: 'クレーム',
    returns: '返品',
    recorded: '計上済み',
    not_recorded: '未計上'
  },
  price: {
    increase: '値上げ',
    decrease: '値下げ'
  },
  normal_user_steps: {
    pending: '承知待ち',
    under_review: '承認中',
    approved: '承認済み',
    rejected: '否認'
  },
  diamet_user_steps: {
    sale_approval: '1.営業承認待ち',
    manufacturing_approval: '2. 生還承認待ち',
    management_approval: '3. 役員承認待ち'
  },
  approver_steps: {
    pending: '承知待ち',
    sale_approval: '営業承認',
    manufacturing_approval: '生産管理承認',
    management_approval: '承認済み'
  },
  approval_status: {
    sale_approval: '1.営業承認',
    manufacturing_approval: '2. 生産管理承認',
    management_approval: '3. 承認済み',
    rejected: '4. 否認'
  },
  claim_status: {
    draft: '下書き',
    untreated: '未処置',
    under_consideration: '処鐙済',
    treated: '検討中',
    solved: '対策済'
  },
  spot_status: {
    confirmed: '確定',
    pending: '確定待ち'
  },
  quantity_value: {
    negative: 'ネガティブ',
    positive: 'ポジティブ'
  }
};