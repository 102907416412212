/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout';
import { RouteContainerComponent } from '@/utils';
import CONSTANTS from '@/constants';
var demandManagementRouter = {
  path: '/demand-management',
  component: Layout,
  redirect: 'noredirect',
  name: 'DemandManagement',
  meta: {
    title: 'demand_management',
    icon: 'component',
    permissions: [CONSTANTS.PERMISSION_NAME.DEMAND.MANAGE]
  },
  children: [{
    path: 'list',
    component: RouteContainerComponent,
    meta: {
      title: 'demand_list',
      permissions: [CONSTANTS.PERMISSION_NAME.DEMAND.MANAGE_DEMAND_LIST]
    },
    children: [{
      path: '',
      component: function component() {
        return import('@/views/demandList/index.vue');
      },
      name: 'DemandList',
      meta: {
        title: 'demand_list',
        activeMenu: '/demand-management/list'
      }
    }]
  },
  // {
  //   path: 'info',
  //   component: RouteContainerComponent,
  //   meta: {
  //     title: 'demand_info',
  //     permissions: [CONSTANTS.PERMISSION_NAME.DEMAND.MANAGE_DEMAND_INFO],
  //   },
  //   children: [
  //     {
  //       path: '',
  //       component: () => import('@/views/demandInfo/index'),
  //       name: 'DemandInfo',
  //       meta: {
  //         title: 'demand_info',
  //         activeMenu: '/demand-management/info',
  //       },
  //     },
  //   ],
  // },
  // {
  //   path: 'confirm',
  //   component: RouteContainerComponent,
  //   meta: {
  //     title: 'demand_confirm',
  //     permissions: [CONSTANTS.PERMISSION_NAME.DEMAND.MANAGE_DEMAND_INFO],
  //   },
  //   children: [
  //     {
  //       path: '',
  //       component: () => import('@/views/demandConfirm/index'),
  //       name: 'DemandConfirm',
  //       meta: {
  //         title: 'demand_confirm',
  //         activeMenu: '/demand-management/confirm',
  //       },
  //     },
  //   ],
  // },
  {
    path: 'spot-order-list',
    component: RouteContainerComponent,
    meta: {
      title: 'spot_order_list',
      permissions: [CONSTANTS.PERMISSION_NAME.DEMAND.MANAGE_SPOT_ORDER]
    },
    children: [{
      path: '',
      component: function component() {
        return import('@/views/spotOrderList/index');
      },
      name: 'SpotOrderList',
      meta: {
        title: 'spot_order_list',
        activeMenu: '/demand-management/spot-order-list'
      }
    }]
  }, {
    path: 'manufacturing-product',
    component: RouteContainerComponent,
    meta: {
      title: 'manufacturing_product',
      permissions: [CONSTANTS.PERMISSION_NAME.DEMAND.MANAGE_MANUFACTURING_LIST]
    },
    children: [{
      path: '',
      component: function component() {
        return import('@/views/manufacturingProduct/index');
      },
      name: 'ManufacturingProduct',
      meta: {
        title: 'manufacturing_product',
        activeMenu: '/demand-management/manufacturing-product'
      }
    }]
  }]
};
export default demandManagementRouter;