export default {
  ACTIVE: 1,
  DEACTIVE: 0,
  IS_MASTER: 1,
  CATEGORY_TYPE: {
    NORMAL: 1,
    PIZZA: 2,
    COMBO: 3,
    SPECIAL: 4
  },
  CLAIM_STATUS: {
    DRAFT: 1,
    UNTREATED: 2
  },
  ADDITIONAL_TYPE: {
    NORMAL: 1,
    ADDON: 2,
    DONATION: 3,
    EXTRA_CHILLI: 4
  },
  TYPES: {
    DELIVERY: 'Delivery',
    TAKEAWAY: 'Take Away',
    DINEIN: 'Dine in',
    EATIN: 'Eat in'
  },
  TYPE_CODE: {
    DELIVERY: 1,
    TAKEAWAY: 2,
    DINEIN: 3
  },
  TYPE_PRICE: {
    DELIVERY: {
      value: 1,
      label: 'Delivery',
      translate: 'delivery'
    },
    TAKEAWAY: {
      value: 2,
      label: 'Take Away',
      translate: 'take_away'
    },
    DINEIN: {
      value: 3,
      label: 'Dine in',
      translate: 'dine_in'
    }
  },
  STATUS: {
    ORDERED: 'Ordered',
    REJECTED: 'Rejected',
    CONFIRMED: 'Confirmed'
  },
  IMAGE_EXTENSION: ['image/jpeg', 'image/png', 'image/jpg', 'image/gif', 'image/bmp'],
  CONFIG_KEY_TAX: 'tax',
  CONFIG_KEY_TYPE: 'type',
  CONFIG_KEY_CHANNEL: 'channel',
  CONFIG_KEY_TIER: 'tier',
  CONFIG_KEY_LOCATION: 'location',
  CONFIG_IS_RECIPE: 'is_recipe',
  ORDER_STATUS_FINISHED: 1,
  CONFIG_TYPE: {
    CLIENT: {
      KEY: 1,
      NAME: 'Client'
    },
    STORE_GROUP: {
      KEY: 2,
      NAME: 'Store Group'
    },
    STORE: {
      KEY: 3,
      NAME: 'Store'
    }
  },
  STATUS_GOOD_RECIEPT: {
    PENDING: {
      KEY: 1,
      NAME: '未確定'
    },
    CONFIRMED: {
      KEY: 2,
      NAME: '確定'
    }
  },
  BANNER_TYPE: {
    CLIENT: {
      KEY: 0,
      NAME: 'Top'
    },
    STORE_GROUP: {
      KEY: 1,
      NAME: 'Right'
    },
    STORE: {
      KEY: 2,
      NAME: 'Left'
    },
    SYSTEM: {
      KEY: 3,
      NAME: 'System'
    },
    STORE_BANNER: {
      KEY: 4,
      NAME: 'Merchant Top Banner'
    },
    STORE_SUB_BANNER: {
      KEY: 5,
      NAME: 'Merchant Bellow Banner'
    },
    TAG_BANNER: {
      KEY: 6,
      NAME: 'Category level 1 Banner'
    },
    CAMPAIGN: {
      KEY: 7,
      NAME: 'Campaign Banner'
    }
  },
  CONFIG_TYPE_VALUE: {
    SINGLE: {
      KEY: 1,
      NAME: 'Single'
    },
    ARRAY: {
      KEY: 2,
      NAME: 'Array'
    },
    OBJECT: {
      KEY: 3,
      NAME: 'Object'
    },
    UPLOAD: {
      KEY: 4,
      NAME: 'Upload'
    }
  },
  ROLE_CODE: {
    SUPERADMIN: 'ADMIN'
  },
  PERMISSION_NAME: {
    DEMAND: {
      MANAGE: 'demand.*',
      MANAGE_DEMAND_LIST: 'demand_list.*',
      MANAGE_DEMAND_INFO: 'demand_info.*',
      UPDATE_DEMAND_LIST: 'demand_list.update',
      CONFIRM_DEMAND_LIST: 'demand_list.confirm',
      UPDATE_DEMAND_INFO: 'demand_info.update',
      EXPORT_DEMAND_LIST: 'demand_list.export',
      MANAGE_MANUFACTURING_LIST: 'manufacturing_list.*',
      UPDATE_MANUFACTURING_LIST: 'manufacturing_list.update',
      MANAGE_SPOT_ORDER: 'spot_order.*',
      ADD_SPOT_ORDER: 'spot_order.add',
      CONFIRM_SPOT_ORDER: 'spot_order.confirm'
    },
    PRICE_CHANGE_REQUEST: {
      MANAGE: 'pricing_change_request.*',
      REQUEST_NEW_PRICING: 'pricing_change_req.request',
      APPROVE: 'pricing_change_req.approve',
      DOWNLOAD: 'pricing_change_req.download'
    },
    JIT: {
      MANAGE: 'jit.*',
      ADD_JIT_RECORD: 'jit.add',
      UPDATE_JIT_RECORD: 'jit.update',
      MANAGE_PRICE_TYPE: 'price_type.*'
    },
    MASTER_DATA: {
      MANAGE: 'master_data.*'
    },
    JIT_IMPORT: {
      MANAGE: 'jit_import.*',
      IMPORT_MAIN: 'jit_import.main',
      IMPORT_IZAWA: 'jit_import.izawa',
      IMPORT_SHIKOKU: 'jit_import.shikoku'
    },
    ORGANIZATION: {
      MANAGE: 'organization.*'
    },
    CLAIM: {
      MANAGE: 'claim.*',
      EXPORT_CLAIM_LIST: 'claim.export',
      ADD_CLAIM_RECORD: 'claim.add',
      UPDATE_CLAIM_RECORD: 'claim.update'
    },
    END_CUSTOMER: {
      MANAGE: 'end_customer.*',
      MANAGE_ON_DEMAND: 'end_customer_on_demand.*',
      MANAGE_ON_JIT: 'end_customer_on_jit.*'
    },
    USER: {
      MANAGE: 'user.*',
      UPDATE: 'user.update',
      CREATE: 'user.create',
      DELETE: 'user.delete'
    },
    SHIPPING_STATUS: {
      MANAGE: 'shipping_status.*',
      EXPORT_LIST: 'shipping_status.export'
    },
    AUTHORIZATION: {
      MANAGE: 'authorization.*',
      ROLE: {
        MANAGE: 'role.*',
        UPDATE: 'role.update',
        CREATE: 'role.create',
        DELETE: 'role.delete'
      },
      PERMISSION: {
        MANAGE: 'permission.*',
        UPDATE: 'permission.update',
        CREATE: 'permission.create',
        DELETE: 'permission.delete'
      }
    }
  },
  JP_CLIENT_CODE: '00002',
  ID_CLIENT_CODE: '00001',
  MT_CLIENT_CODE: '00003',
  OF_CLIENT_CODE: '00004',
  OS_CLIENT_CODE: '00005',
  OFD_CLIENT_CODE: '00006',
  OSPH_CLIENT_CODE: '00007',
  ID_EN_LANG_CODE: 'id_en',
  JP_EN_LANG_CODE: 'jp_en',
  MT_EN_LANG_CODE: 'mt_en',
  OF_EN_LANG_CODE: 'mt_en',
  OS_EN_LANG_CODE: 'mt_en',
  LANGUAGE: {
    '00001': 'id',
    '00002': 'jp'
  },
  CONFIG_STORE_TYPE: {
    PHD: {
      KEY: 1,
      NAME: 'PHD'
    },
    PHR: {
      KEY: 2,
      NAME: 'PHR'
    },
    FCD: {
      KEY: 3,
      NAME: 'FCD'
    },
    FCDD: {
      KEY: 4,
      NAME: 'FCDD'
    },
    FCDE: {
      KEY: 5,
      NAME: 'FCDE'
    }
  },
  CONFIG_STORE_TYPE_BOTH_PHR_PHD: {
    PHD: {
      KEY: 1,
      NAME: 'PHD'
    },
    PHR: {
      KEY: 2,
      NAME: 'PHR'
    },
    PHD_PHR: {
      KEY: 3,
      NAME: 'PHD & PHR'
    },
    FCDC: {
      KEY: 4,
      NAME: 'FCDC'
    },
    FCDE: {
      KEY: 5,
      NAME: 'FCDE'
    }
  },
  GENDER: {
    FEMALE: 0,
    MALE: 1,
    AVERAGE: 2
  },
  DEFAULT_ACTIVE: [{
    key: 1,
    name: 'Active'
  }, {
    key: 0,
    name: 'Inactive'
  }],
  DEFAULT_USE: [{
    key: 1,
    name: 'Is use'
  }, {
    key: 0,
    name: 'Is not use'
  }],
  DEFAULT_ASSIGN: [{
    key: 1,
    name: 'Asign'
  }, {
    key: 0,
    name: 'Unasign'
  }],
  DELIVER_ROLE_CODE: 'mobile.deliver',
  AGENT_LOG_TIME_STATUS: {
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',
    PUT_ON_BREAK: 'PUT ON BREAK',
    PUT_ONLINE: 'PUT ONLINE'
  },
  TYPE_ORDER: {
    ALL: 0,
    CURRENT: 1,
    ADVANCE: 2,
    REJECT: 3,
    PROBLEM: 4,
    RESEND: 5,
    ASSIGN_DRIVER: 6
  },
  CUSTOMER: {
    NORMAL: 'NORMAL',
    BLOCKED: 'BLOCKED'
  },
  CUSTOMER_REWARD_TYPE: {
    USE: 0,
    EARN: 1
  },
  CUSTOMER_REWARD_STATUS: {
    WAITING: 0,
    SUCCESS: 1,
    CANCEL: 2
  },
  CUSTOMER_REWARD_STATUS_NAME: ['WAITING', 'SUCCESS', 'CANCEL'],
  LIST_REWARD_TYPE: {
    PURCHASE: 1,
    REFUND: 2,
    USE: 3
  },
  LANGUAGE_CONFIG: [{
    key: 1,
    value: 'jp_en'
  }, {
    key: 2,
    value: 'jp'
  }, {
    key: 3,
    value: 'id'
  }, {
    key: 4,
    value: 'id_en'
  }],
  CURRENCY: {
    USD: {
      KEY: 1,
      VALUE: 'USD'
    },
    JPY: {
      KEY: 2,
      VALUE: 'JPY'
    }
  },
  LANGUAGE_STORE: {
    ENGLISH: {
      KEY: 1,
      NAME: 'English'
    },
    HINDI: {
      KEY: 2,
      NAME: 'Hindi'
    },
    JAPANESE: {
      KEY: 3,
      NAME: 'Japanese'
    },
    BAHASA_MELAYU: {
      KEY: 4,
      NAME: 'Bahasa Melayu'
    },
    BAHASA_INDONESIA: {
      KEY: 5,
      NAME: 'Bahasa Indonesia'
    },
    THAI: {
      KEY: 6,
      NAME: 'ภาษาไทย'
    },
    CHINESE: {
      KEY: 7,
      NAME: '中文'
    }
  },
  SOCIAL: {
    FACEBOOK: {
      KEY: 1,
      NAME: 'Facebook'
    },
    TWITTER: {
      KEY: 2,
      NAME: 'Twitter'
    },
    INSTAGRAM: {
      KEY: 3,
      NAME: 'Instagram'
    }
  },
  INTEGRATION: {
    SHOPIFY: {
      KEY: 3,
      NAME: 'Shopify'
    },
    SWIGGY: {
      KEY: 3,
      NAME: 'Swiggy'
    },
    ZOMATO: {
      KEY: 3,
      NAME: 'Zomato'
    },
    OPENT_TABLE: {
      KEY: 3,
      NAME: 'Open Table'
    }
  },
  KEY_LOCAL_STORAGE: {
    CLIENT: 'client',
    STORE: 'store',
    UI_CONFIG: 'ui-config',
    CONFIG: 'config'
  },
  KDS_TYPE: {
    MAKE: 1,
    CUT: 2
  },
  ITEM_TYPE: {
    MATERIAL: 1,
    COMPONENT: 2
  },
  NOT_AVAILABLE: 'N/A',
  CATEGORY_TYPE_OBJECT: {
    NORMAL: {
      KEY: 1,
      NAME: 'Normal'
    },
    PIZZA: {
      KEY: 2,
      NAME: 'Pizza'
    },
    COMBO: {
      KEY: 3,
      NAME: 'Combo'
    },
    SPECIAL: {
      KEY: 4,
      NAME: 'Special'
    }
  },
  PAYMENT_TYPE: {
    CASH: {
      KEY: 1,
      NAME: 'cash_payment'
    },
    BANK: {
      KEY: 2,
      NAME: 'bank_payment'
    }
  },
  STORE_CASH_TYPE: {
    WITHDRAWAL: {
      KEY: 1,
      NAME: 'withdrawal'
    },
    DEPOSIT: {
      KEY: 2,
      NAME: 'deposit'
    }
  },
  RELATION_TYPE: {
    PRODUCT: {
      KEY: 0,
      NAME: 'Product'
    },
    CATEGORY: {
      KEY: 1,
      NAME: 'Category'
    },
    CROSS_SELL: {
      KEY: 2,
      NAME: 'Cross-sell'
    }
  },
  LIST_PLATFORMS: [{
    code: 'ANDROID',
    name: 'Android'
  }, {
    code: 'WEBDESKTOP',
    name: 'Web Desktop'
  }, {
    code: 'WEBMOBILE',
    name: 'Web Mobile'
  }, {
    code: 'IOS',
    name: 'Ios'
  }, {
    code: 'CALLCENTER',
    name: 'Call Center'
  }],
  QR_CODE_MANAGEMENT_CODE: 'qr.code.management',
  APP_NOTIFY: {
    BEE_ON_THE_GO: {
      key: 'bee-on-the-go',
      value: 'Bee On The Go'
    },
    PIZZA_HUT_INDONESIA: {
      key: 'pizza-hut-indonesia-fc42e',
      value: 'Pizza Hut Indonesia'
    }
  },
  LIST_TYPE_TOPPING: {
    DEFAULT: {
      KEY: 1,
      NAME: 'Default'
    }
  },
  USER_TYPE: {
    DMS: 1,
    APP: 2
  },
  ROUND_UP: 'UP',
  TYPE_REPORT: {
    TRANSACTION: 1,
    CUSTOMER: 2,
    TRANS_OUTLET: 3,
    EARN_REDEEM_POINT_DETAILS: 4
  },
  LIST_ACTIVITY: [{
    KEY: 1,
    NAME: 'EARN'
  }, {
    KEY: 0,
    NAME: 'REDEEM'
  }],
  ROLES: {
    DRIVER_CODE: 'mobile.deliver',
    MUM_CODE: 'MUM'
  },
  SYSTEM_CONFIG_KEY_LANG: 'lang',
  STATUS_EOD: {
    CONFIRMED: 'Confirmed',
    WARNING: 'Warning'
  },
  JP_CLIENT_UUID: '3b8a7c10-b1b1-11e9-8e0f-4389b2fc3a77',
  INDO_CLIENT_UUID: 'b39773b0-435b-4f41-80e9-163eef20e0ab',
  ORDER_STATUS: {
    DELIVERED: 'DELIVERED',
    CANCELLED: 'CANCELLED',
    FINISHED: 'FINISHED'
  },
  ORDER_STATUS_CODE: {
    VOID_DT: 'VOID-DT',
    FINISHED: 'FINISHED',
    CANCELLED: 'CANCELLED',
    FAILED: 'FAIL',
    DELIVERED: 'DELIVERED',
    DELIVERING: 'DELIVERING',
    SENDTOPOS: 'SENDTOPOS',
    CHECKOUT: 'CHECKOUT',
    COOKED: 'COOKED',
    CONFIRMED: 'CONFIRMED',
    SENTTOPOS: 'SENTTOPOS',
    COOKING: 'COOKING',
    ORDERED: 'ORDERED'
  },
  PACKAGE_TYPES: {
    BOX: {
      KEY: 0,
      NAME: 'Box'
    },
    ENVELOP: {
      KEY: 1,
      NAME: 'Envelop'
    },
    PALLET: {
      KEY: 2,
      NAME: 'Pallet'
    }
  },
  LIST_TYPE_MATERIAL: function LIST_TYPE_MATERIAL() {
    return {
      DAY: {
        KEY: 1,
        NAME: this.$t('table.day')
      },
      WEEK: {
        KEY: 2,
        NAME: this.$t('table.week')
      },
      MONTH: {
        KEY: 3,
        NAME: this.$t('table.month')
      }
    };
  },
  ORDER_ONLINE_STATUS: {
    ORDERED: {
      KEY: 0,
      NAME: 'ORDER CONFIRMED'
    },
    READY_TO_SHIP: {
      KEY: 1,
      NAME: 'READY TO SHIP'
    },
    DRAFT: {
      KEY: 2,
      NAME: 'READY TO SHIP'
    },
    CANCELLED: {
      KEY: 3,
      NAME: 'CANCELLED'
    },
    CONFIRMED: {
      KEY: 4,
      NAME: 'READY TO SHIP'
    },
    SERVICE_PROVIDER_FAILED: {
      KEY: 5,
      NAME: 'READY TO SHIP'
    },
    SERVICE_PROVIDER_DONE: {
      KEY: 6,
      NAME: 'READY TO SHIP'
    },
    RETURN: {
      KEY: 7,
      NAME: 'READY TO SHIP'
    },
    FIRST_MILE_TO_DO: {
      KEY: 8,
      NAME: 'READY TO SHIP'
    },
    FIRST_MILE_DEPARTED_TO_PICKUP_LOCATION: {
      KEY: 9,
      NAME: 'READY TO SHIP'
    },
    FIRST_MILE_ARRIVED_AT_PICKUP_LOCATION: {
      KEY: 10,
      NAME: 'READY TO SHIP'
    },
    FIRST_MILE_DOING: {
      KEY: 11,
      NAME: 'READY TO SHIP'
    },
    FIRST_MILE_DEPARTED_TO_DROPOFF_LOCATION: {
      KEY: 12,
      NAME: 'READY TO SHIP'
    },
    FIRST_MILE_ARRIVED_AT_DROPOFF_LOCATION: {
      KEY: 13,
      NAME: 'READY TO SHIP'
    },
    FIRST_MILE_FAILED: {
      KEY: 14,
      NAME: 'READY TO SHIP'
    },
    FIRST_MILE_FAILED_NO_ONE_RECEIVE: {
      KEY: 15,
      NAME: 'READY TO SHIP'
    },
    FIRST_MILE_FAILED_REJECT: {
      KEY: 16,
      NAME: 'READY TO SHIP'
    },
    FIRST_MILE_FAILED_RESCHEDULED: {
      KEY: 17,
      NAME: 'READY TO SHIP'
    },
    FIRST_MILE_FAILED_ADDRESS_NOT_FOUND: {
      KEY: 18,
      NAME: 'READY TO SHIP'
    },
    FIRST_MILE_FAILED_OUT_OF_TIME: {
      KEY: 19,
      NAME: 'READY TO SHIP'
    },
    FIRST_MILE_FAILED_VEHICLE_BREAKDOWN: {
      KEY: 20,
      NAME: 'READY TO SHIP'
    },
    FIRST_MILE_FAILED_BAD_WEATHER: {
      KEY: 21,
      NAME: 'READY TO SHIP'
    },
    FIRST_MILE_FAILED_CLOSED: {
      KEY: 22,
      NAME: 'READY TO SHIP'
    },
    FIRST_MILE_DONE: {
      KEY: 23,
      NAME: 'READY TO SHIP'
    },
    EXPORT_CUSTOM_CLEARANCE_TO_DO: {
      KEY: 24,
      NAME: 'READY TO SHIP'
    },
    EXPORT_CUSTOM_CLEARANCE_DEPARTED_TO_PICKUP_LOCATION: {
      KEY: 25,
      NAME: 'READY TO SHIP'
    },
    EXPORT_CUSTOM_CLEARANCE_ARRIVED_AT_PICKUP_LOCATION: {
      KEY: 26,
      NAME: 'READY TO SHIP'
    },
    EXPORT_CUSTOM_CLEARANCE_DOING: {
      KEY: 27,
      NAME: 'READY TO SHIP'
    },
    EXPORT_CUSTOM_CLEARANCE_DEPARTED_TO_DROPOFF_LOCATION: {
      KEY: 28,
      NAME: 'READY TO SHIP'
    },
    EXPORT_CUSTOM_CLEARANCE_ARRIVED_AT_DROPOFF_LOCATION: {
      KEY: 29,
      NAME: 'READY TO SHIP'
    },
    EXPORT_CUSTOM_CLEARANCE_FAILED: {
      KEY: 30,
      NAME: 'READY TO SHIP'
    },
    EXPORT_CUSTOM_CLEARANCE_DONE: {
      KEY: 31,
      NAME: 'READY TO SHIP'
    },
    LINE_HAUL_TO_DO: {
      KEY: 32,
      NAME: 'READY TO SHIP'
    },
    LINE_HAUL_DEPARTED_TO_PICKUP_LOCATION: {
      KEY: 33,
      NAME: 'READY TO SHIP'
    },
    LINE_HAUL_ARRIVED_AT_PICKUP_LOCATION: {
      KEY: 34,
      NAME: 'READY TO SHIP'
    },
    LINE_HAUL_DOING: {
      KEY: 35,
      NAME: 'READY TO SHIP'
    },
    LINE_HAUL_DEPARTED_TO_DROPOFF_LOCATION: {
      KEY: 36,
      NAME: 'READY TO SHIP'
    },
    LINE_HAUL_ARRIVED_AT_DROPOFF_LOCATION: {
      KEY: 37,
      NAME: 'READY TO SHIP'
    },
    LINE_HAUL_FAILED: {
      KEY: 38,
      NAME: 'READY TO SHIP'
    },
    LINE_HAUL_DONE: {
      KEY: 39,
      NAME: 'READY TO SHIP'
    },
    IMPORT_CUSTOM_CLEARANCE_TO_DO: {
      KEY: 40,
      NAME: 'READY TO SHIP'
    },
    IMPORT_CUSTOM_CLEARANCE_DEPARTED_TO_PICKUP_LOCATION: {
      KEY: 41,
      NAME: 'READY TO SHIP'
    },
    IMPORT_CUSTOM_CLEARANCE_ARRIVED_AT_PICKUP_LOCATION: {
      KEY: 42,
      NAME: 'READY TO SHIP'
    },
    IMPORT_CUSTOM_CLEARANCE_DOING: {
      KEY: 43,
      NAME: 'READY TO SHIP'
    },
    IMPORT_CUSTOM_CLEARANCE_DEPARTED_TO_DROPOFF_LOCATION: {
      KEY: 44,
      NAME: 'READY TO SHIP'
    },
    IMPORT_CUSTOM_CLEARANCE_ARRIVED_AT_DROPOFF_LOCATION: {
      KEY: 45,
      NAME: 'READY TO SHIP'
    },
    IMPORT_CUSTOM_CLEARANCE_FAILED: {
      KEY: 46,
      NAME: 'READY TO SHIP'
    },
    IMPORT_CUSTOM_CLEARANCE_DONE: {
      KEY: 47,
      NAME: 'READY TO SHIP'
    },
    LAST_MILE_TO_DO: {
      KEY: 48,
      NAME: 'READY TO SHIP'
    },
    LAST_MILE_DEPARTED_TO_PICKUP_LOCATION: {
      KEY: 49,
      NAME: 'READY TO SHIP'
    },
    LAST_MILE_ARRIVED_AT_PICKUP_LOCATION: {
      KEY: 50,
      NAME: 'READY TO SHIP'
    },
    LAST_MILE_DOING: {
      KEY: 51,
      NAME: 'READY TO SHIP'
    },
    LAST_MILE_DEPARTED_TO_DROPOFF_LOCATION: {
      KEY: 52,
      NAME: 'READY TO SHIP'
    },
    LAST_MILE_ARRIVED_AT_DROPOFF_LOCATION: {
      KEY: 53,
      NAME: 'READY TO SHIP'
    },
    LAST_MILE_FAILED: {
      KEY: 54,
      NAME: 'READY TO SHIP'
    },
    LAST_MILE_FAILED_NO_ONE_RECEIVE: {
      KEY: 55,
      NAME: 'READY TO SHIP'
    },
    LAST_MILE_FAILED_REJECT: {
      KEY: 56,
      NAME: 'READY TO SHIP'
    },
    LAST_MILE_FAILED_RESCHEDULED: {
      KEY: 57,
      NAME: 'READY TO SHIP'
    },
    LAST_MILE_FAILED_ADDRESS_NOT_FOUND: {
      KEY: 58,
      NAME: 'READY TO SHIP'
    },
    LAST_MILE_FAILED_OUT_OF_TIME: {
      KEY: 59,
      NAME: 'READY TO SHIP'
    },
    LAST_MILE_FAILED_VEHICLE_BREAKDOWN: {
      KEY: 60,
      NAME: 'READY TO SHIP'
    },
    LAST_MILE_FAILED_BAD_WEATHER: {
      KEY: 61,
      NAME: 'AWAITING PICK-UP'
    },
    LAST_MILE_FAILED_CLOSED: {
      KEY: 62,
      NAME: 'READY TO SHIP'
    },
    LAST_MILE_DONE: {
      KEY: 63,
      NAME: 'ORDER COMPLETED'
    }
  },
  EMPLOYEE_TYPE: {
    FULL_TIME: 6,
    PART_TIME: 1
  },
  ADS_VIDEO_DISPLAY: [{
    key: 1,
    value: 'Customer Display'
  }, {
    key: 2,
    value: 'Price Display'
  }],
  APP_VERSION: [{
    uuid: 'kds',
    value: 'KDS'
  }, {
    uuid: 'dispatch',
    value: 'Dispatch'
  }, {
    uuid: 'taker',
    value: 'Taker'
  }, {
    uuid: 'takeaway_monitor',
    value: 'Takeaway Monitor'
  }, {
    uuid: 'takerclient',
    value: 'Taker Client'
  }],
  SALAD: [{
    key: 1,
    label: 'シーザーサラダ',
    quantity: 1,
    time: new Date()
  }, {
    key: 2,
    label: 'オリエンタルサラダ',
    quantity: 1,
    time: new Date()
  }, {
    key: 3,
    label: 'グリーンサラダ',
    quantity: 1,
    time: new Date()
  }],
  DIQIT_MANAGEMENT_HQ: 'diqit.management.hq',
  CONTRACT_CHANGE_STATUS: {
    CURRENT_CONTRACT: 1,
    NEXT_CONTRACT: 2,
    RETIREMENT: 3
  },
  VALIDATE_EOD_TYPE: {
    GOOD_RECIEPT: 0,
    SHIFT: 1,
    ORDER: 2,
    MONEY_BAG: 3,
    DRIVER: 4
  },
  RABBIT_MQ: {
    URI: 'amqp://zexuucey:JkFpBP9bS8ItHMYdgJUfbeWqHYGPsmeS@flashy-orangutan.rmq.cloudamqp.com/zexuucey',
    QUEUE_NAME: 'JP.EOD.2'
  },
  QUEUE_STATUS: [{
    key: 2,
    value: 'ERROR'
  }, {
    key: 1,
    value: 'INPROCESS'
  }, {
    key: 0,
    value: 'FINISH'
  }],
  PRE_ORDER_TIME: {
    '1': {
      VALUE: 1,
      NAME: '1 day'
    },
    '2': {
      VALUE: 2,
      NAME: '2 days'
    },
    '3': {
      VALUE: 3,
      NAME: '3 days'
    },
    '4': {
      VALUE: 4,
      NAME: '4 days'
    },
    '5': {
      VALUE: 5,
      NAME: '5 days'
    },
    '6': {
      VALUE: 6,
      NAME: '6 days'
    },
    '7': {
      VALUE: 7,
      NAME: '7 days'
    }
  },
  SELF_PICKUP_TIME: {
    '10': {
      VALUE: 10,
      NAME: 'Maximum 10 mins'
    },
    '15': {
      VALUE: 15,
      NAME: 'Maximum 15 mins'
    },
    '20': {
      VALUE: 20,
      NAME: 'Maximum 20 mins'
    },
    '25': {
      VALUE: 25,
      NAME: 'Maximum 25 mins'
    },
    '30': {
      VALUE: 30,
      NAME: 'Maximum 30 mins'
    }
  },
  INTERNAL_CERTIFICATE: [{
    code: 'A',
    name: '認定なし'
  }, {
    code: 'B',
    name: 'LAS'
  }, {
    code: 'C',
    name: 'LARI'
  }, {
    code: 'D',
    name: 'LARII'
  }, {
    code: 'E',
    name: 'LMR'
  }],
  LIST_LEAFLETING_BOX: [{
    key: 0,
    name: 'Taget'
  }, {
    key: 1,
    name: '実績'
  }],
  // Actual,
  LIST_LEAFLETING_METHOD: [{
    key: 0,
    name: 'Self-distributor'
  }, {
    key: 1,
    name: 'Distributor'
  }, {
    key: 2,
    name: 'Newspaper'
  }],
  LIST_LEAFLETING_TYPE: [{
    key: 0,
    name: 'Folder'
  }, {
    key: 1,
    name: 'Broken'
  }],
  LIST_SPECIAL_REQUEST_TYPES: [{
    key: 'CONTACT_LESS_REQ',
    value: 'Contact less'
  }, {
    key: 'DEFAULT',
    value: 'Default'
  }],
  AA_PROMO_TYPES: [{
    key: 5,
    value: 'Free delivery'
  }, {
    key: 2,
    value: 'Monetary'
  }, {
    key: 1,
    value: 'Discount'
  }],
  AA_PROMO_ASSIGNS: [{
    key: 1,
    value: 'All Stores'
  }, {
    key: 2,
    value: 'Specific Stores'
  }, {
    key: 3,
    value: 'All Category'
  }, {
    key: 4,
    value: 'Specific Category'
  }, {
    key: 5,
    value: 'All Food Items'
  }, {
    key: 6,
    value: 'Specific Food Items'
  }],
  AREA_CODE: {
    JP: '81',
    ID: '62'
  },
  KPI_SETTING_KEY: [{
    value: 'on_time',
    title: 'On Time'
  }, {
    value: 'promise_time',
    title: 'Promise Time'
  }, {
    value: 'in_store_time',
    title: 'In Store Time'
  }, {
    value: 'hot_fresh',
    title: 'Hot Fresh'
  }, {
    value: 'web_waiting_time',
    title: 'Web Waiting Time'
  }, {
    value: 'system',
    title: 'System'
  }],
  KPI_SETTING_COLOR: [{
    value: 'red',
    title: 'Red'
  }, {
    value: 'yellow',
    title: 'Yellow'
  }, {
    value: 'blue',
    title: 'Blue'
  }],
  CASH_COUNT_TYPES: [{
    value: 'DISPATCH',
    title: 'DISPATCH'
  }, {
    value: 'OT',
    title: 'OT'
  }],
  VERIFY_STATUS: {
    VERIFY: {
      KEY: 0,
      NAME: 'Verified'
    },
    PENDING: {
      KEY: 1,
      NAME: 'Pending'
    },
    REJECT: {
      KEY: 2,
      NAME: 'Rejected'
    }
  },
  REJECT_REASON: {
    incomplete: {
      KEY: 'incomplete_business_information',
      NAME: 'Incomplete business information'
    },
    not_suitable: {
      KEY: 'not_suitable_candidate',
      NAME: 'Not suitable candidate'
    }
  },
  CLIENT_FOOD: '51f17bbb-3326-49e6-8dd5-75944c3a6daf',
  CLIENT_FOOD_SG: '1135b2dd-5bdd-4217-8514-c0c68d138067',
  FOOD_PERMISSION_CODE: {
    ORDERS: 'management.food.orders.list',
    STORES: 'management.store.v3.list',
    FOOD_ITEM: 'aa.food.management.food.item.list',
    MENU_CATEGORY: 'management.store.category.list',
    STORE_MENU: 'aa.food.management.store.menu.list',
    DASHBOARD: 'aa.food.management.dashboard',
    ACCOUNT_SETTING: 'management.account.list',
    PROMOS: 'management.promotionV3.list',
    SUBSCRIPTION_PLAN: 'management.storeSubscriptionPlan.list'
  },
  FOOD_ROLE_CODE: {
    ADMIN: 'AAOFO',
    MERCHANT: 'OFOM',
    SUB_MERCHANT: 'SOM'
  },
  MERCHANT_STATUS: ['management.store.v3.list', 'management.store.tag.list', 'management.store.notification.list', 'aa.food.management.food.item.list', 'management.store.category.list', 'aa.food.management.store.menu.list', 'aa.food.management.dashboard'],
  STORE_STATUS: ['management.food.orders.list', 'aa.food.management.dashboard', 'management.storeSubscriptionPlan.list'],
  PLAN_STATUS: ['management.account.list', 'management.promotionV3.list'],
  ESTIMATION_TIME: [{
    name: '15min',
    value: 15
  }, {
    name: '20min',
    value: 20
  }, {
    name: '30min',
    value: 30
  }, {
    name: '40min',
    value: 40
  }, {
    name: '50min',
    value: 50
  }, {
    name: '60min',
    value: 60
  }, {
    name: '70min',
    value: 70
  }, {
    name: '80min',
    value: 80
  }, {
    name: '90min',
    value: 90
  }, {
    name: '100min',
    value: 100
  }, {
    name: '110min',
    value: 110
  }, {
    name: '120min',
    value: 120
  }, {
    name: 'more than 120 min',
    value: 180
  }, {
    name: 'Cannot accept order',
    value: -1
  }],
  STORE_MESSAGE_TYPE: [{
    key: 'HQ',
    value: 'HQ'
  }, {
    key: 'Marketing',
    value: 'Marketing'
  }, {
    key: 'SaleTalk',
    value: 'Sale Talk'
  }],
  WEATHER: [{
    value: 1,
    name: 'sunny'
  }, {
    value: 2,
    name: 'cloudy'
  }, {
    value: 3,
    name: 'rainy'
  }, {
    value: 4,
    name: 'snow'
  }],
  ORDER_TYPE: [{
    name: 'On-demand',
    value: 1
  }, {
    name: 'Pre-order',
    value: 2
  }, {
    name: 'Self-pickup',
    value: 3
  }],
  DATE_RANGE: [{
    name: 'Received past 7 days',
    value: 1
  }, {
    name: 'Received last month',
    value: 2
  }, {
    name: 'Received this month',
    value: 3
  }, {
    name: 'Select date range',
    value: 4
  }],
  CHARGE_BACK_COSTS: [{
    key: 'AIRASIA',
    name: 'AirAsia'
  }, {
    key: 'MERCHANT',
    name: 'Merchant'
  }],
  DELIVERY_STATUS: {
    PREPARING: {
      key: 0,
      name: 'Preparing',
      name_self_pickup: 'Preparing',
      value: [-1],
      type: 'success'
    },
    AWAITING: {
      key: 1,
      name: 'Awaiting pickup',
      name_self_pickup: 'Ready to be picked up',
      value: [0, 1, 2, 4, 5, 6, 7],
      type: ''
    },
    CANCELLED: {
      key: 2,
      name: 'Cancelled',
      name_self_pickup: 'Cancelled',
      value: [3],
      type: 'danger'
    },
    READY_TO_PICKUP: {
      key: 3,
      name: 'Ready to pickup (driver accepted)',
      value: [8],
      type: 'warning'
    },
    DEPARTED_LOCATION: {
      key: 4,
      name: 'Departed to pickup location',
      value: [9],
      type: 'warning'
    },
    DEPARTED_AIRPORT: {
      key: 5,
      name: 'Departed to pickup location in destination airport',
      value: [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49],
      type: 'warning'
    },
    ARRIVED_AIRPORT: {
      key: 6,
      name: 'Arrived at pickup location in destination airport',
      value: [50],
      type: 'warning'
    },
    DELIVERING: {
      key: 7,
      name: 'Delivering',
      value: [51],
      type: 'success'
    },
    DEPARTED_DROPOFF: {
      key: 8,
      name: 'Departed to dropoff location',
      value: [52],
      type: 'success'
    },
    ARRIVED_DROPOFF: {
      key: 9,
      name: 'Arrived at dropoff location',
      value: [53, 54, 55, 56, 57, 58, 59, 60, 61, 62],
      type: 'success'
    },
    DELIVERED: {
      key: 10,
      name: 'Delivered',
      name_self_pickup: 'Picked up',
      value: [63],
      type: 'info'
    }
  },
  DAY_OF_WEEK: ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'],
  WEATHER_TYPE: ['N/A', 'sunny', 'cloudy', 'rainy', 'snowy'],
  DELIVERY_STATUS_MERCHANT: {
    CANCELLED: {
      key: 2,
      name: 'Cancelled',
      value: [3],
      type: 'danger'
    },
    DELIVERED: {
      key: 10,
      name: 'Delivered',
      value: [63],
      type: 'info'
    }
  },
  FORMAT_DAY: 'YYYY-MM-DD',
  FORMAT_TIME: 'YYYY-MM-DD HH:mm:ss Z',
  TYPE_DISPLAY: {
    VALUE: 0,
    PERCENT: 1,
    NUMBER_AMOUNT: 2,
    VALUE_PERCENT: 3
  },
  MAX_APPOINTMENT_TIME_AVAIABLE: 6,
  URL_ADS_VIDEO: 'https://api-uat.phj-oss.com/cms-order-pos/v1',
  MATERIAL_TYPE: [{
    key: 99,
    value: '対象外'
  },
  // None
  {
    key: 1,
    value: '日次'
  },
  // Daily
  {
    key: 2,
    value: '週次'
  },
  // Weekly
  {
    key: 3,
    value: '月次'
  } // Monthly
  ],
  LIST_GIFTCARD_TYPE: [{
    key: 1,
    name: 'Cash'
  }, {
    key: 2,
    name: 'Percentage'
  }],
  UNIT_PRICE_TYPE: [{
    key: 1,
    value: '流動'
  }, {
    key: 2,
    value: '補用、'
  }, {
    key: 3,
    value: '試作'
  }],
  NOTIFY_SHOW_DURATION: 2000,
  DEMAND_LIST: {
    NUM_WEEK_DISPLAY: 15,
    NUM_WEEK_DETAIL: 8,
    START_INDEX_OF_SALES_PLAN: 5
  },
  DATA_STREAM_ROW_LITMIT: 200,
  IMPORT_TYPE: {
    MAIN: {
      KEY: 1,
      VALUE: 'import_format_type.main'
    }
    // SHIKOKU: {
    //   KEY: 2,
    //   VALUE: 'import_format_type.shikoku',
    // },
    // IZAWA: {
    //   KEY: 3,
    //   VALUE: 'import_format_type.izawa',
    // },
  },
  REGISTRATION_TYPE: {
    CLAIM: {
      KEY: 1,
      VALUE: 'registration_type.claim'
    },
    RETURNS: {
      KEY: 2,
      VALUE: 'registration_type.returns'
    }
    // RECORDED: {
    //   KEY: 3,
    //   VALUE: 'registration_type.recorded',
    // },
    // NOT_RECORDED: {
    //   KEY: 4,
    //   VALUE: 'registration_type.not_recorded',
    // },
  },
  ISSUE_TYPE: ['重大異常(品証部にて処置決定)', 'クラック・割れ', '未加工・未浸油', '熱処理異常', '具材・異油', '工程飛び', '未経験の異常', 'その他異常', 'カケ', '傷・打痕', 'バリ', '異物付着', '異品混入', '巣', 'アベック', '寸法(+)', '寸法(-)', '金型破損', '図面不良', '精度不良', '加工ミス', '音', '振動', '発熱', '漏れ(油)', '漏れ(エア)', '漏れ(ガス)', '動作異常', 'その他'],
  DEMAND_CONFIRM_STATUS: [{
    label: 'Confirmed',
    value: '1'
  }, {
    label: 'Not Confirmed',
    value: '0'
  }],
  DEFAULT_CHECKMARK: [{
    key: 1,
    name: 'Updated'
  }, {
    key: 0,
    name: 'Not Updated'
  }],
  STATUS_ORDER: {
    CONFIRMED: {
      KEY: 1,
      VALUE: 'confirmed'
    },
    PENDING_CONFIRMATION: {
      KEY: 0,
      VALUE: 'pending_confirmation'
    }
  },
  NUM_DAYS_OF_WEEK: 7,
  PRODUCT_ORDER_TYPE: {
    NORMAL: 'Normal',
    SPOT: 'Spot'
  },
  PRICE_INCREASE: ['原料高騰', '小ロット品', '不味採算品', '仮単価修正', 'バラモノ', 'その他', '修正分', '仕切改定', '為替差', '電力値上げ'],
  PRICE_DECREASE: ['定期値引ＶＡ', '定期値引＋ＶＡ', '仮単価修正', 'その他', '原料費低落', '修正分', '仕切改定', '為替差', '電力値下げ'],
  PRICE: {
    INCREASE: {
      KEY: 1,
      VALUE: 'price.increase'
    },
    DECREASE: {
      KEY: 2,
      VALUE: 'price.decrease'
    }
  },
  REQUESTER_STEPS: [{
    key: 0,
    value: 'normal_user_steps.pending'
  }, {
    key: 1,
    value: 'normal_user_steps.under_review'
  }, {
    key: 2,
    value: 'normal_user_steps.approved'
  }, {
    key: 3,
    value: 'normal_user_steps.rejected'
  }],
  APPROVER_STEPS: [{
    key: 0,
    value: 'approver_steps.pending'
  }, {
    key: 1,
    value: 'approver_steps.sale_approval',
    role: 'sale-team'
  }, {
    key: 2,
    value: 'approver_steps.manufacturing_approval',
    role: 'manufacturing-team'
  }, {
    key: 3,
    value: 'approver_steps.management_approval',
    role: 'management-team'
  }],
  APPROVER_STATUS: [{
    VALUE: 'approval_status.sale_approval',
    KEY: 1
  }, {
    VALUE: 'approval_status.manufacturing_approval',
    KEY: 2
  }, {
    VALUE: 'approval_status.management_approval',
    KEY: 3
  }, {
    VALUE: 'approval_status.rejected',
    KEY: 4
  }],
  REQUESTER_STATUS: [{
    VALUE: 'normal_user_steps.pending',
    KEY: 1
  }, {
    VALUE: 'normal_user_steps.under_review',
    KEY: 2
  }, {
    VALUE: 'normal_user_steps.approved',
    KEY: 3
  }, {
    VALUE: 'normal_user_steps.rejected',
    KEY: 4
  }],
  QUANTITY_OPTIONS: [{
    VALUE: 'quantity_value.negative',
    KEY: 1
  }, {
    VALUE: 'quantity_value.positive',
    KEY: 2
  }],
  MANUFACTURING_ORDER_TYPES: [{
    label: 'table.spot',
    value: 'Spot'
  }, {
    label: 'table.normal',
    value: 'Normal'
  }],
  MANUFACTURING_COLUMNS: [{
    label: 'table.end_customer',
    KEY: 1
  }, {
    label: 'table.internal_manufacturing_product_name',
    KEY: 2
  }, {
    label: 'table.customer_product_id',
    KEY: 3
  }, {
    label: 'table.product_name',
    KEY: 4
  }, {
    label: 'table.product_group',
    KEY: 5
  }, {
    label: 'table.order_type',
    KEY: 6
  }, {
    label: 'table.fixed_weeks_frame',
    KEY: 7
  }],
  SPOT_CONFIRM_STATUS: [{
    label: 'spot_status.confirmed',
    value: '1'
  }, {
    label: 'spot_status.pending',
    value: '0'
  }],
  MAX_EXPORT_WEEK_RANGE: 15,
  CLAIM_OR_RETURNS: [{
    label: 'registration_type.claim',
    value: 1
  }, {
    label: 'registration_type.returns',
    value: 2
  }],
  CLAIM_STATUS_OPTIONS: [{
    label: 'claim_status.draft',
    value: 1
  }, {
    label: 'claim_status.untreated',
    value: 2
  }, {
    label: 'claim_status.under_consideration',
    value: 3
  }, {
    label: 'claim_status.treated',
    value: 4
  }, {
    label: 'claim_status.solved',
    value: 5
  }],
  DIAMET_ORG_CODE: 'DIAMET',
  TABLE_HEIGHT_OFFSET: 210,
  PRICING_HISTORY_TYPE: [{
    label: 'Redshift',
    value: 1
  }, {
    label: 'CMS',
    value: 2
  }],
  END_CUSTOMER_DISTRIBUTOR_TYPE: 1,
  END_CUSTOMER_AGENT_TYPE: 2,
  IS_UPDATE: 1
};