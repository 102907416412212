/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout';
import { RouteContainerComponent } from '@/utils';
import CONSTANTS from '@/constants';
var jitSalesManagementRouter = {
  path: '/jit-sales-management',
  component: Layout,
  redirect: 'noredirect',
  name: 'DemandManagement',
  meta: {
    title: 'jit_sales_management',
    icon: 'component',
    permissions: [CONSTANTS.PERMISSION_NAME.JIT.MANAGE]
  },
  children: [
  // {
  //   path: 'list-master-data',
  //   component: RouteContainerComponent,
  //   meta: {
  //     title: 'jit_list_master_data',
  //     // permissions: [constants.PERMISSION_NAME.MANAGE],
  //   },
  //   children: [
  //     {
  //       path: '',
  //       component: () => import('@/views/JITList/listMasterData'),
  //       name: 'JITListMaster',
  //       meta: {
  //         title: 'jit_list_master_data',
  //         activeMenu: '/jit-sales-management/list-master-data',
  //       },
  //     },
  //   ],
  // },
  {
    path: 'list',
    component: RouteContainerComponent,
    meta: {
      title: 'jit_list'
      // permissions: [constants.PERMISSION_NAME.MANAGE],
    },
    children: [{
      path: '',
      component: function component() {
        return import('@/views/JITList/list');
      },
      name: 'JITListDefault',
      meta: {
        title: 'jit_list',
        activeMenu: '/jit-sales-management/list'
      }
    }]
  }, {
    path: 'import-data',
    component: RouteContainerComponent,
    meta: {
      title: 'import_data',
      permissions: [CONSTANTS.PERMISSION_NAME.JIT_IMPORT.MANAGE]
    },
    children: [{
      path: '',
      component: function component() {
        return import('@/views/JITImport');
      },
      name: 'JITImport',
      meta: {
        title: 'import_data',
        activeMenu: '/jit-sales-management/import-data'
      }
    }]
  }]
};
export default jitSalesManagementRouter;