import "core-js/modules/es6.array.find-index";
import _toConsumableArray from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
import _objectSpread from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
import "core-js/modules/es6.array.find";
import "core-js/modules/es6.function.name";
import Vue from 'vue';
var state = function state() {
  return {
    promotionData: {
      client_uuid: '',
      category_type: null,
      category: {
        uuid: null,
        name: null
      },
      tag_uuids: [],
      code: '',
      sku: '',
      name: '',
      store_group_uuids: [],
      store_uuids: [],
      is_alcohol: 0,
      is_autocook: 0,
      sequence: 0,
      point: 0,
      description: '',
      video: '',
      image: '',
      thumbnail: '',
      type_ids: [],
      tax_rate: [],
      prices: [],
      allowed_type_ids: [],
      additional_type_ids: [],
      is_active: 0,
      kds_make: null,
      make_time: 0,
      kds_cut: null,
      cut_time: 0,
      is_only_lunch: 0,
      is_half_and_half: 0,
      is_combo_half: 0,
      is_discount: 0,
      is_hut_day: 0,
      is_hide_from_ui: 0,
      start_date: null,
      end_date: null,
      main_description: '',
      rate: 0,
      no_of_review: 0,
      currency_uuid: '',
      status_uuid: '',
      ordered_quantity: 0,
      quantity: 0,
      height: 0,
      length: 0,
      weight: 0,
      width: 0,
      package_type: 0
    },
    toppingData: [],
    comboGroupData: [],
    toppingGroupData: [],
    config: {
      types: [],
      channels: [],
      tiers: [],
      taxes: []
    },
    stores: [],
    product_additional_information: {
      language_ids: [],
      doctors: [],
      sub_title: '',
      phone: '',
      name: '',
      origin_id: '',
      discount_percentage: 0,
      location: '',
      discounted_price: 0,
      faq: '',
      t_and_c: '',
      disclaimer: '',
      number_of_unit: 0,
      additional_description: '',
      duration_time: 0,
      advance_day: 0,
      process: 0,
      required_doctor: 1,
      available_times: [],
      snap_duration: '1'
    }
  };
};
var getters = {
  getCategoryType: function getCategoryType(state) {
    return state.productData ? state.productData.category_type : 0;
  },
  getComboGroups: function getComboGroups(state) {
    var result = [];
    state.comboGroupData.map(function (_ref) {
      var uuid = _ref.uuid,
        max = _ref.max,
        min = _ref.min,
        quantity = _ref.quantity,
        name = _ref.name,
        sequence = _ref.sequence,
        comboProducts = _ref.comboProducts;
      result.push({
        uuid: uuid,
        max: max,
        min: min,
        quantity: quantity,
        name: name,
        sequence: sequence,
        comboProductCount: comboProducts.length
      });
    });
    return result;
  },
  getComboGroupByUuid: function getComboGroupByUuid(state) {
    return function (_ref2) {
      var uuid = _ref2.uuid;
      var result = state.comboGroupData.find(function (g) {
        return g.uuid === uuid;
      });
      if (!result) {
        return null;
      }
      return {
        uuid: result.uuid,
        name: result.name,
        quantity: result.quantity,
        min: result.min,
        max: result.max,
        sequence: result.sequence,
        combo_uuid: result.combo_uuid
      };
    };
  },
  getComboProducts: function getComboProducts(state) {
    return function (_ref3) {
      var groupIndex = _ref3.groupIndex;
      if (state.comboGroupData[groupIndex]) {
        return state.comboGroupData[groupIndex].comboProducts;
      }
      return [];
    };
  },
  getComboOptionGroups: function getComboOptionGroups(state) {
    return function (_ref4) {
      var groupIndex = _ref4.groupIndex,
        productIndex = _ref4.productIndex;
      if (!state.comboGroupData[groupIndex].comboProducts[productIndex]) {
        return [];
      }
      return state.comboGroupData[groupIndex].comboProducts[productIndex].optionGroups;
    };
  },
  getComboOptionGroup: function getComboOptionGroup(state) {
    return function (_ref5) {
      var groupIndex = _ref5.groupIndex,
        productIndex = _ref5.productIndex,
        optionGroupIndex = _ref5.optionGroupIndex;
      if (state.comboGroupData[groupIndex].comboProducts[productIndex]) {
        return state.comboGroupData[groupIndex].comboProducts[productIndex].optionGroups[optionGroupIndex];
      }
      return {};
    };
  },
  getComboProduct: function getComboProduct(state) {
    return function (_ref6) {
      var groupIndex = _ref6.groupIndex,
        productIndex = _ref6.productIndex;
      return state.comboGroupData[groupIndex].comboProducts[productIndex];
    };
  },
  getMasterStore: function getMasterStore(state) {
    return state.stores;
  },
  getToppingGroups: function getToppingGroups(state) {
    var result = [];
    state.toppingGroupData.map(function (_ref7) {
      var uuid = _ref7.uuid,
        max = _ref7.max,
        min = _ref7.min,
        name = _ref7.name,
        sequence = _ref7.sequence,
        product_uuid = _ref7.product_uuid,
        topping_uuids = _ref7.topping_uuids;
      result.push({
        uuid: uuid,
        max: max,
        min: min,
        name: name,
        sequence: sequence,
        product_uuid: product_uuid,
        topping_uuids: topping_uuids
      });
    });
    return result;
  },
  getToppingGroupByIndex: function getToppingGroupByIndex(state) {
    return function (_ref8) {
      var index = _ref8.index;
      var result = state.toppingGroupData[index];
      if (!result) {
        return null;
      }
      return {
        uuid: result.uuid,
        name: result.name,
        min: result.min,
        max: result.max,
        sequence: result.sequence,
        product_uuid: result.product_uuid,
        topping_uuids: result.topping_uuids
      };
    };
  }
};
var actions = {
  SetCategoryType: function SetCategoryType(_ref9, categoryType) {
    var commit = _ref9.commit;
    commit('SET_CATEGORY_TYPE', categoryType);
  },
  SetConfig: function SetConfig(_ref10, payload) {
    var commit = _ref10.commit;
    commit('SET_CONFIG', payload);
  },
  SetStores: function SetStores(_ref11, payload) {
    var commit = _ref11.commit;
    commit('SET_STORES', payload);
  },
  SetProductData: function SetProductData(_ref12, payload) {
    var commit = _ref12.commit;
    commit('SET_PRODUCT_DATA', payload);
  },
  SetComboGroupData: function SetComboGroupData(_ref13, payload) {
    var commit = _ref13.commit;
    commit('SET_COMBO_GROUP_DATA', payload);
  },
  AddComboGroup: function AddComboGroup(_ref14, payload) {
    var commit = _ref14.commit;
    commit('ADD_COMBO_GROUP', payload);
  },
  UpdateComboGroup: function UpdateComboGroup(_ref15, payload) {
    var commit = _ref15.commit;
    commit('UPDATE_COMBO_GROUP', payload);
  },
  RemoveComboGroup: function RemoveComboGroup(_ref16, payload) {
    var commit = _ref16.commit;
    commit('REMOVE_COMBO_GROUP', payload);
  },
  AddComboProduct: function AddComboProduct(_ref17, payload) {
    var commit = _ref17.commit;
    commit('ADD_COMBO_PRODUCT', payload);
  },
  RemoveComboProduct: function RemoveComboProduct(_ref18, payload) {
    var commit = _ref18.commit;
    commit('REMOVE_COMBO_PRODUCT', payload);
  },
  AddComboOptionGroup: function AddComboOptionGroup(_ref19, payload) {
    var commit = _ref19.commit;
    commit('ADD_COMBO_OPTION_GROUP', payload);
  },
  SetComboOptionGroup: function SetComboOptionGroup(_ref20, payload) {
    var commit = _ref20.commit;
    commit('SET_COMBO_OPTION_GROUP', payload);
  },
  RemoveComboOptionGroup: function RemoveComboOptionGroup(_ref21, payload) {
    var commit = _ref21.commit;
    commit('REMOVE_COMBO_OPTION_GROUP', payload);
  },
  SetToppingGroupData: function SetToppingGroupData(_ref22, payload) {
    var commit = _ref22.commit;
    commit('SET_TOPPING_GROUP_DATA', payload);
  },
  AddToppingGroup: function AddToppingGroup(_ref23, payload) {
    var commit = _ref23.commit;
    commit('ADD_TOPPING_GROUP', payload);
  },
  UpdateToppingGroup: function UpdateToppingGroup(_ref24, payload) {
    var commit = _ref24.commit;
    commit('UPDATE_TOPPING_GROUP', payload);
  },
  RemoveToppingGroup: function RemoveToppingGroup(_ref25, payload) {
    var commit = _ref25.commit;
    commit('REMOVE_TOPPING_GROUP', payload);
  }
};
var mutations = {
  SET_CATEGORY_TYPE: function SET_CATEGORY_TYPE(state, categoryType) {
    if (typeof state.productData === 'undefined') {
      state.productData = {};
    }
    state.productData.category_type = categoryType;
  },
  SET_CONFIG: function SET_CONFIG(state, _ref26) {
    var types = _ref26.types,
      channels = _ref26.channels,
      tiers = _ref26.tiers,
      taxes = _ref26.taxes;
    state.config = {
      types: types,
      channels: channels,
      tiers: tiers,
      taxes: taxes
    };
  },
  SET_STORES: function SET_STORES(state, stores) {
    state.stores = stores;
  },
  SET_PRODUCT_DATA: function SET_PRODUCT_DATA(state, productData) {
    state.productData = _objectSpread(_objectSpread({}, state.productData), productData);
  },
  SET_COMBO_GROUP_DATA: function SET_COMBO_GROUP_DATA(state, comboGroupData) {
    state.comboGroupData = comboGroupData;

    // trigger re rendering from optionAndOptionGroupCombo
    state.comboGroupData.push({});
    state.comboGroupData.pop();
  },
  ADD_COMBO_GROUP: function ADD_COMBO_GROUP(state, comboGroup) {
    comboGroup.comboProducts = [];
    state.comboGroupData = [].concat(_toConsumableArray(state.comboGroupData), [comboGroup]);
  },
  UPDATE_COMBO_GROUP: function UPDATE_COMBO_GROUP(state, data) {
    var name = data.name,
      min = data.min,
      max = data.max,
      quantity = data.quantity,
      sequence = data.sequence;
    var index = state.comboGroupData.findIndex(function (g) {
      return g.uuid === data.uuid;
    });
    if (index >= 0) {
      Vue.set(state.comboGroupData[index], 'name', name);
      Vue.set(state.comboGroupData[index], 'min', min);
      Vue.set(state.comboGroupData[index], 'max', max);
      Vue.set(state.comboGroupData[index], 'quantity', quantity);
      Vue.set(state.comboGroupData[index], 'sequence', sequence);
    }
  },
  REMOVE_COMBO_GROUP: function REMOVE_COMBO_GROUP(state, index) {
    state.comboGroupData.splice(index, 1);
  },
  ADD_COMBO_PRODUCT: function ADD_COMBO_PRODUCT(state, _ref27) {
    var groupIndex = _ref27.groupIndex,
      data = _ref27.data;
    state.comboGroupData[groupIndex].comboProducts.push(data);
  },
  REMOVE_COMBO_PRODUCT: function REMOVE_COMBO_PRODUCT(state, _ref28) {
    var groupIndex = _ref28.groupIndex,
      productUUID = _ref28.productUUID;
    var index = state.comboGroupData[groupIndex].comboProducts.findIndex(function (p) {
      return p.product_uuid === productUUID;
    });
    state.comboGroupData[groupIndex].comboProducts.splice(index, 1);
  },
  ADD_COMBO_OPTION_GROUP: function ADD_COMBO_OPTION_GROUP(state, _ref29) {
    var groupIndex = _ref29.groupIndex,
      productIndex = _ref29.productIndex,
      data = _ref29.data;
    state.comboGroupData[groupIndex].comboProducts[productIndex].optionGroups.push(data);
  },
  SET_COMBO_OPTION_GROUP: function SET_COMBO_OPTION_GROUP(state, _ref30) {
    var groupIndex = _ref30.groupIndex,
      productIndex = _ref30.productIndex,
      optionGroupIndex = _ref30.optionGroupIndex,
      data = _ref30.data;
    state.comboGroupData[groupIndex].comboProducts[productIndex].optionGroups[optionGroupIndex] = data;
  },
  REMOVE_COMBO_OPTION_GROUP: function REMOVE_COMBO_OPTION_GROUP(state, _ref31) {
    var groupIndex = _ref31.groupIndex,
      productIndex = _ref31.productIndex,
      optionGroupIndex = _ref31.optionGroupIndex;
    state.comboGroupData[groupIndex].comboProducts[productIndex].optionGroups.splice(optionGroupIndex, 1);
  },
  SET_TOPPING_GROUP_DATA: function SET_TOPPING_GROUP_DATA(state, toppingGroupData) {
    state.toppingGroupData = toppingGroupData;

    // trigger re rendering from optionAndOptionGroupCombo
    state.toppingGroupData.push({});
    state.toppingGroupData.pop();
  },
  ADD_TOPPING_GROUP: function ADD_TOPPING_GROUP(state, toppingGroup) {
    state.toppingGroupData = [].concat(_toConsumableArray(state.toppingGroupData), [toppingGroup]);
  },
  UPDATE_TOPPING_GROUP: function UPDATE_TOPPING_GROUP(state, data) {
    var name = data.name,
      topping_uuids = data.topping_uuids,
      min = data.min,
      max = data.max,
      sequence = data.sequence,
      index = data.index;
    if (index >= 0) {
      Vue.set(state.toppingGroupData[index], 'name', name);
      Vue.set(state.toppingGroupData[index], 'min', min);
      Vue.set(state.toppingGroupData[index], 'max', max);
      Vue.set(state.toppingGroupData[index], 'sequence', sequence);
      Vue.set(state.toppingGroupData[index], 'topping_uuids', topping_uuids);
    }
  },
  REMOVE_TOPPING_GROUP: function REMOVE_TOPPING_GROUP(state, index) {
    state.toppingGroupData.splice(index, 1);
  }
};
export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
};