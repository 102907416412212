/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout';
import { RouteContainerComponent } from '@/utils';
import CONSTANTS from '@/constants';
var authorizationRouter = {
  path: '/authorization',
  component: Layout,
  redirect: 'noredirect',
  name: 'AuthorizationManagement',
  meta: {
    title: 'authorization',
    icon: 'lock',
    permissions: [CONSTANTS.PERMISSION_NAME.AUTHORIZATION.MANAGE]
  },
  children: [{
    path: 'role',
    component: RouteContainerComponent,
    meta: {
      title: 'role_management',
      permissions: [CONSTANTS.PERMISSION_NAME.AUTHORIZATION.ROLE.MANAGE]
    },
    children: [{
      path: '',
      component: function component() {
        return import('@/views/roleManagement/list.vue');
      },
      name: 'RoleManagement',
      meta: {
        title: 'role_management',
        activeMenu: '/authorization/role'
      }
    }, {
      path: 'create',
      component: function component() {
        return import('@/views/roleManagement/create.vue');
      },
      name: 'CreateRole',
      meta: {
        title: 'create_role',
        activeMenu: '/authorization/role',
        permissions: [CONSTANTS.PERMISSION_NAME.AUTHORIZATION.ROLE.CREATE]
      },
      hidden: true
    }, {
      path: 'edit',
      component: function component() {
        return import('@/views/roleManagement/edit.vue');
      },
      name: 'EditRole',
      meta: {
        title: 'edit_role',
        activeMenu: '/authorization/role',
        permissions: [CONSTANTS.PERMISSION_NAME.AUTHORIZATION.ROLE.UPDATE]
      },
      hidden: true
    }]
  }, {
    path: 'permission',
    component: RouteContainerComponent,
    meta: {
      title: 'permission_management',
      permissions: [CONSTANTS.PERMISSION_NAME.AUTHORIZATION.PERMISSION.MANAGE]
    },
    children: [{
      path: '',
      component: function component() {
        return import('@/views/permissionManagement/list');
      },
      name: 'PermissionManagement',
      meta: {
        title: 'permission_management',
        activeMenu: '/authorization/permission'
      }
    }, {
      path: 'create',
      component: function component() {
        return import('@/views/permissionManagement/create.vue');
      },
      name: 'CreatePermission',
      meta: {
        title: 'create_permission',
        activeMenu: '/authorization/permission',
        permissions: [CONSTANTS.PERMISSION_NAME.AUTHORIZATION.PERMISSION.CREATE]
      },
      hidden: true
    }, {
      path: 'edit/:uuid',
      component: function component() {
        return import('@/views/permissionManagement/components/detail');
      },
      name: 'EditPermission',
      meta: {
        title: 'edit_permission',
        activeMenu: '/authorization/permission',
        permissions: [CONSTANTS.PERMISSION_NAME.AUTHORIZATION.PERMISSION.UPDATE]
      },
      hidden: true
    }]
  }]
};
export default authorizationRouter;