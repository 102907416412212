/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout';
import CONSTANTS from '@/constants';
var claimManagementRouter = {
  path: '/claim-management',
  component: Layout,
  redirect: 'noredirect',
  name: 'ClaimManagement',
  meta: {
    icon: 'form',
    permissions: [CONSTANTS.PERMISSION_NAME.CLAIM.MANAGE]
  },
  children: [{
    path: '',
    component: function component() {
      return import('@/views/claim/index.vue');
    },
    name: 'ClaimManagement',
    meta: {
      title: 'claim_management',
      activeMenu: '/claim-management'
    }
  }]
};
export default claimManagementRouter;