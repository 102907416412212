import request from '@/utils/request';
var AUTH_API = "".concat(process.env.VUE_APP_AUTH_API, "/v1");
export function login(data) {
  var url = "".concat(AUTH_API, "/login");
  return request({
    url: url,
    method: 'post',
    data: data
  });
}
export function getInfo() {
  return request({
    url: "".concat(AUTH_API, "/profile"),
    method: 'get'
  });
}
export function logout() {
  return request({
    url: "".concat(AUTH_API, "/logout"),
    method: 'post'
  });
}
export function forgotPassword(data) {
  return request({
    url: "".concat(AUTH_API, "/forgot-password"),
    method: 'post',
    data: data
  });
}
export function resetPassword(token, data) {
  return request({
    url: "".concat(AUTH_API, "/reset-password/").concat(token),
    method: 'post',
    data: data
  });
}