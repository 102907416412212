import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _objectSpread from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Cookies from 'js-cookie';
import elementJaLocale from 'element-ui/lib/locale/lang/ja';
import elementEnLocale from 'element-ui/lib/locale/lang/en'; // element-ui lang
import jpLocale from "./jp";
import idLocale from "./id";
import idEnLocale from "./id_en";
import jpEnLocale from "./jp_en";
import enLocale from "./en";
import beePosEnLocale from "./bee_pos_en";
import beePosJpLocale from "./bee_pos_jp";
Vue.use(VueI18n);
export var messages = {
  en: _objectSpread(_objectSpread({}, enLocale), elementEnLocale),
  '00002_jp': _objectSpread(_objectSpread({}, jpLocale), elementJaLocale),
  '00001_id': _objectSpread(_objectSpread({}, idLocale), elementEnLocale),
  '00001_en': _objectSpread(_objectSpread({}, idEnLocale), elementEnLocale),
  '00002_en': _objectSpread(_objectSpread({}, jpEnLocale), elementEnLocale),
  '00004_en': _objectSpread(_objectSpread({}, beePosEnLocale), elementEnLocale),
  '00004_jp': _objectSpread(_objectSpread({}, beePosJpLocale), elementEnLocale)
};
export function getLanguage() {
  var chooseLanguage = Cookies.get('language');
  if (chooseLanguage) return chooseLanguage;

  // if has not choose language
  var language = (navigator.language || navigator.browserLanguage).toLowerCase();
  var locales = Object.keys(messages);
  for (var _i = 0, _locales = locales; _i < _locales.length; _i++) {
    var locale = _locales[_i];
    if (language.indexOf(locale) > -1) {
      return locale;
    }
  }
  // return 'en' // Set return 'en' if want to show as English Language
  return '00002_jp'; // Set default language is Japanese
}
var i18n = new VueI18n({
  // set locale
  // options: en | zh | es
  locale: getLanguage(),
  // fallbackLocale: 'en', // Set return 'en' if want to show as English Language
  fallbackLocale: '00002_jp',
  // Use Japanese if the text does not translate
  // set locale messages
  messages: messages
});
export default i18n;