import _objectSpread from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//

import { mapGetters } from 'vuex';
export default {
  name: 'App',
  computed: _objectSpread({}, mapGetters({
    stores: 'user/stores',
    storeGroups: 'user/storeGroups',
    client_uuid: 'user/clientUUID'
  }))
};