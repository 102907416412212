import _objectSpread from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/web.dom.iterable";
import Vue from 'vue';
import Vuex from 'vuex';
import getters from "./getters";
Vue.use(Vuex);

// https://webpack.js.org/guides/dependency-management/#requirecontext
var modulesFiles = require.context('./modules', false, /\.js$/);

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
var modules = modulesFiles.keys().reduce(function (modules, modulePath) {
  // set './app.js' => 'app'
  var moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
  var value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});
var otherModulesFiles = require.context('./modules', true, /.*\/index\.js$/);
var otherModules = otherModulesFiles.keys().reduce(function (modules, modulePath) {
  var moduleName = modulePath.replace(/^\.\/(.*)\/.*/, '$1');
  var value = otherModulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});
var store = new Vuex.Store({
  modules: _objectSpread(_objectSpread({}, modules), otherModules),
  getters: getters
});
export default store;