import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.split";
import store from '@/store';
var _document = document,
  body = _document.body;
var WIDTH = 992; // refer to Bootstrap's responsive design

export default {
  beforeCreate: function beforeCreate() {
    var isEditPage = false;
    var tmpPath = this.$route.path.split('/');
    tmpPath.forEach(function (item) {
      if (['create', 'edit'].indexOf(item) > -1) {
        isEditPage = true;
        return;
      }
    });
    store.dispatch('app/isEditPage', isEditPage);
  },
  watch: {
    $route: function $route(route) {
      if (this.device === 'mobile' && this.sidebar.opened) {
        store.dispatch('app/closeSideBar', {
          withoutAnimation: false
        });
      }
      var isEditPage = false;
      var tmpPath = route.path.split('/');
      tmpPath.forEach(function (item) {
        if (['create', 'edit'].indexOf(item) > -1) {
          isEditPage = true;
          return;
        }
      });
      store.dispatch('app/isEditPage', isEditPage);
    }
  },
  beforeMount: function beforeMount() {
    window.addEventListener('resize', this.resizeHandler);
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler);
  },
  mounted: function mounted() {
    var isMobile = this.isMobile();
    if (isMobile) {
      store.dispatch('app/toggleDevice', 'mobile');
      store.dispatch('app/closeSideBar', {
        withoutAnimation: true
      });
    }
  },
  methods: {
    isMobile: function isMobile() {
      var rect = body.getBoundingClientRect();
      return rect.width - 1 < WIDTH;
    },
    resizeHandler: function resizeHandler() {
      if (!document.hidden) {
        var isMobile = this.isMobile();
        store.dispatch('app/toggleDevice', isMobile ? 'mobile' : 'desktop');
        if (isMobile) {
          store.dispatch('app/closeSideBar', {
            withoutAnimation: true
          });
        }
      }
    }
  }
};