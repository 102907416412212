export default {
  route: {
    authorization: 'Authorization',
    role_management: 'Role Management',
    create_role: 'Create Role',
    edit_role: 'Edit Role',
    permission_management: 'Permission Management',
    create_permission: 'Create Permission',
    edit_permission: 'Edit Permission',
    end_customer_detail: 'End Customer Detail',
    organization_management: 'Organization Management',
    user_management: 'User Management',
    jit_sales_management: 'JIT Sales Management',
    jit_list: 'JIT List',
    jit_list_master_data: 'JIT List Master',
    import_data: 'Import Data',
    demand_management: 'Demand Management',
    demand_list: 'Demand List',
    demand_info: 'Demand Info',
    user_profile: 'User Profile',
    order_delivery: 'Order Delivery',
    trans_order_outlet: 'Trans Order Outlet',
    transaction_order: 'Transaction Order',
    export: 'Export',
    package: 'Package',
    edit_package_information: 'Edit Package Information',
    create_package_information: 'Create Package Information',
    package_information: 'Package Information',
    edit_country: 'Edit Country',
    create_country: 'Create Country',
    country: 'Country',
    edit_group_type: 'Edit Group Type',
    create_group_type: 'Create Group Type',
    group_type: 'Group Type',
    edit_staff: 'Edit Staff',
    create_staff: 'Create Staff',
    staff: 'Staff',
    edit_campaign: 'Edit Campaign',
    create_campaign: 'Create Campaign',
    campaign: 'Campaign',
    detail_store_subscription_plan: 'Detail Store Subscription Plan',
    store_subscription_plan: 'Store Subscription Plan',
    edit_plan: 'Edit Plan',
    create_plan: 'Create Plan',
    plan_listing: 'Plan Listing',
    packages: 'Packages',
    subscription_plan: 'Subscription Plan',
    histories: 'Histories',
    edit_bank: 'Edit Bank',
    create_bank: 'Create Bank',
    banks: 'Banks',
    tags: 'Tags',
    edit_ads_video: 'Edit Ads Video',
    create_ads_video: 'Create Ads Video',
    create_customer: 'Create Customer',
    deviation: 'Deviation',
    ace: 'ACE',
    ads_video: 'Ads Video',
    store_message: 'Store Message',
    clear_data: 'Clear Data',
    type: 'Type',
    log_app: 'Log App',
    organization: 'Organization',
    management: 'Management',
    salad: 'Salad',
    tasting_label_output: 'Tasting Label Output',
    date_label_output: 'Date Label Output',
    electronic_journal: 'Electronic Journal',
    report_dashboard: 'Report Dashboard',
    earn_redeem_point: 'Earn Redeem Point',
    redemption_rate_voucher: 'Redemption Rate Voucher',
    report_order_promotion: 'Report Order Promotion',
    food_loss: 'Food Loss',
    dashboard: 'Dashboard',
    documentation: 'Documentation',
    guide: 'Guide',
    role: 'Role',
    permission: 'Permission',
    pagePermission: 'Page Permission',
    rolePermission: 'Role Permission',
    directivePermission: 'Directive Permission',
    icons: 'Icons',
    components: 'Components',
    tinymce: 'Tinymce',
    markdown: 'Markdown',
    jsonEditor: 'JSON Editor',
    dndList: 'Dnd List',
    splitPane: 'SplitPane',
    avatarUpload: 'Avatar Upload',
    dropzone: 'Dropzone',
    sticky: 'Sticky',
    countTo: 'Count To',
    componentMixin: 'Mixin',
    backToTop: 'Back To Top',
    dragDialog: 'Drag Dialog',
    dragSelect: 'Drag Select',
    dragKanban: 'Drag Kanban',
    charts: 'Charts',
    keyboardChart: 'Keyboard Chart',
    lineChart: 'Line Chart',
    mixChart: 'Mix Chart',
    example: 'Example',
    nested: 'Nested Routes',
    Table: 'Table',
    dynamicTable: 'Dynamic Table',
    dragTable: 'Drag Table',
    inlineEditTable: 'Inline Edit',
    complexTable: 'Complex Table',
    tab: 'Tab',
    form: 'Form',
    errorPages: 'Error Pages',
    page401: '401',
    page404: '404',
    errorLog: 'Error Log',
    excel: 'Excel',
    exportExcel: 'Export Excel',
    selectExcel: 'Export Selected',
    mergeHeader: 'Merge Header',
    uploadExcel: 'Upload Excel',
    zip: 'Zip',
    pdf: 'PDF',
    exportZip: 'Export Zip',
    theme: 'Theme',
    clipboardDemo: 'Clipboard',
    i18n: 'I18n',
    externalLink: 'External Link',
    inventory: 'Inventory',
    master_material: 'Material',
    master_uom: 'Master Uom',
    master_conversion_uom: 'Master Conversion Uom',
    master_sku: 'Master Sku',
    master_sku_bom: 'Master Sku Bom',
    master_component: 'Master Component',
    master_component_bom: 'Master Component Bom',
    option_group: 'Option',
    option: 'Size',
    user: 'User',
    create_user: 'Create User',
    edit_user: 'Edit User',
    driver: 'Driver',
    customer: 'Customer',
    customer_list: 'Customer',
    blocked_list: 'Blocked List',
    edit_customer: 'Edit Customer',
    customer_address: 'Customer Address',
    master_meterial_category: 'Master Material Category',
    create_master_meterial_category: 'Create Master Material Category',
    edit_master_meterial_category: 'Edit Master Material Category',
    create_master_material: 'Create Material',
    edit_master_material: 'Edit Material',
    create_master_uom: 'Create UOM',
    edit_master_uom: 'Edit UOM',
    create_master_conversion_uom: 'Create UOM Conversion',
    edit_master_conversion_uom: 'Edit UOM Conversion',
    master_brand: 'Master Brand',
    create_master_brand: 'Create Master Brand',
    edit_master_brand: 'Edit Master Brand',
    master_sku_category: 'Master Sku Category',
    create_master_sku_category: 'Create Master Sku Category',
    edit_master_sku_category: 'Edit Master Sku Category',
    master_sku_header: 'Master Sku Header',
    create_master_sku_header: 'Create Master Sku Header',
    edit_master_sku_header: 'Edit Master Sku Header',
    create_master_sku: 'Create Master Sku',
    edit_master_sku: 'Edit Master Sku',
    create_master_sku_bom: 'Create Master Sku Bom',
    edit_master_sku_bom: 'Edit Master Sku Bom',
    create_master_component: 'Create Master Component',
    edit_master_component: 'Edit Master Component',
    create_master_component_bom: 'Create Master Component Bom',
    edit_master_component_bom: 'Edit Master Component Bom',
    purchase_order: 'Purchase Order',
    create_purchase_order: 'Create Purchase Order',
    edit_purchase_order: 'Edit Purchase Order',
    purchase_order_detail: 'Purchase Order Detail',
    create_purchase_order_detail: 'Create Purchase Order Detail',
    edit_purchase_order_detail: 'Edit Purchase Order Detail',
    good_reciept: 'Good Reciept',
    create_good_reciept: 'Create Good Reciept',
    edit_good_reciept: 'Edit Good Reciept',
    good_reciept_detail: 'Good Reciept Detail',
    create_good_reciept_detail: 'Create Good Reciept Detail',
    edit_good_reciept_detail: 'Edit Good Reciept Detail',
    management_pos: 'Management POS',
    management_order: 'Management Order',
    order: 'Orders',
    all_order: 'All Order',
    detail_order: 'Detail Order',
    merchant: 'Merchants',
    all_merchant: 'All Merchant',
    detail_merchant: 'Detail Merchant',
    merchant_detail: 'Merchant Detail',
    all_order_detail: 'All Order Detail',
    current_order: 'Current Order',
    advance_order: 'Advance Order',
    reject_order: 'Reject Order',
    problem_order: 'Problem Order',
    process_order: 'Process Order',
    resend_order: 'Resend Order',
    upper_text: 'Upper Text',
    view_order: 'View Order',
    view_order_detail: 'View Order Detail',
    view_order_online_detail: 'View Order Online Detail',
    view_order_online_detail_list: 'View Order Online Detail List',
    view_utilization_detail: 'View Utilization Detail',
    view_order_payment: 'View Order Payment',
    view_order_log_status: 'View Order Log Status',
    payment_method: 'Payment Method',
    create_payment_method: 'Create Payment Method',
    edit_payment_method: 'Edit Payment Method',
    menu: 'Menu',
    topping: 'Topping',
    create_topping: 'Create Topping',
    edit_topping: 'Edit Topping',
    doctor: 'Doctor',
    create_doctor: 'Create Doctor',
    edit_doctor: 'Edit Doctor',
    tag: 'Tag',
    notification: 'Notification',
    create_tag: 'Create Tag',
    edit_tag: 'Edit Tag',
    group_tag: 'Group Tag',
    create_group_tag: 'Create Group Tag',
    edit_group_tag: 'Edit Group Tag',
    create_inventory: 'Create Inventory',
    edit_inventory: 'Edit Inventory',
    origin: 'Origin',
    create_origin: 'Create Origin',
    edit_origin: 'Edit Origin',
    product_provider: 'Product Provider',
    create_product_provider: 'Create Product Provider',
    edit_product_provider: 'Edit Product Provider',
    category: 'Category',
    create_category: 'Create Category',
    edit_category: 'Edit Category',
    product: 'Product',
    create_product: 'Create Product',
    edit_product: 'Edit Product',
    add_variant_addon: 'Add Variant Addon',
    add_combo: 'Add Combo',
    create_option: 'Create Option',
    edit_option: 'Edit Option',
    create_option_group: 'Create Crust',
    edit_option_group: 'Edit Crust',
    kds: 'Kds',
    create_kds: 'Create Kds',
    edit_kds: 'Edit Kds',
    store: 'Store',
    qr_code: 'QR Code',
    create_store: 'Create Store',
    edit_store: 'Edit Store',
    edit_store_address_mapping: 'Edit Store Address Mapping',
    store_v3: 'Store',
    create_store_v3: 'Create Store',
    edit_store_v3: 'Edit Store',
    store_group: 'Store Group',
    store_target: 'Store Target',
    create_store_group: 'Create Store Group',
    edit_store_group: 'Edit Store Group',
    store_tag: 'Store Tag',
    create_store_tag: 'Create Store Tag',
    edit_store_tag: 'Edit Store Tag',
    store_featured: 'Store Featured',
    create_store_featured: 'Create Store Featured',
    edit_store_featured: 'Edit Store Featured',
    address: 'Address',
    system: 'System',
    client: 'Client',
    create_client: 'Create Client',
    edit_client: 'Edit Client',
    config: 'Config',
    create_config: 'Create Config',
    edit_config: 'Edit Config',
    public_config: 'Public Config',
    create_public_config: 'Create Public Config',
    edit_public_config: 'Edit Public Config',
    partner: 'Partner',
    create_partner: 'Create Partner',
    edit_partner: 'Edit Partner',
    printer: 'Printer',
    create_printer: 'Create Printer',
    edit_printer: 'Edit Printer',
    shift: 'Shift',
    content: 'Content',
    create_content: 'Create Content',
    edit_content: 'Edit Content',
    content_category: 'Content Category',
    create_content_category: 'Create Content Category',
    edit_content_category: 'Edit Content Category',
    promotion: 'Promotion',
    create_promotion: 'Create Promotion',
    edit_promotion: 'Edit Promotion',
    promotion_master: 'Promotion master',
    create_promotion_master: 'Create Promotion master',
    edit_promotion_master: 'Edit Promotion master',
    promotion_user: 'Promotion user',
    create_promotion_user: 'Create Promotion user',
    edit_promotion_user: 'Edit Promotion user',
    promotion_product: 'Promotion Product',
    create_promotion_product: 'Create Promotion Product',
    edit_promotion_product: 'Edit Promotion Product',
    promotion_condition: 'Promotion Condition',
    create_promotion_condition: 'Create Promotion Condition',
    edit_promotion_condition: 'Edit Promotion Condition',
    order_processing_time: 'Order Processing Time',
    overview: 'Overview',
    sale_today: 'Today Sales',
    sale_history: 'Sale History',
    product_mix: 'Product Mix',
    sale_trend: 'Sale Trend',
    summary_sales: 'Summary Sales',
    summary_date_sales: 'Summary Date Sales',
    summary_tracker: 'Summary Tracker',
    store_cash: 'Store Cash',
    create_store_cash: 'Create Store Cash',
    edit_store_cash: 'Edit Store Cash',
    money_bag: 'Money Bag',
    create_money_bag: 'Create Money Bag',
    edit_money_bag: 'Edit Money Bag',
    payment: 'Payment',
    payment_method_type: 'Payment Method Type',
    create_payment_method_type: 'Create Payment Method Type',
    edit_payment_method_type: 'Edit Payment Method Type',
    payment_method_group: 'Payment Method Group',
    create_payment_method_group: 'Create Payment Method Group',
    edit_payment_method_group: 'Edit Payment Method Group',
    payment_method_level1: 'Payment Method Level 1',
    create_payment_method_level1: 'Create Payment Method Level 1',
    edit_payment_method_level1: 'Edit Payment Method Level 1',
    payment_method_bank: 'Payment Method Bank',
    create_payment_method_bank: 'Create Payment Method Bank',
    edit_payment_method_bank: 'Edit Payment Method Bank',
    edit_employee: 'Edit Employee',
    contract: 'Contract',
    create_contract: 'Create Contract',
    edit_contract: 'Edit Contract',
    product_half_and_half: 'Product Half and half',
    create_product_half_and_half: 'Create Product Half and half',
    edit_product_half_and_half: 'Edit Product Half and half',
    ui_configuration: 'Ui Configuration',
    create_ui_configuration: 'Create Ui Configuration',
    edit_ui_configuration: 'Edit Ui Configuration',
    banner: 'Banner',
    create_banner: 'Create Banner',
    edit_banner: 'Edit Banner',
    comment: 'Comment',
    create_comment: 'Create Comment',
    edit_comment: 'Edit Comment',
    units: 'Units',
    edit_unit: 'Edit Unit',
    create_unit: 'Create Unit',
    currency: 'Currency',
    create_currency: 'Create Currency',
    edit_currency: 'Edit Currency',
    print_image: 'Print Image',
    create_item: 'Create Item',
    edit_item: 'Edit Item',
    items: 'Items',
    create_component: 'Create Component',
    edit_component: 'Edit Component',
    component: 'Component',
    employee: 'Employee',
    create_employee: 'Create Employee',
    report: 'Report',
    area_management: 'Area Management',
    device: 'Device',
    edit_device: 'Edit Device',
    create_device: 'Create Device',
    push_notify: 'Push Notify',
    create_notify: 'Create Notify',
    booking: 'Booking',
    detail_booking: 'Detail Booking',
    nationality: 'Nationality',
    create_nationality: 'Create Nationality',
    edit_nationality: 'Edit Nationality',
    rank: 'Rank',
    create_rank: 'Create Rank',
    edit_rank: 'Edit Rank',
    position: 'Position',
    create_position: 'Create Position',
    edit_position: 'Edit Position',
    occupation: 'Occupation',
    create_occupation: 'Create Occupation',
    edit_occupation: 'Edit Occupation',
    changeStatus: 'Change Status',
    create_changeStatus: 'Create Change Status',
    edit_changeStatus: 'Edit Change Status',
    visaStatus: 'Visa Status',
    create_visaStatus: 'Create Visa Status',
    edit_visaStatus: 'Edit Visa Status',
    nationalityCategory: 'Nationality Category',
    upload_mobile: 'Upload Mobile',
    create_nationalityCategory: 'Create Nationality Category',
    edit_nationalityCategory: 'Edit Nationality Category',
    bloodType: 'Blood Type',
    create_bloodType: 'Create Blood Type',
    edit_bloodType: 'Edit Blood Type',
    productList: 'Product List',
    chooseCategoryType: 'Choose Category Type',
    transfer: 'Transfer',
    create_transfer: 'Create Transfer',
    edit_transfer: 'Edit Transfer',
    foodWaste: 'Food Waste',
    createFoodWaste: 'Create Food Waste',
    editFoodWaste: 'Edit Food Waste',
    eod: 'End Of Date',
    eod_list: 'EOD',
    check_eod: 'Check End Of Date',
    eod_management: 'List EOD',
    money_closure: 'Money Closure',
    labour_closure: 'Labour Closure',
    inventory_closure: 'Inventory Closure',
    order_promotion: 'Order Promotion',
    temporaryAssignment: 'Temporary Assignment',
    create_temporaryAssignment: 'Create Temporary Assignment',
    edit_temporaryAssignment: 'Edit Temporary Assignment',
    temporaryAssignmentHQ: 'Temporary Assignment HQ',
    create_temporaryAssignmentHQ: 'Create Temporary Assignment HQ',
    edit_temporaryAssignmentHQ: 'Edit Temporary Assignment HQ',
    labor: 'Labor',
    standardConfiguration: 'Standard Configuration',
    create_standardConfiguration: 'Create Standard Configuration',
    edit_standardConfiguration: 'Edit Standard Configuration',
    certificate: 'Certificate',
    create_certificate: 'Create Certificate',
    edit_certificate: 'Edit Certificate',
    order_forecast: 'Order Forecast',
    create_order_forecast: 'Create Order Forecast',
    edit_order_forecast: 'Edit Order Forecast',
    driver_forecast: 'Driver Forecast',
    create_driver_forecast: 'Create Driver Forecast',
    edit_driver_forecast: 'Edit Driver Forecast',
    store_target_label: 'Store Target 2',
    sale_his: 'Sales History',
    product_mix_v2: 'Product Mix  ',
    manage_order: 'Manage Order  ',
    today_sales: 'Today Sales',
    reportv2: 'Report V2',
    report_inventory: 'Report Inventory',
    store_health: 'Store Healthy',
    store_common: 'Store Common',
    store_dashboard: 'Store Dashboard',
    order_driver_pool: 'Order Driver Pool',
    report_food_cost: 'Report Food Cost',
    report_order_detail: 'Report Order Detail',
    labour_cost: 'Labour Cost',
    slot_configuration: 'Slot Configuration',
    edit_slot_configuration: 'Edit Slot Configuration',
    driver_roaster: 'Driver Roaster',
    create_driver_roaster: 'Create Driver Roaster',
    edit_driver_roaster: 'Edit Driver Roaster',
    exception_date: 'Exception Date',
    edit_exception_date: 'Edit Exception Date',
    create_exception_date: 'Create Exception Date',
    matrix: 'Matrix',
    edit_matrix: 'Edit Matrix',
    create_matrix: 'Create Matrix',
    store_cash_list: 'Store Cash List',
    brand: 'Brand',
    store_category: 'Merchant Category',
    create_brand: 'Create Brand',
    edit_brand: 'Create Brand',
    create_store_category: 'Create Merchant Category',
    edit_store_category: 'Edit Merchant Category',
    contract_period: 'Contract Period',
    create_contract_period: 'Create Contract Period',
    edit_contract_period: 'Edit Contract Period',
    food_item: 'Food Items',
    create_menu: 'Create Menu',
    edit_menu: 'Edit Menu',
    store_notification: 'Store Notification',
    leafleting_result: 'Leafleting Result',
    edit_leafleting_result: 'Edit Leafleting Result',
    create_leafleting_result: 'Create Leafleting Result',
    leafleting_target: 'Leafleting Target',
    edit_leafleting_target: 'Edit Leafleting Target',
    create_leafleting_target: 'Create Leafleting Target',
    special_request: 'Special Request',
    add_special_request: 'Add Special Request',
    edit_special_request: 'Edit Special Request',
    report_order: 'Report Order',
    loyalist_point_report: 'Loyalist Point Report',
    payroll_error_message: 'Payroll Error Message',
    setting_kpi: 'Setting KPI',
    add_setting_kpi: 'Add Setting KPI',
    edit_setting_kpi: 'Edit Setting KPI',
    setting_color_kpi: 'Setting Color KPI',
    add_setting_color_kpi: 'Add Setting Color KPI',
    edit_setting_color_kpi: 'Edit Setting Color KPI',
    pj_input: 'PJ Input',
    labor_import: 'Labor Import',
    check_order: 'Check order',
    daily_report: 'Daily Report',
    customer_factory: 'Customer Factory',
    create_customer_factory: 'Create Customer Factory',
    edit_customer_factory: 'Edit Customer Factory',
    ace_cancel_refund: 'Cancel Refund',
    ace_cancel: 'Cancel',
    ace_refund: 'Refund',
    ace_cash_voucher: 'Ace Cash Voucher',
    ace_cash_count: 'Ace Cash Count',
    ace_coupon_history: 'Ace Coupon History',
    ace_labour_cost: 'Ace Labour Cost',
    ace_under_18: 'Ace Under 18',
    ace_sale: 'Ace Sale',
    ace_sale_daily: 'Ace Sale Daily',
    sales_by_hour: 'Sales By Hour',
    sales_mix_by_segment: 'Sales Mix By Segment',
    sales_details: 'Sales details',
    report_menu_mix: 'Menu mix',
    report_store_group: 'Report Store Group',
    create_report_store_group: 'Create Report Store Group',
    edit_report_store_group: 'Edit Report Store Group',
    dateLabel: 'Date Label',
    create_master_date_label: 'Create Master Date Label',
    edit_master_date_label: 'Edit Master Date Label',
    date_label_category: 'Date Label Category',
    edit_master_date_label_category: 'Edit Date Label Category',
    create_master_date_label_category: 'Create Date Label Category',
    report_usage_analysis: 'Report Usage Analysis',
    app_version_log: 'App Version Log',
    menu_category: 'Menu Categories',
    create_menu_category: 'Create Menu Category',
    edit_menu_category: 'Edit Menu Category',
    store_menu: 'Store Menus',
    edit_store_menu: 'Edit Store Menu',
    store_purchase: 'Store Purchase',
    edit_store_purchase: 'Edit Store Purchase',
    create_store_purchase: 'Create Store Purchase',
    giftcard: 'Gift Card',
    create_giftcard: 'Create Gift Card',
    edit_giftcard: 'Edit Gift Card',
    view_list_giftcard: 'Gift Card Item List',
    view_detail_giftcard: 'Campaign Detail',
    manufacturing_product: 'Manufacturing Product',
    claim_management: 'Claim Management',
    claim_registration: 'Claim Registration',
    list_claim: 'Claim List',
    create_claim: 'Create Claim',
    demand_confirm: 'Demand Confirm',
    edit_claim: 'Edit Claim',
    spot_order_list: 'Spot Order List',
    master_data: 'Master Data',
    product_price: 'Product Price',
    lot_number: 'Lot Number',
    pricing_change: 'Pricing Change',
    pricing_change_request_list: 'Pricing Change Request List',
    pricing_change_approval: 'Pricing Change Approval',
    pricing_change_history: 'Pricing Change History',
    shipping_status: 'Shipping Status'
  },
  giftcard: {
    select_file: 'Select file',
    cancel: 'Cancel',
    search: 'Search',
    no_giftcard: 'No Gift Card added yet',
    add_giftcard: 'Add new Gift Card',
    create_giftcard: 'Create',
    edit_giftcard: 'Edit Gift Card',
    store: 'Store',
    id: '#',
    name: 'Campaign Name',
    code: 'Campaign Code',
    start_date: 'Start Date',
    end_date: 'End Date',
    quantity: 'Quantity',
    value: 'Value',
    status: 'Status',
    basic_information: 'Campaign Info',
    detail: 'Basic information',
    type: 'Type',
    code_format: 'Gift Card Code Format',
    advanced_condition: 'Advanced Conditions',
    length: 'Length',
    prefix: 'Prefix',
    minimum: 'Purchase at Minimum',
    minimum_hint: '(Order\'s total is greater than or equal to)',
    maximum_value: 'Maximum Gift Card Value',
    store_includes: 'Included Store',
    store_excludes: 'Excluded Store',
    description: 'Description',
    seach_code: 'Search Gift Card Code',
    campaign_name: 'Campaign Name',
    allocation: 'Manage Gift Cards',
    history: 'Gift Card History',
    total_giftcode: 'Total Gift Code',
    generated_giftcode: 'Generated Gift Card Code',
    remaining_giftcode: 'Remaining Gift Card Code',
    used_giftcode: 'Gift Code Used',
    item_detail: 'Campaign Detail',
    generate: 'Generate Code',
    import: 'Import File',
    input_quantity: 'Input Quantity',
    import_giftcode: 'Import file to Generate Gift Card Code',
    download_sample_file: 'Download sample file: ',
    excel_file: 'Excel file',
    note_import: 'Information!',
    note_import_description: 'The system allows importing up to 10000 rows of Gift Card code per file',
    search_code_name: 'Search campain by Code/Name',
    code_length: 'Length',
    minimum_purchase: 'Purchase at Minimum',
    order_code: 'Order Code',
    store_name: 'Store',
    used_at: 'Time',
    customer: 'Customer',
    search_customer: 'Search by Customer Name/Email/Phone',
    search_code_order: 'Search by Gift card code/Order',
    order_value: "Order's Total",
    giftcard_value: 'Gift Card Value',
    giftcard_refund: 'Gift Card Refund',
    redeem: 'Gift Card Redeemed',
    refund: 'Gift Card Refunded',
    giftcode: 'Gift Card Code',
    export: 'Export code',
    customer_info: 'Customer Info'
  },
  dashboard: {
    welcome_to_diamet: 'Welcome to DIAMET',
    top_selling: 'Top selling',
    sale_by_hour_and_date: 'Sale by hour and date',
    avg_order: 'Avg. Order Value',
    total_order: 'Total Order',
    end_date: 'End Date',
    start_date: 'Start Date',
    select_store: 'Select Store',
    new_visits: 'New Visits',
    messages: 'Messages',
    purchases: 'Purchases',
    shoppings: 'Shoppings',
    total_sales: 'Total Sales',
    total_bill: 'Total Bill',
    average_bill: 'Average Bill',
    sos: 'SOS',
    sales_by_channel: 'Sales By Channel',
    sales_by_type: 'Sales By Type',
    bill_by_channel: 'Bill By Channel',
    bill_by_type: 'Bill By Type',
    top_10: 'TOP 10',
    no_data: 'No Data'
  },
  navbar: {
    logOut: 'Log Out',
    dashboard: 'Dashboard',
    github: 'Github',
    theme: 'Theme',
    size: 'Global Size'
  },
  login: {
    title: '',
    action: 'Enter the details below to log in.',
    help: 'Not a customer? Call for help',
    logIn: 'Login',
    username: 'Username',
    password: 'Password',
    confirmpassword: 'Confirm Password',
    any: 'any',
    thirdparty: 'Or connect with',
    remember_me: 'Remember me',
    forgot_password: 'Forgot password',
    merchant: 'Merchant platform',
    power: 'powered by Diqit',
    store_code: 'Store Code'
  },
  forgot_password: {
    title: 'Reset password',
    action: 'Provide your email address and an email with a reset link will be sent to you.',
    help: 'Back to Log In',
    username: 'Username',
    email_note: 'Enter email address',
    reset: 'Reset',
    forgot_password: 'Forgot password',
    email: 'Email'
  },
  reset_password: {
    title: 'Reset your password',
    new_password: 'New Password',
    confirm_password: 'Confirm Password',
    reset: 'Reset'
  },
  documentation: {
    documentation: 'Documentation',
    github: 'Github Repository'
  },
  permission: {
    addRole: 'New Role',
    editPermission: 'Edit',
    roles: 'Your roles',
    switchRoles: 'Switch roles',
    delete: 'Delete',
    confirm: 'Confirm',
    cancel: 'Cancel'
  },
  guide: {
    button: 'Show Guide'
  },
  common: {
    keyWord: 'Key word',
    titleDelete: 'Delete record?',
    titleClearCache: 'Clear Cache',
    sun: 'Sun',
    mon: 'Mon',
    tue: 'Tue',
    wed: 'Wed',
    thu: 'Thu',
    fri: 'Fri',
    sat: 'Sat',
    hour: 'Hour',
    minute: 'Minute',
    week: 'Week',
    week_number: 'Week {weekNumber}'
  },
  table: {
    new_password: 'New Password',
    role_code: 'Role Code',
    role_name: 'Role Name',
    role_description: 'Role Description',
    role_permissions: 'Role Permissions',
    permission_code: 'Permission Code',
    permission_name: 'Permission Name',
    permission_description: 'Permission Description',
    organization_id: 'Organization ID',
    organization_name: 'Organization Name',
    sale_organization_id: 'Sale Organization ID',
    sale_organization_name: 'Sale Organization Name',
    customer: 'Customer',
    end_customer: 'End Customer',
    end_customer_id: 'End Customer ID',
    end_customer_type: 'End Customer Type',
    internal_product_id: 'Internal Product ID',
    customer_product_id: 'Customer Product ID',
    product_name: 'Product Name',
    unit_price: 'Unit Price',
    currency: 'Currency',
    fixed_weeks_frame: 'Fixed Weeks Frame',
    sales_plan: 'Sales Plan',
    delivery_slip_number: 'Delivery Slip Number',
    price_type: 'Price type',
    delivery_quantity: 'Quantity',
    total: 'Total',
    delivery_date: 'Delivery Date',
    update_time: 'Update Time',
    user_id: 'User ID',
    user_name: 'User Name',
    user_code: 'User Code',
    user_roles: 'User Roles',
    remain: 'Remain',
    user_search: 'Search by name, email',
    order_type: 'Order Type',
    pin_code: 'Pin Code',
    start_amount: 'Start Amount',
    end_amount: 'End Amount',
    is_special: 'Is Special',
    finish: 'Finish',
    message: 'Message',
    is_valid: 'Is Valid',
    is_verify_eod: 'Is Verify Eod',
    period: 'Period',
    user_total: 'User Total',
    purchase: 'Purchase',
    user_total_redeem: 'User Total Redeem',
    user_total_earn: 'User Total Earn',
    point_total_earn: 'Point Total Earn',
    point_total_redeem: 'Point Total Redeem',
    qty_release: 'Quantity Release',
    qty_redeem: 'Quantity Redeem',
    item: 'Item',
    day_before: 'The Day Before',
    for_day: 'For The Day',
    bank_balance: 'Bank Balance',
    internal_change: 'Internal Change Fund',
    bank: 'Bank',
    cash: 'Cash',
    total_deposit_day: 'Total Deposit On The Day',
    total_withdraw_day: 'Total Withdraw On The Day',
    name_cash: 'Application Name',
    is_unique_code: 'Is Unique Code',
    limit_quantity_cart: 'Limit Quantity Cart',
    rp: 'Rp',
    is_oc_discount: 'Is Oc Discount',
    submit: 'Submit',
    title: 'Title',
    importance: 'Imp',
    type: 'Type',
    type_name: 'Type Name',
    remark: 'Remark',
    search: 'Search',
    add: 'Add',
    export: 'Export',
    import: 'Import',
    reviewer: 'reviewer',
    id: 'ID',
    uom_from_uuid: 'UOM From',
    code: 'Code',
    name: 'Name',
    name_kanji: 'Name Kanji',
    sku_uuid: 'Sku',
    component_uuid: 'Component',
    uom_to_uuid: 'UOM To',
    material_uuid: 'Material',
    conversion: 'Conversion',
    uom_uuid: 'UOM',
    replacement_material_uuid: 'Replacement Material Uuid',
    replacement_uom_uuid: 'Replacement Uom Uuid',
    replacement_quantity: 'Replacement Quantity',
    number_of_slots: 'Number of slots',
    email: 'Email',
    date_of_purchase: 'Date Of Purchase',
    date_of_receive: 'Date Of Receive',
    amount: 'Amount',
    username: 'Username',
    role: 'Role',
    date: 'Date',
    author: 'Author',
    readings: 'Readings',
    status: 'Status',
    is_use: 'Is Use',
    is_point: 'Is Point',
    actions: 'Actions',
    edit: 'Edit',
    publish: 'Publish',
    draft: 'Draft',
    delete: 'Delete',
    create: 'Create',
    update: 'Update',
    cancel: 'Cancel',
    ready: 'Ready',
    close: 'Close',
    confirm: 'Confirm',
    created_at: 'Created Date',
    description: 'Description',
    category: 'Category',
    sku: 'Sku',
    price: 'Price',
    sequence: 'Sequence',
    location: 'Location',
    client: 'Client',
    category_type: 'Category Type',
    additional_type_ids: 'Additional Type',
    store: 'Store',
    store_group: 'Store Group',
    store_type: 'Store Type',
    point: 'Point',
    start_date: 'Start Date',
    end_date: 'End Date',
    delivery_start_date: 'Delivery Start Date',
    delivery_end_date: 'Delivery End Date',
    delivery_time: 'Delivery Time',
    is_special_option_group: 'Is Special',
    key: 'Key',
    value: 'Value',
    ip: 'IP',
    store_code: 'Store Code',
    store_name: 'Store Name',
    slip_number: 'Slip Number',
    building: 'Building',
    district: 'District',
    petty_cash: 'Petty Cash',
    sale_cash: 'Sale Cash',
    real_cash: 'Real Cash',
    variant_cash: 'Variant Cash',
    is_open: 'Is Open',
    user: 'User',
    start_time: 'Start Time',
    end_time: 'End Time',
    cash_out: 'Cash Out',
    street: 'Street',
    phone: 'Phone',
    createdBy: 'Created By',
    updatedBy: 'Updated By',
    back: 'Back',
    store_type_id: 'Store type',
    keyword: 'Keyword',
    warning: 'Warning',
    tier: 'Tier',
    channel: 'Channel',
    image: 'Image',
    thumbnail: 'Thumbnail',
    stores: 'Stores',
    store_groups: 'Store Groups',
    tax: 'Tax',
    group_name: 'Group name',
    min: 'Min',
    max: 'Max',
    full_name: 'Full Name',
    short_name: 'Short name',
    value_type: 'Value Type',
    en_route: 'En Route',
    available: 'Available',
    option_group_name: 'Option group name',
    default: 'DEFAULT',
    additinal: 'ADDITIONAL',
    choose_product: 'Choose product',
    products_label: 'Products:',
    time_label: 'Time:',
    time: 'Time',
    rate_time: 'Rate Time',
    bake_time: 'Bake Time',
    kds_products_label: 'KDS Products:',
    your_roles_label: 'Your roles:',
    editor_dashboard: "Editor's Dashboard",
    see_detail: 'See Detail...',
    special: 'Special',
    aggregator_code: 'Aggregator Code',
    size: 'Size',
    active: 'Active',
    content: 'Content',
    how_to_make_image: 'How To Make Image',
    drivertracker_min_version: 'Driver Tracker Min Version',
    ios_customerapp_min_version: 'IOS Customer App Min Version',
    android_customerapp_min_version: 'Android Customer App Min Version',
    file_name: 'File Name',
    icon: 'Icon',
    avatar: 'Avatar',
    rate: 'Rate',
    tags: 'Tags',
    assign: 'Assign',
    detail: 'Detail',
    log: 'Log',
    main_description: 'Main Description',
    product_providers: 'Product Providers',
    price_with_tax: 'Price With Tax',
    no_of_review: 'No Of Review',
    origins: 'Origins',
    parent_category: 'Parent Category',
    sub_category: 'Sub Category',
    parent_tag: 'Parent Tag',
    no_data: 'No data',
    allowed_type_ids: 'Allowed Types',
    short_description: 'Short Description',
    full_description: 'Full Description',
    page: 'Page',
    url: 'Url',
    app_url: 'App Url',
    field: 'Field',
    show: 'Show',
    tag: 'Tag',
    group_type: 'Group Type',
    notification: 'Notification',
    large_image: 'Large Image',
    middle_image: 'Middle Image',
    small_image: 'Small Image',
    platform: 'Platform',
    model: 'Model',
    localizedModel: 'LocalizedModel',
    version: 'Version',
    sub_title: 'Sub Title',
    name_seller: 'Name Seller',
    validity: 'Validity',
    product_additional_information_name: 'Additional Name',
    discount_percentage: 'Discount Percentage',
    symbol: 'Symbol',
    select: 'Select',
    doctors: 'Doctors',
    country: 'Country',
    languages: 'Languages',
    designation: 'Designation',
    specialisation: 'Specialisation',
    qualifications: 'Qualifications',
    review: 'Review',
    discounted_price: 'Discounted Price',
    faq: 'Faq',
    contact: 'Contact',
    t_and_c: 'T And C',
    disclaimer: 'Disclaimer',
    number_of_unit: 'Number Of Unit',
    additional_description: 'Additional Description',
    origin: 'Origin',
    doctor: 'Doctor',
    refund_policy: 'Refund Policy',
    cancellation_policy: 'Cancellation Policy',
    comment: 'Comment',
    reply: 'Reply',
    subject: 'Subject',
    body: 'Body',
    application: 'Application',
    campaign: 'Campaign',
    notify: 'Notify',
    booking: 'Booking',
    reservation_time: 'Reservation Time',
    num_of_people: 'Num Of People',
    smoking: 'Smoking',
    booking_device_uuid: 'Booking Device',
    note: 'Note',
    booking_status: 'Booking Status',
    slug: 'Slug',
    check_all: 'Check All',
    generate: 'Generate',
    sku_name: 'SKU Name',
    takeaway: 'Takeaway',
    delivery: 'Delivery',
    eat_in: 'Eat In',
    aggregator: 'Aggregator',
    total_bill: 'Total Number of Bill',
    total_sales: 'Total Sales',
    avg_bill: 'Avg Bill',
    register: 'Register',
    available_times: 'Available Time',
    available_packets: 'Available Packets',
    date_range: 'Date Range',
    time_range: 'Time Range',
    day_of_weeks: 'Day Of Weeks',
    month: 'Month',
    day: 'Day',
    week: 'Week',
    ordered_quantity: 'Ordered Quantity',
    advance_day: 'Advance Day',
    no: 'No',
    outlet_name: 'Outlet Name',
    outlet_code: 'Outlet Code',
    total_online_order_delivery: 'Total Order',
    total_order_swiped: 'Total Order Swiped',
    driver_tracker_usage_percent: 'Usage (%)',
    sos_gt_30: 'SOS > 30',
    sos_gt_30_percent: 'SOS > 30 (%)',
    sos_lt_30: 'SOS < 30',
    sos_lt_30_percent: 'SOS < 30 (%)',
    group_tag: 'Group Tag',
    inventory: 'Inventory',
    is_master: 'Is Master',
    is_alcohol: 'Is Alcohol',
    externalCode: 'External Code',
    business_date: 'Business Date',
    delivery_sale: 'Delivery Sale',
    delivery_bill: 'Delivery Bill',
    takeaway_sale: 'Takeaway Sale',
    takeaway_bill: 'Takeaway Bill',
    total_sale: 'Total Sale',
    total_bills: 'Total Bill',
    avo: 'AVO',
    height: 'Height (cm)',
    length: 'Length (cm)',
    weight: 'Weight (kg)',
    width: 'Width (cm)',
    package_type: 'Package Type',
    shift: 'Shift',
    app: 'App',
    send_to: 'Send to',
    select_app: 'Select App',
    version_code: 'Version Code',
    version_name: 'Version Name',
    kds: 'Kds',
    dispatch: 'Dispatch',
    taker: 'Taker',
    takeaway_monitor: 'Takeaway Monitor',
    taker_client: 'Taker Client',
    upload_file: 'Upload File',
    required_doctor: 'Required Doctor',
    live: 'sync directly',
    is_driver_pool: 'Use Driver Pool',
    snap_duration: 'Duration of stay',
    commodity_id: 'Commodity Id',
    transaction_date: 'Transaction Date',
    order_code: 'Order Code',
    activity: 'Activity',
    outlet: 'Outlet',
    voucher_name: 'Voucher name',
    redeem_code: 'Redeem code',
    total_count: 'Total count',
    organization: 'Organization',
    api_end_point: 'Api end point',
    name_group: 'Name Group',
    link_group: 'Link Group',
    slot_per_hour: 'Slots/Hour',
    driver_trip_per_hour: 'Driver Trip/Hour',
    order_number: 'Order number',
    lob: 'Lob',
    time_from: 'Time From',
    time_to: 'Time To',
    brands: 'Brands',
    store_categories: 'Merchant categories',
    menu_name: 'Menu name',
    brand: 'Brand',
    total_redeemptions: 'Total redeemptions',
    is_send_S3: 'Send EOD',
    is_live: 'Is Live',
    advance_only: 'Advance only',
    free: 'Free',
    send: 'Send',
    component: 'Component',
    error: 'Error',
    year: 'Year',
    display_price: 'Price',
    print_name: 'Print name',
    marketing_description: 'Marketing Description',
    view_subscription_logs: 'View subscription logs',
    store_message_type: 'Store mesage type',
    assigned_stores: 'Assigned stores',
    brand_name: 'Brand name',
    fallback: 'Fallback',
    advance: 'Advance',
    show_on_report: 'Show on report',
    category_code: 'Category code',
    category_name: 'Category name',
    product: 'Product',
    reason: 'Reason',
    material: 'Material',
    cross_sell: 'Cross sell',
    meta_title: 'Meta Title',
    meta_description: 'Meta Description',
    meta_key: 'Meta Key',
    date_label_category: 'Date Label Category',
    thaw_time: 'Defrosting time',
    disposal_time_after_thawing: 'Disposal Time After Thawing',
    expiration_date_after_opening: 'Expiration Date After Opening',
    deadline_after_change: 'Deadline After Change',
    information: 'Information',
    date_label_detail: 'Date Label Detail',
    lot_number: 'Lot Number',
    from: 'From',
    to: 'To',
    last_modify_date: 'Last Modify Date',
    last_modify_by: 'Last Modify By',
    confirm_status: 'Confirm Status',
    product_count: 'Product Count',
    claim: 'Claim',
    created_by: 'Created By',
    claim_or_returns: 'Claim or Returns',
    recorded_or_not_recorded: 'Recorded or Not Recorded',
    product_group: 'Product Group',
    is_update_check_mark: 'Check Mark Status',
    default_flag: 'Default Flag',
    unknown: 'unknown',
    other: 'Other',
    new_issue: 'New Issue',
    claim_id: 'Claim ID',
    register_spot_order: 'Register Spot Order',
    due_date: 'Due Date',
    order_quantity: 'Order Quantity',
    registered_date: 'Registered Date',
    registered_by: 'Registered By',
    confirmed_date: 'Confirmed Date',
    confirmed_by: 'Confirmed By',
    confirm_order: 'Confirm Order',
    spot_order_confirm: 'Spot Order Confirm',
    confirmed: 'Confirmed',
    pending_confirmation: 'Pending Confirmation',
    price_date: 'Price Date',
    price_date_from: 'Price Date From',
    price_date_to: 'Price Date To',
    new_price: 'New Price',
    effective_date: 'Effective Date',
    uneffective_date: 'Uneffective Date',
    price_change_title: 'Price Change Request Registration',
    price_change_detail: 'Price Change Request | Detail',
    internal_product_name: 'Internal Product Name',
    external_product_id: 'External Product ID',
    chang_value: 'Pricing change value',
    pricing_change_req_id: 'Pricing change request ID',
    old_price: 'Old Price',
    increase: 'Increase',
    decrease: 'Decrease',
    price_validity_from: 'Price Validity (from)',
    price_validity_to: 'Price Validity (to)',
    price_validity: 'Price Validity',
    change_status: 'Change Status',
    approved: 'Approve',
    declined: 'Decline',
    price_change_approve: 'Price Change Request Approval',
    price_change_decline: 'Price Change Request Decline',
    new_unit_price_type: 'New Unit Price Type',
    new_unit_price: 'New Unit Price',
    new_currency: 'New Currency',
    old_unit_price_type: 'Old Unit Price Type',
    old_unit_price: 'Old Unit Price',
    old_currency: 'Old Currency',
    updated_by: 'Updated By',
    defective_lot: 'How to return a defective lot',
    defect_type: 'Defect Type',
    normal: 'Normal',
    spot: 'Spot',
    internal_manufacturing_product_name: 'Internal Manufacturing Product Name',
    internal_manufacturing_product_id: 'Internal Manufacturing Product ID',
    lot_quantity: 'Lot Quantity',
    update_manufaturing_product_name: 'Update Manufaturing Product Name',
    ship_date: 'Ship date',
    shipping_destination: 'Shipping Destination',
    plant: 'Plant',
    quantity: 'Quantity',
    pcs_per_box: 'Pcs/Box',
    number_of_boxes: 'Number of Boxes',
    number_of_incomplete_boxes: 'Number of Incomplete Boxes',
    order_no: 'Order No',
    select_column: 'Select Column'
  },
  errorLog: {
    tips: 'Please click the bug icon in the upper right corner',
    description: 'Now the management system are basically the form of the spa, it enhances the user experience, but it also increases the possibility of page problems, a small negligence may lead to the entire page deadlock. Fortunately Vue provides a way to catch handling exceptions, where you can handle errors or report exceptions.',
    documentation: 'Document introduction'
  },
  excel: {
    export: 'Export',
    selectedExport: 'Export Selected Items',
    placeholder: 'Please enter the file name (default excel-list)'
  },
  zip: {
    export: 'Export',
    placeholder: 'Please enter the file name (default file)'
  },
  pdf: {
    tips: 'Here we use window.print() to implement the feature of downloading PDF.'
  },
  theme: {
    change: 'Change Theme',
    documentation: 'Theme documentation',
    tips: 'Tips: It is different from the theme-pick on the navbar is two different skinning methods, each with different application scenarios. Refer to the documentation for details.'
  },
  tagsView: {
    refresh: 'Refresh',
    close: 'Close',
    closeOthers: 'Close Others',
    closeAll: 'Close All'
  },
  settings: {
    title: 'Page style setting',
    theme: 'Theme Color',
    tagsView: 'Open Tags-View',
    fixedHeader: 'Fixed Header',
    sidebarLogo: 'Sidebar Logo'
  },
  message: {
    num_rows_affected: 'Number of rows added',
    num_rows_already_exists: 'Number of rows already exists',
    num_rows_failed: 'Number of rows failed',
    hint_to_select_internal_product_id: 'Please select an Internal Product ID to proceed',
    not_allow_remain: 'Some products have "remain" greater than 0',
    use_hyphen_instead_of_slash: 'Please use "-" instead of "/"',
    please_input_correct_date_format: 'Please input correct date format (yyyy-mm-dd) or (yyyymmdd)',
    date_input_out_of_range: 'Just input past values up to previous month & future day +7 days from today',
    no_data: 'No data',
    no_data_matches_filter: 'No data matches the filter criteria',
    add: 'Added successfully',
    remain_is_not_allowed_negative: 'Remain is not allowed to be negative',
    show_report: 'Are you sure to show report?',
    confirm_export: 'Click button to export data',
    update: 'Updated successfully.',
    delete: 'Deleted successfully.',
    import: 'Import file successfully',
    allow_one_file: 'Only allow upload one file',
    file_has_invalid_data: 'Uploaded file has invalid data. Please recheck the file',
    changeStatus: 'Changed status successfully',
    tokenExpired: 'Token expired.',
    tokenInvalid: 'Token invalid.',
    loggedOut: 'You have been logged out.',
    registered: 'You have registered successfully.',
    invalidExtension: 'The extension file is invalid.',
    confirm_delete: 'Are you sure to delete this item?',
    confirmRefund: 'Are you sure to refund this item?',
    confirmResend: 'Are you sure to Resend this item?',
    confirmReUpload: 'Do you want to re-upload?',
    noRowSelected: 'Please select at least one item.',
    tokenTimeout: 'You session have been expired, press Cancel to stay on this page, OK to logout',
    takeAway: 'C',
    delivery: 'D',
    eatIn: 'E',
    take_away_text: 'Collection',
    delivery_text: 'Delivery',
    eatInText: 'Eat In',
    only_accept_image: 'Only accept: *.jpeg,bmp,png,gif,jpg',
    delete_file: 'This will permanently delete the file. Continue?',
    delete_completed: 'Delete completed',
    delete_canceled: 'Delete canceled',
    email_not_correct: 'Email not correct',
    copy_successfully: 'Copy successfully',
    confirmAddPromotionUser: 'Are you sure to add these items?',
    confirmDeletePromotionUser: 'Are you sure to delete these items?',
    confirmClone: 'Are you sure to clone this item?',
    confirmChangeDataBySku: 'Are you sure want to change data by sku?',
    warnSelectDeliverRole: 'You must select leasted one store and driver who created will be belong the first store',
    confirmGenerateCoupon: 'Are you want to generate coupon?',
    inputStandardConfig: 'Please input Standard Configuration',
    confirmUpdate: 'Are you sure to update?',
    updateAll: 'Are you sure to confirm all value?',
    requiredSizeAndCrust: 'Please add size and crust',
    uploadKML: 'Upload KML success',
    dataNotSave: 'Your change will not be saved',
    saveBeforeChange: 'Do you want to save before countinue?',
    name_lang_is_required: 'Name :lang is required',
    num_rows_updated: 'Number of rows updated',
    total_quantity_child_not_equal_total_quantity_parent: 'Total quantity child not equal quantity parent',
    new_price_is_not_allowed_negative: 'New Price is not allowed to be negative',
    note_approve_price_change: 'By approving this request, you are agreeing to the new price change for the product. Please note that this action cannot be modified or cancelled.',
    note_decline_price_change: 'By declining this request, you are disagreeing to the new price change for the product. Please note that this action cannot be modified or cancelled.',
    create: 'Created successfully.',
    confirmDelete: 'Are you sure to delete this item?',
    date_range_is_required: 'Date range is required',
    product_quantity_is_exceed: 'The product quantity is exceed. Max available quantity: ',
    product_quantity_must_greater_than_zero: 'The product quantity must greater than 0',
    no_data_export: 'No data to export',
    syncDataToAsprovaSuccess: 'Sync data to Asprova successfully'
  },
  upload_file: {
    csv_upload_rule: 'Only csv/xlsx files can be uploaded, up to 500kb',
    csv_import_demand_rule: 'Only csv/xlsx files can be uploaded, up to 5MB',
    txt_upload_rule: 'Only txt files can be uploaded, up to 500kb',
    drag_file_or_click: 'Drag files here, or click ',
    upload: 'Upload',
    drag_file: 'Drag files here, ',
    or_click: 'or click'
  },
  button: {
    add_role: 'Add Role',
    add_permission: 'Add Permission',
    on_demand: 'OnDemand',
    jit: 'JIT',
    update: 'Update',
    confirm: 'Confirm',
    download: 'Download',
    downloading: 'Downloading',
    edit: 'Edit',
    apply: 'Apply',
    add_record: 'Add Record',
    submit: 'Submit',
    filter: 'Filter',
    import: 'Import',
    reset: 'Reset',
    save: 'Save',
    next: 'Next',
    back: 'Back',
    add_new: 'Add new',
    add_menu: 'Add menu',
    add_group_type: 'Add group type',
    remove_group: 'Remove group',
    add_group: 'Add group',
    set_price: 'Set price',
    set_topping: 'Set topping',
    set_video: 'Set video',
    set_doctor_appointment: 'Set Doctor Appointment',
    add_option_group_option: 'Add option group - option',
    add_topping_option: 'Add topping - option',
    ok: 'OK',
    cancel: 'Cancel',
    add: 'Add',
    delete: 'Delete',
    set_schedule: 'Set schedule',
    go_login: 'Go Login',
    select: 'Select',
    option: 'Option',
    option_group: 'Crust',
    add_combo_group_item: 'Add Combo Group - Item',
    add_variant_addon: 'Add Variant - Addon',
    set_kds_products: 'Set KDS Products',
    copy: 'Copy',
    component_bom: 'Component Bom',
    set_size: 'Set Size',
    for_approval: 'For Approval',
    published: 'Published',
    add_user: 'Add User',
    add_promotio_user: 'Add Promotion User',
    delete_promotion_user: 'Delete Promotion User',
    put_on_break: 'Put On Break',
    put_online: 'Put Online',
    resend: 'Resend',
    connect: 'Connect',
    show_review: 'Show Review',
    add_category: 'Add Category',
    add_unit: 'Add Unit',
    add_option_group: 'Add Crust',
    add_material: 'Add Material',
    add_option: 'Add Size',
    add_comment: 'Add Comment',
    add_banner: 'Add Banner',
    add_tag: 'Add Tag',
    add_country: 'Add Country',
    add_group_tag: 'Add Group Tag',
    add_inventory: 'Add Inventory',
    add_store: 'Add Store',
    add_store_group: 'Add Store Group',
    add_store_cash: 'Add Store Cash',
    add_store_tag: 'Add Store Tag',
    add_store_notification: 'Add Store Notification',
    add_origin: 'Add Origin',
    add_doctor: 'Add Doctor',
    add_coupon: 'Add Coupon',
    add_notify: 'Add Notify',
    add_campaign: 'Add Campaign',
    add_po: 'Add Purchase Order',
    add_good_reciept: 'Add Good Reciept',
    detail: 'Detail',
    add_type: 'Add Type',
    down_format: 'Format Import',
    create: 'Create',
    add_payment_method_level1: 'Add Payment Method Level 1',
    add_payment_method: 'Add Payment Method',
    add_order_type: 'Add Order Type',
    add_order_status: 'Add Order Status',
    add_promotion_master: 'Add Promotion Master',
    add_promotion: 'Add Promotion',
    adjust_status: 'Adjust Status',
    clear_data: 'Clear Data',
    add_more: 'Add more',
    add_ads_video: 'Add Ads Video',
    add_combo_group: 'Add Combo Group',
    add_product: 'Add Product',
    export: 'Export',
    add_zone: 'Add Zone',
    add_matrix: 'Add Matrix',
    add_brand: 'Add Brand',
    add_subscription_plan: 'Add subscription plan',
    add_account: 'Add account',
    add_store_featured: 'Add featured list',
    add_attachment: 'Attach file or picture',
    save_draft: 'Save as draft',
    add_claim: 'Add Claim',
    add_spot_order: 'Add spot order',
    confirm_order: 'Agree and proceed',
    add_new_price_req: 'Add new price request',
    asprova_export: 'Send to Asprova'
  },
  user: {
    isLogin: 'Is Login',
    permisstion: 'Permission',
    checkAll: 'Check All',
    userType: 'User Type',
    basicDetail: 'Basic Detail',
    permissions: 'Permissions',
    text_add_role: 'Add a role now!',
    text_no_role: 'No role added yet.',
    text_no_driver: 'No driver yet',
    text_no_permissions: 'No permissions added yet',
    text_add_permissions: 'Add a permission now!',
    text_add_user: 'Add a user now!',
    text_no_user: 'No user added yet',
    loginInfo: 'Login Information',
    role_type: 'Role Type',
    sub_role: 'Sub Roles',
    is_normal_role: 'Normal Role',
    is_manager_role: 'Manager Role'
  },
  store: {
    store_listing: 'Store Listing',
    stores: 'Stores',
    openedAt: 'Opening Hour',
    closedAt: 'Closing Hour',
    mon: 'Mon:',
    tue: 'Tue:',
    wed: 'Wed:',
    thu: 'Thur:',
    fri: 'Fri:',
    sat: 'Sat:',
    sun: 'Sun:',
    hol: 'Holiday:',
    public_holiday: 'Holiday:',
    basic_information: 'Basic Information',
    payment: 'Payment Information',
    jp_information: '提携社',
    time_setting: 'Time Settings Information',
    store_group: 'Store Group:',
    tier: 'Tier:',
    type: 'Store Type:',
    code: 'Store Code:',
    name: 'Store Name',
    phone: 'Phone:',
    fax: 'Fax:',
    tax_rate: 'Store tax (%)',
    operation_hour: 'Operation hours:',
    opening_timing: 'Opening Timings:',
    aggregator_code: 'Aggregator Code:',
    am_sv_code: 'AM/SV Code:',
    am_sv_name: 'AM/SV Name:',
    owner_name: 'Owner Name:',
    owner_code: 'Owner Code:',
    email: 'Email',
    desc: 'Description:',
    remark: 'Remark:',
    accept: 'Accept Online Order:',
    accept_online: 'Accept Online Order:',
    note: '※It is necessary to notify the acceptance or rejection separately to the partner',
    alcohol: 'Alcohol Treatment:',
    estimated_time: 'Estimated Time (min):',
    collection: 'Collection',
    business_period: 'Business Period:',
    business_hour: 'Business Hours',
    schedule: 'Opening Schedule:',
    lunch_time: 'Lunch Time',
    time_zone: 'Time Zone:',
    minimum_cart: 'Minimum Cost Value (¥):',
    lat: 'Lat',
    long: 'Long',
    location: 'Location:',
    disposition: 'Dispositions',
    cash_on_delivery: 'Cash On Delivery:',
    credit_card: 'Credit Card:',
    ocbc: 'OCBC',
    payment_info: 'Payment Information',
    delivery_fee: 'Delivery Fee',
    menu_setting: 'Menu Control',
    take_away_text: 'Collection',
    delivery_text: 'Delivery',
    calendar: 'Calendar:',
    bt_calendar: 'View Calendar',
    period: 'Period',
    period_count: 'Period Count',
    start_day_week: 'Starting day of the week',
    title: 'Name',
    bt_add: 'Save Event',
    start_date: 'Date',
    end_date: 'End date',
    event: 'Event',
    time: 'Time',
    click_upload: 'Click to upload',
    note_kml: '※ Only accept KML file',
    confirmDelete: 'Are you sure to delete Event?',
    bt_public_menu: 'Public Menu & Combo To Production',
    duration: 'Duration:',
    search_keyword: 'Type code or name',
    menu_option: 'Exclude Size',
    menu_option_group: 'Exclude Crust',
    mixed_option_group: 'Exclude Size & Crust',
    geometry: 'Geometry',
    trade_zone: 'Trade Zone',
    sub_trade_zone: 'Sub Trade Zone',
    special_business_hours: 'Special Business Hours',
    special_date_close: 'Special Date Close',
    pizza_hut_day: "Pizza Hut's Day",
    order_duration: 'Order Duration',
    public_day: 'Public Holiday',
    stop_ordering: 'Stop Ordering',
    enter_a_location: 'Enter a location',
    store_detail: 'Store Details',
    additional_infomation: 'Additional Infomation',
    qr_code: 'QR Code',
    pin_code: 'Pin Code',
    contact_detail: 'Contact Details',
    integration: 'Integrations',
    language: 'Language',
    currency: 'Currency',
    tax_detail: 'Tax Details',
    is_inclusive_tax: 'Store price inclusive tax?',
    owner_detail: 'Owner Details',
    marketing_detail: 'Marketing Details',
    website: 'Website',
    tax_number: 'Tax Number',
    tax_persentage: 'Tax Persentage (%)',
    my_store: 'My stores',
    my_store_group: 'My store groups',
    add_store: 'Add a store now!',
    no_store: 'No store added yet.',
    view_map: 'View Map',
    show_all_sub_zone: 'Show All Sub Zone',
    add_sub_zone: 'Add Sub Zone',
    whatsapp_number: 'WhatsApp',
    address1: 'Address 1',
    address2: 'Address 2',
    zip_code: 'Zip Code',
    delivery_time: 'Delivery Time',
    ops_delivery_text: 'Ops delivery text',
    breakfast: 'Breakfast',
    full_address: 'Full Address',
    postal_code: 'Postal Code',
    fallback_banner: 'Fallback banner',
    address_level_0: 'Address Level 0',
    address_level_1: 'House No',
    address_level_2: 'Street',
    address_level_3: 'City',
    address_level_4: 'State',
    address_level_5: 'Country',
    address_level_6: 'Country Code',
    address_level_7: 'Address Level 7',
    address_level_8: 'Address Level 8',
    address_level_9: 'Address Level 9',
    set_max_order: 'Set Max Order',
    airport_mapping: 'Airport Mapping',
    sequence: 'Sequence',
    no_order_forecast: 'No Order Forecast',
    add_order_forecast: 'Add Order Forecast',
    no_driver_forecast: 'No Driver Forecast',
    add_driver_forecast: 'Add Driver Forecast',
    add_store_tag: 'Add a store tag now!',
    no_store_tag: 'No store tag added yet',
    store_tag_uuids: 'Store Tag',
    images: 'Images',
    bank_details: 'Bank Details',
    bank_name: 'Bank Name',
    bank_branch_name: 'Branch Name',
    bank_account_name: 'Account Name',
    bank_account_number: 'Account Number',
    add_store_notification: 'Add a store notification now!',
    no_store_notification: 'No store notification added yet',
    store_notification_uuids: 'Store Notification',
    opening_hours: 'Opening Hours',
    pre_orders: 'Pre-Orders',
    pre_order_time: 'Pre-Order lead time',
    delivery_options: 'Delivery Options',
    pre_order_settings: 'Pre-order settings',
    self_pickup_settings: 'Pickup settings',
    self_pickup: 'Enable self-pickup',
    self_pickup_time: 'Estimated preperation time',
    display_phone: 'Display Phone Number',
    categories: 'Restaurant Category',
    food_categories: 'Food Category (max 4)',
    dietary_categories: 'Dietary Requirement (max 1)',
    pickup_address: 'Pickup Address',
    dual_shift: 'Dual Shift',
    opening_time: 'Opening Time',
    closing_time: 'Closing Time',
    open_today: 'Open Today',
    subscription_plan: 'Subscription Plan',
    plan: 'Plan',
    view_more_plans: 'View More Plans',
    max_distance: 'Max Distance',
    store_url: 'Store URL',
    no_store_featured: 'No Store Featured',
    add_store_featured: 'Add Store Featured',
    order_commission: 'Order Commission',
    expired_subscription_plan: 'Expires in {days} days',
    commission_message: '{commission_rate}% per order',
    period_ending_message: 'Trial period ending in {months} months',
    no_plan: 'No Plan',
    commission_plan: 'Commission Plan',
    commission: 'Commission',
    commission_percentage: 'Commission Percentage',
    store_approval: 'Store Approval',
    rejected_reason: 'Reject Reason',
    money_bag_warning_price: 'Money Bag Warning Price',
    text_add_config: 'Add a store config now!',
    text_no_config: 'No store config added yet',
    campaign_information: 'Campaign Information',
    number_ticket_message: 'Number Ticket Message',
    edit_store_address_mapping: 'Edit Store Address Mapping',
    parcel: 'Parcel',
    remove_trade_zone: 'Remove Trade Zone',
    timezone: 'Timezone',
    translation: 'Translation',
    is_free_delivery: 'Is Free Delivery',
    applicable_documents: 'Licenses, permits, and authorisation applicable to the Merchant',
    store_code: 'Store Code',
    language_for_email: 'Language for notification',
    owner: 'Owner',
    owner_co: 'CO',
    owner_fc: 'FC',
    report_store_group: 'Report Store Group'
  },
  shift: {
    cashOut: 'Cash Out',
    description: 'Description',
    createdDate: 'Created Date',
    createdBy: 'Created By'
  },
  topping: {
    shortName: 'Short Name',
    sku: 'SKU',
    size: 'Size',
    default: 'Default',
    extra: 'Extra',
    topping: 'Topping',
    extra_cheese: 'Extra Cheese',
    topping_name: 'Topping name',
    delivery_tax: 'Delivery tax',
    dine_in_tax: 'Dine in tax',
    take_away_tax: 'Take away tax',
    no_topping_added_yet: 'No topping added yet',
    add_a_topping_now: 'Add a topping now',
    add_topping: 'Add topping',
    short_name: 'Short name',
    type: 'Type',
    category: 'Category',
    sequence: 'Sequence',
    description: 'Description',
    topping_detail: 'Topping detail',
    status: 'Status',
    desposition_pricing: 'Desposition & Pricing',
    isAfterBaking: 'Is After Baking',
    isAttachedCondiments: 'Is Attached Condiments',
    relatedItems: 'Related Items'
  },
  store_group: {
    store_group_detail: 'Store Group Details',
    total_store: 'Total Store',
    store_available: 'Store Available',
    store_added: 'Store Added',
    add_store_group: 'Add a store group now!',
    no_store_group: 'No store group added yet.'
  },
  store_purchase: {
    name: 'Name',
    quantity: 'Quantity',
    unit: 'Unit',
    price: 'Price',
    date: 'Date',
    store: 'Store',
    no_store_purchase: 'Please select filter for get list Store Purchase',
    add_store_purchase: 'Add Store Purchase',
    create_store_purchase: 'Create',
    detail: 'Store Purchase Detail',
    basic_information: 'Basic Information'
  },
  address: {
    outlet_code: 'Store Code',
    level1: 'Level1',
    level2: 'Level2',
    level3: 'Level3',
    level4: 'Level4',
    level5: 'Level5',
    level6: 'Level6',
    level7: 'Level7',
    from_date: 'From Date',
    to_date: 'To Date',
    zip: 'Zip',
    address_code: 'Address Code',
    outlet: 'Store',
    from: 'From',
    to: 'To'
  },
  purchase_order: {
    amount: 'Amount',
    amount_receive: 'Amount Receive',
    supplier_name: 'Supplier Name',
    supplier_code: 'Supplier Code',
    supplier_contact: 'Supplier Contact',
    code1: 'Code1',
    code2: 'Code2'
  },
  purchase_order_detail: {
    purchase_order_uuid: 'PO Code',
    receive_quantity: 'Receive Qty',
    receive_price: 'Receive Price',
    receive_amount: 'Receive Amount',
    is_cancel: 'Cancelled',
    is_return: 'Returned',
    detail: 'Detail',
    keyword: 'Keyword'
  },
  material_master: {
    selflife_number: 'Self Life Number',
    selflife_number_type: 'Self Life Number Type',
    inventory_check: 'Inventory Check',
    inventory_uom: 'Inventory UOM',
    external_refence_code: 'External refence code',
    inventory_quantity: 'Inventory Quantity',
    storage_condition_category: 'Storage Condition Category',
    temperature_management_category: 'Temperature Management Category',
    consumption_time_limit: 'Consumption Time Limit',
    unfreeze_time: 'UnFreeze Time',
    min_time: 'Min Time',
    time_after_opening: 'Time After Opening',
    inventory_applied: 'Inventory Applied',
    purchasing_unit: 'Purchasing Unit',
    stocking_unit: 'Stocking Unit',
    uses_unit: 'Uses Unit',
    max_number_of_order: 'Maximum Number Of Order',
    recipe: 'Recipe',
    equal: 'Equal',
    co: 'CO',
    fc: 'FC',
    account: 'Account',
    order_period: 'Order Period',
    delivery_period: 'Delivery Period',
    condition: 'Condition',
    cost_management: 'Cost Management',
    purchase_price: 'Purchase price',
    uom: 'UOM',
    logistic_information: 'Logistic Information',
    inventory_management: 'Inventory Management',
    detail_information: 'Detail Information',
    general_information: 'General Information',
    gram: 'Gram',
    case: 'Case',
    bag: 'Bag',
    basic_detail: 'Basic Details',
    more_detail: 'More Details',
    max_no_of_order: 'Max No. of Orders',
    type_material: 'Type Material'
  },
  master_material_category: {
    master_material_category_name: 'Material Category'
  },
  customer: {
    is_blocked: 'Is Blocked',
    register_date: 'Registered Date',
    register_channel: 'Registered Channel',
    first_order_date: 'First Order Date',
    first_order_channel: 'First Order Channel',
    point: 'Point',
    amount: 'Amount',
    bill: 'Bill',
    level_point: 'Level Point',
    reward_type: 'Reward Type',
    reward_point: 'Reward Point',
    reward_status: 'Reward Status',
    reward_success: 'Success',
    reward_waiting: 'Waiting',
    reward_cancel: 'Cancel',
    reward_use: 'Use',
    reward_earn: 'Earn',
    history: 'History',
    gender: 'Gender',
    date_time: 'Datetime',
    male: 'Male',
    female: 'Female',
    average: 'Average',
    phone: 'Phone',
    birthday: 'Birthday',
    note: 'Note',
    address: 'Address',
    customer_address: 'Customer Address',
    view_customer: 'View Customer',
    first_name: 'First Name',
    last_name: 'Last Name',
    alternative_phone: 'Alternative Phone',
    street: 'Street',
    trade_zone_name: 'Trade Zone Name',
    trade_zone_lat: 'Trade Zone Lat',
    trade_zone_long: 'Trade Zone Long',
    disposition: 'Disposition',
    store_name: 'Store Name',
    is_headquarter: 'Is Headquarter',
    headquarter_code: 'Headquarter Code'
  },
  validate: {
    order_type_required: 'Order Type is requried',
    pin_code: 'Pin Code is reqired and type is Number',
    limit_quantity_cart: 'Limit Quantity Cart is Required and is Number',
    platforms: 'Platforms is Required',
    payment_value: 'Value is Number',
    point: 'Point is required and type is Number',
    amount: 'Amount is required and type is float',
    order_type: 'Segment is required',
    bill: 'Bill is required',
    store: 'Store is required ',
    reward_type: 'Reward type is required',
    client_required: 'Client is required',
    option_group: 'Option Group must has Option',
    is_required: ' is required',
    fullname: 'Full name is required',
    option_group_uuid: 'Option group is required',
    option_uuid: 'Option is reuqired',
    tax_rate: 'Tax is required',
    sku: 'SKU is required',
    store_group_uuids: 'Store group is required',
    sku_header: 'SKU Header is required',
    name: 'Name is required',
    short_name_required: 'Short Name is required',
    type_ids: 'Type is required',
    category_uuid: 'Category is required',
    code: 'Code is required',
    zone_code: 'Zone code is required',
    duration: 'duration is required',
    waiting_time: 'Waiting time is required',
    min_cart: 'Min card is required',
    password: 'Please input the password',
    password_again: 'Please input the password again',
    password_dont_match: "Two inputs don't match!",
    option_empty: 'Option group can not empty.',
    ip_required: 'IP is required',
    store_required: 'Store is required',
    pin_code_required: 'Pin Code is required',
    user_type_required: 'User Type is required',
    code_length: 'Code length should be 1 to 20',
    selflife_number: 'Self Life Number is required',
    selflife_number_type: 'Self Life Number Type is required',
    external_refence_code: 'External refence code is required',
    uom_uuid: 'UOM is required',
    master_material_category_name: 'Material Category is required',
    uom_from_uuid: 'UOM From is required',
    uom_to_uuid: 'UOM To is required',
    conversion: 'Conversion is required',
    material_uuid: 'Material is required',
    component_uuid: 'Component is required',
    start_date: 'Start Date is required',
    end_date: 'End Date is required',
    username_lowercase: 'Username contain only lowercase letters, numbers, - _ . and accept email format',
    username_required: 'Username is required',
    password_required: 'Password is required',
    product_uuid: 'Product is required',
    time_required: 'Time is required',
    email: 'Please input the email',
    email_required: 'Email is required',
    phone_required: 'Phone is required',
    display_phone_required: 'Display phone is required',
    onwer_name_required: 'Owner Name is required',
    birthday_required: 'Birthday is required',
    level1_required: 'Level1 is required',
    level2_required: 'Level2 is required',
    level3_required: 'Level3 is required',
    address_code_required: 'Address Code is required',
    permissions_required: 'Permissions is required',
    tier_required: 'Tier is required',
    pool_required: 'Pool is required',
    store_type_required: 'Store Type is required',
    store_group_required: 'Store Group is required',
    operation_hours_required: 'Operation Hours is required',
    location_required: 'Location is required',
    lat_required: 'Lat is required',
    long_required: 'Long is required',
    disposition_required: 'Disposition is required',
    timezone_required: 'Timezone is required',
    description_required: 'Description is required',
    type_uuid: 'Type is required',
    bank_uuid: 'Bank is required',
    group_uuid: 'Group is required',
    date_required: 'Date is required',
    price_channel: 'Channel is required',
    price_tier: 'Tier is required',
    price_partner: 'Partner is required',
    price: 'Price is required',
    kana_name_required: 'Kana Name is required',
    kanji_name_required: 'Kanji Name is required',
    gender_required: 'Gender is required',
    birth_date_required: 'Birth Date is required',
    contract_title_required: 'Contract Title is required',
    contract_period_required: 'Contract Period is required',
    nationality_required: 'Nationality is required',
    position_required: 'Position is required',
    rank_required: 'Rank is required',
    additional_type_ids: 'Additional Type is required',
    name_required: 'Name is required',
    kds_make: 'Kds Make is required',
    kds_cut: 'Kds Cut is required',
    make_time: 'Make Time is required',
    cut_time: 'Cut Time is required',
    code_required: 'Code is required',
    size_required: 'Size is required',
    crust_required: 'Crust is required',
    evidence_number_required: 'Evidence number is required',
    payment_type_required: 'Payment type is required',
    option_required: 'Size is required',
    min: 'Min is required',
    max: 'Max is required',
    quantity: 'Quantity is required',
    option_group_required: 'Crust is required',
    material_required: 'Material is required',
    roles_required: 'Roles is required',
    campaign_required: 'Campaign is required',
    application_required: 'Application is required',
    short_name: 'Short name is required',
    sub_price: 'Sub price is required',
    start_date_required: 'Start Date is required',
    end_date_required: 'End Date is required',
    userType_required: 'User Type is required',
    page_required: 'Page is required',
    url_required: 'URL is required',
    field_required: 'Field is required',
    first_name_required: 'First Name is required',
    last_name_required: 'Last Name is required',
    hurigana_first_name_required: 'Hurigana First Name is required',
    hurigana_last_name_required: 'Hurigana Last Name is required',
    topping_required: 'Topping is required',
    from_store_required: 'From Store is required',
    to_store_required: 'To Store is required',
    type_required: 'Type is required',
    slip_number_required: 'Slip Number is required',
    product_required: 'Product is required',
    quantity_required: 'Quantity is required',
    reason_required: 'Reason is required',
    kanjiFirstName_required: 'Kanji First Name is required',
    kanjiLastName_required: 'Kanji Last Name is required',
    kanaFirstName_required: 'Kana First Name is required',
    kanaLastName_required: 'Kana Last Name is required',
    bloodType_required: 'Blood Type is required',
    telNumber_required: 'Tel Number is required',
    sending_time: 'Sending time is required',
    account_type_uuid_required: 'Account Type is required',
    employee_required: 'Employee is required',
    registrationDate_required: 'Registration Date is required',
    zipCode_required: 'Zip Code is required',
    address1_required: 'Address 1 is required',
    address2_required: 'Address 2 is required',
    externalCode_required: 'External Code is required',
    bankCode_required: 'Bank Code is required',
    branchCode_required: 'Branch Code is required',
    accountNumber_required: 'Account Number is required',
    accountName_required: 'Account Name is required',
    component_required: 'Component is required',
    title_required: 'Title is required',
    content_required: 'Content is required',
    image: 'Image is required',
    gallery: 'Gallery is required',
    sub_category: 'Sub category is required',
    upload_file_required: 'Upload file is required',
    display_type_required: 'Display type is required',
    app_required: 'App is required',
    kds_required: 'Kds is required',
    dispatch_required: 'Dispatch is required',
    taker_required: 'Taker is required',
    takeaway_monitor_required: 'TakeAway Monitor is required',
    prices: 'Prices is required',
    height: 'Height is required',
    length: 'Length is required',
    weight: 'Weight is required',
    width: 'Width is required',
    options: 'Options is required',
    tel_number: 'Tel Number must be a number',
    em_tel_number: 'Emergency Tel Number must be a number',
    mobile_tel_number: 'Mobile Tel Number must be a number',
    driver_license_number: 'Driver License Number must be a number',
    code_number: 'Code must be a number',
    zip_code_number: 'Zip Code must be a number',
    bank_code_number: 'Bank Code must be a number',
    bank_branch_code_number: 'Branch Code must be a number',
    bank_account_number: 'Account Number must be a number',
    emTelNumber_required: 'Emergency Tel Number is required',
    radius: 'Radius is required',
    postal_code: 'Postal code is required',
    origin_point: 'Origin point is required',
    destination_point: 'Destination point is required',
    slot_per_hour: 'Slot per hour is required',
    driver_trip_per_hour: 'Driver trip per hour is required',
    user_required: 'User is required',
    current_password: 'Please input current password',
    promo_type: 'Promo type is required',
    discount_value: 'Discount value is required',
    required: 'Required',
    method_required: 'Method is required',
    distribution_date_required: 'Distribution date is required',
    distributor_required: 'Distributor is required',
    commission_rate: 'Commission rate is required',
    status: 'Status is required',
    business_name: 'Business name is required',
    brand_name: 'Brand name is required',
    business_number: 'Business number',
    phone: 'Phone is required',
    display_phone: 'Display phone is required',
    account_name: 'Account name is required',
    account_number: 'Account number is required',
    unit: 'Unit is required',
    city: 'City is required',
    currency: 'Currency is required',
    postcode: 'Postcode is required',
    street: 'Street is required',
    state: 'State is required',
    range_height: 'Height should be between 0.1 and 100 cm',
    range_weight: 'Weight should be between 0 and 30 kg',
    range_width: 'Width  should be between 0.1 and 100 cm',
    range_length: 'Length  should be between 0.1 and 100 cm',
    tracking_number: 'Tracking nsumber is required',
    password_strength: 'Input Password and Submit [8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character - !@#$%&*]',
    address_level_1_required: 'House No is required',
    address_level_2_required: 'Street is required',
    address_level_3_required: 'City is required',
    address_level_4_required: 'State is required',
    max_length: '{attr} should not exceed {value} characters',
    food_name: 'Food name',
    without_emoji: '{attr} should be without emoji',
    slug: 'Slug is required',
    type: 'Type is required',
    start_time: 'Start time is required',
    end_time: 'End time is required'
  },
  payment_method: {
    bank: 'Bank',
    is_show_at_menu_order: 'Is show at menu',
    is_show_pos: 'Is Show Pos',
    is_card_number: 'Is card number',
    is_remark: 'Is remark',
    is_approval_code: 'Is approval code',
    is_active: 'Is active',
    group: 'Group',
    level1: 'Level 1',
    payment_method_detail: 'Payment Method Detail',
    no_payment_method: 'No Payment Method',
    add_payment_method: 'Add Payment Method',
    is_change: 'Is Change',
    cash_count: 'Show in Cash Count',
    input_cash_count: 'Input Cash Count',
    is_physical_voucher: 'Physical Voucher',
    order_type: 'Order type'
  },
  component: {
    component_detail: 'Component Details',
    component_code: 'Component Code',
    component_name: 'Component Name',
    component_short_name: 'Short Name',
    component_status: 'Status',
    size_and_material: 'Size and Materials',
    quantity: 'QTY',
    price: 'price',
    material_name: 'Material Name',
    s: 'S',
    m: 'M',
    l: 'L',
    unit_name: 'Unit Name',
    add_new: 'Add New'
  },
  order: {
    no: 'No',
    del_vs_quote_time: 'SOS<30',
    order_confirm_csc: 'Confirm CSC',
    order_send_store: 'Sent to Store',
    store_accept_order: 'Ordered',
    cooking: 'Cooking',
    start_delivery: 'Start Delivery',
    swipe_done: 'Swipe Done',
    encashment: 'Encashment',
    finish: 'Finished Time',
    void: 'Driver Tracker Status',
    dispatch: 'Dispatch',
    cooked: 'Cooked',
    driver_tracker: 'Driver Tracker Status',
    id: 'ID',
    code: 'Order Code',
    quote_time: 'Quote Time	',
    type: 'Segment',
    status: 'Order Status',
    date: 'Order Date',
    start_date: 'Start Date',
    end_date: 'End Date',
    sale_amount: 'Sale Amount',
    sub_total: 'Sub Total',
    sub_delivery_fee: 'Sub Delivery Fee',
    address_delivery: 'Address Delivery',
    tax_value: 'Tax Value',
    donation: 'Donation',
    action: 'Action',
    payment_method: 'Payment Method',
    payment_amount: 'Payment Amount',
    variance_status: 'Variance Status',
    view_order: 'View Order',
    edit_order: 'Edit Order',
    view_payment: 'View Payment',
    view_order_detail: 'View Order Detail',
    view_utilization_detail: 'View Utilization Detail',
    view_delivery: 'View Delivery',
    view_log_status: 'View Log Status',
    pulcode: 'Pulcode',
    price: 'Price',
    sub_price: 'Sub Price',
    menu_name: 'Menu Name',
    menu_price: 'Menu Price',
    add_on_price: 'Add-on Price',
    value: 'Value',
    change: 'Change',
    total_payment: 'Total Payment',
    new_status: 'New Status',
    reason: 'Reason',
    device: 'Channel',
    type_and_device: 'Type/Channel',
    timestamp: 'Timestamp',
    passed: 'Passed',
    variance_payment: 'Variance Payment',
    order_number: 'Order Number',
    channel: 'Channel',
    on_process_by: 'On Process By',
    driver_id: 'Driver Code',
    order_type: 'Segment',
    order_date: 'Order Date',
    kds_time: 'Kds Time',
    cooked_time: 'Cooked Time',
    delivery_start_time: 'Delivery Time',
    customer_recieved: 'Receive Time',
    encashment_time: 'Encashment Time',
    sos_achived: 'SOS',
    upper_text: 'Upper Text',
    hide_upper_text: 'Hide Upper Text',
    show_upper_text: 'Show Upper Text',
    choose_agent: 'Choose Agent',
    confirm_by_agent: 'Confirmed By',
    time_order: 'Order Time',
    user_type_guest: 'Guest',
    user_type_member: 'Member',
    reject_status: 'Reject Reason',
    confirm_status: 'SMS Sent',
    duration_time: 'Duration Time',
    user_type: 'User Type',
    keyword: 'Keyword',
    current_point: 'Current Point',
    earn_point: 'Earn Point',
    redeemed_point: 'Redeemed Point',
    request: 'Request',
    detail_Address: 'Address and Map',
    past_order_history: 'Past Order History',
    detail: 'Detail',
    advance: 'Advance',
    delivering_time: 'Delivering Time',
    delivered_time: 'Delivered Time',
    note: 'Note',
    adjust_status_delivery: 'Adjust Status Delivery',
    status_delivery: 'Status Delivery',
    ready_to_ship: 'Ready To Ship',
    tracking_url: 'Track Order >',
    phone: 'Phone Number',
    promise_date: 'Takeaway/Delivery Date',
    order_no: 'Order No',
    packing_slip: 'Packing Slip',
    invoice: 'Invoice',
    invoice_and_payslip: 'Packing Slip And Invoice',
    consignment_note: 'Consignment Note',
    invoice_date: 'Invoice Date',
    invoice_no: 'Invoice No',
    payment_type: 'Payment Type',
    shipping_address: 'Shipping Address',
    billing_address: 'Billing Address',
    item_description: 'Item Description',
    unit_price: 'Unit Price',
    discount: 'Discount',
    total: 'Total',
    store_status: 'Store status',
    is_invoice: 'Is Invoice',
    tracking_number: 'Tracking Number',
    toppings: 'Toppings'
  },
  sku: {
    sku: 'Sku',
    sku_header: 'Sku Header',
    brand_uuid: 'Brand',
    category_uuid: 'Sku Category',
    food_id: 'Food Id'
  },
  menu: {
    topping: 'Topping',
    product: 'Product',
    toppings: 'Toppings',
    products: 'Products',
    category: 'Category',
    option_group: 'Crust',
    option: 'Size',
    option_groups: 'Crusts',
    options: 'Sizes',
    select_option: 'Select Option',
    add_category: 'Add a category now!',
    no_category: 'No category added yet.',
    category_detail: 'category Details',
    no_option_group: 'No crust added yet.',
    add_option_group: 'Add a crust now!',
    add_comment: 'Add a Comment now!',
    no_comment: 'No Comment added yet.',
    add_banner: 'Add a Banner now!',
    no_banner: 'No Banner added yet.',
    no_option: 'No size added yet.',
    add_option: 'Add a size now!',
    add_origin: 'Add a origin now!',
    no_origin: 'No origin added yet',
    no_order: 'No order added yet',
    add_tag: 'Add a tag now!',
    no_tag: 'No tag added yet',
    add_type: 'Add a type now!',
    no_type: 'No type added yet',
    add_country: 'Add a country now!',
    no_country: 'No country added yet',
    add_group_tag: 'Add a group tag now!',
    no_group_tag: 'No group tag added yet',
    no_group_type: 'No group type added yet',
    add_group_type: 'Add a group type now!',
    add_inventory: 'Add a inventory now!',
    no_inventory: 'No inventory added yet',
    add_doctor: 'Add a Doctor now!',
    no_doctor: 'No Doctor added yet',
    vailable_choices: 'Vailable Choises',
    your_menu: 'Your Menu',
    is_show_website: 'Is Show Website',
    no_product_half_and_half: 'No product half and half',
    add_new: 'Add New',
    set_quantity: 'Set Quantity',
    add_combo_group: 'Add a combo group now!',
    no_combo_group: 'No combo group added yet',
    add_product: 'Add a product now!',
    no_product: 'No product added yet',
    add_brand: 'Add a brand now!',
    no_brand: 'No brand added yet',
    package_information: 'Package information',
    topping_group: 'Topping Group',
    no_topping_group: 'No topping group',
    add_topping_group: 'Add topping group',
    add_new_topping_group: 'Add new topping group',
    no_item_yet: 'No item yet',
    items: 'items',
    add_topping: 'Add Topping',
    new_topping_group: 'New Topping Group',
    available_choices: 'Available choices',
    selected_topping: 'Topping selected'
  },
  product: {
    other_info: 'Advance Info',
    attachment_product: 'Attachment Product',
    product_name: 'Product Name',
    menu_code: 'Menu Code',
    default_topping: 'Default Topping',
    product_topping: 'Product Topping',
    topping: 'Topping',
    max_default_topping: 'Max default topping',
    max_additional_topping: 'Max additional topping',
    add_crust_size: 'Add Crust Size',
    auto_cook: 'Auto Cook',
    slug: 'Slug',
    target: 'Target',
    url: 'URL',
    hide_from_ui: 'Hide From UI',
    half_and_half: 'Half And Half',
    discount: 'Discount',
    only_lunch: 'Only Lunch',
    hut_day: "Hut's Day",
    video: 'Video',
    normal: 'Normal',
    pizza: 'Pizza',
    combo: 'Combo',
    special: 'Special',
    doctor_appointment: 'Doctor Appointment',
    addon: 'Addon',
    donation: 'Donation',
    extra_chilli: 'Extra Chilli',
    select_add_into_group: 'SELECT ADD INTO GROUP',
    product_detail: 'Product detail',
    maximum_additional_topping: 'Maximum Additional Topping',
    maximum_minus_topping: 'Maximum Minus Topping',
    draft: 'Draft',
    forApproval: 'For Approval',
    pulished: 'Published',
    marketing_price: 'Marketing Price',
    kds_make: 'Kds Make',
    kds_cut: 'Kds Cut',
    make_time: 'Make Time',
    cut_time: 'Cut Time',
    depositionAndPricing: 'Deposition & Pricing',
    chooseCategoryType: 'Choose category type',
    basicInfoTitle: 'Basic info',
    isAlcohol: 'Serves Alcohol',
    isAutoCook: 'Auto Cook',
    storeInfoTitle: 'Store info',
    orderInfo: 'Order info',
    typeTitle: 'Type',
    taxTitle: 'Tax',
    emptyTypeMessage: 'Please select at least one type.',
    gallery: 'Gallery',
    toppingTitle: 'Topping',
    channel: 'Channel',
    tier: 'Tier',
    partner: 'Partner',
    priceWithoutTax: 'Price Without Tax',
    addMore: 'Add more',
    image: 'Image',
    thumbnail: 'Thumbnail',
    how_to_make: 'How To Make',
    priceWithTax: 'Price With Tax',
    is_menu_point_free_delivery: 'Is Menu Point Free Delivery',
    is_flag_driver: 'Is Flag Driver',
    guide: 'Guide',
    size_and_crust: 'Size And Crust',
    is_salad: 'Is Salad',
    is_print_label: 'Print Label',
    addMultipleLots: 'Add Multiple Lots'
  },
  content: {
    content_category: 'Content Category',
    slug: 'Slug',
    target: 'Target',
    url: 'URL',
    no_content: 'No content added yet!'
  },
  store_target: {
    store_code: 'Store Code',
    store_name: 'Store Name',
    target_sale: 'Target Sale',
    rate: 'Rate',
    result: 'Result',
    month: 'Month'
  },
  report: {
    order_type: 'Segment',
    order_status: 'Order Status',
    channel: 'Channel',
    order_number: 'Order Number',
    total_count: 'Total Count',
    total_amount: 'Total Amount',
    activity: 'Activity'
  },
  popup: {
    remove_option: 'Are you sure to remove this option?',
    remove_group: 'Are you sure to remove this group?',
    remove_product: 'Are you sure to remove this product?',
    warning: 'Warning',
    error: 'Error',
    MoneyBagLog: 'Money Bag Log',
    notify: 'Notify',
    export: 'Export Data',
    select_date: 'Select date range',
    copy_to_clip: 'Copy to clipboard success'
  },
  err: {
    submit: 'error submit!!',
    checkQRCodeUser: 'One store only have one User belong QR'
  },
  defined: {
    active: 'Active',
    inactive: 'Inactive',
    all: 'ALL',
    show: 'Show',
    not_show: 'Not Show'
  },
  dialog: {
    detail_event: 'Detail Event',
    warn_before_close: 'Are you sure to close this dialog?',
    import_manufacturing_product: 'Import Manufacturing Product',
    import_demand: 'Import Demand Data',
    manufacturing_export: 'Manufacturing plan ASPROVA interface',
    manufacturing_export_note: 'You are about to generate a file based on this manufacturing plan and send it to ASPROVA'
  },
  tooltip: {
    capslock: 'Caps lock is On'
  },
  clipboard: {
    please_input: 'Please input',
    use_clipboard_by_v_directive: 'use clipboard by v-directive',
    use_clipboard_directly: 'use clipboard directly'
  },
  promotion: {
    promotion_master: 'Promotion Master',
    promotion: 'Promotion',
    discount_type: 'Discount Type',
    discount_value: 'Discount Value',
    auto_apply: 'Auto apply',
    multiple: 'Multiple',
    include_stores: 'Include Stores',
    exclude_stores: 'Exclude Stores',
    limit: 'Limit',
    total_use: 'Total Use',
    min_purchase: 'Min Purchase',
    category_uuid: 'Categorie',
    coupon: 'Coupon',
    user_available: 'User available',
    promotion_user: 'Promotion User',
    is_use: 'Is Use',
    basic_details: 'Basic Details',
    active_period: 'Active period',
    time_setting: 'Time settings',
    condition: 'Condition',
    product: 'Product',
    number_of_order: 'Number of order',
    no_coupon: 'No coupons added yet.',
    add_coupon: 'Add a coupon now!',
    upload_user: 'Upload User',
    upload_file_csv: 'Upload file csv',
    customer_name: 'Customer name',
    child_promotion_master: 'Child Promotion Master',
    public: 'Public',
    platform: 'Platform',
    expired_day: 'Expired Day',
    generate_coupon: 'Generate coupon',
    no_promotion_master: 'No Promotion Master',
    add_promotion_master: 'Add Promotion Master',
    exclude_store: 'Exclude Store',
    include_store: 'Include Store',
    no_promotion: 'No Promotion',
    add_promotion: 'Add Promotion',
    auto_give: 'Auto give',
    adjust_status: 'Adjust Status',
    is_one_time: 'Is one time',
    is_for_employee: 'Is for employee',
    limit_value: 'Limit VaLue',
    prefix_apply_auto: 'Prefix Apply Auto',
    limit_apply_auto: 'Limit Apply Auto'
  },
  doctor: 'Doctor',
  review: 'Review',
  your_browser_does_not_support_video_playback: 'Your browser does not support video playback',
  picker: {
    today: 'Today',
    yesterday: 'Yesterday',
    this_month: 'This month',
    this_year: 'This year',
    last_week: 'Last week',
    last_month: 'Last month'
  },
  store_cash: {
    name: 'name',
    type_date: 'Type Date',
    store_cash: 'Store Cash',
    driver: 'Driver',
    evidence_number: 'Evidence Number',
    payment_type: 'Payment Type',
    cash_payment: 'Cash Payment',
    bank_payment: 'Bank Payment',
    deposit: 'Deposit',
    withdrawal: 'Withdrawal',
    deposit_amount: 'Deposit Amount',
    withdrawal_amount: 'Withdrawal Amount',
    receipt_date: 'Receipt Date',
    registration_date: 'Registration Date',
    account_type: 'Account Type',
    account_name: 'Account Name',
    add_store_cash: 'Add Store Cash',
    no_store_cash: 'No Store Cash!',
    add_money_bag: 'Add Money Bag',
    no_money_bag: 'No Money Bag!',
    type_store_cash: 'Type Store Cash'
  },
  appointment: {
    appointment: 'Appointment',
    date: 'Date',
    time_slot: 'Time Slot',
    total_slot: 'Total Slot',
    available_slot: 'Available Slot',
    addMore: 'Add more',
    recalculate: 'Recalculate',
    save: 'Save'
  },
  employee: {
    basic_details: 'Basic Details',
    company_name: 'Company Name',
    gender: 'Gender',
    birth_date: 'Birth Date',
    male: 'Male',
    female: 'Female',
    unknown: 'Unknown',
    address_contact_details: 'Address & Contact Details',
    cert_zip_code: 'Certificated Zip Code',
    cert_address1: 'Certificated Address 1',
    cert_address2: 'Certificated Address 2',
    zip_code: 'Current Zip Code',
    address1: 'Current Address 1',
    address2: 'Current Address 2',
    mobile_tel_number: 'Mobile Tel Number',
    tel_number: 'Tel Number',
    em_tel_number: 'Emergency Tel Number',
    bank_information: 'Bank Information',
    bank_name: 'Bank Name',
    bank_branch_name: 'Branch Name',
    bank_account_name: 'Account Name',
    bank_account_number: 'Account Number',
    driver_license_information: 'Driver License Information',
    driver_license_number: 'License Number',
    driver_license_expire_date: 'License Expire Date',
    other_information: 'Other Information',
    is_temp_worker: 'Temporary Worker',
    is_provisional_contract: 'Provisional Contract',
    education: 'Education',
    retirement_date: 'Retirement Date',
    occupation: 'Occupation',
    nationality_category: 'Nationality Category',
    visa_status: 'Visa Status',
    alien_certificate_number: 'Residence Card No',
    period_of_stay: 'Period of Stay',
    change_status: 'Change Status',
    blood_type: 'Blood Type',
    note: 'Note',
    contract_title: 'Contract Title',
    contract_period: 'Contract Period',
    position: 'Position',
    rank: 'Rank',
    nationality: 'Nationality',
    mon: 'Mon',
    tue: 'Tue',
    wed: 'Wed',
    thu: 'Thu',
    fri: 'Fri',
    sat: 'Sat',
    sun: 'Sun',
    employee_information: 'Employee Information',
    salary_and_allowance_details: 'Salary & Allowance',
    base_hourly_pay: 'Base Hourly Pay',
    allowance: 'Allowance',
    overtime: 'Overtime',
    driving: 'Driving',
    holiday: 'Holiday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    commuting: 'Commuting',
    flyer_posting: 'Flyer Posting',
    shift_manager: 'Shift Manager',
    midnight_shift: 'Midnight Shift',
    other: 'Other',
    weekly_working_day: 'Weekly Working Day',
    weekly_working_hour: 'Weekly Working Hour',
    work_time: 'Work Time',
    rest_hour: 'Rest Hour',
    working_hour: 'Working Hour',
    contract: 'Contract',
    period: 'Period',
    em_tel_name: 'Emergency Tel Name',
    bank_details: 'Bank Details',
    driver_license_details: 'Driver License Details',
    additional_information: 'Additional Information',
    visa_information: 'Visa Information',
    contracts: 'Contracts',
    new_contract: 'New Contract',
    accounts: 'Accounts',
    password: 'Password',
    confirm_password: 'Confirm Password',
    roles: 'Roles',
    new_user_login: 'New User Login',
    day: 'Day',
    text_add_employee: 'Add a Employee now!',
    text_no_employee: 'No Employee added yet.',
    upload_driver_license_btn: 'Upload Driver License',
    upload_driver_license_tip: 'Upload photos the front and back of the Driver License',
    contract_form: {
      overtime: 'Overtime',
      driving: 'Driving',
      holiday: 'Holiday',
      saturday: 'Saturday',
      sunday: 'Sunday',
      commuting: 'Commuting',
      flyer_posting: 'Flyer Posting',
      shift_manager: 'Shift Manager',
      midnight_shift: 'Midnight Shift',
      other: 'Other'
    },
    bank_code: 'Bank Code',
    bank_branch_code: 'Branch Code',
    text_add_contract: 'Add a Contract now!',
    text_no_contract: 'No Contract added yet.',
    text_add_user_login: 'Add a User Login now!',
    text_no_user_login: 'No User Login added yet.',
    first_name: 'First Name',
    last_name: 'Last Name',
    furiganaName: 'Furigana Name',
    is_retired: 'Is Retired',
    age: 'Age',
    edit_contract: 'Edit Contract',
    createNewContract: 'Create New Contract',
    updateCurrentContract: 'Update Current Contract',
    createNextContract: 'Create Next Contract',
    updateNextContract: 'Update Next Contract',
    retirementDate: 'Retirement Date',
    passwordExpiredDate: 'Password Expire Date',
    driverDisplay: 'Driver Display',
    fullName: 'Full Name',
    driverLicense: 'Driver License',
    certificate: 'Certificate',
    kanjiName: 'Kanji Name',
    kanaName: 'Kana Name',
    expireDate: 'Expire Date',
    yearOld: 'year old',
    fullTime: 'Full Time',
    partTime: 'Part Time',
    employeeType: 'Employee Type',
    assignToStore: 'Assign To Store',
    registrationDate: 'Registration Date',
    detailInformation: 'Detail Information',
    text_add_temporaryAssignment: 'Add a Temporary Assignment now!',
    text_no_temporaryAssignment: 'No Temporary Assignment added yet.',
    role_parent: 'Role Parent',
    role_child: 'Role Child',
    add_role: 'Add Role',
    text_add_standardConfiguration: 'Add a Standard Configuration now!',
    text_no_standardConfiguration: 'No Standard Configuration added yet.',
    salaryAndOvertime: 'Salary And Overtime',
    minimumSalary: 'Minimum Salary',
    realWorkTime: 'Real Work Time',
    nightWorkTime: 'Night Work Time',
    overtimeRate: 'Overtime Rate',
    nightRate: 'Night Rate',
    settingToLaborCheck: 'Setting To Labor Check',
    breakTime: 'Break Time',
    workingTime: 'Working Time',
    minBreakTime: 'Minimum Break Time',
    dailyWorkTimeLimit: 'Daily Work Time Limit',
    workTimeRange: 'Work Time Range',
    teenagerUnder18: 'Teenager (under 18)',
    restTime: 'Rest Time',
    ownStore: 'Own Store',
    retirement_flag: 'Retirement Flag',
    address: 'Address',
    phone_contact: 'Phone Contact',
    current_contract: 'Current Contract',
    next_contract: 'Next Contract',
    bank_account_type: 'Account Type',
    bank_account_type_normal: 'Normal',
    bank_account_type_current: 'Current',
    thirdParty: 'Third Party',
    export: 'Export',
    code: 'Code',
    type: 'Type',
    create_employee: 'Create',
    text_no_contract_period: 'No Contract Period added yet.',
    text_add_contract_period: 'Add a Contract Period now!',
    internal_certificate: 'DC Certification',
    help_end_flg: 'Help End Flag',
    help_end_date: 'Help End Date'
  },
  inventory: {
    not_confirmed: 'Not Confirmed',
    all: 'All',
    name: 'Name',
    transfer: 'Transfer',
    action: 'Action',
    category_detail: 'Category Details',
    no_category_added_yet: 'No category added yet',
    add_category_now: 'Add a category now!',
    unit_detail: 'Unit Details',
    no_unit: 'No unit added yet.',
    add_unit: 'Add a product now!',
    basic_details: 'Basic Details',
    option_and_option_group: 'Size And Crust',
    add_new: 'Add New',
    add_new_version: 'Add New Version',
    add_version: 'Add Version',
    pricing: 'Pricing',
    option_group: 'Crust',
    option: 'Size',
    version_name: 'Version Name',
    version_code: 'Version Code',
    type: 'Type',
    material_component: 'Material/Component',
    qty: 'Qty',
    cost: 'Cost',
    unit: 'Unit',
    add_item: 'Add Item',
    text_add_item: 'Add a Item now!',
    text_no_item: 'No Item added yet.',
    no_material: 'No material added yet.',
    add_material: 'Add a material now!',
    add_component: 'Add Component',
    material: 'Material',
    option_and_material: 'Size And Material',
    text_add_component: 'Add a Component now!',
    text_no_component: 'No Component added yet.',
    component: 'Component',
    item: 'Item',
    total: 'Total',
    normal_product: 'Normal Product',
    add_po: 'Add Purchase Order',
    no_po: 'No Purchase Order yet.',
    add_good_reciept: 'Add Good Reciept',
    no_good_reciept: 'No Good Reciept yet.',
    line_nummber: 'Line Number',
    date_of_good_reciept: 'Date of Good Reciept',
    good_reciept_detail: 'Good Reciept Detail',
    purchase_order_detail: 'Purchase Order Detail',
    slip_number: 'Slip Number',
    from_store: 'From Store',
    to_store: 'To Store',
    pending: 'Pending',
    confirmed: 'Confirmed',
    price: 'Price',
    amount: 'Amount',
    text_add_transfer: 'Add a Transfer now!',
    text_no_transfer: 'No Transfer added yet.',
    current_stock: 'Current Stock',
    last_inventory: 'Last Inventory',
    warehousing: 'Warehousing',
    eod_warehousing: 'EOD Warehousing',
    current_quantity: 'Current Quantity',
    actual_use: 'Actual Use',
    eod_actual_use: 'EOD Actual Use',
    unit_price: 'Unit Price',
    stock_value: 'Stock Value',
    actual_usage_amount: 'Actual Usage Amount',
    text_add_foodWaste: 'Add a Food Waste now!',
    text_no_foodWaste: 'No Food Waste added yet.',
    reason: 'Reason',
    timeOver: 'Time Over',
    operationMistake: 'Operation Mistake',
    cancellation: 'Cancellation',
    theft: 'Theft',
    is_show_loss: 'Show On Food Loss'
  },
  currency: {
    currencies: 'Currencies',
    currency: 'Currency',
    default: 'Default',
    rate: 'Rate'
  },
  placeholder: {
    import_format_type: 'Import Format Type',
    search_for_product_of_customer_product_id: 'Search for Internal Product ID or Customer Product ID',
    date: 'YYYY-MM-DD',
    zipcode: '0000',
    code: '0000',
    phone: '+0(000)000-00-00',
    hour: '00:00',
    select: 'Select',
    na: 'N/A',
    search_for_internal_product_id: 'Search for Internal Product ID'
  },
  campaign: {
    add_campaign: 'Add Campaign',
    no_campaign: 'No Campaign'
  },
  notify: {
    warning: 'Warning',
    add_notify: 'Add Notify',
    no_notify: 'No Notify',
    totalSuccess: 'Total Success',
    totalFalse: 'Total False',
    success: 'Success',
    false: 'False',
    error: 'Error'
  },
  type: {
    add_type: 'Add Type',
    no_type: 'No Type',
    totalSuccess: 'Total Success',
    totalFalse: 'Total False'
  },
  master: {
    text_add_nationality: 'Add a Nationality now!',
    text_no_nationality: 'No Nationality added yet.',
    text_add_nationalityCategory: 'Add a Nationality Category now!',
    text_no_nationalityCategory: 'No Nationality Category added yet.',
    text_add_rank: 'Add a Rank now!',
    text_no_rank: 'No Rank added yet.',
    text_add_position: 'Add a Position now!',
    text_no_position: 'No Position added yet.',
    text_add_occupation: 'Add a Occupation now!',
    text_no_occupation: 'No Occupation added yet.',
    text_add_changeStatus: 'Add a Change Status now!',
    text_no_changeStatus: 'No Change Status added yet.',
    text_add_visaStatus: 'Add a Visa Status now!',
    text_no_visaStatus: 'No Visa Status added yet.',
    text_add_bloodType: 'Add a Blood Type now!',
    text_no_bloodType: 'No Blood Type added yet.',
    text_add_certificate: 'Add a Certificate now!',
    text_no_certificate: 'No Certificate added yet.'
  },
  system: {
    basic_details: 'Basic Details',
    app_version: 'App Version',
    currencies: 'Currencies',
    currency: 'Currency',
    currency_unit: 'Currency Unit',
    default: 'Default',
    rate: 'Rate',
    configuration: 'Configuration',
    text_no_client: 'No Client added yet.',
    text_add_client: 'Add a Client now!',
    text_no_config: 'No Config added yet.',
    text_add_config: 'Add a Config now!',
    key_value: 'Key - Value',
    text_no_partner: 'No Partner added yet.',
    text_add_partner: 'Add a Partner now!',
    text_no_printer: 'No Printer added yet.',
    text_add_printer: 'Add a Printer now!',
    text_no_uiConfiguration: 'No UI Configuration added yet.',
    text_add_uiConfiguration: 'Add a UI Configuration now!',
    text_no_currency: 'No Currency added yet.',
    text_add_currency: 'Add a Currency now!',
    text_no_shift: 'No Shift added yet.',
    send_notify: 'Send notify',
    sending_time: 'Sending time',
    features: 'Features',
    features_cms: 'Features CMS',
    features_order: 'Features Order',
    cms_menu_detail: 'CMS Detail',
    auto_create_menu_version: 'Auto Create Menu Version',
    cms_update_waiting_time_fcds: 'CMS Update FCDS',
    cms_enable_input_code: 'CMS Enable Input Code',
    dynamodb_qrcode: 'Dynamodb Qrcode',
    config: 'Config',
    partner: 'Partner',
    cms_sync: 'CMS Sync',
    analytic_sync: 'Analytic Sync',
    sync_local_pos: 'Sync Local POS',
    send_order_confirm_email: 'Send Order Confirm Email',
    send_online_order_to_mobile: 'Send Online Order To Mobile',
    send_ges_email: 'Send GES Email',
    push_ges_email: 'Push GES Email',
    text_no_data: 'No data',
    number_of_decimal: 'Number Of System',
    text_no_setting: 'No Setting added yet.',
    text_add_setting: 'Add a Setting now!'
  },
  money_bag_log: {
    driver: 'Driver',
    money_bag: 'Money Bag',
    created_at: 'Created Date',
    active: 'Active',
    disable: 'Disable'
  },
  eod: {
    eod: 'End of Date',
    working_times: 'Working times',
    step: 'Step',
    check_eod: 'Check EOD',
    Warning: 'Warning',
    Confirmed: 'Confirmed',
    input_quantity: 'Input Quantity',
    quantity_store: 'Quantity Store',
    last_quantity_store: 'Last Quantity',
    sales_info: 'Sales Information',
    actual: 'Actual balalance',
    excess_deficiency: 'Excess And Deficiency',
    foh_closing_amount: 'FOH Closing Amount',
    amount_in_safe: 'Amount In Safe',
    accounts_receivable: 'Accounts Receivable',
    sales_info_money: 'Sales Info Money',
    number_sheets: 'Number Sheets',
    amount_of_money: 'Amount Of Money',
    shareholder_complimentary_coupon: 'Shareholder Complimentary Coupon',
    cash_voucher_change: 'Cash Voucher Change',
    cash_voucher_without_change: 'Cash Voucher Without Change',
    point: 'Point',
    commission: ' Commission',
    order_desposit: 'Order Desposit',
    gross_sales_amount: 'Gross Sales Amount',
    total_sales_promotion: 'Total Sales Promotion',
    employee_discount: 'Employee Discount',
    net_sales_amount: 'Net Sales Amount',
    accounts_generate_receivable: 'Accounts Generate Receivable',
    cash_voucher_total: 'Cash Voucher Total',
    other_deposit_total: 'Other Deposit Total',
    refund: 'Refund',
    gourment_card: 'Gourment Card',
    headquarters_remittance: 'Headquarters Remittance',
    scheduled_bank_deposit: 'Scheduled Bank Deposit',
    bank_deposit_amount: 'Bank Deposit Amount',
    excess_bank_deposit: 'Excess Bank Deposit',
    petty_information: 'Petty Information',
    cumulative_cash_shortage: 'Cumulative Cash Shortage',
    bank_book: 'Bank Book',
    store_cash: 'Store Cash',
    small_cash: 'Small Cash',
    safe_drawer_change: 'Safe Drawer Change',
    pouch_change: 'Pouch Change',
    total: 'Total',
    cumulative_amount: 'Cumulative Amount',
    reason_for_excess_or_deficiency: 'Reason For Excess Or Deficiency',
    change: 'Change',
    sales: 'Sales',
    pretty_cash: 'Pretty Cash',
    next_day_used_pouch: 'Next Day Used Pouch',
    bank_account_excess: 'Bank Account Excess',
    no: 'No',
    use: 'Use',
    drawer_change: 'Drawer Change',
    next_day_drawer_pouch_change: 'Next Day Drawer Pouch Change',
    next_day_bank_change_balance: 'Next Day Bank Change Balance',
    balance_change_safe: 'Balance Change Safe',
    balance_pouch_change_safe: 'Balance Pouch Change Safe',
    store_code: 'Store Code',
    employee_code: 'Employee Code',
    name: 'Name',
    confirm_item: 'Confirm Item',
    full_part_time: 'Full Part Time',
    rank: 'Rank',
    under_18: 'Under 18',
    number_of_attendance: 'Number of Attendance',
    check_in: 'Check In',
    checkin_hour: 'Checkin Hour',
    checkout_hour: 'Checkout Hour',
    start_work: 'Start Work',
    end_work: 'End Work',
    working_content: 'Working Content',
    working_time: 'Working Time',
    break_time: 'Break Time',
    noted: 'Noted',
    confirmed: 'Confirmed',
    time_update: 'Time Update',
    is_off: 'Is off',
    work_division: 'Work Division',
    addMoreWorkingTime: 'Add More Working Time',
    addMoreCheckin: 'Add More Checkin',
    role_time: 'Role Time',
    is_sent_to_s3: 'Sent to s3',
    start_time: 'Start Time',
    end_time: 'End Time',
    resend: 'Re-send',
    need_to_verify: 'Some items need to verify',
    note_need_to_verify: '* Below, items whose status is not ✔ need to be retransmitted because data linkage failed.',
    finish_good_reciept: 'Confirm all good reciept.',
    finish_close_shift: 'Close all shift.',
    finish_order: 'Finish all orders first.',
    finish_money_bag: 'Release all money bags first.',
    finish_driver: 'Release all drivers first.',
    finish_money_closure: 'Confirm money closure.',
    finish_labour_closure: 'Confirm labour closure.',
    finish_inventory_closure: 'Confirm inventory closure.',
    completed_end_of_date: 'Completed EOD',
    is_eod: 'Store EOD success',
    have_data: 'Have Data EOD',
    more_info: 'More infomation',
    wheather: 'Wheather',
    finish_order_payment: 'Finish Order Payment',
    data_order_wrong: 'Data of order still wrong',
    total_order_payment: 'Total Order Payment',
    order_payment: 'Order Payment',
    time_eod: 'Time EOD',
    waiting_for_data: 'Waiting For Data',
    flash_report: 'Flash Report',
    daily_summary_report: 'Daily Summary Report',
    loadding_report: 'Please wait around 2 minutes to download report'
  },
  payment_method_level1: {
    payment_method_level1_detail: 'Payment Method Level 1 detail',
    no_payment_method_level1: 'No Payment Method Level 1',
    add_payment_method_level1: 'Add Payment Method Level 1'
  },
  summary_tracker: {
    no: 'No',
    outlet_name: 'Outlet Name',
    outlet_code: 'Outlet Code',
    total_online_order_delivery: 'Total Order',
    total_order_swiped: 'Total Order Swiped',
    driver_tracker_usage_percent: 'Usage (%)',
    sos_gt_30: 'SOS > 30',
    sos_gt_30_percent: 'SOS > 30 (%)',
    sos_lt_30: 'SOS < 30',
    sos_lt_30_percent: 'SOS < 30 (%)',
    all_store: 'All Store'
  },
  store_target_v2: {
    store_code: 'CODE',
    store_name: 'STORE NAME',
    store_group: 'STORE_GROUP',
    daily_target: 'DAILY TARGET',
    monthly_target: 'MONTHLY TARGET',
    filter_area_title: 'FILTER BY',
    store_group_filter_label: 'Store Group',
    store_name_filter_label: 'Store Name',
    business_date_filter_label: 'Bussines Date',
    checkbox_daiily_target_label: 'Daily Target',
    export_button_label: 'Export',
    upload_button_label: 'Upload CSV',
    dialog_expot_title_label: 'Export',
    export_option_today: 'Today',
    export_option_last7days: 'Last 7 day',
    export_option_this_month: 'This Month',
    export_option_this_year: 'This Year',
    export_option_custom_date: 'Custom Date',
    btn_cancel_export_popup: 'Cancel',
    btn_ok_export_popup: 'Ok',
    btn_cancel_upload: 'Cancel',
    business_date: 'BUSINESS DATE'
  },
  sale_history: {
    filter_title: 'FILTER BY',
    store_group_lb: 'Store Group',
    store_type_lb: 'Store Type',
    store_code_lb: 'Store Code',
    input_store_code_placeholder: 'Enter store code',
    date_select_placeholder: 'Pick a day',
    total_sale_by_chanel_title: 'TOTAL SALES BY CHANNELS',
    total_bill_by_channel_title: 'TOTAL BILL BY CHANNELS',
    sos_title: 'SOS',
    fresh_and_hot_title: 'FRESH & HOT',
    sos_delivery: 'DELIVERY',
    sos_take_away: 'TAKE AWAY',
    hot_fresh_delivery: 'DELIVERY',
    hot_fresh_take_away: 'TAKE AWAY',
    date_lb: 'Date',
    btn_apply: 'Apply'
  },
  product_mix_v2: {
    filter_area_title: 'FILTER BY',
    store_group_filter_label: 'Store Group',
    store_type_filter_label: 'Store Type',
    product_code_table_header: 'PRODUCT CODE',
    store_group_table_header: 'STORE GROUP',
    store_type_table_header: 'STORE TYPE',
    product_name_table_header: 'PRODUCT NAME',
    quantity_table_header: 'QUANTITY',
    button_add: 'ADD NEW',
    export_button_label: 'EXPORT',
    dialog_expot_title_label: 'Export Option',
    export_option_today: 'Today',
    export_option_last7days: 'Last 7 days',
    export_option_this_month: 'This Month',
    export_option_this_year: 'This year',
    export_option_custom_date: 'Custom date',
    dialog_button_cancel: 'Cancel',
    dialog_button_ok: 'OK',
    message_error: 'Something when wrong!'
  },
  manage_order: {
    filter_area_title: 'FILTER BY',
    store_group_filter_label: 'Store Group',
    store_type_filter_label: 'Store Type',
    product_code_table_header: 'PRODUCT CODE',
    store_group_table_header: 'STORE GROUP',
    store_type_table_header: 'STORE TYPE',
    product_name_table_header: 'PRODUCT NAME',
    quantity_table_header: 'QUANTITY',
    button_add: 'ADD NEW',
    export_button_label: 'EXPORT',
    dialog_expot_title_label: 'Export Option',
    export_option_today: 'Today',
    export_option_last7days: 'Last 7 days',
    export_option_this_month: 'This Month',
    export_option_this_year: 'This year',
    export_option_custom_date: 'Custom date',
    dialog_button_cancel: 'Cancel',
    dialog_button_ok: 'OK',
    message_error: 'Something when wrong!'
  },
  today_sales: {
    filter_title: 'FILTER',
    store_group_lb: 'Store Group',
    store_name_lb: 'Store Name',
    placeholder_store_name: 'Input store name.',
    sales_title: 'SALES',
    placeholder_date: 'Pick a day',
    lb_total_sales: 'Total Sales',
    lb_total_bill: 'Total Bill',
    lb_avg_bill: 'Average Bill',
    lb_sos: 'SOS',
    lb_fresh_hot: 'Fresh & Hot',
    target_stat_title: 'TARTGET STATS',
    daily_lb: 'Daily Target',
    monthly_lb: 'Monthly Target',
    sale_by_target_title: 'SALE BY TARGET',
    sale_by_type_title: 'SALE BY TYPE',
    bill_by_channel_title: 'BILL BY CHANNEL',
    bill_by_type: 'BILL BY TYPE',
    delivery_key: 'Delivery',
    take_away_key: 'Take Away',
    dine_in_key: 'Dine In',
    store_code_lb: 'Store Code',
    placeholder_store_code: 'Input Store Code',
    popover_current_daily_lb: 'Current',
    popover_target_daily_lb: 'Target',
    popover_current_monthly_lb: 'Current',
    popover_target_monthly_lb: 'Target',
    btn_apply: 'Apply'
  },
  order_device: {
    add_order_device: 'Add Order Device',
    order_device: 'Order Device',
    no_order_device: 'No Order Device'
  },
  clear_data: {
    driver_location: 'Driver Location',
    driver_log: 'Driver Log',
    driver_start_log: 'Driver Start Log',
    driver_swipe_done_log: 'Driver',
    money_bag_log: 'Money Bag Log',
    money_bag_tracking: 'Money Bag Tracking',
    shift_log_open: 'Shift Log Open',
    user: 'User',
    user_checkin: 'User checkin',
    driver: 'Driver',
    money_bag: 'Money Bag',
    user_driver: 'User driver',
    order_deliver: 'Order deliver',
    order: 'Order',
    order_detail: 'Order detail',
    order_promotion: 'Order promotion',
    order_pament: 'Order pament',
    order_status_log: 'Order status log',
    order_detail_log_time: 'Order detail log time',
    order_detail_log_drop: 'Order detail log drop',
    shift: 'Shift',
    shift_transaction: 'Shift transaction',
    user_break_time: 'User break time',
    user_token: 'User Token',
    working_time_closure: 'Working Time Closure',
    user_checkin_closure: 'User Checkin Closure',
    material_stock_report: 'Material Stock Report',
    material_potential_utilization: 'Material Potential Utilization',
    material_current_stock: 'Material Current Stock',
    material_stock_log: 'material Stock Log',
    store_cash: 'Store cash',
    end_of_date: 'End of date',
    eod: 'EOD',
    next_day: 'Next day',
    timesheet: 'Timesheet',
    supplier_product: 'Supplier Product',
    store_daily: 'Store daily',
    menu_mix: 'Menu mix',
    labour_cost_state: 'Labour cost state',
    inventory: 'Inventory',
    in_out_inventory: 'In out inventory',
    employee: 'Employee',
    petty_cash_statement: 'Petty cash statement',
    money_closure: 'Money closure',
    system: 'System',
    catalogue_log_drawer: 'Catalogue Log Drawer',
    material_quantity_log: 'Material Quantity Log',
    kpi: 'KPI'
  },
  message_store: {
    title: 'Message Store',
    title_message: 'Title Message',
    content_message: 'Content Message',
    text_no_store_message: 'No store message',
    text_add_store_message: 'Add store message',
    start_time: 'Start Time',
    end_time: 'End Time',
    store_message: 'Store Message'
  },
  ads_video: {
    upload_horizontal_file: 'Upload Horizontal File',
    upload_vertical_file: 'Upload Vertical File',
    display_type: 'Display Type',
    no_ads_video: 'No Ads Video',
    add_ads_video: 'Add Ads Video'
  },
  app_version: {
    no_app_version: 'No App Version',
    add_app_version: 'Add App Version',
    hotfix: 'Hot Fix'
  },
  app_version_manager: {
    no_app_version_manager: 'No App Version Manager',
    add_app_version_manager: 'Add App Version Manager'
  },
  store_health: {
    ft_store_code_lb: 'Store Code',
    ft_date_lb: 'Date',
    ph_select_store: 'Select store code',
    ph_date: 'Select Date',
    btn_current_status: 'Current Status',
    btn_log: 'Log',
    card_filter: 'Filter',
    card_device_status: 'Device Status',
    card_log: 'Log',
    last_update_lb: 'Last Updated',
    col_business_date: 'Business Date',
    col_store: 'Store Code/Name',
    col_device_type: 'Device Type',
    col_status: 'Status',
    col_status_date_time: 'Status Date Time'
  },
  pool: {
    pool: 'Pool',
    code: 'Code',
    name: 'Name',
    description: 'Description',
    lat: 'Lat',
    long: 'Long',
    pool_detail: 'Pool Detail',
    add_pool: 'Add Pool',
    assign_driver: 'Assign Driver',
    reassign_driver: 'ReAssign Driver',
    list_driver: 'List Driver',
    confirm_assign_driver: 'Do you want to assign driver ?',
    confirm_reassign_driver: 'Do you want to reassign driver ?',
    list_order_assign_together: 'List Order Assign Together'
  },
  store_dashboard: {
    item: 'Item',
    order: 'Order',
    total_food_cost: 'Total Food Cost',
    order_cost: 'Order Cost',
    loss_cost: 'Loss Cost',
    labour_cost: 'Labour Cost',
    other_cost: 'Other Cost',
    profit_and_lost: 'Profit And Lost',
    store_dashboard: 'Store Dashboard',
    sale_by_channel: 'Sale By Channel',
    sale_by_device: 'Sale By Device',
    sale_by_type: 'Sale By Type',
    store: 'Store',
    store_group: 'Store Group',
    lb_total_sales: 'Total Sales',
    lb_total_bill: 'Total Bills',
    lb_avg_bill: 'Avg',
    lb_sos: 'SOS',
    lb_fresh_hot: 'F&H',
    online: 'Online',
    offline: 'Offline',
    call_center: 'Call center',
    grab: 'Grab',
    gojek: 'Gojek',
    delivery: 'Delivery',
    take_away: 'Take Away',
    dine_in: 'Dine In',
    total_delivery: 'Total Delivery',
    total_take_away: 'Total Take Away',
    total_dine_in: 'Total Dine In',
    pos: 'Pos',
    order_overview: 'Order Overview',
    hour: ':00',
    total_bill_overview: 'Total Bill Overview',
    total_sale_overview: 'Total Sale Overview',
    avg_overview: 'Avg Sale Overview',
    sos_overview: 'SOS Overview',
    fnh_overview: 'F&H Overview',
    fresh_and_hot: 'Fresh and Hot',
    not_fresh_and_hot: 'Not Fresh anh Hot',
    sos: 'Sos',
    not_sos: 'Not Sos',
    btn_apply: 'Apply',
    total_delivery_bill: 'Total Delivery Bills'
  },
  common_validate: {
    number: '{attr} must be a number',
    length_digit: '{attr} must have exactly {value} digits',
    max_length_digit: '{attr} has a maximum of {value} digits',
    required: '{attr} is required',
    missing: 'Missing {attr}',
    invalid: 'Invalid {attr}'
  },
  food_cost: {
    date: 'date',
    food_cost: 'Food Cost',
    rate_with_revenue: '% of revenue',
    food_cost_report: 'Food Cost Report',
    revenue: 'Revenue'
  },
  labour_cost: {
    date: 'Date',
    labour_cost: 'Labour Cost',
    order_sale: 'Total Sale',
    percent: 'Percent',
    no: 'No',
    working_date: 'Working Date',
    day_of_the_week: 'Day of the week',
    attendance: 'Attendance',
    working_time: 'Working Time',
    over_time: 'Over Time',
    mid_night: 'Mid Night',
    allowance: 'allowance',
    labour_cost_total: 'labour cost total',
    basic_salary: 'basic salary',
    overtime_pay: 'overtime pay',
    pos: 'pos',
    driving: 'driving',
    festival: 'festival',
    shift_m: 'shift manager',
    orther: 'orther',
    commuting: 'commuting',
    paid: 'paid'
  },
  fleet: {
    active_from: 'Active from',
    active_to: 'Active to',
    zone_detail: 'Zone details',
    total_order: 'Total Order',
    exception_total_order: 'Exception Total Order',
    total_driver: 'Maximum Total Diver',
    exception_total_driver: 'Maximum Exception Total Driver',
    upload_csv: 'Upload CSV',
    date_exception: 'Date Exception',
    value: 'Value',
    zone_name: 'Full Name',
    country_name: 'Country',
    zone_map: 'Zone',
    type: 'Delivery Zone By',
    zone_code: 'Zone code',
    radius_distance: 'Radius distance (m)',
    radius: 'Radius',
    postal_code: 'Postal code',
    code: 'Code',
    no_zone: 'No zone added yet.',
    add_zone: 'Add a zone now!',
    btn_add_zone: 'Add Zone',
    day_of_week: {
      mon: 'Mon',
      tue: 'Tue',
      wed: 'Wed',
      thu: 'Thu',
      fri: 'Fri',
      sat: 'Sat',
      sun: 'Sun'
    },
    validate: {
      time_from_required: 'Time from is required',
      time_to_required: 'Time to is required',
      driver_config_required: 'Driver number is required',
      client_uuid_required: 'Client is required',
      exception_date_required: 'Date is required',
      zone_uuid_required: 'Zone is required'
    },
    view_exception: 'View Exception',
    date_from: 'Date from',
    date_to: 'Date to',
    time_from: 'Time from',
    time_to: 'Time to',
    number_of_driver: 'Number of driver',
    add_time: 'Add time',
    remove_time: 'Remove time',
    origin_point: 'Origin Point',
    destination_point: 'Destination Point',
    origin: 'Origin',
    destination: 'Destination',
    no_matrix: 'No matrix added yet.',
    add_matrix: 'Add a matrix now!',
    matrix_detail: 'Matrix details',
    download_example_template: 'Download Example Template',
    destination_zones: 'Destination Zones',
    max_distance: 'Max distance',
    distance: 'Distance',
    max_weight: 'Max weight',
    earliest_time_slot: 'Earliest delivery time slot',
    is_time_slot_delivery: 'Time slot delivery',
    delivery_fee: 'Delivery fee',
    delivery_sla: 'Delivery SLA',
    min_distance: 'Min distance'
  },
  sale_digital_channel: {
    total_bill: 'Total Bill',
    total_sales: 'Total Sales (IDR)',
    average_bill: 'Average Bill (IDR)'
  },
  food_item: {
    no_food_item: 'Empty Food Item',
    add_food_item: 'Add Food Item',
    create_food_item: 'Create Food Item',
    currency: 'Currency',
    assinged_menu: 'Assinged Menu',
    menu_food_item: 'Food Info',
    aa_food_price: 'Food Info',
    name: 'Food Name',
    image: 'Food Image',
    action: 'Action',
    food_item: 'Food Item'
  },
  food_store_category: {
    assigned_store: 'Assigned Stores',
    number_item: 'No. of Items',
    menu_category: 'Menu Categories',
    food_image: 'Food Image',
    food_name: 'Food Name',
    qty_available: 'Qty available',
    menu_category_detail: 'Menu Category Details',
    food_listing: 'Food Listing',
    items: 'Items',
    update_product: 'Do you want to update product?'
  },
  subscription_plan: {
    basic_details: 'Basic details'
  },
  store_subscription_plan: {
    order_left: 'Order left',
    renewed_on: 'Renewed on',
    expires_on: 'Expires on',
    view: 'View',
    renew: 'Renew',
    get_this_plan: 'Get this plan',
    new: 'New',
    expires: 'Expires',
    plans: 'Plans'
  },
  account: {
    account_details: 'Account detail',
    company_name: 'Company name',
    business_name: 'Business name',
    email: 'Email',
    phone_number: 'Phone number',
    social_media: 'Social media',
    facebook_url: 'Facebook url',
    instagram_url: 'Instagram url',
    bank_details: 'Bank details',
    bank_account_name: 'Bank account name',
    bank_account_number: 'Bank account number',
    bank: 'Bank',
    assigned_stores: 'Assigned stores',
    date_created: 'Date created',
    current_password: 'Current password',
    password: 'Password',
    change_password: 'Change password',
    link_facebook: 'Link facebook',
    link_instagram: 'Link instagram',
    new_password: 'New password',
    confirm_password: 'Confirm password'
  },
  promotionV3: {
    promo_code: 'Promo code',
    promo_type: 'Promo type',
    stack_promo_code: 'Stack promo code',
    show_from_ui: 'Show promo code on website',
    promo_details: 'Promo details',
    promo_assignment: 'Promo assignment',
    assign_to: 'Assign to',
    assigned_items: 'Assigned items',
    redemption_limit: 'Redemption limit',
    minimum_purchase: 'Minimum purchase',
    percentage: 'Percentage',
    max_discount_value: 'Max discount value',
    assigned_stores: 'Assigned stores',
    assigned_category: 'Assigned category',
    quick_view: 'Quick view',
    redeemed_thus_far: 'Redeemed thus far',
    chargeback_cost: 'Chargeback cost',
    is_per_store: 'Is per store',
    is_auto_redeem: 'Is auto redeem'
  },
  orders: {
    all: 'All orders',
    pre_order: 'Pre-order',
    on_demand: 'On-demand',
    self_pickup: 'Self-pickup',
    restaurant: 'Restaurant filter',
    zone: 'Zone',
    destination_zone: 'Destination Zone',
    calendar: 'Calendar view',
    code: 'Order ID',
    delivery_time: 'Delivery time',
    receipient_detail: 'Receipient details',
    instruction: 'Special instructions',
    order_channel: 'Order channel',
    quick_view: 'Quick view',
    items: 'Items',
    status: 'Status',
    action: 'Action',
    find: 'Finding available riders',
    preparing: 'Preparing',
    pending: 'Pending',
    stand_by: 'Stand-by',
    pick_up: 'Pick-up',
    delivering: 'Delivering',
    delivered: 'Delivered',
    canceled: 'Canceled',
    order_status: 'Order status',
    payment_status: 'Payment status',
    delivery_status: 'Delivery status',
    order_type: 'Order type',
    date_range: 'Date range',
    order_detail: 'Order details',
    food_items: 'Food items',
    quantity: 'Quantity',
    item: 'Item',
    price: 'Price',
    instructions: 'Instructions',
    mobile: 'Mobile number',
    customer_name: 'Customer name',
    receipient_name: 'Receipient name',
    email: 'Email',
    delivery_fee: 'Delivery fee',
    discount: 'Discount',
    discount_code: 'Discount code',
    discount_type: 'Discount type',
    address: 'Address',
    order_time: 'Order time',
    rider_detail: 'Rider Details',
    rider_name: 'Rider name',
    rider_status: 'Pick up',
    estimated_time: 'Estimated delivery time',
    commission_details: 'Commission Details',
    commission_amount: 'Commission Amount',
    commission_rate: 'Commission Percentage',
    recipient_details: 'Recipient details',
    order_date_range: 'Order Date Range',
    delivery_date_range: 'Delivery Date Range',
    sure: 'Are you sure order',
    is_ready: 'is ready',
    is_cancel: 'Do you want to cancel order'
  },
  leafleting: {
    text_no_item: 'No Item added yet.',
    add_item: 'Add Item',
    text_add_item: 'Add a Item now!',
    basic_details: 'Basic details',
    date_posting: 'Date posting',
    distributor: 'Distributor',
    posting_date: 'Posting date',
    target: 'Target',
    result: 'Result',
    method: 'Method',
    type: 'Type',
    distribution_date: 'Distribution Date',
    address1: 'Address 1',
    address2: 'Address 2',
    edit_leafleting: 'Edit leafleting'
  },
  store_notification: {
    start_date: 'Start Date',
    end_date: 'End Date',
    message: 'Notification Message'
  },
  special_request: {
    no_special_request: 'No special request',
    add_special_request: 'Add special request'
  },
  payroll: {
    part_code: 'Part Code',
    part_name: 'Part Name',
    error_code: 'Error Code',
    error_message: 'Error Message',
    working_date: 'Working Date',
    text_no_message: 'No Error Message added yet.'
  },
  ace: {
    on_time: 'On Time',
    promise_time: 'Promise Time',
    storein_time: 'Store in Time',
    less_equal: '<=',
    more: '>',
    percent: '%',
    minutes: 'Minutes',
    total: 'Total',
    output_period: 'Output period',
    business_day: 'Business day',
    cancel_refund: 'Cancel Refund',
    cancel: 'Cancel',
    refund: 'Refund'
  },
  ace_sale: {
    sales_number_related_figures_over_all: '■ Sales / number related figures (overall)',
    sales_number_related_figures_category: '■ Sales / number related figures (excluding tax, by sales category)',
    sales_number_related_figures_web_category: '■ WEB-related sales / number-related figures (excluding tax, by sales category)',
    target_sale: 'Target sales (PJ)',
    gross_sales_amount_tax: 'Gross sales amount (tax included)',
    gross_sales_amount: 'Gross sales amount (excluding tax)',
    net_sales_amount_tax: 'Net sales amount (tax included)',
    net_sales_amount: 'Net sales amount (excluding tax)',
    consumption_tax_amount: 'Consumption tax amount',
    order_target_actual_number: 'Order target / actual number',
    order_unit_price_tax: 'Order unit price (tax included)',
    order_unit_price: 'Order unit price (excluding tax)',
    number_refund_amount: 'Number of refunds / amount',
    employee_discount_amount: 'Employee discount amount',
    promotional_discount_amount: 'Promotional (coupon) discount amount',
    Amount_indicated_discount: 'Amount indicated discount number / amount',
    cb_coupon_discount: 'CB coupon discount number / amount',
    phc_points_used: 'Number of PHC points used / amount',
    ponta_usage: 'Ponta usage count / amount',
    store_account_receivable: 'Number of store accounts receivable / amount',
    store_account_receivable_amount: 'Store accounts receivable amount',
    uncollected_account_receivable: 'Number of uncollected accounts receivable / balance',
    shareholder_benefit_amount: 'Shareholder benefit amount',
    gourmet_card_amount: 'Gourmet card amount',
    voucher_amount: 'Voucher amount',
    fee: 'Fee',
    other_deposit_amount_1: 'Other deposit amount (1)',
    other_deposit_amount_2: 'Other deposit amount (2)',
    other_deposit_amount_3: 'Other deposit amount (3)',
    bank_deposit_amount: 'Bank deposit amount (Sales Headquarters remittance)',
    bank_deposit_excess: 'Bank deposit excess / deficiency amount',
    number_of_cancellation_amount: 'Number of cancellations / amount',
    number_of: 'Number of',
    sales_amount: 'sales amount',
    unit_price: 'unit price',
    minutes: 'minutes',
    seconds: 'seconds',
    average_order_tech_time: 'Average order tech time (minutes and seconds)',
    average_make_up_time: 'Average make up time (minutes and seconds)',
    average_rack_time: 'Average rack time (minutes and seconds)',
    average_delivery_time: 'Average delivery time (minutes and seconds)'
  },
  rp_dashboard: {
    pj: 'PJ',
    sales: 'Sales Details',
    analysi: 'Analysis Table',
    menu: 'Menu mix',
    inventory: 'Inventory Order',
    store: 'Store Management',
    smg: 'SMG'
  },
  ace_sale_daily: {
    daily_account_table: 'Daily Account Table',
    working_date: 'Working Date',
    weather: 'Weather',
    first_order: 'First Order',
    last_order: 'Last Order',
    start_time_of_foh: 'Start Time Of FOH',
    end_time_of_foh: 'End Time Of FOH',
    end_time_of_boh: 'End Time Of BOH',
    earnings: 'Earnings',
    tax_included: 'Tax included',
    consumption_tax: 'Consumption tax',
    tax_excluded: 'Tax excluded',
    gross_sales_amount: 'Gross sales amount',
    sales_promotion_total: 'Sales promotion total ① ＋ ②',
    net_sales_amount: 'Net sales amount',
    standard_tax_rate: 'Inside) Standard tax rate',
    reduced_tax_rate: 'Inside) Reduced tax rate',
    discount_details: 'Discount details',
    number_amount_of_money: 'number/Amount of money',
    coupon_discount: 'Coupon discount',
    amount_indicated_discount: 'Amount indicated discount',
    sales_promotion_total_1: 'Sales promotion total ①',
    employee_discount: 'Employee discount ②',
    deposit_classification: 'Deposit classification',
    payment_breakdown: 'Payment breakdown',
    number: 'number',
    amount_of_money: 'Amount of money',
    regular_product_sales: 'Regular product sales',
    Refund: 'Refund [---]',
    total_sales: 'Total sales',
    point_deposit_sales_transfer: 'Point deposit sales transfer [-]',
    total_sales_deposit: 'Total sales deposit',
    point_deposit_transfer_deposit_plus: 'Point deposit Transfer deposit [+]',
    point_deposit_transfer_deposit_minus: 'Point deposit Transfer deposit [-]',
    other_deposit_1: 'Other deposit 1',
    other_deposit_2: 'Other deposit 2',
    other_deposit_3: 'Other deposit 3',
    total_deposit: 'Total deposit',
    fee: 'Fee',
    total_deposit_including_fees: 'Total deposit (including fees)',
    deposit_type: 'Deposit type',
    cash_tickets: 'Cash / tickets',
    breakdown_of_tickets: '(Breakdown of tickets)',
    cash: 'Cash',
    mumber_of_collected_sheets: 'Number of collected sheets',
    collection_amount: 'Collection amount',
    excess_or_deficiency: 'Excess or deficiency',
    gourmet_card: 'Gourmet card',
    gold_ticket_1: 'Gold ticket 1',
    gold_ticket_2: 'Gold ticket 2',
    gold_ticket_3: 'Gold ticket 3',
    gold_ticket_4: 'Gold ticket 4',
    gold_ticket_5: 'Gold ticket 5',
    point: 'Point',
    ticket_total: 'Ticket total',
    accounts_receivable: 'Accounts receivable',
    headquarters_accounts_receivable_amount: 'Headquarters accounts receivable amount',
    special_accounts_receivable_amount: 'Special accounts receivable amount',
    bank_deposit_excess_deficiency: 'Bank deposit / excess / deficiency',
    ticket_change_total: 'Ticket change total',
    cash_that_should_be: 'Cash that should be',
    bank_deposit_headquarters_remittance: 'Bank deposit (headquarters remittance)',
    over_under_accounts_receivable: 'Over / under accounts receivable',
    orther: 'Other',
    accounts_receivable_balance: 'Accounts receivable balance',
    next_day_reservation: 'Next day reservation',
    cancel: 'Cancel',
    deposit_information: 'Deposit information',
    last_deposit_total: 'Last deposit total',
    total_deposit_this_time: 'Total deposit this time',
    bank_book: 'Bank book',
    balance_at_the_end_of_the_previous_day: 'Balance at the end of the previous day',
    headquarters_deposit_amount: 'Headquarters deposit amount',
    interest_deposit_amount: 'Interest deposit amount',
    withdrawal_amount: 'Withdrawal amount',
    store_to_bank: 'Bank ← store',
    bank_to_store: 'Bank → store',
    sameday_balance_theoretical_balance: 'Same-day balance (theoretical balance)',
    sameday_balance_actual_balance: 'Same-day balance (actual balance)',
    passbook_excess_deficiency: 'Passbook excess / deficiency',
    store_cash: 'Store cash',
    cost_amount: 'Cost amount',
    cash_excess_deficiency: 'Cash excess or deficiency',
    next_day_change: 'Next day change',
    number_of_pouches_used: 'Number of pouches used',
    pouch_amount: 'Pouch amount',
    drawer_amount: 'Drawer amount',
    remaining_change_in_the_safe: 'Remaining change in the safe',
    safe_pouch_change_balance: 'Safe pouch change balance',
    foh_time_payment: 'FOHAt the time of payment',
    total_excess_and_deficiency_of_cash_vouchers: 'Total excess and deficiency of cash vouchers',
    sales_using_points_on_the_day: 'Sales using points on the day',
    inside_orders_made_on_the_day: '(Inside) Orders made on the day',
    inside_preorder: '(Inside) Pre-order',
    number_of_sheets: 'Number of sheets',
    day_of_the_week: 'Day Of The Week',
    no: 'No',
    passbook: 'Passbook',
    petty_cash: 'Pretty Cash',
    balance: 'Balance'
  },
  ace_labour: {
    labor_related_figures: '■ Labor-related figures',
    number_of_employees: 'Number of employees',
    total_employee_time: 'Total employee time',
    number_of_part_timers: 'Number of part-timers',
    part_time_worker_total_time: 'Part-time worker total time',
    average_time_for_part_time_workers: 'Average time for part-time workers',
    total_amount_of_part_time_workers_including_allowance: 'Total amount of part-time workers (including allowance)',
    average_hourly_wage_of_part: 'Average hourly wage of part-time workers (including allowance)',
    part_time_worker_labor_productivity: 'Part-time worker labor productivity',
    labor_productivity_including_employees: 'Labor productivity (including employees)',
    number_of_people: 'Number of people',
    total_time: 'Total time',
    average_time: 'Average time',
    total_amount: 'Total amount',
    average_hourly_wage: 'Average hourly wage',
    labor_productivity: 'Labor productivity',
    efficiency: 'Efficiency',
    employee_included_part_only: 'Employee included / part only',
    operation: 'Operation',
    manager: 'Manager',
    in_store: 'In store',
    driver: 'Driver',
    service: 'Service',
    posting: 'Posting',
    trainer: 'Trainer',
    help: 'Help'
  },
  ace_coupon_history: {
    number_of_sheets: 'Number of sheets',
    day_of_the_week: 'Day Of The Week',
    working_date: 'Working Date',
    no: 'No',
    slip_number: 'Slip Number',
    customer_number: 'Customer Number',
    order_time: 'Order Time',
    input_person: 'Input person',
    delivery_person: 'Delivery Person',
    coupon: 'Coupon',
    code: 'Code',
    name: 'Name',
    discount_amount: 'Discount Amount'
  },
  store_address_mapping: {
    address2_code: 'ADD2_CD',
    zipcode: 'ZIPCODE',
    address1_name: 'ADD1_NAME',
    address1_kana: 'ADD1_KANA',
    address1_ryaku: 'ADD1_RYAKU',
    address2_name: 'ADD2_NAME',
    address2_kana: 'ADD2_KANA',
    address2_ryaku: 'ADD2_RYAKU',
    area_ol_flg: 'AREA_OL_FLG',
    area_ol_com: 'AREA_OL_COM',
    web_null_flg: 'WEB_NULL_FLG',
    kanri_setai_cnt: 'KANRI_SETAI_CNT',
    kyaku_cnt: 'KYAKU_CNT',
    kyogo_tenpo_cnt: 'KYOGO_TENPO_CNT',
    dlvtime: 'DLVTIME',
    map_cd: 'MAP_CD',
    ind_name: 'IND_NAME',
    sdata_make_date: 'SDATA_MAKE_DATE',
    tdata_make_date: 'TDATA_MAKE_DATE',
    yuko_post_cnt: 'YUKO_POST_CNT',
    yobi01: 'YOBI01',
    yobi02: 'YOBI02',
    yobi03: 'YOBI03'
    // @Summary 登录
    // @Description 登录
    // @Produce json
    // @Param body body controllers.LoginParams true "body参数"
    // @Success 200 {string} string "ok" "返回用户信息"
    // @Failure 400 {string} string "err_code：10002 参数错误； err_code：10003 校验错误"
    // @Failure 401 {string} string "err_code：10001 登录失败"
    // @Failure 500 {string} string "err_code：20001 服务错误；err_code：20002 接口错误；err_code：20003 无数据错误；err_code：20004 数据库异常；err_code：20005 缓存异常"
    // @Router /user/person/login [post]
  },
  deviation: {
    no_deviation: 'No Deviation',
    order_number: 'Order Number',
    actutal_make_time: 'Actutal Make Time',
    expected_starting_make_time: 'Expected Starting Make Time',
    time_difference: 'Time Difference'
  },
  cash_count: {
    no: 'No',
    time_of_day: 'Time Of Day',
    type: 'Type',
    processing: 'Processing',
    before_processing: 'Full amount before processing',
    change: 'Change',
    sales: 'Sales',
    sales_include: 'Sales (including some)',
    earnings: 'Earnings',
    gold_ticket: 'Gold Ticket',
    part: 'Part',
    excess_or_deficiency: 'Excess Or Deficiency',
    expenses: 'Expenses',
    human_power_amount: 'Human Power Amount',
    after_processing: 'Amount after processing',
    action_name: 'Action Name'
  },
  merchant: {
    business_name: 'Company name',
    brand_name: 'Brand name',
    detail: 'Merchant details',
    country: 'Country',
    contact: 'Contact',
    sign_up: 'Date signed up',
    approved: 'Date approved',
    all: 'All',
    verify: 'Verifed',
    pending: 'Pending verification',
    reject: 'Reject',
    approval_status: 'Business approval status',
    business: 'Business Infomation',
    phone_display: 'Display phone number',
    contact_number: 'Contact number',
    email: 'Email Address',
    commission_rate: 'Commissions rate',
    business_number: 'Business registration number',
    status: 'Status',
    reject_reason: 'Reject reason',
    bank_info: 'Bank Information',
    bank_name: 'Bank account name',
    bank_number: 'Bank account number',
    bank: 'Bank',
    pickup: 'Business registration address',
    unit: 'Unit, Block',
    city: 'City',
    postcode: 'Postcode',
    street: 'Street address',
    state: 'State',
    business_doc: 'Business Document',
    document: 'Document',
    link: 'Link',
    restaurant: 'Restaurant Listing',
    document_type: 'Document Type',
    document_name: 'File Name',
    business_document: 'Business Document',
    view: 'View',
    code: 'Code',
    store_name: 'Store name',
    tag: 'Tags',
    plan: 'Plan',
    contact_detail: 'Contact details',
    agreement_sign_off: 'Merchant Agreement Sign Off',
    delivery_timestamp: 'Delivery Timestamp',
    email_deliveries: 'Email Deliveries'
  },
  under_18: {
    no: 'No',
    working_date: 'Working Date',
    day_of_the_week: 'Day Of The Week',
    employee_registration: 'Employee Registration',
    sign_up: 'Sign Up',
    update: 'Update',
    retirement: 'Retirement',
    boxed_person: 'Boxed Person',
    help_box: 'Help Box',
    youth: 'Youth',
    hourly_wage_change: 'Hourly Wage Change',
    '1_month_unemployed': '1 Month Unemployed',
    administrative_check: 'Administrative check',
    overtime_occurs: 'Overtime Occurs',
    juvenile_violation: 'Juvenile Violation',
    work_correction: 'Work Correction',
    cancellation_of_work: 'Cancellation Of Work',
    week_over: 'Week Over',
    continuous_work: 'Continuous Work',
    no_break: 'No Break'
  },
  history: {
    order_code: 'ID',
    id: 'DB Record ID',
    old_teleport_status_code: 'Last Status',
    new_teleport_status_code: 'Current Status',
    updated_by: 'Updated By',
    created_at: 'Date Modified'
  },
  date_label_output: {
    date_time: 'Datetime',
    availabel_datetime: 'Availabel Datetime',
    discard_datetime: 'Discard Datetime',
    time_limit: 'Time Limit'
  },
  packageInformation: {
    code: 'Code',
    package_name: 'Package name',
    package_type: 'Package type',
    width: 'Width',
    height: 'Height',
    weight: 'Weight',
    length: 'Length'
  },
  order_v2: {
    order_information: 'Order Information',
    order_code: 'Order ID',
    store_name: 'Store Name',
    customer_name: 'Customer Name',
    customer_id: 'Customer ID',
    customer_phone: 'Phone Number',
    customer_email: 'Email Address',
    employee_name: 'Ordered By',
    segment: 'Segment',
    delivery_address: 'Address',
    waiting_time: 'Waiting Time',
    collection_time: 'Collection Time',
    order_time: 'Order Time',
    order_status_code: 'Order Status',
    order_driver_name: 'Driver Name',
    order_detail: 'Product Information',
    order_log_time: 'Order Log Time',
    make_time: 'Start Make Time',
    bake_time: 'Start Bake Time',
    checkout_time: 'Checkout Time',
    start_delivery_time: 'Start Delivery',
    finish_time: 'Collection Time',
    actual: 'ACTUAL',
    expected: 'EXPECTED',
    payment_information: 'Payment Information',
    coupon_used: 'Coupon Used',
    tax_included: 'Tax Included',
    tax_standard: 'Standard Tax Rate Portion',
    tax_reduced: 'Reduced Tax Rate Portion',
    grand_total: 'Grand Total',
    payment_methods: 'Payment Methods',
    by: 'By',
    sub_total: 'Sub Total',
    discount: 'Discount',
    total: 'Total',
    tax_value: 'Tax Value',
    code: 'Code',
    name: 'Name',
    coupon_information: 'Coupon Information',
    remark: 'Remark'
  },
  campaignaa: {
    name: 'Name',
    slug: 'Slug',
    tac: 'TAC',
    campaign_detail: 'Campaign Detail',
    campaign_date: 'Campaign Date',
    tag_campaigns: 'Tag Campaigns'
  },
  customer_factory: {
    customer_factory: 'Customer Factory',
    domains: 'Domains',
    full_address: 'Full Address',
    factory_address: 'Factory Address',
    add_customer_factory: 'Add',
    no_customer_factory: 'No Customer Factory'
  },
  ace_cancel: {
    sale_date: 'Sale date',
    day_of_week: 'Day Of Week',
    sales_cancellation: 'Sales cancellation',
    total: 'Total',
    cancel: 'Cancel',
    number_of_time: 'Number of time',
    amount_of_money: 'Amount of money',
    employee: 'Employee',
    non_employee: 'Non employee',
    sales_refund: 'Sales refund'
  },
  ace_refund: {
    person_in_charge: 'Person in charge',
    refund: 'Refund',
    cd: 'CD',
    full_name: 'Fullname',
    number_of_times: 'Number of times',
    amount_of_money: 'Amount of money'
  },
  sale_payment: {
    sale_daily: 'Sale daily',
    excess_or_deficiency: 'Excess or deficiency',
    store_amount: 'Store amount',
    deposit_type: 'Deposit Type',
    amount_when_eod_before: 'Amount when eod before',
    this_day_balance: 'This day balance',
    cash_variant: 'Cash variant',
    total: 'Total',
    next_day_change_prepare_amount: 'Next day "change" prepare amount',
    money_bag_amount: 'Money bag amount',
    drawer_amount: 'Drawer amount',
    sale: 'Sale',
    date: 'Date',
    close_store_person: 'Close store person',
    net_sale: 'Net sale',
    expected_bank_deposit: 'Expected bank deposit',
    include_tax: 'Include tax',
    consumer: 'Consumer',
    exclude_tax: 'Exclude tax',
    reduce_tax: 'Reduce tax',
    standard_tax: 'Standard tax',
    coupon: 'Coupon'
  },
  menu_mix: {
    number_of_promo: 'Number Of Promo',
    number_of_sale: 'Number of sale',
    order_ratio: 'Order ratio',
    code: 'Code',
    total_number: 'Total Number',
    product_name: 'Product name',
    product_category_name: 'Product Category Name'
  },
  usage_analysis: {
    group: 'Group',
    material_code: 'Material Code',
    material_name: 'Material Name',
    unit: 'Unit',
    last_inventory: 'Last Inventory',
    current_inventory: 'Current Inventory',
    amount: 'Amount',
    quantity: 'Quantity',
    unknown_loss: 'Unknown Loss',
    loss: 'Loss',
    theoritical_use: 'Theoritical Use',
    real_use: 'Real Use',
    out: 'Out',
    in: 'In'
  },
  order_rts: {
    rts_log: 'RTS log',
    request: 'Request',
    response: 'Response',
    order_type: 'Order Type',
    time_order: 'Order Time',
    create_teleport_list: 'Ready to ship many orders',
    code: 'Çode',
    store: 'Merchant',
    item: 'Food Item',
    status: 'Status',
    action: 'Action',
    payload_response: 'Log RTS',
    rts: 'Ready to ship'
  },
  end_customer_type: {
    distributor: 'Distributor',
    agent: 'Agent'
  },
  import_format_type: {
    main: 'Main Format',
    shikoku: 'Shikoku Format',
    izawa: 'Izawa Format'
  },
  registration_type: {
    claim: 'Claim',
    returns: 'Returns',
    recorded: 'Recorded',
    not_recorded: 'Not Recorded'
  },
  price: {
    increase: 'Increase',
    decrease: 'Decrease'
  },
  normal_user_steps: {
    pending: 'Pending',
    under_review: 'Under Review',
    approved: 'Approved',
    rejected: 'Rejected'
  },
  approver_steps: {
    pending: 'Pending',
    sale_approval: 'Sale Approval',
    manufacturing_approval: 'Manufacturing Approval',
    management_approval: 'Management Approval'
  },
  approval_status: {
    sale_approval: 'Sale Approval',
    manufacturing_approval: 'Manufacturing Approval',
    management_approval: 'Management Approval',
    rejected: 'Rejected'
  },
  claim_status: {
    draft: 'Draft',
    untreated: 'Untreated',
    under_consideration: 'Under Consideration',
    treated: 'Treated',
    solved: 'Solved'
  },
  spot_status: {
    confirmed: 'Confirmed',
    pending: 'Pending'
  },
  quantity_value: {
    negative: 'Negative',
    positive: 'Positive'
  }
};