//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import logoDiamet from '@/assets/logo/diamet.png';
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      title: 'DIAMET',
      logoDiamet: logoDiamet
    };
  }
};