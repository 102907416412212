exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n", ""]);

// exports
exports.locals = {
	"orange": "#EF4324",
	"menuText": "#bfcbd9",
	"menuActiveText": "#fff",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#23293C",
	"menuHover": "#42485B",
	"subMenuBg": "#42485B",
	"subMenuHover": "#4E556A",
	"sideBarWidth": "276px"
};