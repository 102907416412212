import Cookies from 'js-cookie';
import { getLanguage } from '@/lang/index';
var state = {
  sidebar: {
    opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
    withoutAnimation: false
  },
  device: 'desktop',
  language: getLanguage(),
  size: Cookies.get('size') || 'medium',
  isEditPage: false,
  pageName: '',
  isLoadding: false,
  func: function func() {}
};
var mutations = {
  TOGGLE_SIDEBAR: function TOGGLE_SIDEBAR(state) {
    state.sidebar.opened = !state.sidebar.opened;
    state.sidebar.withoutAnimation = false;
    if (state.sidebar.opened) {
      Cookies.set('sidebarStatus', 1);
    } else {
      Cookies.set('sidebarStatus', 0);
    }
  },
  CLOSE_SIDEBAR: function CLOSE_SIDEBAR(state, withoutAnimation) {
    Cookies.set('sidebarStatus', 0);
    state.sidebar.opened = false;
    state.sidebar.withoutAnimation = withoutAnimation;
  },
  TOGGLE_DEVICE: function TOGGLE_DEVICE(state, device) {
    state.device = device;
  },
  SET_LANGUAGE: function SET_LANGUAGE(state, language) {
    state.language = language;
    Cookies.set('language', language);
  },
  SET_LIST_LANGUAGE: function SET_LIST_LANGUAGE(state, listLanguages) {
    state.listLanguages = listLanguages;
    Cookies.set('listLanguages', listLanguages);
  },
  SET_SIZE: function SET_SIZE(state, size) {
    state.size = size;
    Cookies.set('size', size);
  },
  SET_PAGE: function SET_PAGE(state, pageName) {
    state.pageName = pageName;
    Cookies.set('pageName', pageName);
  },
  SET_FUNCTION_CLIENT_CHANGE: function SET_FUNCTION_CLIENT_CHANGE(state, func) {
    state.func = func;
  },
  IS_EDIT_PAGE: function IS_EDIT_PAGE(state, isEditPage) {
    state.isEditPage = isEditPage;
  },
  IS_LOADDING: function IS_LOADDING(state, isLoadding) {
    state.isLoadding = isLoadding;
  },
  SET_FILTER: function SET_FILTER(state, filter) {
    state.filter = filter;
    Cookies.set('filter', filter);
  }
};
var actions = {
  toggleSideBar: function toggleSideBar(_ref) {
    var commit = _ref.commit;
    commit('TOGGLE_SIDEBAR');
  },
  closeSideBar: function closeSideBar(_ref2, _ref3) {
    var commit = _ref2.commit;
    var withoutAnimation = _ref3.withoutAnimation;
    commit('CLOSE_SIDEBAR', withoutAnimation);
  },
  toggleDevice: function toggleDevice(_ref4, device) {
    var commit = _ref4.commit;
    commit('TOGGLE_DEVICE', device);
  },
  setLanguage: function setLanguage(_ref5, language) {
    var commit = _ref5.commit;
    commit('SET_LANGUAGE', language);
  },
  setListLanguage: function setListLanguage(_ref6, listLanguages) {
    var commit = _ref6.commit;
    commit('SET_LIST_LANGUAGE', listLanguages);
  },
  setSize: function setSize(_ref7, size) {
    var commit = _ref7.commit;
    commit('SET_SIZE', size);
  },
  setPage: function setPage(_ref8, pageName) {
    var commit = _ref8.commit;
    commit('SET_PAGE', pageName);
  },
  setFunctionClientChange: function setFunctionClientChange(_ref9, func) {
    var commit = _ref9.commit;
    commit('SET_FUNCTION_CLIENT_CHANGE', func);
  },
  isEditPage: function isEditPage(_ref10, _isEditPage) {
    var commit = _ref10.commit;
    commit('IS_EDIT_PAGE', _isEditPage);
  },
  isLoadding: function isLoadding(_ref11, _isLoadding) {
    var commit = _ref11.commit;
    commit('IS_LOADDING', _isLoadding);
  },
  setFilter: function setFilter(_ref12, filter) {
    var commit = _ref12.commit;
    commit('SET_FILTER', filter);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};