export default {
  route: {
    salad: 'Salad',
    tasting_label_output: 'Tasting Label Output',
    date_label_output: 'Date Label Output',
    electronic_journal: 'Electronic Journal',
    report_dashboard: 'Report Dashboard',
    earn_redeem_point: 'Earn Redeem Point',
    redemption_rate_voucher: 'Redemption Rate Voucher',
    report_order_promotion: 'Report Order Promotion',
    food_loss: 'Food Loss',
    dashboard: 'Dashboard',
    documentation: 'Documentation',
    guide: 'Guide',
    role: 'Role',
    permission: 'Permission',
    pagePermission: 'Page Permission',
    rolePermission: 'Role Permission',
    directivePermission: 'Directive Permission',
    icons: 'Icons',
    components: 'Components',
    tinymce: 'Tinymce',
    markdown: 'Markdown',
    jsonEditor: 'JSON Editor',
    dndList: 'Dnd List',
    splitPane: 'SplitPane',
    avatarUpload: 'Avatar Upload',
    dropzone: 'Dropzone',
    sticky: 'Sticky',
    countTo: 'Count To',
    componentMixin: 'Mixin',
    backToTop: 'Back To Top',
    dragDialog: 'Drag Dialog',
    dragSelect: 'Drag Select',
    dragKanban: 'Drag Kanban',
    charts: 'Charts',
    keyboardChart: 'Keyboard Chart',
    lineChart: 'Line Chart',
    mixChart: 'Mix Chart',
    example: 'Example',
    nested: 'Nested Routes',
    Table: 'Table',
    dynamicTable: 'Dynamic Table',
    dragTable: 'Drag Table',
    inlineEditTable: 'Inline Edit',
    complexTable: 'Complex Table',
    tab: 'Tab',
    form: 'Form',
    errorPages: 'Error Pages',
    page401: '401',
    page404: '404',
    errorLog: 'Error Log',
    excel: 'Excel',
    exportExcel: 'Export Excel',
    selectExcel: 'Export Selected',
    mergeHeader: 'Merge Header',
    uploadExcel: 'Upload Excel',
    zip: 'Zip',
    pdf: 'PDF',
    exportZip: 'Export Zip',
    theme: 'Theme',
    clipboardDemo: 'Clipboard',
    i18n: 'I18n',
    externalLink: 'External Link',
    inventory: 'Inventory',
    master_material: 'Material',
    master_uom: 'Master Uom',
    master_conversion_uom: 'Master Conversion Uom',
    master_sku: 'Master Sku',
    master_sku_bom: 'Master Sku Bom',
    master_component: 'Master Component',
    master_component_bom: 'Master Component Bom',
    option_group: 'Crust',
    option: 'Size',
    user: 'User',
    create_role: 'Create Role',
    edit_role: 'Edit Role',
    create_permission: 'Create Permisstion',
    edit_permission: 'Edit Permisstion',
    create_user: 'Create User',
    edit_user: 'Edit User',
    driver: 'Driver',
    customer: 'Customer',
    customer_list: 'Customer',
    blocked_list: 'Blocked List',
    edit_customer: 'Edit Customer',
    customer_address: 'Customer Address',
    master_meterial_category: 'Master Material Category',
    create_master_meterial_category: 'Create Master Material Category',
    edit_master_meterial_category: 'Edit Master Material Category',
    create_master_material: 'Create Material',
    edit_master_material: 'Edit Material',
    create_master_uom: 'Create UOM',
    edit_master_uom: 'Edit UOM',
    create_master_conversion_uom: 'Create UOM Conversion',
    edit_master_conversion_uom: 'Edit UOM Conversion',
    master_brand: 'Master Brand',
    create_master_brand: 'Create Master Brand',
    edit_master_brand: 'Edit Master Brand',
    master_sku_category: 'Master Sku Category',
    create_master_sku_category: 'Create Master Sku Category',
    edit_master_sku_category: 'Edit Master Sku Category',
    master_sku_header: 'Master Sku Header',
    create_master_sku_header: 'Create Master Sku Header',
    edit_master_sku_header: 'Edit Master Sku Header',
    create_master_sku: 'Create Master Sku',
    edit_master_sku: 'Edit Master Sku',
    create_master_sku_bom: 'Create Master Sku Bom',
    edit_master_sku_bom: 'Edit Master Sku Bom',
    create_master_component: 'Create Master Component',
    edit_master_component: 'Edit Master Component',
    create_master_component_bom: 'Create Master Component Bom',
    edit_master_component_bom: 'Edit Master Component Bom',
    purchase_order: 'Purchase Order',
    create_purchase_order: 'Create Purchase Order',
    edit_purchase_order: 'Edit Purchase Order',
    purchase_order_detail: 'Purchase Order Detail',
    create_purchase_order_detail: 'Create Purchase Order Detail',
    edit_purchase_order_detail: 'Edit Purchase Order Detail',
    good_reciept: 'Good Reciept',
    create_good_reciept: 'Create Good Reciept',
    edit_good_reciept: 'Edit Good Reciept',
    good_reciept_detail: 'Good Reciept Detail',
    create_good_reciept_detail: 'Create Good Reciept Detail',
    edit_good_reciept_detail: 'Edit Good Reciept Detail',
    management_pos: 'Management POS',
    management_order: 'Management Order',
    order: 'Orders',
    all_order: 'All Order',
    detail_order: 'Detail Order',
    merchant: 'Merchants',
    all_merchant: 'All Merchant',
    detail_merchant: 'Detail Merchant',
    merchant_detail: 'Merchant Detail',
    all_order_detail: 'All Order Detail',
    current_order: 'Current Order',
    advance_order: 'Advance Order',
    reject_order: 'Reject Order',
    problem_order: 'Problem Order',
    process_order: 'Process Order',
    order_delivery: 'Order Delivery',
    resend_order: 'Resend Order',
    upper_text: 'Upper Text',
    view_order: 'View Order',
    view_order_detail: 'View Order Detail',
    view_order_online_detail: 'View Order Online Detail',
    view_order_online_detail_list: 'View Order Online Detail List',
    view_utilization_detail: 'View Utilization Detail',
    view_order_payment: 'View Order Payment',
    view_order_log_status: 'View Order Log Status',
    payment_method: 'Payment Method',
    create_payment_method: 'Create Payment Method',
    edit_payment_method: 'Edit Payment Method',
    menu: 'Menu',
    topping: 'Topping',
    create_topping: 'Create Topping',
    edit_topping: 'Edit Topping',
    doctor: 'Doctor',
    create_doctor: 'Create Doctor',
    edit_doctor: 'Edit Doctor',
    tag: 'Tag',
    notification: 'Notification',
    create_tag: 'Create Tag',
    edit_tag: 'Edit Tag',
    create_country: 'Create Country',
    edit_country: 'Edit Country',
    group_tag: 'Group Tag',
    create_group_tag: 'Create Group Tag',
    edit_group_tag: 'Edit Group Tag',
    create_inventory: 'Create Inventory',
    edit_inventory: 'Edit Inventory',
    origin: 'Origin',
    create_origin: 'Create Origin',
    edit_origin: 'Edit Origin',
    product_provider: 'Product Provider',
    create_product_provider: 'Create Product Provider',
    edit_product_provider: 'Edit Product Provider',
    category: 'Category',
    create_category: 'Create Category',
    edit_category: 'Edit Category',
    product: 'Product',
    create_product: 'Create Product',
    edit_product: 'Edit Product',
    add_variant_addon: 'Add Variant Addon',
    add_combo: 'Add Combo',
    create_option: 'Create Option',
    edit_option: 'Edit Option',
    create_option_group: 'Create Crust',
    edit_option_group: 'Edit Crust',
    kds: 'Kds',
    create_kds: 'Create Kds',
    edit_kds: 'Edit Kds',
    store: 'Store',
    create_store: 'Create Store',
    edit_store: 'Edit Store',
    edit_store_address_mapping: 'Edit Store Address Mapping',
    store_v3: 'Store',
    create_store_v3: 'Create Store',
    edit_store_v3: 'Edit Store',
    customer_factory: 'Customer Factory',
    create_customer_factory: 'Create Customer Factory',
    edit_customer_factory: 'Edit Customer Factory',
    store_group: 'Store Group',
    store_target: 'Store Target',
    create_store_group: 'Create Store Group',
    edit_store_group: 'Edit Store Group',
    store_tag: 'Store Tag',
    create_store_tag: 'Create Store Tag',
    edit_store_tag: 'Edit Store Tag',
    store_featured: 'Store Featured',
    create_store_featured: 'Create Store Featured',
    edit_store_featured: 'Edit Store Featured',
    address: 'Address',
    system: 'System',
    client: 'Client',
    create_client: 'Create Client',
    edit_client: 'Edit Client',
    config: 'Config',
    create_config: 'Create Config',
    edit_config: 'Edit Config',
    public_config: 'Public Config',
    create_public_config: 'Create Public Config',
    edit_public_config: 'Edit Public Config',
    partner: 'Partner',
    create_partner: 'Create Partner',
    edit_partner: 'Edit Partner',
    printer: 'Printer',
    create_printer: 'Create Printer',
    edit_printer: 'Edit Printer',
    shift: 'Shift',
    content: 'Content',
    create_content: 'Create Content',
    edit_content: 'Edit Content',
    content_category: 'Content Category',
    create_content_category: 'Create Content Category',
    edit_content_category: 'Edit Content Category',
    promotion: 'Promotion',
    create_promotion: 'Create Promotion',
    edit_promotion: 'Edit Promotion',
    promotion_master: 'Promotion master',
    create_promotion_master: 'Create Promotion master',
    edit_promotion_master: 'Edit Promotion master',
    promotion_user: 'Promotion user',
    create_promotion_user: 'Create Promotion user',
    edit_promotion_user: 'Edit Promotion user',
    promotion_product: 'Promotion Product',
    create_promotion_product: 'Create Promotion Product',
    edit_promotion_product: 'Edit Promotion Product',
    promotion_condition: 'Promotion Condition',
    create_promotion_condition: 'Create Promotion Condition',
    edit_promotion_condition: 'Edit Promotion Condition',
    order_processing_time: 'Order Processing Time',
    overview: 'Overview',
    sale_today: 'Today Sales',
    sale_history: 'Sale History',
    product_mix: 'Product Mix',
    sale_trend: 'Sale Trend',
    summary_sales: 'Summary Sales',
    summary_date_sales: 'Summary Date Sales',
    summary_tracker: 'Summary Tracker',
    store_cash: 'Store Cash',
    create_store_cash: 'Create Store Cash',
    edit_store_cash: 'Edit Store Cash',
    money_bag: 'Money Bag',
    create_money_bag: 'Create Money Bag',
    edit_money_bag: 'Edit Money Bag',
    payment: 'Payment',
    payment_method_type: 'Payment Method Type',
    create_payment_method_type: 'Create Payment Method Type',
    edit_payment_method_type: 'Edit Payment Method Type',
    payment_method_group: 'Payment Method Group',
    create_payment_method_group: 'Create Payment Method Group',
    edit_payment_method_group: 'Edit Payment Method Group',
    payment_method_level1: 'Payment Method Level 1',
    create_payment_method_level1: 'Create Payment Method Level 1',
    edit_payment_method_level1: 'Edit Payment Method Level 1',
    payment_method_bank: 'Payment Method Bank',
    create_payment_method_bank: 'Create Payment Method Bank',
    edit_payment_method_bank: 'Edit Payment Method Bank',
    edit_employee: 'Edit Employee',
    contract: 'Contract',
    create_contract: 'Create Contract',
    edit_contract: 'Edit Contract',
    product_half_and_half: 'Product Half and half',
    create_product_half_and_half: 'Create Product Half and half',
    edit_product_half_and_half: 'Edit Product Half and half',
    ui_configuration: 'Ui Configuration',
    create_ui_configuration: 'Create Ui Configuration',
    edit_ui_configuration: 'Edit Ui Configuration',
    banner: 'Banner',
    create_banner: 'Create Banner',
    edit_banner: 'Edit Banner',
    comment: 'Comment',
    create_comment: 'Create Comment',
    edit_comment: 'Edit Comment',
    units: 'Units',
    edit_unit: 'Edit Unit',
    create_unit: 'Create Unit',
    currency: 'Currency',
    create_currency: 'Create Currency',
    edit_currency: 'Edit Currency',
    print_image: 'Print Image',
    create_item: 'Create Item',
    edit_item: 'Edit Item',
    items: 'Items',
    create_component: 'Create Component',
    edit_component: 'Edit Component',
    component: 'Component',
    employee: 'Employee',
    create_employee: 'Create Employee',
    report: 'Report',
    area_management: 'Area Management',
    device: 'Device',
    edit_device: 'Edit Device',
    create_device: 'Create Device',
    push_notify: 'Push Notify',
    create_notify: 'Create Notify',
    booking: 'Booking',
    detail_booking: 'Detail Booking',
    nationality: 'Nationality',
    create_nationality: 'Create Nationality',
    edit_nationality: 'Edit Nationality',
    rank: 'Rank',
    create_rank: 'Create Rank',
    edit_rank: 'Edit Rank',
    position: 'Position',
    create_position: 'Create Position',
    edit_position: 'Edit Position',
    occupation: 'Occupation',
    create_occupation: 'Create Occupation',
    edit_occupation: 'Edit Occupation',
    changeStatus: 'Change Status',
    create_changeStatus: 'Create Change Status',
    edit_changeStatus: 'Edit Change Status',
    visaStatus: 'Visa Status',
    create_visaStatus: 'Create Visa Status',
    edit_visaStatus: 'Edit Visa Status',
    nationalityCategory: 'Nationality Category',
    upload_mobile: 'Upload Mobile',
    create_nationalityCategory: 'Create Nationality Category',
    edit_nationalityCategory: 'Edit Nationality Category',
    bloodType: 'Blood Type',
    create_bloodType: 'Create Blood Type',
    edit_bloodType: 'Edit Blood Type',
    productList: 'Product List',
    chooseCategoryType: 'Choose Category Type',
    transfer: 'Transfer',
    create_transfer: 'Create Transfer',
    edit_transfer: 'Edit Transfer',
    foodWaste: 'Food Waste',
    createFoodWaste: 'Create Food Waste',
    editFoodWaste: 'Edit Food Waste',
    eod: 'End Of Date',
    eod_list: 'EOD',
    check_eod: 'Check End Of Date',
    eod_management: 'List EOD',
    money_closure: 'Money Closure',
    labour_closure: 'Labour Closure',
    inventory_closure: 'Inventory Closure',
    order_type: 'Order Type',
    order_status: 'Order Status',
    order_promotion: 'Order Promotion',
    store_target_label: 'Store Target 2',
    sale_his: 'Sales History',
    product_mix_v2: 'Product Mix  ',
    manage_order: 'Manage Order  ',
    today_sales: 'Today Sales',
    reportv2: 'Report V2',
    add_order_device: 'Add Order Device',
    order_device: 'Order Device',
    order_driver_pool: 'Order Driver Pool',
    slot_configuration: 'Slot Configuration',
    edit_slot_configuration: 'Edit Slot Configuration',
    driver_roaster: 'Driver Roaster',
    create_driver_roaster: 'Create Driver Roaster',
    edit_driver_roaster: 'Edit Driver Roaster',
    exception_date: 'Exception Date',
    edit_exception_date: 'Edit Exception Date',
    create_exception_date: 'Create Exception Date',
    matrix: 'Matrix',
    edit_matrix: 'Edit Matrix',
    create_matrix: 'Create Matrix',
    store_cash_list: 'Store Cash List',
    brand: 'Brand',
    store_category: 'Merchant Category',
    create_brand: 'Create Brand',
    edit_brand: 'Create Brand',
    create_store_category: 'Create Merchant Category',
    edit_store_category: 'Edit Merchant Category',
    food_item: 'Food Items',
    store_notification: 'Store Notification',
    leafleting_result: 'Leafleting Result',
    edit_leafleting_result: 'Edit Leafleting Result',
    create_leafleting_result: 'Create Leafleting Result',
    leafleting_target: 'Leafleting Target',
    edit_leafleting_target: 'Edit Leafleting Target',
    create_leafleting_target: 'Create Leafleting Target',
    special_request: 'Special Request',
    add_special_request: 'Add Special Request',
    edit_special_request: 'Edit Special Request',
    report_order: 'Report Order',
    loyalist_point_report: 'Loyalist Point Report',
    check_order: 'Check order',
    sales_by_hour: 'Sales By Hour',
    ace_cancel_refund: 'Cancel Refund',
    ace_cancel: 'Cancel',
    ace_refund: 'Refund',
    sales_mix_by_segment: 'Sales Mix By Segment',
    sales_details: 'Sales details',
    report_menu_mix: 'Menu mix',
    report_usage_analysis: 'Report Usage Analysis'
  },
  dashboard: {
    new_visits: 'New Visits',
    messages: 'Messages',
    purchases: 'Purchases',
    shoppings: 'Shoppings',
    total_sales: 'Total Sales',
    total_bill: 'Total Bill',
    average_bill: 'Average Bill',
    sos: 'SOS',
    sales_by_channel: 'Sales By Channel',
    sales_by_type: 'Sales By Type',
    bill_by_channel: 'Bill By Channel',
    bill_by_type: 'Bill By Type',
    top_10: 'TOP 10',
    no_data: 'No Data'
  },
  navbar: {
    logOut: 'Log Out',
    dashboard: 'Dashboard',
    github: 'Github',
    theme: 'Theme',
    size: 'Global Size'
  },
  login: {
    title: '',
    action: 'Enter the details below to log in.',
    help: 'Not a customer? Call for help',
    logIn: 'Login',
    username: 'Username',
    password: 'Password',
    confirmpassword: 'Confirm Password',
    any: 'any',
    thirdparty: 'Or connect with',
    remember_me: 'Remember me',
    forgot_password: 'Forgot password',
    merchant: 'Merchant platform',
    power: 'powered by Diqit'
  },
  forgot_password: {
    title: 'Reset password',
    action: 'Provide your email address and an email with a reset link will be sent to you.',
    help: 'Back to Log In',
    username: 'Username',
    email: 'Enter email address',
    reset: 'Reset'
  },
  reset_password: {
    title: 'Reset your password',
    new_password: 'New Password',
    confirm_password: 'Confirm Password',
    reset: 'Reset'
  },
  documentation: {
    documentation: 'Documentation',
    github: 'Github Repository'
  },
  permission: {
    addRole: 'New Role',
    editPermission: 'Edit',
    roles: 'Your roles',
    switchRoles: 'Switch roles',
    delete: 'Delete',
    confirm: 'Confirm',
    cancel: 'Cancel'
  },
  guide: {
    button: 'Show Guide'
  },
  common: {
    keyWord: 'Key word',
    titleDelete: 'Delete record?',
    titleClearCache: 'Clear Cache',
    sun: 'Sun',
    mon: 'Mon',
    tue: 'Tue',
    wed: 'Wed',
    thu: 'Thu',
    fri: 'Fri',
    sat: 'Sat'
  },
  table: {
    start_amount: 'Start Amount',
    end_amount: 'End Amount',
    is_special: 'Is Special',
    finish: 'Finish',
    message: 'Message',
    is_valid: 'Is Valid',
    is_verify_eod: 'Is Verify Eod',
    period: 'Period',
    user_total: 'User Total',
    purchase: 'Purchase',
    user_total_redeem: 'User Total Redeem',
    user_total_earn: 'User Total Earn',
    point_total_earn: 'Point Total Earn',
    point_total_redeem: 'Point Total Redeem',
    qty_release: 'Quantity Release',
    qty_redeem: 'Quantity Redeem',
    item: 'Item',
    day_before: 'The Day Before',
    for_day: 'For The Day',
    bank_balance: 'Bank Balance',
    internal_change: 'Internal Change Fund',
    bank: 'Bank',
    cash: 'Cash',
    total_deposit_day: 'Total Deposit On The Day',
    total_withdraw_day: 'Total Withdraw On The Day',
    name_cash: 'Application Name',
    is_unique_code: 'Is Unique Code',
    limit_quantity_cart: 'Limit Quantity Cart',
    rp: 'Rp',
    is_oc_discount: 'Is Oc Discount',
    submit: 'Submit',
    title: 'Title',
    importance: 'Imp',
    type: 'Type',
    type_name: 'Type Name',
    remark: 'Remark',
    search: 'Search',
    add: 'Add',
    export: 'Export',
    reviewer: 'reviewer',
    id: 'ID',
    uom_from_uuid: 'UOM From',
    code: 'Code',
    name: 'Name',
    name_kanji: 'Name Kanji',
    sku_uuid: 'Sku',
    component_uuid: 'Component',
    uom_to_uuid: 'UOM To',
    material_uuid: 'Material',
    conversion: 'Conversion',
    uom_uuid: 'UOM',
    replacement_material_uuid: 'Replacement Material Uuid',
    replacement_uom_uuid: 'Replacement Uom Uuid',
    replacement_quantity: 'Replacement Quantity',
    quantity: 'Quantity',
    number_of_slots: 'Number of slots',
    email: 'Email',
    date_of_purchase: 'Date Of Purchase',
    date_of_receive: 'Date Of Receive',
    amount: 'Amount',
    username: 'Username',
    role: 'Role',
    date: 'Date',
    author: 'Author',
    readings: 'Readings',
    status: 'Status',
    is_use: 'Is Use',
    is_point: 'Is Point',
    actions: 'Actions',
    edit: 'Edit',
    publish: 'Publish',
    draft: 'Draft',
    delete: 'Delete',
    create: 'Create',
    update: 'Update',
    cancel: 'Cancel',
    ready: 'Ready',
    close: 'Close',
    confirm: 'Confirm',
    created_at: 'Created Date',
    description: 'Description',
    category: 'Category',
    sku: 'Sku',
    price: 'Price',
    sequence: 'Sequence',
    location: 'Location',
    client: 'Client',
    category_type: 'Category Type',
    additional_type_ids: 'Additional Type',
    store: 'Store',
    store_group: 'Store Group',
    store_type: 'Store Type',
    point: 'Point',
    start_date: 'Start Date',
    end_date: 'End Date',
    delivery_start_date: 'Delivery Start Date',
    delivery_end_date: 'Delivery End Date',
    delivery_time: 'Delivery Time',
    is_special_option_group: 'Is Special',
    key: 'Key',
    value: 'Value',
    ip: 'IP',
    store_code: 'Store Code',
    store_name: 'Store Name',
    slip_number: 'Slip Number',
    building: 'Building',
    district: 'District',
    petty_cash: 'Petty Cash',
    sale_cash: 'Sale Cash',
    real_cash: 'Real Cash',
    variant_cash: 'Variant Cash',
    is_open: 'Is Open',
    user: 'User',
    start_time: 'Start Time',
    end_time: 'End Time',
    cash_out: 'Cash Out',
    street: 'Street',
    customer: 'Customer Details',
    phone: 'Phone',
    createdBy: 'Created By',
    updatedBy: 'Updated By',
    back: 'Back',
    store_type_id: 'Store type',
    keyword: 'Keyword',
    warning: 'Warning',
    tier: 'Tier',
    channel: 'Channel',
    image: 'Image',
    thumbnail: 'Thumbnail',
    stores: 'Stores',
    store_groups: 'Store Groups',
    tax: 'Tax',
    group_name: 'Group name',
    min: 'min',
    max: 'max',
    full_name: 'Full Name',
    short_name: 'Short name',
    value_type: 'Value Type',
    en_route: 'En Route',
    available: 'Available',
    option_group_name: 'Option group name',
    default: 'DEFAULT',
    additinal: 'ADDITIONAL',
    choose_product: 'Choose product',
    products_label: 'Products:',
    time_label: 'Time:',
    time: 'Time',
    rate_time: 'Rate Time',
    bake_time: 'Bake Time',
    kds_products_label: 'KDS Products:',
    your_roles_label: 'Your roles:',
    editor_dashboard: "Editor's Dashboard",
    see_detail: 'See Detail...',
    special: 'Special',
    aggregator_code: 'Aggregator Code',
    size: 'Size',
    active: 'Active',
    content: 'Content',
    how_to_make_image: 'How To Make Image',
    drivertracker_min_version: 'Driver Tracker Min Version',
    ios_customerapp_min_version: 'IOS Customer App Min Version',
    android_customerapp_min_version: 'Android Customer App Min Version',
    file_name: 'File Name',
    icon: 'Icon',
    avatar: 'Avatar',
    rate: 'Rate',
    tags: 'Tags',
    assign: 'Assign',
    detail: 'Detail',
    log: 'Log',
    main_description: 'Main Description',
    product_providers: 'Product Providers',
    price_with_tax: 'Price With Tax',
    no_of_review: 'No Of Review',
    origins: 'Origins',
    parent_category: 'Parent Category',
    sub_category: 'Sub Category',
    parent_tag: 'Parent Tag',
    no_data: 'No data',
    allowed_type_ids: 'Allowed Types',
    short_description: 'Short Description',
    full_description: 'Full Description',
    page: 'Page',
    url: 'Url',
    app_url: 'App Url',
    field: 'Field',
    show: 'Show',
    tag: 'Tag',
    group_type: 'Group Type',
    notification: 'Notification',
    large_image: 'Large Image',
    middle_image: 'Middle Image',
    small_image: 'Small Image',
    platform: 'Platform',
    model: 'Model',
    localizedModel: 'LocalizedModel',
    version: 'Version',
    sub_title: 'Sub Title',
    name_seller: 'Name Seller',
    validity: 'Validity',
    product_additional_information_name: 'Additional Name',
    discount_percentage: 'Discount Percentage',
    symbol: 'Symbol',
    select: 'Select',
    doctors: 'Doctors',
    country: 'Country',
    languages: 'Languages',
    designation: 'Designation',
    specialisation: 'Specialisation',
    qualifications: 'Qualifications',
    review: 'Review',
    discounted_price: 'Discounted Price',
    faq: 'Faq',
    contact: 'Contact',
    t_and_c: 'T And C',
    disclaimer: 'Disclaimer',
    number_of_unit: 'Number Of Unit',
    additional_description: 'Additional Description',
    origin: 'Origin',
    doctor: 'Doctor',
    refund_policy: 'Refund Policy',
    cancellation_policy: 'Cancellation Policy',
    comment: 'Comment',
    reply: 'Reply',
    subject: 'Subject',
    body: 'Body',
    application: 'Application',
    campaign: 'Campaign',
    notify: 'Notify',
    booking: 'Booking',
    reservation_time: 'Reservation Time',
    num_of_people: 'Num Of People',
    smoking: 'Smoking',
    booking_device_uuid: 'Booking Device',
    note: 'Note',
    booking_status: 'Booking Status',
    slug: 'Slug',
    check_all: 'Check All',
    generate: 'Generate',
    sku_name: 'SKU Name',
    takeaway: 'Takeaway',
    delivery: 'Delivery',
    eat_in: 'Eat In',
    aggregator: 'Aggregator',
    total_bill: 'Total Number of Bill',
    total_sales: 'Total Sales',
    avg_bill: 'Avg Bill',
    register: 'Register',
    available_times: 'Available Time',
    available_packets: 'Available Packets',
    date_range: 'Date Range',
    time_range: 'Time Range',
    day_of_weeks: 'Day Of Weeks',
    ordered_quantity: 'Ordered Quantity',
    advance_day: 'Advance Day',
    no: 'No',
    outlet_name: 'Outlet Name',
    outlet_code: 'Outlet Code',
    total_online_order_delivery: 'Total Order',
    total_order_swiped: 'Total Order Swiped',
    driver_tracker_usage_percent: 'Usage (%)',
    sos_gt_30: 'SOS > 30',
    sos_gt_30_percent: 'SOS > 30 (%)',
    sos_lt_30: 'SOS < 30',
    sos_lt_30_percent: 'SOS < 30 (%)',
    group_tag: 'Group Tag',
    inventory: 'Inventory',
    is_master: 'Is Master',
    is_alcohol: 'Is Alcohol',
    business_date: 'Business Date',
    delivery_sale: 'Delivery Sale',
    delivery_bill: 'Delivery Bill',
    takeaway_sale: 'Takeaway Sale',
    takeaway_bill: 'Takeaway Bill',
    total_sale: 'Total Sale',
    total_bills: 'Total Bill',
    avo: 'AVO',
    height: 'Height (cm)',
    length: 'Length (cm)',
    weight: 'Weight (kg)',
    width: 'Width (cm)',
    package_type: 'Package Type',
    shift: 'Shift',
    select_app: 'Select App',
    version_code: 'Version Code',
    version_name: 'Version Name',
    app: 'Application',
    kds: 'Kds',
    dispatch: 'Dispatch',
    taker: 'Taker',
    takeaway_monitor: 'Takeaway Monitor',
    taker_client: 'Taker Client',
    upload_file: 'Upload File',
    required_doctor: 'Required Doctor',
    snap_duration: 'Duration of stay',
    commodity_id: 'Commodity Id',
    transaction_date: 'Transaction Date',
    order_code: 'Order Code',
    activity: 'Activity',
    outlet: 'Outlet',
    voucher_name: 'Voucher name',
    redeem_code: 'Redeem code',
    total_count: 'Total count',
    live: 'sync directly',
    is_driver_pool: 'Use Driver Pool',
    organization: 'Organization',
    api_end_point: 'Api end point',
    name_group: 'Name Group',
    link_group: 'Link Group',
    slot_per_hour: 'Slots/Hour',
    driver_trip_per_hour: 'Driver Trip/Hour',
    order_number: 'Order number',
    lob: 'Lob',
    time_from: 'Time From',
    time_to: 'Time To',
    brands: 'Brands',
    store_categories: 'Merchant categories',
    menu_name: 'Menu name',
    brand: 'Brand',
    total_redeemptions: 'Total redeemptions',
    is_send_S3: 'Send EOD',
    is_live: 'Is Live',
    advance_only: 'Advance only',
    free: 'Free',
    send: 'Send',
    component: 'Component',
    error: 'Error',
    year: 'Year',
    display_price: 'Price',
    print_name: 'Print name',
    marketing_description: 'Marketing Description',
    view_subscription_logs: 'View subscription logs',
    store_message_type: 'Store mesage type',
    assigned_stores: 'Assigned stores',
    brand_name: 'Brand name',
    fallback: 'Fallback',
    advance: 'Advance',
    show_on_report: 'Show on report',
    category_code: 'Category code',
    category_name: 'Category name',
    product: 'Product',
    reason: 'Reason',
    material: 'Material',
    cross_sell: 'Cross sell',
    meta_title: 'Meta Title',
    meta_description: 'Meta Description',
    meta_key: 'Meta Key'
  },
  errorLog: {
    tips: 'Please click the bug icon in the upper right corner',
    description: 'Now the management system are basically the form of the spa, it enhances the user experience, but it also increases the possibility of page problems, a small negligence may lead to the entire page deadlock. Fortunately Vue provides a way to catch handling exceptions, where you can handle errors or report exceptions.',
    documentation: 'Document introduction'
  },
  excel: {
    export: 'Export',
    selectedExport: 'Export Selected Items',
    placeholder: 'Please enter the file name (default excel-list)'
  },
  zip: {
    export: 'Export',
    placeholder: 'Please enter the file name (default file)'
  },
  pdf: {
    tips: 'Here we use window.print() to implement the feature of downloading PDF.'
  },
  theme: {
    change: 'Change Theme',
    documentation: 'Theme documentation',
    tips: 'Tips: It is different from the theme-pick on the navbar is two different skinning methods, each with different application scenarios. Refer to the documentation for details.'
  },
  tagsView: {
    refresh: 'Refresh',
    close: 'Close',
    closeOthers: 'Close Others',
    closeAll: 'Close All'
  },
  settings: {
    title: 'Page style setting',
    theme: 'Theme Color',
    tagsView: 'Open Tags-View',
    fixedHeader: 'Fixed Header',
    sidebarLogo: 'Sidebar Logo'
  },
  message: {
    show_report: 'Are you sure to show report?',
    confirm_export: 'Click button to export data',
    update: 'Updated successfully.',
    delete: 'Deleted successfully.',
    changeStatus: 'Changed status successfully',
    tokenExpired: 'Token expired.',
    tokenInvalid: 'Token invalid.',
    loggedOut: 'You have been logged out.',
    registered: 'You have registered successfully.',
    invalidExtension: 'The extension file is invalid.',
    confirmDelete: 'Are you sure to delete this item?',
    confirmRefund: 'Are you sure to refund this item?',
    confirmResend: 'Are you sure to Resend this item?',
    confirmReUpload: 'Do you want to re-upload?',
    noRowSelected: 'Please select at least one item.',
    tokenTimeout: 'You session have been expired, press Cancel to stay on this page, OK to logout',
    takeAway: 'C',
    delivery: 'D',
    eatIn: 'E',
    take_away_text: 'Collection',
    delivery_text: 'Delivery',
    eatInText: 'Eat In',
    only_accept_image: 'Only accept: *.jpeg,bmp,png,gif,jpg',
    delete_file: 'This will permanently delete the file. Continue?',
    delete_completed: 'Delete completed',
    delete_canceled: 'Delete canceled',
    email_not_correct: 'Email not correct',
    copy_successfully: 'Copy successfully',
    confirmAddPromotionUser: 'Are you sure to add these items?',
    confirmDeletePromotionUser: 'Are you sure to delete these items?',
    confirmClone: 'Are you sure to clone this item?',
    confirmChangeDataBySku: 'Are you sure want to change data by sku?',
    warnSelectDeliverRole: 'You must select leasted one store and driver who created will be belong the first store',
    confirmGenerateCoupon: 'Are you want to generate coupon?',
    confirmUpdate: 'Are you sure to update?',
    updateAll: 'Are you sure to confirm all value?',
    requiredSizeAndCrust: 'Please add size and crust',
    uploadKML: 'Upload KML success',
    dataNotSave: 'Your change will not be saved',
    saveBeforeChange: 'Do you want to save before countinue?',
    name_lang_is_required: 'Name :lang is required'
  },
  button: {
    submit: 'Submit',
    filter: 'Filter',
    reset: 'Reset',
    save: 'Save',
    next: 'Next',
    back: 'Back',
    add_new: 'Add new',
    add_menu: 'Add menu',
    add_group_type: 'Add group type',
    remove_group: 'Remove group',
    add_group: 'Add group',
    set_price: 'Set price',
    set_topping: 'Set topping',
    set_video: 'Set video',
    set_doctor_appointment: 'Set Doctor Appointment',
    add_option_group_option: 'Add option group - option',
    add_topping_option: 'Add topping - option',
    ok: 'OK',
    cancel: 'Cancel',
    add: 'Add',
    update: 'Update',
    delete: 'Delete',
    set_schedule: 'Set schedule',
    go_login: 'Go Login',
    select: 'Select',
    option: 'Option',
    option_group: 'Crust',
    add_combo_group_item: 'Add Combo Group - Item',
    add_variant_addon: 'Add Variant - Addon',
    set_kds_products: 'Set KDS Products',
    copy: 'Copy',
    component_bom: 'Component Bom',
    set_size: 'Set Size',
    for_approval: 'For Approval',
    published: 'Published',
    add_user: 'Add User',
    add_promotio_user: 'Add Promotion User',
    delete_promotion_user: 'Delete Promotion User',
    put_on_break: 'Put On Break',
    put_online: 'Put Online',
    resend: 'Resend',
    connect: 'Connect',
    show_review: 'Show Review',
    add_category: 'Add Category',
    add_unit: 'Add Unit',
    add_option_group: 'Add Crust',
    add_material: 'Add Material',
    add_option: 'Add Size',
    add_comment: 'Add Comment',
    add_banner: 'Add Banner',
    add_tag: 'Add Tag',
    add_country: 'Add Country',
    add_group_tag: 'Add Group Tag',
    add_inventory: 'Add Inventory',
    add_store: 'Add Store',
    add_store_group: 'Add Store Group',
    add_store_cash: 'Add Store Cash',
    add_store_tag: 'Add Store Tag',
    add_store_notification: 'Add Store Notification',
    add_origin: 'Add Origin',
    add_doctor: 'Add Doctor',
    add_coupon: 'Add Coupon',
    add_notify: 'Add Notify',
    add_campaign: 'Add Campaign',
    add_po: 'Add Purchase Order',
    add_good_reciept: 'Add Good Reciept',
    detail: 'Detail',
    add_type: 'Add Type',
    down_format: 'Format Import',
    create: 'Create',
    add_payment_method_level1: 'Add Payment Method Level 1',
    add_payment_method: 'Add Payment Method',
    add_order_type: 'Add Order Type',
    add_order_status: 'Add Order Status',
    add_promotion_master: 'Add Promotion Master',
    add_promotion: 'Add Promotion',
    adjust_status: 'Adjust Status',
    clear_data: 'Clear Data',
    add_more: 'Add more',
    add_ads_video: 'Add Ads Video',
    add_combo_group: 'Add Combo Group',
    add_product: 'Add Product',
    add_zone: 'Add Zone',
    add_matrix: 'Add Matrix',
    add_brand: 'Add Brand',
    add_subscription_plan: 'Add subscription plan',
    add_account: 'Add account',
    add_store_featured: 'Add featured list'
  },
  user: {
    isLogin: 'Is Login',
    permisstion: 'Permission',
    checkAll: 'Check All',
    userType: 'User Type',
    basicDetail: 'Basic Detail',
    permissions: 'Permissions',
    text_add_role: 'Add a role now!',
    text_no_role: 'No role added yet.',
    text_no_driver: 'No driver yet',
    text_no_permissions: 'No permissions added yet',
    text_add_permissions: 'Add a permission now!',
    text_add_user: 'Add a user now!',
    text_no_user: 'No user added yet',
    loginInfo: 'Login Information',
    role_type: 'Role Type',
    sub_role: 'Sub Roles',
    is_normal_role: 'Normal Role',
    is_manager_role: 'Manager Role'
  },
  store: {
    store_listing: 'Store Listing',
    stores: 'Stores',
    openedAt: 'Opening Hour',
    closedAt: 'Closing Hour',
    mon: 'Mon:',
    tue: 'Tue:',
    wed: 'Wed:',
    thu: 'Thur:',
    fri: 'Fri:',
    sat: 'Sat:',
    sun: 'Sun:',
    hol: 'Holiday:',
    public_holiday: 'Holiday:',
    basic_information: 'Basic Information',
    payment: 'Payment Information',
    jp_information: '提携社',
    time_setting: 'Time Settings Information',
    store_group: 'Store Group:',
    tier: 'Tier:',
    type: 'Store Type:',
    code: 'Store Code:',
    name: 'Store Name',
    phone: 'Phone:',
    fax: 'Fax:',
    tax_rate: 'Store tax (%)',
    operation_hour: 'Operation hours:',
    opening_timing: 'Opening Timings:',
    aggregator_code: 'Aggregator Code:',
    am_sv_code: 'AM/SV Code:',
    am_sv_name: 'AM/SV Name:',
    owner_name: 'Owner Name:',
    owner_code: 'Owner Code:',
    email: 'Email',
    desc: 'Description:',
    remark: 'Remark:',
    accept: 'Accept Online Order:',
    accept_online: 'Accept Online Order:',
    note: '※It is necessary to notify the acceptance or rejection separately to the partner',
    alcohol: 'Alcohol Treatment:',
    estimated_time: 'Estimated Time (min):',
    collection: 'Collection',
    business_period: 'Business Period:',
    business_hour: 'Business Hours',
    schedule: 'Opening Schedule:',
    lunch_time: 'Lunch Time',
    time_zone: 'Time Zone:',
    minimum_cart: 'Minimum Cost Value (¥):',
    lat: 'Lat',
    long: 'Long',
    location: 'Location:',
    disposition: 'Dispositions',
    cash_on_delivery: 'Cash On Delivery:',
    credit_card: 'Credit Card:',
    ocbc: 'OCBC',
    payment_info: 'Payment Information',
    delivery_fee: 'Delivery Fee',
    menu_setting: 'Menu Control',
    take_away_text: 'Collection',
    delivery_text: 'Delivery',
    calendar: 'Calendar:',
    bt_calendar: 'View Calendar',
    period: 'Period',
    period_count: 'Period Count',
    start_day_week: 'Starting day of the week',
    title: 'Name',
    bt_add: 'Save Event',
    start_date: 'Date',
    end_date: 'End date',
    event: 'Event',
    time: 'Time',
    click_upload: 'Click to upload',
    note_kml: '※ Only accept KML file',
    confirmDelete: 'Are you sure to delete Event?',
    bt_public_menu: 'Public Menu & Combo To Production',
    duration: 'Duration:',
    search_keyword: 'Type code or name',
    menu_option: 'Exclude Size',
    menu_option_group: 'Exclude Crust',
    mixed_option_group: 'Exclude Size & Crust',
    geometry: 'Geometry',
    trade_zone: 'Trade Zone',
    sub_trade_zone: 'Sub Trade Zone',
    special_business_hours: 'Special Business Hours',
    special_date_close: 'Special Date Close',
    pizza_hut_day: "Pizza Hut's Day",
    order_duration: 'Order Duration',
    public_day: 'Public Holiday',
    stop_ordering: 'Stop Ordering',
    enter_a_location: 'Enter a location',
    store_detail: 'Store Details',
    additional_infomation: 'Additional Infomation',
    qr_code: 'QR Code',
    pin_code: 'Pin Code',
    contact_detail: 'Contact Details',
    integration: 'Integrations',
    language: 'Language',
    currency: 'Currency',
    tax_detail: 'Tax Details',
    is_inclusive_tax: 'Store price inclusive tax?',
    owner_detail: 'Owner Details',
    marketing_detail: 'Marketing Details',
    website: 'Website',
    tax_number: 'Tax Number',
    tax_persentage: 'Tax Persentage (%)',
    my_store: 'My stores',
    my_store_group: 'My store groups',
    add_store: 'Add a store now!',
    no_store: 'No store added yet.',
    view_map: 'View Map',
    show_all_sub_zone: 'Show All Sub Zone',
    add_sub_zone: 'Add Sub Zone',
    whatsapp_number: 'WhatsApp',
    address1: 'Address 1',
    address2: 'Address 2',
    zip_code: 'Zip Code',
    delivery_time: 'Delivery Time',
    ops_delivery_text: 'Ops delivery text',
    breakfast: 'Breakfast',
    full_address: 'Full Address',
    postal_code: 'Postal Code',
    fallback_banner: 'Fallback banner',
    address_level_0: 'Address Level 0',
    address_level_1: 'House No',
    address_level_2: 'Street',
    address_level_3: 'City',
    address_level_4: 'State',
    address_level_5: 'Country',
    address_level_6: 'Country Code',
    address_level_7: 'Address Level 7',
    address_level_8: 'Address Level 8',
    address_level_9: 'Address Level 9',
    set_max_order: 'Set Max Order',
    airport_mapping: 'Airport Mapping',
    sequence: 'Sequence',
    no_order_forecast: 'No Order Forecast',
    add_order_forecast: 'Add Order Forecast',
    no_driver_forecast: 'No Driver Forecast',
    add_driver_forecast: 'Add Driver Forecast',
    add_store_tag: 'Add a store tag now!',
    no_store_tag: 'No store tag added yet',
    store_tag_uuids: 'Store Tag',
    images: 'Images',
    bank_details: 'Bank Details',
    bank_name: 'Bank Name',
    bank_branch_name: 'Branch Name',
    bank_account_name: 'Account Name',
    bank_account_number: 'Account Number',
    add_store_notification: 'Add a store notification now!',
    no_store_notification: 'No store notification added yet',
    store_notification_uuids: 'Store Notification',
    opening_hours: 'Opening Hours',
    pre_orders: 'Pre-Orders',
    pre_order_time: 'Pre-Order lead time',
    delivery_options: 'Delivery Options',
    pre_order_settings: 'Pre-order settings',
    self_pickup_settings: 'Pickup settings',
    self_pickup: 'Enable self-pickup',
    self_pickup_time: 'Estimated preperation time',
    display_phone: 'Display Phone Number',
    categories: 'Restaurant Category',
    food_categories: 'Food Category (max 4)',
    dietary_categories: 'Dietary Requirement (max 1)',
    pickup_address: 'Pickup Address',
    dual_shift: 'Dual Shift',
    opening_time: 'Opening Time',
    closing_time: 'Closing Time',
    open_today: 'Open Today',
    subscription_plan: 'Subscription Plan',
    plan: 'Plan',
    view_more_plans: 'View More Plans',
    edit_store_address_mapping: 'Edit Store Address Mapping',
    max_distance: 'Max Distance',
    store_url: 'Store URL',
    no_store_featured: 'No Store Featured',
    add_store_featured: 'Add Store Featured',
    order_commission: 'Order Commission',
    expired_subscription_plan: 'Expires in {days} days',
    commission_message: '{commission_rate}% per order',
    period_ending_message: 'Trial period ending in {months} months',
    no_plan: 'No Plan',
    commission_plan: 'Commission Plan',
    commission: 'Commission',
    commission_percentage: 'Commission Percentage',
    store_approval: 'Store Approval',
    rejected_reason: 'Reject Reason',
    parcel: 'Parcel',
    remove_trade_zone: 'Remove Trade Zone',
    timezone: 'Timezone',
    translation: 'Translation',
    applicable_documents: 'Licenses, permits, and authorisation applicable to the Merchant',
    store_code: 'Store Code',
    language_for_email: 'Language for notification'
  },
  shift: {
    cashOut: 'Cash Out',
    description: 'Description',
    createdDate: 'Created Date',
    createdBy: 'Created By'
  },
  topping: {
    shortName: 'Short Name',
    sku: 'SKU',
    size: 'Size',
    default: 'Default',
    extra: 'Extra',
    topping: 'Topping',
    extra_cheese: 'Extra Cheese',
    topping_name: 'Topping name',
    delivery_tax: 'Delivery tax',
    dine_in_tax: 'Dine in tax',
    take_away_tax: 'Take away tax',
    no_topping_added_yet: 'No topping added yet',
    add_a_topping_now: 'Add a topping now',
    add_topping: 'Add topping',
    short_name: 'Short name',
    type: 'Type',
    category: 'Category',
    sequence: 'Sequence',
    description: 'Description',
    topping_detail: 'Topping detail',
    status: 'Status',
    desposition_pricing: 'Desposition & Pricing',
    isAfterBaking: 'Is After Baking',
    isAttachedCondiments: 'Is Attached Condiments',
    relatedItems: 'Related Items'
  },
  store_group: {
    store_group_detail: 'Store Group Details',
    total_store: 'Total Store',
    store_available: 'Store Available',
    store_added: 'Store Added',
    add_store_group: 'Add a store group now!',
    no_store_group: 'No store group added yet.'
  },
  address: {
    outlet_code: 'Store Code',
    level1: 'Level1',
    level2: 'Level2',
    level3: 'Level3',
    level4: 'Level4',
    level5: 'Level5',
    level6: 'Level6',
    level7: 'Level7',
    from_date: 'From Date',
    to_date: 'To Date',
    zip: 'Zip',
    address_code: 'Address Code',
    outlet: 'Store',
    from: 'From',
    to: 'To'
  },
  purchase_order: {
    amount: 'Amount',
    amount_receive: 'Amount Receive',
    supplier_name: 'Supplier Name',
    supplier_code: 'Supplier Code',
    supplier_contact: 'Supplier Contact',
    code1: 'Code1',
    code2: 'Code2'
  },
  purchase_order_detail: {
    purchase_order_uuid: 'PO Code',
    receive_quantity: 'Receive Qty',
    receive_price: 'Receive Price',
    receive_amount: 'Receive Amount',
    is_cancel: 'Cancelled',
    is_return: 'Returned',
    detail: 'Detail',
    keyword: 'Keyword'
  },
  material_master: {
    selflife_number: 'Self Life Number',
    selflife_number_type: 'Self Life Number Type',
    inventory_check: 'Inventory Check',
    inventory_uom: 'Inventory UOM',
    external_refence_code: 'External refence code',
    inventory_quantity: 'Inventory Quantity',
    storage_condition_category: 'Storage Condition Category',
    temperature_management_category: 'Temperature Management Category',
    consumption_time_limit: 'Consumption Time Limit',
    unfreeze_time: 'UnFreeze Time',
    min_time: 'Min Time',
    time_after_opening: 'Time After Opening',
    inventory_applied: 'Inventory Applied',
    purchasing_unit: 'Purchasing Unit',
    stocking_unit: 'Stocking Unit',
    uses_unit: 'Uses Unit',
    max_number_of_order: 'Maximum Number Of Order',
    recipe: 'Recipe',
    equal: 'Equal',
    co: 'CO',
    fc: 'FC',
    account: 'Account',
    order_period: 'Order Period',
    delivery_period: 'Delivery Period',
    condition: 'Condition',
    cost_management: 'Cost Management',
    purchase_price: 'Purchase price',
    uom: 'UOM',
    logistic_information: 'Logistic Information',
    inventory_management: 'Inventory Management',
    detail_information: 'Detail Information',
    general_information: 'General Information',
    gram: 'Gram',
    case: 'Case',
    bag: 'Bag',
    basic_detail: 'Basic Details',
    more_detail: 'More Details',
    max_no_of_order: 'Max No. of Orders',
    type_material: 'Type Material'
  },
  master_material_category: {
    master_material_category_name: 'Material Category'
  },
  customer: {
    is_blocked: 'Is Blocked',
    register_date: 'Registered Date',
    register_channel: 'Registered Channel',
    first_order_date: 'First Order Date',
    first_order_channel: 'First Order Channel',
    point: 'Point',
    amount: 'Amount',
    bill: 'Bill',
    level_point: 'Level Point',
    reward_type: 'Reward Type',
    reward_point: 'Reward Point',
    reward_status: 'Reward Status',
    reward_success: 'Success',
    reward_waiting: 'Waiting',
    reward_cancel: 'Cancel',
    reward_use: 'Use',
    reward_earn: 'Earn',
    history: 'History',
    gender: 'Gender',
    date_time: 'Datetime',
    male: 'Male',
    female: 'Female',
    average: 'Average',
    phone: 'Phone',
    birthday: 'Birthday',
    note: 'Note',
    address: 'Address',
    customer_address: 'Customer Address',
    view_customer: 'View Customer',
    first_name: 'First Name',
    last_name: 'Last Name',
    alternative_phone: 'Alternative Phone',
    street: 'Street',
    trade_zone_name: 'Trade Zone Name',
    trade_zone_lat: 'Trade Zone Lat',
    trade_zone_long: 'Trade Zone Long',
    disposition: 'Disposition',
    store_name: 'Store Name',
    is_headquarter: 'Is Headquarter',
    headquarter_code: 'Headquarter Code'
  },
  validate: {
    limit_quantity_cart: 'Limit Quantity Cart is Required and is Number',
    platforms: 'Platforms is Required',
    payment_value: 'Value is Number',
    point: 'Point is required and type is Number',
    amount: 'Amount is required and type is float',
    order_type: 'Segment is required',
    bill: 'Bill is required',
    store: 'Store is required ',
    reward_type: 'Reward type is required',
    client_required: 'Client is required',
    option_group: 'Option Group must has Option',
    is_required: ' is required',
    fullname: 'Full name is required',
    option_group_uuid: 'Option group is required',
    option_uuid: 'Option is reuqired',
    tax_rate: 'Tax is required',
    sku: 'SKU is required',
    store_group_uuids: 'Store group is required',
    sku_header: 'SKU Header is required',
    name: 'Name is required',
    short_name_required: 'Short Name is required',
    type_ids: 'Type is required',
    category_uuid: 'Category is required',
    code: 'Code is required',
    zone_code: 'Zone code is required',
    duration: 'duration is required',
    waiting_time: 'Waiting time is required',
    min_cart: 'Min card is required',
    password: 'Please input the password',
    password_again: 'Please input the password again',
    password_dont_match: "Two inputs don't match!",
    option_empty: 'Option group can not empty.',
    ip_required: 'IP is required',
    store_required: 'Store is required',
    code_length: 'Code length should be 1 to 20',
    selflife_number: 'Self Life Number is required',
    selflife_number_type: 'Self Life Number Type is required',
    external_refence_code: 'External refence code is required',
    uom_uuid: 'UOM is required',
    master_material_category_name: 'Material Category is required',
    uom_from_uuid: 'UOM From is required',
    uom_to_uuid: 'UOM To is required',
    conversion: 'Conversion is required',
    material_uuid: 'Material is required',
    component_uuid: 'Component is required',
    start_date: 'Start Date is required',
    end_date: 'End Date is required',
    username_lowercase: 'Username contain only lowercase letters, numbers, - _ . and accept email format',
    username_required: 'Username is required',
    password_required: 'Password is required',
    product_uuid: 'Product is required',
    time_required: 'Time is required',
    email: 'Please input the email',
    email_required: 'Email is required',
    phone_required: 'Phone is required',
    display_phone_required: 'Display phone is required',
    onwer_name_required: 'Owner Name is required',
    birthday_required: 'Birthday is required',
    level1_required: 'Level1 is required',
    level2_required: 'Level2 is required',
    level3_required: 'Level3 is required',
    address_code_required: 'Address Code is required',
    permissions_required: 'Permissions is required',
    tier_required: 'Tier is required',
    pool_required: 'Pool is required',
    store_type_required: 'Store Type is required',
    store_group_required: 'Store Group is required',
    operation_hours_required: 'Operation Hours is required',
    location_required: 'Location is required',
    lat_required: 'Lat is required',
    long_required: 'Long is required',
    disposition_required: 'Disposition is required',
    timezone_required: 'Timezone is required',
    description_required: 'Description is required',
    type_uuid: 'Type is required',
    bank_uuid: 'Bank is required',
    group_uuid: 'Group is required',
    date_required: 'Date is required',
    price_channel: 'Channel is required',
    price_tier: 'Tier is required',
    price_partner: 'Partner is required',
    price: 'Price is required',
    kana_name_required: 'Kana Name is required',
    kanji_name_required: 'Kanji Name is required',
    gender_required: 'Gender is required',
    birth_date_required: 'Birth Date is required',
    contract_title_required: 'Contract Title is required',
    contract_period_required: 'Contract Period is required',
    nationality_required: 'Nationality is required',
    position_required: 'Position is required',
    rank_required: 'Rank is required',
    additional_type_ids: 'Additional Type is required',
    name_required: 'Name is required',
    kds_make: 'Kds Make is required',
    kds_cut: 'Kds Cut is required',
    make_time: 'Make Time is required',
    cut_time: 'Cut Time is required',
    code_required: 'Code is required',
    size_required: 'Size is required',
    crust_required: 'Crust is required',
    evidence_number_required: 'Evidence number is required',
    payment_type_required: 'Payment type is required',
    option_required: 'Size is required',
    min: 'Min is required',
    max: 'Max is required',
    quantity: 'Quantity is required',
    option_group_required: 'Crust is required',
    material_required: 'Material is required',
    roles_required: 'Roles is required',
    campaign_required: 'Campaign is required',
    application_required: 'Application is required',
    short_name: 'Short name is required',
    sub_price: 'Sub price is required',
    start_date_required: 'Start Date is required',
    end_date_required: 'End Date is required',
    userType_required: 'User Type is required',
    page_required: 'Page is required',
    url_required: 'URL is required',
    field_required: 'Field is required',
    first_name_required: 'First Name is required',
    last_name_required: 'Last Name is required',
    hurigana_first_name_required: 'Hurigana First Name is required',
    hurigana_last_name_required: 'Hurigana Last Name is required',
    topping_required: 'Topping is required',
    from_store_required: 'From Store is required',
    to_store_required: 'To Store is required',
    type_required: 'Type is required',
    slip_number_required: 'Slip Number is required',
    product_required: 'Product is required',
    quantity_required: 'Quantity is required',
    sending_time: 'Sending time is required',
    account_type_uuid_required: 'Account Type is required',
    title_required: 'Title is required',
    content_required: 'Content is required',
    image: 'Image is required',
    gallery: 'Gallery is required',
    sub_category: 'Sub category is required',
    upload_file_required: 'Upload file is required',
    display_type_required: 'Display type is required',
    app_required: 'App is required',
    kds_required: 'Kds is required',
    dispatch_required: 'Dispatch is required',
    taker_required: 'Taker is required',
    takeaway_monitor_required: 'TakeAway Monitor is required',
    prices: 'Prices is required',
    height: 'Height is required',
    length: 'Length is required',
    weight: 'Weight is required',
    width: 'Width is required',
    options: 'Options is required',
    radius: 'Radius is required',
    postal_code: 'Postal code is required',
    origin_point: 'Origin point is required',
    destination_point: 'Destination point is required',
    slot_per_hour: 'Slot per hour is required',
    driver_trip_per_hour: 'Driver trip per hour is required',
    user_required: 'User is required',
    current_password: 'Please input current password',
    promo_type: 'Promo type is required',
    discount_value: 'Discount value is required',
    required: 'Required',
    method_required: 'Method is required',
    distribution_date_required: 'Distribution date is required',
    distributor_required: 'Distributor is required',
    commission_rate: 'Commission rate is required',
    status: 'Status is required',
    business_name: 'Business name is required',
    brand_name: 'Brand name is required',
    business_number: 'Business number',
    phone: 'Phone is required',
    display_phone: 'Display phone is required',
    account_name: 'Account name is required',
    account_number: 'Account number is required',
    unit: 'Unit is required',
    city: 'City is required',
    currency: 'Currency is required',
    postcode: 'Postcode is required',
    street: 'Street is required',
    state: 'State is required',
    type: 'Type is required',
    start_time: 'Start time is required',
    end_time: 'End time is required'
  },
  payment_method: {
    bank: 'Bank',
    is_show_at_menu_order: 'Is show at menu',
    is_show_pos: 'Is Show Pos',
    is_card_number: 'Is card number',
    is_remark: 'Is remark',
    is_approval_code: 'Is approval code',
    is_active: 'Is active',
    group: 'Group',
    level1: 'Level 1',
    payment_method_detail: 'Payment Method Detail',
    no_payment_method: 'No Payment Method',
    add_payment_method: 'Add Payment Method',
    is_change: 'Is Change',
    cash_count: 'Show in Cash Count',
    input_cash_count: 'Input Cash Count',
    is_physical_voucher: 'Physical Voucher',
    order_type: 'Order type'
  },
  component: {
    component_detail: 'Component Details',
    component_code: 'Component Code',
    component_name: 'Component Name',
    component_short_name: 'Short Name',
    component_status: 'Status',
    size_and_material: 'Size and Materials',
    quantity: 'QTY',
    price: 'price',
    material_name: 'Material Name',
    s: 'S',
    m: 'M',
    l: 'L',
    unit_name: 'Unit Name',
    add_new: 'Add New'
  },
  order: {
    no: 'No',
    del_vs_quote_time: 'SOS<30',
    order_confirm_csc: 'Confirm CSC',
    order_send_store: 'SenT to Store',
    store_accept_order: 'Ordered',
    cooking: 'Cooking',
    start_delivery: 'Start Delivery',
    swipe_done: 'Swipe Done',
    encashment: 'Encashment',
    finish: 'Finished Time',
    void: 'Driver Tracker Status',
    dispatch: 'Dispatch',
    cooked: 'Cooked',
    driver_tracker: 'Driver Tracker Status',
    id: 'ID',
    code: 'Order Code',
    quote_time: 'Quote Time	',
    type: 'Segment',
    status: 'Order Status',
    date: 'Order Date',
    start_date: 'Start Date',
    end_date: 'End Date',
    sale_amount: 'Sale Amount',
    sub_total: 'Sub Total',
    sub_delivery_fee: 'Sub Delivery Fee',
    address_delivery: 'Address Delivery',
    tax_value: 'Tax Value',
    donation: 'Donation',
    action: 'Action',
    payment_method: 'Payment Method',
    payment_amount: 'Payment Amount',
    variance_status: 'Variance Status',
    view_order: 'View Order',
    edit_order: 'Edit Order',
    view_payment: 'View Payment',
    view_order_detail: 'View Order Detail',
    view_utilization_detail: 'View Utilization Detail',
    view_delivery: 'View Delivery',
    view_log_status: 'View Log Status',
    pulcode: 'Pulcode',
    price: 'Price',
    sub_price: 'Sub Price',
    menu_name: 'Menu Name',
    menu_price: 'Menu Price',
    add_on_price: 'Add-on Price',
    value: 'Value',
    change: 'Change',
    total_payment: 'Total Payment',
    new_status: 'New Status',
    reason: 'Reason',
    device: 'Channel',
    type_and_device: 'Type/Channel',
    timestamp: 'Timestamp',
    passed: 'Passed',
    variance_payment: 'Variance Payment',
    order_number: 'Order Number',
    channel: 'Channel',
    on_process_by: 'On Process By',
    driver_id: 'Driver Code',
    order_type: 'Segment',
    order_date: 'Order Date',
    kds_time: 'Kds Time',
    cooked_time: 'Cooked Time',
    delivery_start_time: 'Delivery Time',
    customer_recieved: 'Receive Time',
    encashment_time: 'Encashment Time',
    sos_achived: 'SOS',
    upper_text: 'Upper Text',
    hide_upper_text: 'Hide Upper Text',
    show_upper_text: 'Show Upper Text',
    choose_agent: 'Choose Agent',
    confirm_by_agent: 'Confirmed By',
    time_order: 'Order Time',
    user_type_guest: 'Guest',
    user_type_member: 'Member',
    reject_status: 'Reject Reason',
    confirm_status: 'SMS Sent',
    duration_time: 'Duration Time',
    user_type: 'User Type',
    keyword: 'Keyword',
    current_point: 'Current Point',
    earn_point: 'Earn Point',
    redeemed_point: 'Redeemed Point',
    request: 'Request',
    detail_Address: 'Address and Map',
    past_order_history: 'Past Order History',
    detail: 'Detail',
    advance: 'Advance',
    // order_type: 'Order Type',
    add_order_type: 'Add Order Type',
    no_order_type: 'No Order Type',
    order_status: 'Order Status',
    add_order_status: 'Add Order Status',
    no_order_status: 'No Order Status',
    delivering_time: 'Delivering Time',
    delivered_time: 'Delivered Time',
    note: 'Note',
    adjust_status_delivery: 'Adjust Status Delivery',
    status_delivery: 'Status Delivery',
    ready_to_ship: 'Ready To Ship',
    tracking_url: 'Track Order >',
    phone: 'Phone Number',
    promise_date: 'Takeaway/Delivery Date',
    order_no: 'Order No',
    packing_slip: 'Packing Slip',
    invoice: 'Invoice',
    invoice_and_payslip: 'Packing Slip And Invoice',
    consignment_note: 'Consignment Note',
    invoice_date: 'Invoice Date',
    invoice_no: 'Invoice No',
    payment_type: 'Payment Type',
    shipping_address: 'Shipping Address',
    billing_address: 'Billing Address',
    item_description: 'Item Description',
    unit_price: 'Unit Price',
    discount: 'Discount',
    total: 'Total',
    store_status: 'Store status',
    is_invoice: 'Is Invoice',
    tracking_number: 'Tracking Number',
    toppings: 'Toppings',
    password_strength: 'Input Password and Submit [8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character - !@#$%&*]',
    address_level_1_required: 'House No is required',
    address_level_2_required: 'Street is required',
    address_level_3_required: 'City is required',
    address_level_4_required: 'State is required',
    max_length: '{attr} should not exceed {value} characters',
    food_name: 'Food name',
    without_emoji: '{attr} should be without emoji',
    slug: 'Slug is required'
  },
  sku: {
    sku: 'Sku',
    sku_header: 'Sku Header',
    brand_uuid: 'Brand',
    category_uuid: 'Sku Category',
    food_id: 'Food Id'
  },
  menu: {
    topping: 'Topping',
    product: 'Product',
    toppings: 'Toppings',
    products: 'Products',
    category: 'Category',
    option_group: 'Crust',
    option: 'Size',
    option_groups: 'Crusts',
    options: 'Sizes',
    select_option: 'Select Option',
    add_category: 'Add a category now!',
    no_category: 'No category added yet.',
    category_detail: 'category Details',
    no_option_group: 'No crust added yet.',
    add_option_group: 'Add a crust now!',
    add_comment: 'Add a Comment now!',
    no_comment: 'No Comment added yet.',
    add_banner: 'Add a Banner now!',
    no_banner: 'No Banner added yet.',
    no_option: 'No size added yet.',
    add_option: 'Add a size now!',
    add_origin: 'Add a origin now!',
    no_origin: 'No origin added yet',
    no_order: 'No order added yet',
    add_tag: 'Add a tag now!',
    no_tag: 'No tag added yet',
    add_type: 'Add a type now!',
    no_type: 'No type added yet',
    add_country: 'Add a country now!',
    no_country: 'No country added yet',
    add_group_tag: 'Add a group tag now!',
    no_group_tag: 'No group tag added yet',
    no_group_type: 'No group type added yet',
    add_group_type: 'Add a group type now!',
    add_inventory: 'Add a inventory now!',
    no_inventory: 'No inventory added yet',
    add_doctor: 'Add a Doctor now!',
    no_doctor: 'No Doctor added yet',
    vailable_choices: 'Vailable Choises',
    your_menu: 'Your Menu',
    is_show_website: 'Is Show Website',
    no_product_half_and_half: 'No product half and half',
    add_new: 'Add New',
    set_quantity: 'Set Quantity',
    add_combo_group: 'Add a combo group now!',
    no_combo_group: 'No combo group added yet',
    add_product: 'Add a product now!',
    no_product: 'No product added yet',
    add_brand: 'Add a brand now!',
    no_brand: 'No brand added yet',
    package_information: 'Package information'
  },
  product: {
    other_info: 'Advance Info',
    attachment_product: 'Attachment Product',
    product_name: 'Product Name',
    menu_code: 'Menu Code',
    default_topping: 'Default Topping',
    product_topping: 'Product Topping',
    topping: 'Topping',
    max_default_topping: 'Max default topping',
    max_additional_topping: 'Max additional topping',
    add_crust_size: 'Add Crust Size',
    auto_cook: 'Auto Cook',
    slug: 'Slug',
    target: 'Target',
    url: 'URL',
    hide_from_ui: 'Hide From UI',
    half_and_half: 'Half And Half',
    discount: 'Discount',
    only_lunch: 'Only Lunch',
    hut_day: "Hut's Day",
    video: 'Video',
    normal: 'Normal',
    pizza: 'Pizza',
    combo: 'Combo',
    special: 'Special',
    doctor_appointment: 'Doctor Appointment',
    addon: 'Addon',
    donation: 'Donation',
    extra_chilli: 'Extra Chilli',
    select_add_into_group: 'SELECT ADD INTO GROUP',
    product_detail: 'Product detail',
    maximum_additional_topping: 'Maximum Additional Topping',
    maximum_minus_topping: 'Maximum Minus Topping',
    draft: 'Draft',
    forApproval: 'For Approval',
    pulished: 'Published',
    marketing_price: 'Marketing Price',
    kds_make: 'Kds Make',
    kds_cut: 'Kds Cut',
    make_time: 'Make Time',
    cut_time: 'Cut Time',
    depositionAndPricing: 'Deposition & Pricing',
    chooseCategoryType: 'Choose category type',
    basicInfoTitle: 'Basic info',
    isAlcohol: 'Serves Alcohol',
    isAutoCook: 'Auto Cook',
    storeInfoTitle: 'Store info',
    orderInfo: 'Order info',
    typeTitle: 'Type',
    taxTitle: 'Tax',
    emptyTypeMessage: 'Please select at least one type.',
    gallery: 'Gallery',
    toppingTitle: 'Topping',
    channel: 'Channel',
    tier: 'Tier',
    partner: 'Partner',
    priceWithoutTax: 'Price Without Tax',
    addMore: 'Add more',
    image: 'Image',
    thumbnail: 'Thumbnail',
    how_to_make: 'How To Make',
    priceWithTax: 'Price With Tax',
    is_menu_point_free_delivery: 'Is Menu Point Free Delivery',
    is_flag_driver: 'Is Flag Driver',
    size_and_crust: 'Size And Crust'
  },
  content: {
    content_category: 'Content Category',
    slug: 'Slug',
    target: 'Target',
    url: 'URL',
    no_content: 'No content added yet!'
  },
  store_target: {
    store_code: 'Store Code',
    store_name: 'Store Name',
    target_sale: 'Target Sale',
    rate: 'Rate',
    result: 'Result',
    month: 'Month'
  },
  report: {
    order_type: 'Segment',
    order_status: 'Order Status',
    channel: 'Channel',
    order_number: 'Order Number',
    total_count: 'Total Count',
    total_amount: 'Total Amount',
    activity: 'Activity'
  },
  popup: {
    remove_option: 'Are you sure to remove this option?',
    remove_group: 'Are you sure to remove this group?',
    remove_product: 'Are you sure to remove this product?',
    warning: 'Warning',
    error: 'Error',
    MoneyBagLog: 'Money Bag Log',
    notify: 'Notify',
    export: 'Export Data',
    select_date: 'Select date range',
    copy_to_clip: 'Copy to clipboard success'
  },
  err: {
    submit: 'error submit!!',
    checkQRCodeUser: 'One store only have one User belong QR'
  },
  defined: {
    active: 'Active',
    inactive: 'Inactive',
    all: 'ALL',
    show: 'Show',
    not_show: 'Not Show'
  },
  dialog: {
    detail_event: 'Detail Event'
  },
  tooltip: {
    capslock: 'Caps lock is On'
  },
  clipboard: {
    please_input: 'Please input',
    use_clipboard_by_v_directive: 'use clipboard by v-directive',
    use_clipboard_directly: 'use clipboard directly'
  },
  promotion: {
    promotion_master: 'Promotion Master',
    promotion: 'Promotion',
    discount_type: 'Discount Type',
    discount_value: 'Discount Value',
    auto_apply: 'Auto apply',
    multiple: 'Multiple',
    include_stores: 'Include Stores',
    exclude_stores: 'Exclude Stores',
    limit: 'Limit',
    total_use: 'Total Use',
    min_purchase: 'Min Purchase',
    category_uuid: 'Categorie',
    coupon: 'Coupon',
    user_available: 'User available',
    promotion_user: 'Promotion User',
    is_use: 'Is Use',
    basic_details: 'Basic Details',
    active_period: 'Active period',
    time_setting: 'Time settings',
    condition: 'Condition',
    product: 'Product',
    number_of_order: 'Number of order',
    no_coupon: 'No coupons added yet.',
    add_coupon: 'Add a coupon now!',
    upload_user: 'Upload User',
    upload_file_csv: 'Upload file csv',
    customer_name: 'Customer name',
    child_promotion_master: 'Child Promotion Master',
    public: 'Public',
    platform: 'Platform',
    expired_day: 'Expired Day',
    generate_coupon: 'Generate coupon',
    no_promotion_master: 'No Promotion Master',
    add_promotion_master: 'Add Promotion Master',
    exclude_store: 'Exclude Store',
    include_store: 'Include Store',
    no_promotion: 'No Promotion',
    add_promotion: 'Add Promotion',
    auto_give: 'Auto give',
    adjust_status: 'Adjust Status',
    is_one_time: 'Is one time',
    is_for_employee: 'Is for employee',
    limit_value: 'Limit VaLue',
    prefix_apply_auto: 'Prefix Apply Auto',
    limit_apply_auto: 'Limit Apply Auto'
  },
  doctor: 'Doctor',
  review: 'Review',
  your_browser_does_not_support_video_playback: 'Your browser does not support video playback',
  picker: {
    today: 'Today',
    yesterday: 'Yesterday',
    this_month: 'This month',
    this_year: 'This year',
    last_week: 'Last week',
    last_month: 'Last month'
  },
  store_cash: {
    name: 'Name',
    type_date: 'Type Date',
    store_cash: 'Store Cash',
    driver: 'Driver',
    evidence_number: 'Evidence Number',
    payment_type: 'Payment Type',
    cash_payment: 'Cash Payment',
    bank_payment: 'Bank Payment',
    deposit: 'Deposit',
    withdrawal: 'Withdrawal',
    deposit_amount: 'Deposit Amount',
    withdrawal_amount: 'Withdrawal Amount',
    receipt_date: 'Receipt Date',
    registration_date: 'Registration Date',
    account_type: 'Account Type',
    account_name: 'Account Name',
    add_store_cash: 'Add Store Cash',
    no_store_cash: 'No Store Cash!',
    add_money_bag: 'Add Money Bag',
    no_money_bag: 'No Money Bag!',
    type_store_cash: 'Type Store Cash'
  },
  appointment: {
    appointment: 'Appointment',
    date: 'Date',
    time_slot: 'Time Slot',
    total_slot: 'Total Slot',
    available_slot: 'Available Slot',
    addMore: 'Add more',
    recalculate: 'Recalculate',
    save: 'Save'
  },
  employee: {
    basic_details: 'Basic Details',
    company_name: 'Company Name',
    gender: 'Gender',
    birth_date: 'Birth Date',
    male: 'Male',
    female: 'Female',
    address_contact_details: 'Address & Contact Details',
    zip_code: 'Zip Code',
    mobile_tel_number: 'Mobile Tel Number',
    tel_number: 'Tel Number',
    em_tel_number: 'Emergency Tel Number',
    address1: 'Address 1',
    address2: 'Address 2',
    bank_information: 'Bank Information',
    bank_name: 'Bank Name',
    bank_branch_name: 'Branch Name',
    bank_account_name: 'Account Name',
    bank_account_number: 'Account Number',
    driver_license_information: 'Driver License Information',
    driver_license_number: 'License Number',
    driver_license_expire_date: 'License Expire Date',
    other_information: 'Other Information',
    is_temp_worker: 'Temporary Worker',
    is_provisional_contract: 'Provisional Contract',
    education: 'Education',
    retirement_date: 'Retirement Date',
    occupation: 'Occupation',
    nationality_category: 'Nationality Category',
    visa_status: 'Visa Status',
    alien_certificate_number: 'Residence Card No',
    period_of_stay: 'Period of Stay',
    change_status: 'Change Status',
    blood_type: 'Blood Type',
    note: 'Note',
    contract_title: 'Contract Title',
    contract_period: 'Contract Period',
    position: 'Position',
    rank: 'Rank',
    nationality: 'Nationality',
    mon: 'Monday',
    tue: 'Tuesday',
    wed: 'Wednesday',
    thu: 'Thursday',
    fri: 'Friday',
    sat: 'Saturday',
    sun: 'Sunday',
    employee_information: 'Employee Information',
    salary_and_allowance_details: 'Salary & Allowance',
    base_hourly_pay: 'Base Hourly Pay',
    allowance: 'Allowance',
    overtime: 'Overtime',
    driving: 'Driving',
    holiday: 'Holiday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    commuting: 'Commuting',
    flyer_posting: 'Flyer Posting',
    shift_manager: 'Shift Manager',
    midnight_shift: 'Midnight Shift',
    other: 'Other',
    weekly_working_day: 'Weekly Working Day',
    weekly_working_hour: 'Weekly Working Hour',
    work_time: 'Work Time',
    rest_hour: 'Rest Hour',
    working_hour: 'Working Hour',
    contract: 'Contract',
    period: 'Period',
    em_tel_name: 'Emergency Tel Name',
    bank_details: 'Bank Details',
    driver_license_details: 'Driver License Details',
    additional_information: 'Additional Information',
    visa_information: 'Visa Information',
    contracts: 'Contracts',
    new_contract: 'New Contract',
    accounts: 'Accounts',
    password: 'Password',
    confirm_password: 'Confirm Password',
    roles: 'Roles',
    new_user_login: 'New User Login',
    day: 'Day',
    text_add_employee: 'Add a Employee now!',
    text_no_employee: 'No Employee added yet.',
    upload_driver_license_btn: 'Upload Driver License',
    upload_driver_license_tip: 'Upload photos the front and back of the Driver License',
    contract_form: {
      overtime: 'Overtime',
      driving: 'Driving',
      holiday: 'Holiday',
      saturday: 'Saturday',
      sunday: 'Sunday',
      commuting: 'Commuting',
      flyer_posting: 'Flyer Posting',
      shift_manager: 'Shift Manager',
      midnight_shift: 'Midnight Shift',
      other: 'Other'
    },
    bank_code: 'Bank Code',
    bank_branch_code: 'Branch Code',
    text_add_contract: 'Add a Contract now!',
    text_no_contract: 'No Contract added yet.',
    text_add_user_login: 'Add a User Login now!',
    text_no_user_login: 'No User Login added yet.',
    first_name: 'First Name',
    last_name: 'Last Name',
    hurigana_first_name: 'Hurigana First Name',
    hurigana_last_name: 'Hurigana Last Name',
    is_retired: 'Is Retired',
    age: 'Age'
  },
  inventory: {
    not_confirmed: 'Not Confirmed',
    all: 'All',
    name: 'Name',
    transfer: 'Transfer',
    action: 'Action',
    category_detail: 'Category Details',
    no_category_added_yet: 'No category added yet',
    add_category_now: 'Add a category now!',
    unit_detail: 'Unit Details',
    no_unit: 'No unit added yet.',
    add_unit: 'Add a product now!',
    basic_details: 'Basic Details',
    option_and_option_group: 'Size And Crust',
    add_new: 'Add New',
    add_new_version: 'Add New Version',
    add_version: 'Add Version',
    pricing: 'Pricing',
    option_group: 'Crust',
    option: 'Size',
    version_name: 'Version Name',
    version_code: 'Version Code',
    type: 'Type',
    material_component: 'Material/Component',
    qty: 'Qty',
    cost: 'Cost',
    unit: 'Unit',
    add_item: 'Add Item',
    text_add_item: 'Add a Item now!',
    text_no_item: 'No Item added yet.',
    no_material: 'No material added yet.',
    add_material: 'Add a material now!',
    add_component: 'Add Component',
    material: 'Material',
    option_and_material: 'Size And Material',
    text_add_component: 'Add a Component now!',
    text_no_component: 'No Component added yet.',
    component: 'Component',
    item: 'Item',
    total: 'Total',
    normal_product: 'Normal Product',
    add_po: 'Add Purchase Order',
    no_po: 'No Purchase Order yet.',
    add_good_reciept: 'Add Good Reciept',
    no_good_reciept: 'No Good Reciept yet.',
    line_nummber: 'Line Number',
    date_of_good_reciept: 'Date of Good Reciept',
    good_reciept_detail: 'Good Reciept Detail',
    purchase_order_detail: 'Purchase Order Detail',
    slip_number: 'Slip Number',
    from_store: 'From Store',
    to_store: 'To Store',
    pending: 'Pending',
    confirmed: 'Confirmed',
    price: 'Price',
    amount: 'Amount',
    text_add_transfer: 'Add a Transfer now!',
    text_no_transfer: 'No Transfer added yet.',
    current_stock: 'Current Stock',
    last_inventory: 'Last Inventory',
    warehousing: 'Warehousing',
    eod_warehousing: 'EOD Warehousing',
    current_quantity: 'Current Quantity',
    actual_use: 'Actual Use',
    eod_actual_use: 'EOD Actual Use',
    unit_price: 'Unit Price',
    stock_value: 'Stock Value',
    actual_usage_amount: 'Actual Usage Amount',
    text_add_foodWaste: 'Add a Food Waste now!',
    text_no_foodWaste: 'No Food Waste added yet.'
  },
  currency: {
    currencies: 'Currencies',
    currency: 'Currency',
    default: 'Default',
    rate: 'Rate'
  },
  placeholder: {
    date: 'YYYY-MM-DD',
    zipcode: '0000',
    phone: '+0(000)000-00-00',
    hour: '00:00',
    select: 'Select',
    na: 'N/A'
  },
  campaign: {
    add_campaign: 'Add Campaign',
    no_campaign: 'No Campaign'
  },
  notify: {
    add_notify: 'Add Notify',
    no_notify: 'No Notify',
    totalSuccess: 'Total Success',
    totalFalse: 'Total False',
    success: 'Success',
    false: 'False'
  },
  type: {
    add_type: 'Add Type',
    no_type: 'No Type',
    totalSuccess: 'Total Success',
    totalFalse: 'Total False'
  },
  master: {
    text_add_nationality: 'Add a Nationality now!',
    text_no_nationality: 'No Nationality added yet.',
    text_add_nationalityCategory: 'Add a Nationality Category now!',
    text_no_nationalityCategory: 'No Nationality Category added yet.',
    text_add_rank: 'Add a Rank now!',
    text_no_rank: 'No Rank added yet.',
    text_add_position: 'Add a Position now!',
    text_no_position: 'No Position added yet.',
    text_add_occupation: 'Add a Occupation now!',
    text_no_occupation: 'No Occupation added yet.',
    text_add_changeStatus: 'Add a Change Status now!',
    text_no_changeStatus: 'No Change Status added yet.',
    text_add_visaStatus: 'Add a Visa Status now!',
    text_no_visaStatus: 'No Visa Status added yet.',
    text_add_bloodType: 'Add a Blood Type now!',
    text_no_bloodType: 'No Blood Type added yet.'
  },
  system: {
    basic_details: 'Basic Details',
    app_version: 'App Version',
    currencies: 'Currencies',
    currency: 'Currency',
    currency_unit: 'Currency Unit',
    default: 'Default',
    rate: 'Rate',
    configuration: 'Configuration',
    text_no_client: 'No Client added yet.',
    text_add_client: 'Add a Client now!',
    text_no_config: 'No Config added yet.',
    text_add_config: 'Add a Config now!',
    key_value: 'Key - Value',
    text_no_partner: 'No Partner added yet.',
    text_add_partner: 'Add a Partner now!',
    text_no_printer: 'No Printer added yet.',
    text_add_printer: 'Add a Printer now!',
    text_no_uiConfiguration: 'No UI Configuration added yet.',
    text_add_uiConfiguration: 'Add a UI Configuration now!',
    text_no_currency: 'No Currency added yet.',
    text_add_currency: 'Add a Currency now!',
    text_no_shift: 'No Shift added yet.',
    send_notify: 'Send notify',
    sending_time: 'Sending time',
    features: 'Features',
    features_cms: 'Features CMS',
    features_order: 'Features Order',
    cms_menu_detail: 'CMS Detail',
    auto_create_menu_version: 'Auto Create Menu Version',
    cms_update_waiting_time_fcds: 'CMS Update Fcds',
    cms_enable_input_code: 'CMS Enable Input Code',
    dynamodb_qrcode: 'Dynamodb Qrcode',
    config: 'Config',
    partner: 'Partner',
    cms_sync: 'CMS Sync',
    analytic_sync: 'Analytic Sync',
    sync_local_pos: 'Sync Local POS',
    send_order_confirm_email: 'Send Order Confirm Email',
    send_online_order_to_mobile: 'Send Online Order To Mobile',
    send_ges_email: 'Send GES Email',
    push_ges_email: 'Push GES Email',
    text_no_data: 'No data',
    number_of_decimal: 'Number Of System'
  },
  money_bag_log: {
    driver: 'Driver',
    money_bag: 'Money Bag',
    created_at: 'Created Date',
    active: 'Active',
    disable: 'Disable'
  },
  eod: {
    eod: 'End of Date',
    working_times: 'Working times',
    step: 'Step',
    check_eod: 'Check EOD',
    Warning: 'Warning',
    Confirmed: 'Confirmed',
    input_quantity: 'Input Quantity',
    quantity_store: 'Quantity Store',
    last_quantity_store: 'Last Quantity',
    sales_info: 'Sales Information',
    actual: 'Actual balalance',
    excess_deficiency: 'Excess And Deficiency',
    foh_closing_amount: 'FOH Closing Amount',
    amount_in_safe: 'Amount In Safe',
    accounts_receivable: 'Accounts Receivable',
    sales_info_money: 'Sales Info Money',
    number_sheets: 'Number Sheets',
    amount_of_money: 'Amount Of Money',
    shareholder_complimentary_coupon: 'Shareholder Complimentary Coupon',
    cash_voucher_change: 'Cash Voucher Change',
    cash_voucher_without_change: 'Cash Voucher Without Change',
    point: 'Point',
    commission: ' Commission',
    order_desposit: 'Order Desposit',
    gross_sales_amount: 'Gross Sales Amount',
    total_sales_promotion: 'Total Sales Promotion',
    employee_discount: 'Employee Discount',
    net_sales_amount: 'Net Sales Amount',
    accounts_generate_receivable: 'Accounts Generate Receivable',
    cash_voucher_total: 'Cash Voucher Total',
    other_deposit_total: 'Other Deposit Total',
    refund: 'Refund',
    gourment_card: 'Gourment Card',
    headquarters_remittance: 'Headquarters Remittance',
    scheduled_bank_deposit: 'Scheduled Bank Deposit',
    bank_deposit_amount: 'Bank Deposit Amount',
    excess_bank_deposit: 'Excess Bank Deposit',
    petty_information: 'Petty Information',
    cumulative_cash_shortage: 'Cumulative Cash Shortage',
    bank_book: 'Bank Book',
    store_cash: 'Store Cash',
    small_cash: 'Small Cash',
    safe_drawer_change: 'Safe Drawer Change',
    pouch_change: 'Pouch Change',
    total: 'Total',
    cumulative_amount: 'Cumulative Amount',
    reason_for_excess_or_deficiency: 'Reason For Excess Or Deficiency',
    change: 'Change',
    sales: 'Sales',
    pretty_cash: 'Pretty Cash',
    next_day_used_pouch: 'Next Day Used Pouch',
    bank_account_excess: 'Bank Account Excess',
    no: 'No',
    use: 'Use',
    drawer_change: 'Drawer Change',
    next_day_drawer_pouch_change: 'Next Day Drawer Pouch Change',
    next_day_bank_change_balance: 'Next Day Bank Change Balance',
    balance_change_safe: 'Balance Change Safe',
    balance_pouch_change_safe: 'Balance Pouch Change Safe',
    store_code: 'Store code',
    employee_code: 'Labor code',
    name: 'Name',
    confirm_item: '確認項目',
    full_part_time: 'Fulltime/Crew',
    rank: 'Rank',
    under_18: 'Under 18',
    number_of_attendance: 'Number of attendance',
    check_in: 'Checkin',
    checkin_hour: 'Checking hour',
    checkout_hour: 'Checkout hour',
    start_work: 'Start work',
    end_work: 'End work',
    working_content: 'Work content',
    working_time: 'Work time',
    break_time: 'Break time',
    noted: 'Noted',
    confirmed: 'Confirmed',
    time_update: 'Time Update',
    is_off: 'Day Off',
    work_division: 'Work Division',
    addMoreWorkingTime: 'Add More Working Time',
    addMoreCheckin: 'Add More Checkin',
    role_time: 'Role Time',
    is_sent_to_s3: 'Sent to s3',
    start_time: 'Start Time',
    end_time: 'End Time',
    resend: 'Re-send',
    need_to_verify: 'Some items need to verify',
    note_need_to_verify: '* Below, items whose status is not ✔ need to be retransmitted because data linkage failed.',
    finish_good_reciept: 'Confirm all good reciept.',
    finish_close_shift: 'Close all shift.',
    finish_order: 'Finish all orders first.',
    finish_money_bag: 'Release all money bags first.',
    finish_driver: 'Release all drivers first.',
    finish_money_closure: 'Confirm money closure.',
    finish_labour_closure: 'Confirm labour closure.',
    finish_inventory_closure: 'Confirm inventory closure.',
    completed_end_of_date: 'Completed EOD',
    is_eod: 'Store EOD success',
    have_data: 'Have Data EOD',
    more_info: 'More infomation',
    weather: 'Weather'
  },
  weather: {
    sunny: 'Sunny',
    cloudy: 'Cloudy',
    rainy: 'Rainy',
    snow: 'Snow'
  },
  payment_method_level1: {
    payment_method_level1_detail: 'Payment Method Level 1 detail',
    no_payment_method_level1: 'No Payment Method Level 1',
    add_payment_method_level1: 'Add Payment Method Level 1'
  },
  summary_tracker: {
    no: 'No',
    outlet_name: 'Outlet Name',
    outlet_code: 'Outlet Code',
    total_online_order_delivery: 'Total Order',
    total_order_swiped: 'Total Order Swiped',
    driver_tracker_usage_percent: 'Usage (%)',
    sos_gt_30: 'SOS > 30',
    sos_gt_30_percent: 'SOS > 30 (%)',
    sos_lt_30: 'SOS < 30',
    sos_lt_30_percent: 'SOS < 30 (%)',
    all_store: 'All Store'
  },
  store_target_v2: {
    store_code: 'CODE',
    store_name: 'STORE NAME',
    store_group: 'STORE_GROUP',
    daily_target: 'DAILY TARGET',
    monthly_target: 'MONTHLY TARGET',
    filter_area_title: 'FILTER BY',
    store_group_filter_label: 'Store Group',
    store_name_filter_label: 'Store Name',
    business_date_filter_label: 'Bussines Date',
    checkbox_daiily_target_label: 'Daily Target',
    export_button_label: 'Export',
    upload_button_label: 'Upload CSV',
    dialog_expot_title_label: 'Export',
    export_option_today: 'Today',
    export_option_last7days: 'Last 7 day',
    export_option_this_month: 'This Month',
    export_option_this_year: 'This Year',
    export_option_custom_date: 'Custom Date',
    btn_cancel_export_popup: 'Cancel',
    btn_ok_export_popup: 'Ok',
    btn_cancel_upload: 'Cancel',
    business_date: 'BUSINESS DATE'
  },
  sale_history: {
    filter_title: 'FILTER BY',
    store_group_lb: 'Store Group',
    store_type_lb: 'Store Type',
    store_code_lb: 'Store Code',
    input_store_code_placeholder: 'Enter store code',
    date_select_placeholder: 'Pick a day',
    total_sale_by_chanel_title: 'TOTAL SALES BY CHANNELS',
    total_bill_by_channel_title: 'TOTAL BILL BY CHANNELS',
    sos_title: 'SOS',
    fresh_and_hot_title: 'FRESH & HOT',
    sos_delivery: 'DELIVERY',
    sos_take_away: 'TAKE AWAY',
    hot_fresh_delivery: 'DELIVERY',
    hot_fresh_take_away: 'TAKE AWAY',
    date_lb: 'Date',
    btn_apply: 'Apply'
  },
  product_mix_v2: {
    filter_area_title: 'FILTER BY',
    store_group_filter_label: 'Store Group',
    store_type_filter_label: 'Store Type',
    product_code_table_header: 'PRODUCT CODE',
    store_group_table_header: 'STORE GROUP',
    store_type_table_header: 'STORE TYPE',
    product_name_table_header: 'PRODUCT NAME',
    quantity_table_header: 'QUANTITY',
    button_add: 'ADD NEW',
    export_button_label: 'EXPORT',
    dialog_expot_title_label: 'Export Option',
    export_option_today: 'Today',
    export_option_last7days: 'Last 7 days',
    export_option_this_month: 'This Month',
    export_option_this_year: 'This year',
    export_option_custom_date: 'Custom date',
    dialog_button_cancel: 'Cancel',
    dialog_button_ok: 'OK',
    message_error: 'Something when wrong!',
    report_inventory: 'Report Inventory',
    store_health: 'Store Healthy',
    store_common: 'Store Common',
    store_dashboard: 'Store Dashboard',
    report_food_cost: 'Report Food Cost',
    report_order_detail: 'Report Order Detail',
    labour_cost: 'Labour Cost'
  },
  today_sales: {
    filter_title: 'FILTER',
    store_group_lb: 'Store Group',
    store_name_lb: 'Store Name',
    placeholder_store_name: 'Input store name.',
    sales_title: 'SALES',
    placeholder_date: 'Pick a day',
    lb_total_sales: 'Total Sales',
    lb_total_bill: 'Total Bill',
    lb_avg_bill: 'Average Bill',
    lb_sos: 'SOS',
    lb_fresh_hot: 'Fresh & Hot',
    target_stat_title: 'TARTGET STATS',
    daily_lb: 'Daily Target',
    monthly_lb: 'Monthly Target',
    sale_by_target_title: 'SALE BY TARGET',
    sale_by_type_title: 'SALE BY TYPE',
    bill_by_channel_title: 'BILL BY CHANNEL',
    bill_by_type: 'BILL BY TYPE',
    delivery_key: 'Delivery',
    take_away_key: 'Take Away',
    dine_in_key: 'Dine In',
    store_code_lb: 'Store Code',
    placeholder_store_code: 'Input Store Code',
    popover_current_daily_lb: 'Current',
    popover_target_daily_lb: 'Target',
    popover_current_monthly_lb: 'Current',
    popover_target_monthly_lb: 'Target',
    btn_apply: 'Apply'
  },
  order_device: {
    add_order_device: 'Add Order Device',
    order_device: 'Order Device',
    no_order_device: 'No Order Device'
  },
  clear_data: {
    driver_location: 'Driver Location',
    driver_log: 'Driver Log',
    driver_start_log: 'Driver Start Log',
    driver_swipe_done_log: 'Driver',
    money_bag_log: 'Money Bag Log',
    money_bag_tracking: 'Money Bag Tracking',
    shift_log_open: 'Shift Log Open',
    user: 'User',
    user_checkin: 'User checkin',
    driver: 'Driver',
    money_bag: 'Money Bag',
    user_driver: 'User driver',
    order_deliver: 'Order deliver',
    order: 'Order',
    order_detail: 'Order detail',
    order_promotion: 'Order promotion',
    order_pament: 'Order pament',
    order_status_log: 'Order status log',
    order_detail_log_time: 'Order detail log time',
    order_detail_log_drop: 'Order detail log drop',
    shift: 'Shift',
    shift_transaction: 'Shift transaction',
    user_break_time: 'User break time',
    user_token: 'User Token',
    working_time_closure: 'Working Time Closure',
    user_checkin_closure: 'User Checkin Closure',
    material_stock_report: 'Material Stock Report',
    material_potential_utilization: 'Material Potential Utilization',
    material_current_stock: 'Material Current Stock',
    material_stock_log: 'material Stock Log',
    store_cash: 'Store cash',
    end_of_date: 'End of date',
    eod: 'EOD',
    next_day: 'Next day',
    timesheet: 'Timesheet',
    supplier_product: 'Supplier Product',
    store_daily: 'Store daily',
    menu_mix: 'Menu mix',
    labour_cost_state: 'Labour cost state',
    inventory: 'Inventory',
    in_out_inventory: 'In out inventory',
    employee: 'Employee',
    petty_cash_statement: 'Petty cash statement',
    money_closure: 'Money closure',
    system: 'System',
    catalogue_log_drawer: 'Catalogue Log Drawer',
    material_quantity_log: 'Material Quantity Log',
    kpi: 'KPI'
  },
  message_store: {
    title: 'Message Store',
    title_message: 'Title Message',
    content_message: 'Content Message',
    text_no_store_message: 'No store message',
    text_add_store_message: 'Add store message',
    start_time: 'Start Time',
    end_time: 'End Time',
    store_message: 'Store Message'
  },
  ads_video: {
    upload_horizontal_file: 'Upload Horizontal File',
    upload_vertical_file: 'Upload Vertical File',
    display_type: 'Display Type',
    no_ads_video: 'No Ads Video',
    add_ads_video: 'Add Ads Video'
  },
  app_version: {
    no_app_version: 'No App Version',
    add_app_version: 'Add App Version'
  },
  app_version_manager: {
    no_app_version_manager: 'No App Version Manager',
    add_app_version_manager: 'Add App Version Manager'
  },
  store_health: {
    ft_store_code_lb: 'Store Code',
    ft_date_lb: 'Date',
    ph_select_store: 'Select store code',
    ph_date: 'Select Date',
    btn_current_status: 'Current Status',
    btn_log: 'Log',
    card_filter: 'Filter',
    card_device_status: 'Device Status',
    card_log: 'Log',
    last_update_lb: 'Last Updated',
    col_business_date: 'Business Date',
    col_store: 'Store Code/Name',
    col_device_type: 'Device Type',
    col_status: 'Status',
    col_status_date_time: 'Status Date Time'
  },
  pool: {
    pool: 'Pool',
    code: 'Code',
    name: 'Name',
    description: 'Description',
    lat: 'Lat',
    long: 'Long',
    pool_detail: 'Pool Detail',
    add_pool: 'Add Pool',
    assign_driver: 'Assign Driver',
    reassign_driver: 'ReAssign Driver',
    list_driver: 'List Driver',
    confirm_assign_driver: 'Do you want to assign driver ?',
    confirm_reassign_driver: 'Do you want to reassign driver ?',
    list_order_assign_together: 'List Order Assign Together'
  },
  store_dashboard: {
    total_food_cost: 'Total Food Cost',
    order_cost: 'Order Cost',
    loss_cost: 'Loss Cost',
    labour_cost: 'Labour Cost',
    other_cost: 'Other Cost',
    profit_and_lost: 'Profit And Lost',
    store_dashboard: 'Store Dashboard',
    sale_by_channel: 'Sale By Channel',
    sale_by_device: 'Sale By Device',
    sale_by_type: 'Sale By Type',
    store: 'Store',
    store_group: 'Store Group',
    lb_total_sales: 'Total Sales',
    lb_total_bill: 'Total Bills',
    lb_avg_bill: 'Avg',
    lb_sos: 'SOS',
    lb_fresh_hot: 'F&H',
    online: 'Online',
    offline: 'Offline',
    call_center: 'Call center',
    grab: 'Grab',
    gojek: 'Gojek',
    delivery: 'Delivery',
    take_away: 'Take Away',
    dine_in: 'Dine In',
    total_delivery: 'Total Delivery',
    total_take_away: 'Total Take Away',
    total_dine_in: 'Total Dine In',
    pos: 'Pos'
  },
  food_cost: {
    date: 'date',
    food_cost: 'Food Cost',
    rate_with_revenue: '% of revenue',
    food_cost_report: 'Food Cost Report',
    revenue: 'Revenue'
  },
  labour_cost: {
    date: 'Date',
    labour_cost: 'Labour Cost',
    order_sale: 'Total Sale',
    percent: 'Percent',
    no: 'No',
    working_date: 'Working Date',
    day_of_the_week: 'Day of the week',
    attendance: 'Attendance',
    working_time: 'Working Time',
    over_time: 'Over Time',
    mid_night: 'Mid Night',
    allowance: 'allowance',
    labour_cost_total: 'labour cost total',
    basic_salary: 'basic salary',
    overtime_pay: 'overtime pay',
    pos: 'pos',
    driving: 'driving',
    festival: 'festival',
    shift_m: 'shift manager',
    orther: 'orther',
    commuting: 'commuting',
    paid: 'paid'
  },
  fleet: {
    active_from: 'Active from',
    active_to: 'Active to',
    zone_detail: 'Zone details',
    total_order: 'Total Order',
    exception_total_order: 'Exception Total Order',
    total_driver: 'Maximum Total Diver',
    exception_total_driver: 'Maximum Exception Total Driver',
    upload_csv: 'Upload CSV',
    date_exception: 'Date Exception',
    value: 'Value',
    zone_name: 'Full Name',
    country_name: 'Country',
    zone_map: 'Zone',
    type: 'Delivery Zone By',
    zone_code: 'Zone code',
    radius_distance: 'Radius distance (m)',
    radius: 'Radius',
    postal_code: 'Postal code',
    code: 'Code',
    no_zone: 'No zone added yet.',
    add_zone: 'Add a zone now!',
    btn_add_zone: 'Add Zone',
    origin_point: 'Origin Point',
    destination_point: 'Destination Point',
    origin: 'Origin',
    destination: 'Destination',
    no_matrix: 'No matrix added yet.',
    add_matrix: 'Add a matrix now!',
    matrix_detail: 'Matrix details',
    day_of_week: {
      mon: 'Mon',
      tue: 'Tue',
      wed: 'Wed',
      thu: 'Thu',
      fri: 'Fri',
      sat: 'Sat',
      sun: 'Sun'
    },
    validate: {
      time_from_required: 'Time from is required',
      time_to_required: 'Time to is required',
      driver_config_required: 'Driver number is required',
      client_uuid_required: 'Client is required',
      exception_date_required: 'Date is required',
      zone_uuid_required: 'Zone is required'
    },
    view_exception: 'View Exception',
    date_from: 'Date from',
    date_to: 'Date to',
    time_from: 'Time from',
    time_to: 'Time to',
    number_of_driver: 'Number of driver',
    add_time: 'Add time',
    remove_time: 'Remove time',
    download_example_template: 'Download Example Template',
    destination_zones: 'Destination Zones',
    max_distance: 'Max distance',
    distance: 'Distance',
    max_weight: 'Max weight',
    earliest_time_slot: 'Earliest delivery time slot',
    is_time_slot_delivery: 'Time slot delivery',
    delivery_fee: 'Delivery fee',
    delivery_sla: 'Delivery SLA',
    min_distance: 'Min distance'
  },
  sale_digital_channel: {
    total_bill: 'Total Bill',
    total_sales: 'Total Sales (IDR)',
    average_bill: 'Average Bill (IDR)'
  },
  food_store_category: {
    assigned_store: 'Assigned Stores',
    number_item: 'No. of Items',
    menu_category: 'Menu Categories',
    food_image: 'Food Image',
    food_name: 'Food Name',
    qty_available: 'Qty available',
    menu_category_detail: 'Menu Category Details',
    food_listing: 'Food Listing',
    items: 'Items',
    update_product: 'Do you want to update product?'
  },
  subscription_plan: {
    basic_details: 'Basic details'
  },
  store_subscription_plan: {
    order_left: 'Order left',
    renewed_on: 'Renewed on',
    expires_on: 'Expires on',
    view: 'View',
    renew: 'Renew',
    get_this_plan: 'Get this plan',
    new: 'New',
    expires: 'Expires'
  },
  account: {
    account_details: 'Account detail',
    company_name: 'Company name',
    business_name: 'Business name',
    email: 'Email',
    phone_number: 'Phone number',
    social_media: 'Social media',
    facebook_url: 'Facebook url',
    instagram_url: 'Instagram url',
    bank_details: 'Bank details',
    bank_account_name: 'Bank account name',
    bank_account_number: 'Bank account number',
    bank: 'Bank',
    assigned_stores: 'Assigned stores',
    date_created: 'Date created',
    current_password: 'Current password',
    password: 'Password',
    change_password: 'Change password',
    link_facebook: 'Link facebook',
    link_instagram: 'Link instagram',
    new_password: 'New password',
    confirm_password: 'Confirm password'
  },
  promotionV3: {
    promo_code: 'Promo code',
    promo_type: 'Promo type',
    stack_promo_code: 'Stack promo code',
    show_from_ui: 'Show promo code on website',
    promo_details: 'Promo details',
    promo_assignment: 'Promo assignment',
    assign_to: 'Assign to',
    assigned_items: 'Assigned items',
    redemption_limit: 'Redemption limit',
    minimum_purchase: 'Minimum purchase',
    percentage: 'Percentage',
    max_discount_value: 'Max discount value',
    assigned_stores: 'Assigned stores',
    assigned_category: 'Assigned category',
    quick_view: 'Quick view',
    redeemed_thus_far: 'Redeemed thus far',
    chargeback_cost: 'Chargeback cost',
    is_per_store: 'Is per store',
    is_auto_redeem: 'Is auto redeem'
  },
  orders: {
    all: 'All orders',
    pre_order: 'Pre-order',
    on_demand: 'On-demand',
    self_pickup: 'Self-pickup',
    restaurant: 'Restaurant filter',
    zone: 'Zone',
    destination_zone: 'Destination Zone',
    calendar: 'Calendar view',
    code: 'Order ID',
    delivery_time: 'Delivery time',
    receipient_detail: 'Receipient details',
    instruction: 'Special instructions',
    order_channel: 'Order channel',
    quick_view: 'Quick view',
    items: 'Items',
    status: 'Status',
    action: 'Action',
    find: 'Finding available riders',
    preparing: 'Preparing',
    pending: 'Pending',
    stand_by: 'Stand-by',
    pick_up: 'Pick-up',
    delivering: 'Delivering',
    delivered: 'Delivered',
    canceled: 'Canceled',
    order_status: 'Order status',
    payment_status: 'Payment status',
    delivery_status: 'Delivery status',
    order_type: 'Order type',
    date_range: 'Date range',
    order_detail: 'Order details',
    food_items: 'Food items',
    quantity: 'Quantity',
    item: 'Item',
    price: 'Price',
    instructions: 'Instructions',
    mobile: 'Mobile number',
    customer_name: 'Customer name',
    receipient_name: 'Receipient name',
    email: 'Email',
    delivery_fee: 'Delivery fee',
    discount: 'Discount',
    discount_code: 'Discount code',
    discount_type: 'Discount type',
    address: 'Address',
    order_time: 'Order time',
    rider_detail: 'Rider Details',
    rider_name: 'Rider name',
    rider_status: 'Pick up',
    estimated_time: 'Estimated delivery time',
    commission_details: 'Commission Details',
    commission_amount: 'Commission Amount',
    commission_rate: 'Commission Percentage',
    recipient_details: 'Recipient details',
    order_date_range: 'Order Date Range',
    delivery_date_range: 'Delivery Date Range',
    sure: 'Are you sure order',
    is_ready: 'is ready',
    is_cancel: 'Do you want to cancel order'
  },
  leafleting: {
    text_no_item: 'No Item added yet.',
    add_item: 'Add Item',
    text_add_item: 'Add a Item now!',
    basic_details: 'Basic details',
    date_posting: 'Date posting',
    distributor: 'Distributor',
    posting_date: 'Posting date',
    target: 'Target',
    result: 'Result',
    method: 'Method',
    type: 'Type',
    distribution_date: 'Distribution Date',
    address1: 'Address 1',
    address2: 'Address 2',
    edit_leafleting: 'Edit leafleting'
  },
  store_notification: {
    start_date: 'Start Date',
    end_date: 'End Date',
    message: 'Notification Message'
  },
  special_request: {
    no_special_request: 'No special request',
    add_special_request: 'Add special request'
  },
  store_address_mapping: {
    address2_code: 'ADD2_CD',
    zipcode: 'ZIPCODE',
    address1_name: 'ADD1_NAME',
    address1_kana: 'ADD1_KANA',
    address1_ryaku: 'ADD1_RYAKU',
    address2_name: 'ADD2_NAME',
    address2_kana: 'ADD2_KANA',
    address2_ryaku: 'ADD2_RYAKU',
    area_ol_flg: 'AREA_OL_FLG',
    area_ol_com: 'AREA_OL_COM',
    web_null_flg: 'WEB_NULL_FLG',
    kanri_setai_cnt: 'KANRI_SETAI_CNT',
    kyaku_cnt: 'KYAKU_CNT',
    kyogo_tenpo_cnt: 'KYOGO_TENPO_CNT',
    dlvtime: 'DLVTIME',
    map_cd: 'MAP_CD',
    ind_name: 'IND_NAME',
    sdata_make_date: 'SDATA_MAKE_DATE',
    tdata_make_date: 'TDATA_MAKE_DATE',
    yuko_post_cnt: 'YUKO_POST_CNT',
    yobi01: 'YOBI01',
    yobi02: 'YOBI02',
    yobi03: 'YOBI03'
  },
  deviation: {
    no_deviation: 'No Deviation',
    order_number: 'Order Number',
    actutal_make_time: 'Actutal Make Time',
    expected_starting_make_time: 'Expected Starting Make Time',
    time_difference: 'Time Difference'
  },
  merchant: {
    business_name: 'Company name',
    brand_name: 'Brand name',
    detail: 'Merchant details',
    country: 'Country',
    contact: 'Contact',
    sign_up: 'Date signed up',
    approved: 'Date approved',
    all: 'All',
    verify: 'Verifed',
    pending: 'Pending verification',
    reject: 'Reject',
    approval_status: 'Business approval status',
    business: 'Business Infomation',
    phone_display: 'Display phone number',
    contact_number: 'Contact number',
    email: 'Email Address',
    commission_rate: 'Commissions rate',
    business_number: 'Business registration number',
    status: 'Status',
    reject_reason: 'Reject reason',
    bank_info: 'Bank Information',
    bank_name: 'Bank account name',
    bank_number: 'Bank account number',
    bank: 'Bank',
    pickup: 'Business registration address',
    unit: 'Unit, Block',
    city: 'City',
    postcode: 'Postcode',
    street: 'Street address',
    state: 'State',
    business_doc: 'Business Document',
    document: 'Document',
    link: 'Link',
    restaurant: 'Restaurant Listing',
    document_type: 'Document Type',
    document_name: 'File Name',
    business_document: 'Business Document',
    view: 'View',
    code: 'Code',
    store_name: 'Store name',
    tag: 'Tags',
    plan: 'Plan',
    contact_detail: 'Contact details',
    agreement_sign_off: 'Merchant Agreement Sign Off',
    delivery_timestamp: 'Delivery Timestamp',
    email_deliveries: 'Email Deliveries'
  },
  history: {
    order_code: 'ID',
    id: 'DB Record ID',
    old_teleport_status_code: 'Last Status',
    new_teleport_status_code: 'Current Status',
    updated_by: 'Updated By',
    created_at: 'Date Modified'
  },
  date_label_output: {
    date_time: 'Datetime',
    availabel_datetime: 'Availabel Datetime',
    discard_datetime: 'Discard Datetime',
    time_limit: 'Time Limit'
  },
  packageInformation: {
    code: 'Code',
    package_name: 'Package name',
    package_type: 'Package type',
    width: 'Width',
    height: 'Height',
    weight: 'Weight',
    length: 'Length'
  },
  order_v2: {
    order_information: 'Order Information',
    order_code: 'Order ID',
    store_name: 'Store Name',
    customer_name: 'Customer Name',
    customer_id: 'Customer ID',
    customer_phone: 'Phone Number',
    customer_email: 'Email Address',
    employee_name: 'Ordered By',
    segment: 'Segment',
    delivery_address: 'Address',
    waiting_time: 'Waiting Time',
    collection_time: 'Collection Time',
    order_time: 'Order Time',
    order_status_code: 'Order Status',
    order_driver_name: 'Driver Name',
    order_detail: 'Product Information',
    order_log_time: 'Order Log Time',
    make_time: 'Start Make Time',
    bake_time: 'Start Bake Time',
    checkout_time: 'Checkout Time',
    start_delivery_time: 'Start Delivery',
    finish_time: 'Collection Time',
    actual: 'ACTUAL',
    expected: 'EXPECTED',
    payment_information: 'Payment Information',
    coupon_used: 'Coupon Used',
    tax_included: 'Tax Included',
    tax_standard: 'Standard Tax Rate Portion',
    tax_reduced: 'Reduced Tax Rate Portion',
    grand_total: 'Grand Total',
    payment_methods: 'Payment Methods',
    by: 'By',
    sub_total: 'Sub Total',
    discount: 'Discount',
    total: 'Total',
    tax_value: 'Tax Value',
    code: 'Code',
    name: 'Name',
    coupon_information: 'Coupon Information',
    remark: 'Remark'
  },
  customer_factory: {
    customer_factory: 'Customer Factory',
    domains: 'Domains',
    full_address: 'Full Address',
    factory_address: 'Factory Address',
    add_customer_factory: 'Add',
    no_customer_factory: 'No Customer Factory'
  },
  ace_cancel: {
    sale_date: 'Sale date',
    day_of_week: 'Day Of Week',
    sales_cancellation: 'Sales cancellation',
    total: 'Total',
    cancel: 'Cancel',
    number_of_time: 'Number of time',
    amount_of_money: 'Amount of money',
    employee: 'Employee',
    non_employee: 'Non employee',
    sales_refund: 'Sales refund'
  },
  sale_payment: {
    sale_daily: 'Sale daily',
    excess_or_deficiency: 'Excess or deficiency',
    store_amount: 'Store amount',
    deposit_type: 'Deposit Type',
    amount_when_eod_before: 'Amount when eod before',
    this_day_balance: 'This day balance',
    cash_variant: 'Cash variant',
    total: 'Total',
    next_day_change_prepare_amount: 'Next day "change" prepare amount',
    money_bag_amount: 'Money bag amount',
    drawer_amount: 'Drawer amount',
    sale: 'Sale',
    date: 'Date',
    close_store_person: 'Close store person',
    net_sales: 'Net sales',
    expected_bank_deposit: 'Expected bank deposit',
    include_tax: 'Include tax',
    consumer: 'Consumer',
    exclude_tax: 'Exclude tax'
  },
  menu_mix: {
    number_of_promo: 'Number Of Promo',
    number_of_sale: 'Number of sale',
    order_ratio: 'Order ratio',
    code: 'Code',
    total_number: 'Total Number',
    product_name: 'Product name',
    product_category_name: 'Product Category Name'
  },
  order_rts: {
    rts_log: 'RTS log',
    request: 'Request',
    response: 'Response',
    order_type: 'Order Type',
    time_order: 'Order Time',
    create_teleport_list: 'Ready to ship many orders',
    code: 'Çode',
    store: 'Merchant',
    item: 'Food Item',
    status: 'Status',
    action: 'Action',
    payload_response: 'Log RTS',
    rts: 'Ready to ship'
  }
};