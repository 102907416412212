import Layout from '@/layout';
import { RouteContainerComponent } from '@/utils';
import CONSTANTS from '@/constants';
var masterDataRouter = {
  path: '/master-data',
  component: Layout,
  redirect: 'noredirect',
  name: 'MasterData',
  meta: {
    title: 'master_data',
    icon: 'component',
    permissions: [CONSTANTS.PERMISSION_NAME.MASTER_DATA.MANAGE]
  },
  children: [{
    path: 'product',
    component: RouteContainerComponent,
    meta: {
      title: 'product'
    },
    children: [{
      path: '',
      component: function component() {
        return import('@/views/masterData/index');
      },
      name: 'Product',
      meta: {
        title: 'product',
        activeMenu: '/master-data/product'
      }
    }]
  }, {
    path: 'product-price',
    component: RouteContainerComponent,
    meta: {
      title: 'product_price'
    },
    children: [{
      path: '',
      component: function component() {
        return import('@/views/productPrice/index');
      },
      name: 'ProductPrice',
      meta: {
        title: 'product_price',
        activeMenu: '/master-data/product-price'
      }
    }]
  }, {
    path: 'lot-number',
    component: RouteContainerComponent,
    meta: {
      title: 'lot_number'
    },
    children: [{
      path: '',
      component: function component() {
        return import('@/views/lotNumber/index');
      },
      name: 'LotNumber',
      meta: {
        title: 'lot_number',
        activeMenu: '/master-data/lot-number'
      }
    }]
  }, {
    path: 'shipped-product',
    component: RouteContainerComponent,
    meta: {
      title: 'shipped_product'
    },
    children: [{
      path: '',
      component: function component() {
        return import('@/views/shippingStatus/index');
      },
      name: 'ShippingStatus',
      meta: {
        title: 'shipping_status',
        activeMenu: '/master-data/shipping_status'
      }
    }]
  }, {
    path: 'history',
    component: RouteContainerComponent,
    meta: {
      title: 'pricing_change_history',
      permissions: [CONSTANTS.PERMISSION_NAME.PRICE_CHANGE_REQUEST.MANAGE]
    },
    children: [{
      path: '',
      component: function component() {
        return import('@/views/pricingChangeHistory/list.vue');
      },
      name: 'PricingChangeHistory',
      meta: {
        title: 'pricing_change_history',
        activeMenu: '/pricing-change/history'
      }
    }]
  }]
};
export default masterDataRouter;