var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "has-logo": _vm.showLogo } },
    [
      _vm.showLogo
        ? _c("logo", { attrs: { collapse: _vm.isCollapse } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-scrollbar",
        { attrs: { "wrap-class": "scrollbar-wrapper" } },
        [
          _c(
            "el-menu",
            {
              attrs: {
                "default-active": _vm.activeMenu,
                collapse: _vm.isCollapse,
                "background-color": _vm.variables.menuBg,
                "text-color": _vm.variables.menuText,
                "active-text-color": _vm.variables.menuActiveText,
                "collapse-transition": false,
                mode: "vertical",
              },
            },
            _vm._l(_vm.permission_routes, function (route) {
              return _c("sidebar-item", {
                key: route.path,
                attrs: { item: route, "base-path": route.path },
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "info-user" }, [
        _c("div", { staticClass: "custom-row" }, [
          _c("div", { staticClass: "custom-row-icon" }, [
            _c("img", {
              staticClass: "user-avatar",
              attrs: { src: _vm.avatar },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "infinite-scroll",
                  rawName: "v-infinite-scroll",
                  value: false,
                  expression: "false",
                },
              ],
              staticClass: "custom-row-user-info",
              staticStyle: { overflow: "auto" },
              attrs: { span: 20 },
            },
            [
              _c("h3", { staticClass: "infinite-list-item" }, [
                _vm._v(_vm._s(_vm.name)),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "infinite-list-item" }, [
                _vm._v(_vm._s(_vm.email)),
              ]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("ul", { staticClass: "action" }, [
        _c("li", { on: { click: _vm.logout } }, [
          _c("i", { staticClass: "el-icon-right" }),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }