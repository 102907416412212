/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout';
import CONSTANTS from '@/constants';
var organizationManagementRouter = {
  path: '/organization-management',
  component: Layout,
  redirect: 'noredirect',
  meta: {
    title: 'organization_management',
    icon: 'peoples',
    permissions: [CONSTANTS.PERMISSION_NAME.ORGANIZATION.MANAGE]
  },
  children: [{
    path: '',
    component: function component() {
      return import('@/views/organizationManagement/index.vue');
    },
    name: 'OrganizationManagement',
    meta: {
      title: 'organization_management',
      activeMenu: '/organization-management'
    }
  }, {
    path: 'end-customer-detail',
    component: function component() {
      return import('@/views/organizationManagement/endCustomerDetail/index.vue');
    },
    name: 'EndCustomerDetail',
    meta: {
      title: 'end_customer_detail',
      activeMenu: '/organization-management',
      permissions: [CONSTANTS.PERMISSION_NAME.END_CUSTOMER.MANAGE]
    },
    hidden: true
  }]
};
export default organizationManagementRouter;